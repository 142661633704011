import * as React from 'react';

import { ChunkTypeEnum } from 'store/models/chunks/types';

import AttachmentsChunk from './AttachmentsChunk';
import CallbackTaskChunk from './CallbackTaskChunk/CallbackTaskChunk';
import CodeEditorChunk from './CodeEditorChunk';
import HomeworkChunk from './HomeworkChunk';
import ImageChunk from './ImageChunk';
import MercuryTaskChunk from './MercuryTaskChunk';
// import ImageChunkEdit from './ImageChunk/ImageChunkEdit';
// import QuizChunk from './QuizChunk/QuizChunk';
import QuizAnswer from './QuizChunk/QuizQuestion/QuizAnswer/QuizAnswer';
import QuizQuestion from './QuizChunk/QuizQuestion/QuizQuestion';
import TextChunk from './TextChunk';
import { WithChunk } from './types';
import VideoChunk from './VideoChunk';
import EmbedChunk from './EmbedChunk';

export const typeToChunk: Partial<
  Record<ChunkTypeEnum, React.ComponentType<WithChunk>>
> = {
  [ChunkTypeEnum.image]: ImageChunk,
  [ChunkTypeEnum.text]: TextChunk,
  [ChunkTypeEnum.editorjs]: TextChunk,
  // [ChunkTypeEnum.quizGroup]: QuizChunk,
  [ChunkTypeEnum.quiz]: QuizQuestion,
  [ChunkTypeEnum.quizAnswer]: QuizAnswer,
  [ChunkTypeEnum.video]: VideoChunk,
  [ChunkTypeEnum.attachments]: AttachmentsChunk,
  [ChunkTypeEnum.homework]: HomeworkChunk,
  [ChunkTypeEnum.callbackTask]: CallbackTaskChunk,
  [ChunkTypeEnum.codeTask]: CodeEditorChunk,
  [ChunkTypeEnum.mercuryTask]: MercuryTaskChunk,
  [ChunkTypeEnum.embed]: EmbedChunk,
};

export const typeToChunkEdit: Partial<
  Record<ChunkTypeEnum, React.ComponentType<WithChunk>>
> = {};

export const complexChunks = [ChunkTypeEnum.quiz, ChunkTypeEnum.quizAnswer];
