import * as React from 'react';
import cn from 'classnames';

import PlusIcon from 'components/icons/PlusIcon';
import Button from 'components/common/Button';

import styles from './ButtonPlus.modules.scss';

type Props = {
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
};

const ButtonPlus: React.FC<Props> = ({
  children,
  onClick,
  className,
}: Props) => {
  return (
    <Button onClick={onClick} className={cn(styles.root, className)}>
      {children}
      <PlusIcon className={styles.icon} />
    </Button>
  );
};

export default React.memo(ButtonPlus);
