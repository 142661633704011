import { EditorPlugin } from '@draft-js-plugins/editor';
import { ContentBlock } from 'draft-js';

import { ChunkTypeEnum } from 'store/models/chunks/types';

import UnsupportedChunk from '../UnsupportedChunk/UnsupportedChunk';

import { createDraftJsChunks, DraftJsChunkCreatorConfig } from './config';

export const createChunkPlugin = (
  config: DraftJsChunkCreatorConfig
): EditorPlugin => {
  const chunks = createDraftJsChunks(config);

  return {
    blockRendererFn: (block: ContentBlock, { getEditorState }) => {
      if (block.getType() === 'atomic') {
        try {
          const contentState = getEditorState().getCurrentContent();
          const entity = contentState.getEntity(block.getEntityAt(0));

          if (entity.getType() !== 'CHUNK') {
            return null;
          }

          const { type }: { type: ChunkTypeEnum } = entity.getData();

          const component = chunks[type] || UnsupportedChunk;

          return {
            component,
            editable: false,
          };
        } catch (e) {
          //
        }
      }
      return null;
    },
  };
};
