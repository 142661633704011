import cn from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';

import Spinner from 'components/common/Spinner';
import { useAttachmentUpload } from 'store/hooks/useAttachmentUpload';
import { useBunnyVideoPolling } from 'store/hooks/useBunnyVideoPolling';
import {
  AttachmentSourceEnum,
  BunnyAttachmentStatusEnum,
} from 'store/models/attachment/types';
import { VideoChunkModel } from 'store/models/chunks/video/VideoChunkModel';

import CircleButton from '../../../common/BaseEdit/CircleButton';
import { CircleButtonTypeEnum } from '../../../common/BaseEdit/CircleButton/config/types';
import styles from '../common/styles/MediaChunk.modules.scss';
import { WithChunk } from '../types';

import defaultImg from './img/defaultImg.png';
import VideoJS from './VideoJSPlayer';

type Props = WithChunk<VideoChunkModel>;

const VideoChunk: React.FC<Props> = ({ chunk, isEditMode }: Props) => {
  const { attachment, setAttachment } = chunk.data;

  const [uploadModel, handleFileChange] = useAttachmentUpload(
    setAttachment,
    true
  );

  useBunnyVideoPolling(attachment);

  return (
    <div id={chunk.id} className={cn(styles.wrapper)}>
      {attachment?.source !== AttachmentSourceEnum.bunny && (
        <>
          {isEditMode && (
            <video
              src={attachment?.url}
              poster={attachment?.url ? undefined : defaultImg}
              className={styles.preview}
            />
          )}
          {attachment?.url && !isEditMode && (
            <VideoJS
              options={{
                autoplay: false,
                controls: !isEditMode,
                responsive: true,
                fluid: true,
                playbackRates: [0.5, 1, 1.5, 2],
                poster: attachment.url ? undefined : defaultImg,
                sources: [
                  {
                    src: attachment.url,
                    type: 'video/mp4',
                  },
                ],
              }}
            />
          )}
        </>
      )}
      {attachment?.source === AttachmentSourceEnum.bunny && (
        <>
          {attachment.playerUrl && (
            <div className={styles.iframeWrapper}>
              {(attachment.status === BunnyAttachmentStatusEnum.playable ||
                attachment.status === BunnyAttachmentStatusEnum.finished) && (
                <iframe
                  src={`${attachment.playerUrl}?autoplay=false`}
                  allow="accelerometer; gyroscope; encrypted-media; picture-in-picture;"
                  allowFullScreen
                />
              )}
            </div>
          )}
          {attachment.status === BunnyAttachmentStatusEnum.uploaded && (
            <div className={styles.videoProcessing} />
          )}

          {attachment.status === BunnyAttachmentStatusEnum.failed && (
            <div className={styles.videoProcessing} />
          )}
        </>
      )}
      {isEditMode && (
        <>
          <div
            className={cn(
              styles.selectedWrapper,
              uploadModel.isLoading && styles.visible
            )}
          >
            {uploadModel.isLoading ? (
              <Spinner className={cn(styles.inputIcon, styles.loading)} />
            ) : (
              <CircleButton
                className={styles.inputIcon}
                type={CircleButtonTypeEnum.edit}
              >
                <input
                  onChange={handleFileChange}
                  type="file"
                  accept="video/*"
                  multiple={false}
                  className={cn(styles.imgInput)}
                />
              </CircleButton>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default observer(VideoChunk);
