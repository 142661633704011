import * as React from 'react';

import Datetime from 'components/form/Datetime';
import { Field } from 'components/form/Field';

type Props = {
  onChange(d: Date): void;
  value: Date | null;
  isAdmin: boolean;
};

const Deadline: React.FC<Props> = ({ value, onChange, isAdmin }: Props) => {
  if (!isAdmin) {
    if (!value) {
      return null;
    }

    return <Field>Дедлайн: {value.toLocaleString()}</Field>;
  }

  return (
    <Field>
      Дедлайн:{' '}
      <Datetime
        value={value}
        onChange={onChange}
        minDate={value || new Date()}
      />
    </Field>
  );
};

export default React.memo(Deadline);
