import * as React from 'react';
import { observer } from 'mobx-react';

import BaseCard from 'components/common/BaseCard';
import { useUserStore } from 'store';

import styles from './Header.modules.scss';

const Header: React.FC = () => {
  const userStore = useUserStore();

  return (
    <BaseCard className={styles.card}>
      <div className={styles.name}>{userStore.fullName}</div>
      <div className={styles.info}>
        <div className={styles.infoLabel}>E-mail</div>
        <div>{userStore.email}</div>
      </div>
      <div className={styles.info}>
        <div className={styles.infoLabel}>Список компаний</div>
        <div>{userStore.companies.map((c) => c.name).join(', ')}</div>
      </div>
    </BaseCard>
  );
};

export default observer(Header);
