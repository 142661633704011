import * as React from 'react';
import cogoToast from 'cogo-toast';
import { observer } from 'mobx-react';

import { InputField } from 'components/form';
import Button from 'components/common/Button';
import { useCurrentCompany } from 'store/stores';
import FullSideSpinner from 'components/common/Spinner/FullSideSpinner';
import {
  YandexPaymentCreationFormModel,
  YandexPaymentFieldsEnum,
} from 'store/models/payments/yandex/YandexPaymentCreationFormModel';
import useForm from 'components/form/hooks/useForm';
import PaymentSuccess from 'components/common/PaymentSuccess/PaymentSuccess';

import Row from '../Row';

import styles from './YandexPayment.modules.scss';

const YandexPayment: React.FC = () => {
  const [yandexPaymentForm] = React.useState(
    () => new YandexPaymentCreationFormModel()
  );

  const company = useCurrentCompany();

  const form = useForm(
    yandexPaymentForm,
    Object.values(YandexPaymentFieldsEnum)
  );

  // Cостояние для показа кнопок (по-умолчанию кнопки не показывается, они показываются когда одно из полей меняется)
  const [hasControls, setHasControls] = React.useState(false);

  React.useEffect(() => {
    if (company) {
      yandexPaymentForm.load(company.id);
    }
  }, [yandexPaymentForm, company]);

  React.useEffect(() => {
    yandexPaymentForm.validate();
  }, [form.secretKey.value, form.shopID.value]);

  const save = React.useCallback(async () => {
    if (company) {
      if (!(await yandexPaymentForm.save(company.id))) {
        cogoToast.error('Проверьте заполненные поля');
      }
    }
  }, [company]);

  const onChangeShopID = React.useCallback((value: string) => {
    form.shopID.setValue(value);
    setHasControls(true);
  }, []);

  const onChangeSecretKey = React.useCallback((value: string) => {
    form.secretKey.setValue(value);
    setHasControls(true);
  }, []);

  return (
    <div className={styles.wrapper}>
      <FullSideSpinner isOpen={yandexPaymentForm.isLoading} />
      {!yandexPaymentForm.isLoading && (
        <>
          <div className={styles.header}>Данные для подключения</div>
          <Row
            leftContent={
              <>
                <InputField
                  onChange={onChangeShopID}
                  value={form.shopID.value}
                  label="ShopID"
                  noLabel
                />
                <InputField
                  onChange={onChangeSecretKey}
                  value={form.secretKey.value}
                  label="Секретный ключ"
                  noLabel
                />
              </>
            }
            rightContent={
              <div className={styles.text}>
                Чтобы настроить прием платежей нужно подключиться
                к&nbsp;Яндекс.Кассе и&nbsp;получить параметры <b>shopId</b>{' '}
                и&nbsp;
                <b>Секретный ключ</b>.
              </div>
            }
          />
          <div className={styles.footer}>
            {!yandexPaymentForm.isSuccessLoaded ? (
              hasControls && (
                <>
                  <Button
                    onClick={save}
                    isDisabled={
                      yandexPaymentForm.hasErrors || yandexPaymentForm.isError
                    }
                  >
                    Сохранить
                  </Button>
                  {!yandexPaymentForm.isInitial && (
                    <div
                      onClick={yandexPaymentForm.clear}
                      className={styles.dropButton}
                    >
                      Сбросить изменения
                    </div>
                  )}
                </>
              )
            ) : (
              <PaymentSuccess />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default observer(YandexPayment);
