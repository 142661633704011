import { observer } from 'mobx-react';
import * as React from 'react';

import CompanyIcon from 'components/icons/CompanyIcon';
import { Dropdown } from 'components/form';
import { useUserStore } from 'store/index';

import styles from './CompanySelector.modules.scss';

const CompanySelector: React.FC = () => {
  const user = useUserStore();

  if (!user.selectedCompany) {
    return null;
  }

  const onSelect = (id: string | number) => user.selectCompany(Number(id));

  return (
    <>
      <div className={styles.caption}>компания</div>
      <div className={styles.selectorWrapper}>
        <CompanyIcon className={styles.icon} />
        <div className={styles.selector}>
          <Dropdown
            noBorder
            classNameOptions={styles.selectorOptions}
            selected={user.selectedCompany.id}
            onChange={onSelect}
            options={user.companies.map((c) => ({
              key: c.id,
              label: c.name,
            }))}
          />
        </div>
      </div>
    </>
  );
};

export default observer(CompanySelector);
