import { useCallback, useState } from 'react';

export const useModal = (): [boolean, () => void] => {
  const [show, setShow] = useState(false);
  const toggle = useCallback(() => {
    setShow((s) => !s);
  }, []);

  return [show, toggle];
};
