/* eslint-disable */
import * as React from 'react';

type Props = {
  onBlur: (e: MouseEvent) => void;
  onClick?: (e: MouseEvent) => void;
  children: React.ReactElement;
  excludedRoots?: (HTMLElement | null)[];
};

export default class Blurrable extends React.PureComponent<Props> {
  static defaultProps = {
    excludedRoots: [],
  };

  componentDidMount(): void {
    const { children } = this.props;

    if (children && (children as any).ref) {
      this.refNode = (children as any).ref;
    }

    this.listenEvents();
  }

  componentWillUnmount() {
    this.stopListenEvents();
  }

  refNode = React.createRef<HTMLElement>();

  listenEvents = () => {
    document.addEventListener('click', this.onDocumentClick, false);
  };

  stopListenEvents() {
    document.removeEventListener('click', this.onDocumentClick, false);
  }

  onDocumentClick = (event: MouseEvent) => {
    if (
      this.refNode.current?.contains(event.target as Node) ||
      this.props.excludedRoots?.some((r) => r?.contains(event.target as Node))
    ) {
      this.props.onClick?.(event);
      return;
    }

    this.props.onBlur(event);
  };

  render() {
    const { children } = this.props;

    return (children as any).ref
      ? children
      : React.cloneElement(children, {
          ref: this.refNode,
        });
  }
}
