import * as React from 'react';
import * as propTypes from 'prop-types';

const LogoIcon = (props) => {
  const { className } = props;

  return (
    <svg
      fill="none"
      height="32"
      viewBox="0 0 140 32"
      width="140"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fill="#262626">
        <path d="m13.1872 31.3664c-4.16 0-7.4048-1.151-9.7344-3.4528-2.30186-2.3018-3.4528-5.5882-3.4528-9.8592v-16.30721h6.7392v16.05761c0 5.2138 2.1632 7.8208 6.4896 7.8208 2.1078 0 3.7162-.624 4.8256-1.872 1.1094-1.2758 1.664-3.2586 1.664-5.9488v-16.05761h6.656v16.30721c0 4.271-1.1648 7.5574-3.4944 9.8592-2.3018 2.3018-5.5328 3.4528-9.6928 3.4528z" />
        <path d="m42.9925 31.2c-2.3852 0-4.5344-.4854-6.448-1.456-1.886-.9984-3.3696-2.3712-4.4512-4.1184-1.054-1.7472-1.5808-3.7302-1.5808-5.9488s.5268-4.2016 1.5808-5.9488c1.0816-1.7472 2.5652-3.1062 4.4512-4.0768 1.9136-.99841 4.0628-1.49761 6.448-1.49761 2.3572 0 4.4096.4992 6.1568 1.49761 1.7748.9706 3.0644 2.3712 3.8687 4.2016l-5.0336 2.704c-1.1648-2.0522-2.8427-3.0784-5.0336-3.0784-1.6917 0-3.0923.5546-4.2015 1.664-1.1094 1.1094-1.6641 2.6208-1.6641 4.5344s.5547 3.425 1.6641 4.5344c1.1092 1.1094 2.5098 1.664 4.2015 1.664 2.2187 0 3.8965-1.0262 5.0336-3.0784l5.0336 2.7456c-.8043 1.775-2.0939 3.1616-3.8687 4.16-1.7472.9984-3.7996 1.4976-6.1568 1.4976z" />
        <path d="m57.792 0h6.4896v30.8672h-6.4896z" />
        <path d="m80.532 8.15359c3.4664 0 6.1288.83201 7.9872 2.49601 1.8576 1.6362 2.7872 4.1184 2.7872 7.4464v12.7712h-6.0736v-2.7872c-1.2208 2.08-3.4944 3.12-6.8227 3.12-1.7195 0-3.2171-.2912-4.4928-.8736-1.248-.5824-2.2048-1.3866-2.8704-2.4128s-.9984-2.191-.9984-3.4944c0-2.08.7765-3.7162 2.3296-4.9088 1.5808-1.1926 4.0075-1.7888 7.28-1.7888h5.1587c0-1.4144-.4304-2.496-1.2896-3.2448-.86-.7766-2.1496-1.1648-3.8691-1.1648-1.1925 0-2.3712.1942-3.536.5824-1.1371.3606-2.1077.8598-2.912 1.4976l-2.3296-4.5344c1.2203-.85977 2.6763-1.52537 4.368-1.99681 1.7195-.47144 3.4805-.7072 5.2835-.7072zm-.4992 18.67841c1.1088 0 2.0936-.2496 2.9536-.7488.8592-.527 1.4696-1.2896 1.8304-2.288v-2.288h-4.4512c-2.6627 0-3.9939.8736-3.9939 2.6208 0 .832.3189 1.4976.9568 1.9968.6656.4714 1.5669.7072 2.7043.7072z" />
        <path d="m105.909 31.2c-1.858 0-3.675-.2218-5.45-.6656-1.7743-.4714-3.1887-1.0538-4.2431-1.7472l2.1632-4.6592c.9984.6378 2.2049 1.1648 3.6189 1.5808 1.415.3882 2.802.5824 4.16.5824 2.746 0 4.119-.6794 4.119-2.0384 0-.6378-.375-1.0954-1.123-1.3728-.749-.2774-1.9-.513-3.453-.7072-1.831-.2774-3.342-.5962-4.535-.9568-1.1917-.3606-2.2317-.9984-3.1197-1.9136-.8592-.9152-1.2896-2.2186-1.2896-3.9104 0-1.4144.4024-2.6624 1.2064-3.744.832-1.1094 2.0248-1.96904 3.5779-2.5792 1.581-.61016 3.439-.91521 5.574-.91521 1.581 0 3.148.18024 4.701.5408 1.581.3328 2.885.80424 3.91 1.41441l-2.163 4.6176c-1.969-1.1094-4.118-1.664-6.448-1.664-1.386 0-2.426.1942-3.12.5824-.693.3882-1.04.8874-1.04 1.4976 0 .6934.375 1.1786 1.123 1.456.749.2774 1.942.5408 3.578.7904 1.83.305 3.328.6378 4.493.9984 1.165.3328 2.177.9568 3.037 1.872s1.289 2.191 1.289 3.8272c0 1.3866-.416 2.6208-1.248 3.7024s-2.052 1.9274-3.661 2.5376c-1.58.5824-3.466.8736-5.657.8736z" />
        <path d="m129.209 31.2c-1.858 0-3.675-.2218-5.45-.6656-1.774-.4714-3.189-1.0538-4.243-1.7472l2.163-4.6592c.999.6378 2.205 1.1648 3.619 1.5808 1.415.3882 2.802.5824 4.16.5824 2.746 0 4.119-.6794 4.119-2.0384 0-.6378-.375-1.0954-1.123-1.3728-.749-.2774-1.9-.513-3.453-.7072-1.831-.2774-3.342-.5962-4.535-.9568-1.192-.3606-2.232-.9984-3.12-1.9136-.859-.9152-1.289-2.2186-1.289-3.9104 0-1.4144.402-2.6624 1.206-3.744.832-1.1094 2.025-1.96904 3.578-2.5792 1.581-.61016 3.439-.91521 5.574-.91521 1.581 0 3.148.18024 4.701.5408 1.581.3328 2.885.80424 3.91 1.41441l-2.163 4.6176c-1.969-1.1094-4.118-1.664-6.448-1.664-1.386 0-2.426.1942-3.12.5824-.693.3882-1.04.8874-1.04 1.4976 0 .6934.375 1.1786 1.123 1.456.749.2774 1.942.5408 3.578.7904 1.83.305 3.328.6378 4.493.9984 1.165.3328 2.177.9568 3.037 1.872s1.289 2.191 1.289 3.8272c0 1.3866-.416 2.6208-1.248 3.7024s-2.052 1.9274-3.661 2.5376c-1.58.5824-3.466.8736-5.657.8736z" />
      </g>
    </svg>
  );
};

LogoIcon.propTypes = {
  className: propTypes.string,
};

LogoIcon.defaultProps = {
  className: null,
};

export default LogoIcon;
