import * as React from 'react';
import { observer } from 'mobx-react';

import LogoIcon from 'components/icons/LogoIcon';

import styles from './GroupPaymentLayout.modules.scss';

interface Props {
  children: React.ReactNode;
}

const GroupPayment: React.FC<Props> = ({ children }: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <LogoIcon />
      </div>
      <div className={styles.content}>
        <div className={styles.form}>{children}</div>
      </div>
    </div>
  );
};

export default observer(GroupPayment);
