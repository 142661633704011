import * as React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import BaseCard from 'components/common/BaseCard';
import { ChapterModel } from 'store/models/chapter/ChapterModel';
import Title from 'components/common/Title';
import { HomeworkStatus } from 'store/models/chunks/homework/types';
import pluralize from 'utils/pluralizer';
import ButtonPlus from 'components/common/Button/ButtonPlus';
import LessonCreationModal from 'components/containers/course/LessonCreationModal';
import { useModal } from 'components/form/hooks/useModal';

import Badge from '../../../common/Badge/Badge';
import { BADGE_COLOR } from '../../../Constructor/Chunks/HomeworkChunk/HomeworkChunk';

import CountLessons from './CountLessons';
import LessonElement from './LessonElement';
import ChapterAdmin from './ChapterAdmin';
import stylesCommon from './styles/ChapterBlockCommon.modules.scss';
import cardStyles from './styles/ChapterCard.modules.scss';

type Props = {
  chapter: ChapterModel;
  isEditMode: boolean;
  isAdmin: boolean;
};

const ChapterBlock: React.FC<Props> = ({
  isEditMode,
  chapter,
  isAdmin,
}: Props) => {
  const [showLessonModal, toggleLessonModal] = useModal();

  return (
    <BaseCard>
      {isAdmin && <ChapterAdmin chapter={chapter} />}
      <div className={cn(cardStyles.root, cardStyles.interactiveBlue)}>
        {isAdmin && isEditMode && chapter.isPublished && (
          <Badge
            className={cardStyles.isPublishedBadge}
            color={BADGE_COLOR[HomeworkStatus.finished]}
          >
            Опубликована
          </Badge>
        )}
        <Title index={3} className={stylesCommon.title}>
          {chapter.title}
        </Title>
        <p className={stylesCommon.subtitle}>
          {chapter.lessons.length}{' '}
          {pluralize(chapter.lessons.length, 'урок', 'урока', 'уроков')}
        </p>
        <p className={stylesCommon.description}>{chapter.description}</p>
        <CountLessons
          isLocked={false}
          countCompleted={chapter.countCompleteLessons}
          count={chapter.lessons.length}
        />
      </div>

      {isAdmin && isEditMode && (
        <div className={stylesCommon.addLesson}>
          <ButtonPlus onClick={toggleLessonModal}>Добавить урок</ButtonPlus>
          {showLessonModal && (
            <LessonCreationModal
              resetOnOpen
              isOpen={showLessonModal}
              onClose={toggleLessonModal}
              onSuccess={chapter.addLesson}
              chapter={chapter}
            />
          )}
        </div>
      )}

      {chapter.lessons.length > 0 && (
        <div className={stylesCommon.lessons}>
          {chapter.lessons.map((lesson, index) => (
            <LessonElement
              index={index}
              key={lesson.id}
              lesson={lesson}
              isAdmin={isAdmin}
              disabled={!lesson.hasEnoughAccessLevel}
            />
          ))}
        </div>
      )}
    </BaseCard>
  );
};

export default observer(ChapterBlock);
