import * as React from 'react';

import ButtonDelete from 'components/common/Button/ButtonDelete';

import { TokenModel } from './store/TokenModel';

export const columns = [
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Действителен до',
    dataIndex: 'expires',
    key: 'expires',
  },
  {
    title: 'Создан',
    dataIndex: 'created',
    key: 'created',
  },
  {
    title: 'Действие',
    key: 'action',
    render: (text: string, record: TokenModel) => (
      <ButtonDelete isLoading={record.isRemoving} onClick={record.remove}>
        Удалить
      </ButtonDelete>
    ),
  },
];
