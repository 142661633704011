import cn from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import Title from 'components/common/Title';
import { InputField } from 'components/form';
import useField from 'components/form/hooks/useField';
import ProfileIcon from 'components/icons/ProfileIcon';
import Button, { ButtonColor, ButtonSize } from 'components/common/Button';
import { useLocal } from 'utils/hooks/useLocal';

import styles from '../Password.modules.scss';

import { ResetPasswordStore } from './ResetPasswordStore';

const ResetPassword: React.FC = () => {
  const resetPasswordStore = useLocal(() => new ResetPasswordStore());

  const [email, setEmail, emailErrors] = useField(resetPasswordStore, 'email');

  const history = useHistory();

  const onLogin = React.useCallback(() => {
    history.push('/');
  }, []);

  const onKeyPress = React.useCallback(
    async (event: React.KeyboardEvent): Promise<void> => {
      if (event.key === 'Enter') {
        await resetPasswordStore.save();
      }
    },
    [resetPasswordStore]
  );

  return (
    <>
      <Title index={1} className={styles.form__title}>
        Сбросить пароль
      </Title>
      {resetPasswordStore.isSaved ? (
        <div>
          Письмо было отправлено на адрес {email}
          <br />
          <br />
          Если письмо не приходит, проверьте спам
        </div>
      ) : (
        <div>Введите адрес почты, к которой привязан аккаунт</div>
      )}
      {!resetPasswordStore.isSaved && (
        <>
          <InputField
            formElementClassName={styles.form__row}
            icon={<ProfileIcon className={styles['form__row-icon']} />}
            type="email"
            placeholder="Email"
            noLabel
            value={email}
            errors={emailErrors}
            onChange={setEmail}
            onKeyPress={onKeyPress}
            disabled={resetPasswordStore.isSaved}
          />

          <Button
            className={cn(styles.form__row, styles.form__row_wide)}
            isLoading={resetPasswordStore.isSaving}
            onClick={resetPasswordStore.save}
          >
            Отправить письмо
          </Button>
        </>
      )}
      <Button
        className={cn(styles.form__row, styles.form__row_small)}
        color={ButtonColor.transparent}
        size={ButtonSize.small}
        onClick={onLogin}
      >
        Войти в аккаунт
      </Button>
    </>
  );
};

export default observer(ResetPassword);
