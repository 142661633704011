import { DropdownOption } from 'components/form/Dropdown/Dropdown';

export function staticImplements<T>() {
  return <U extends T>(constructor: U) => {
    constructor;
  };
}

export interface IChunkModel<
  ServerDataType = any,
  AdminServerDataType = ServerDataType
> {
  toJson(): AdminServerDataType;
  validate?: () => string[];
  afterSave?: (data: ServerDataType) => void;
  removeFromParent?: () => void;
}

// export interface IChunkModel<ServerDataType = any> {
//   new (): IChunkModelBase<ServerDataType>;
//   fromJson(chunk: ChunkServerType<ServerDataType>): IChunkModel<ServerDataType>;
// }

export enum ChunkAlignEnum {
  left = 'left',
  right = 'right',
  top = 'top',
  bottom = 'bottom',
  center = 'center',
  centerHorizontal = 'centerHorizontal', // Используется только для отображения
}

export enum ChunkGroupTypeEnum {
  text = 'text',
  image = 'image',
  tasks = 'tasks',
  video = 'video',
  files = 'files',
}

export const ChunkGroupLabels: DropdownOption<ChunkGroupTypeEnum>[] = [
  { label: 'Текст', key: ChunkGroupTypeEnum.text },
  { label: 'Изображения', key: ChunkGroupTypeEnum.image },
  { label: 'Задания', key: ChunkGroupTypeEnum.tasks },
  { label: 'Видео', key: ChunkGroupTypeEnum.video },
  { label: 'Файлы', key: ChunkGroupTypeEnum.files },
];

export enum ChunkTypeEnum {
  text = 'editorjs',
  image = 'image',
  editorjs = 'editorjs',
  quizGroup = 'quizGroup',
  quiz = 'quiz',
  quizAnswer = 'answer',
  video = 'video',
  attachments = 'attachments',
  homework = 'homework',
  callbackTask = 'callbackTask',
  codeTask = 'codeTask',
  mercuryTask = 'mercuryTask',
  code = 'code',
  embed = 'iframe',
}

export enum ComplexChunkEnum {
  images2inRow = 'images2inRow',
  imageWithRightText = 'imageWithRightText',
  imageWithLeftText = 'imageWithLeftText',
  portfolio = 'portfolio',

  text2columns = 'text2columns',
}

export type AnyChunkType = ChunkTypeEnum | ComplexChunkEnum;

export const ChunkTypeLabels: Partial<
  Record<ChunkGroupTypeEnum, DropdownOption<AnyChunkType>[]>
> = {
  [ChunkGroupTypeEnum.text]: [
    // { label: 'Текст', key: ChunkTypeEnum.text },
    {
      label: 'Код',
      key: ChunkTypeEnum.code,
    },
  ],
  [ChunkGroupTypeEnum.image]: [
    { label: 'Простое изображение', key: ChunkTypeEnum.image },
    // { label: '2 картинки в ряд', key: ComplexChunkEnum.images2inRow },
    // {
    //   label: 'Картинка и текст справа',
    //   key: ComplexChunkEnum.imageWithRightText,
    // },
    // {
    //   label: 'Картинка и текст слева',
    //   key: ComplexChunkEnum.imageWithLeftText,
    // },
    // {
    //   label: 'Портфолио',
    //   key: ComplexChunkEnum.portfolio,
    // },
  ],
  [ChunkGroupTypeEnum.tasks]: [
    // {
    //   label: 'Вопрос',
    //   key: ChunkTypeEnum.quiz,
    // },
    // {
    //   label: 'Кнопка проверки',
    //   key: ChunkTypeEnum.quizGroup,
    // },
    { label: 'Код', key: ChunkTypeEnum.codeTask },
    { label: 'Mercury', key: ChunkTypeEnum.mercuryTask },
    {
      label: 'Тест',
      key: ChunkTypeEnum.quizGroup,
    },
    {
      label: 'Домашнее задание',
      key: ChunkTypeEnum.homework,
    },
    {
      label: 'Задание с автопроверкой',
      key: ChunkTypeEnum.callbackTask,
    },
  ],
  [ChunkGroupTypeEnum.video]: [{ label: 'Видео', key: ChunkTypeEnum.video }],
  [ChunkGroupTypeEnum.files]: [
    { label: 'Файл', key: ChunkTypeEnum.attachments },
    { label: 'iFrame', key: ChunkTypeEnum.embed },
  ],
};
