import * as React from 'react';
import * as propTypes from 'prop-types';

import generateUniqueString from 'utils/generateUniqueString';

const pathId = generateUniqueString();

const LogoIcon = (props) => {
  const { className } = props;

  return (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <defs>
        <path
          d="M6,16 L14,16 L14,6 L6,6 L6,16 L6,16 Z M6,24 L14,24 L14,18 L6,18 L6,24 L6,24 Z M16,24 L24,24 L24,14 L16,14 L16,24 L16,24 Z M16,6 L16,12 L24,12 L24,6 L16,6 L16,6 Z"
          id={pathId}
        />
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-6.000000, -6.000000)">
          <mask fill="white">
            <use xlinkHref={`#${pathId}`} />
          </mask>
          <use fill="currentColor" xlinkHref={`#${pathId}`} />
        </g>
      </g>
    </svg>
  );
};

LogoIcon.propTypes = {
  className: propTypes.string.isRequired,
};

export default LogoIcon;
