import * as React from 'react';
import cn from 'classnames';

import styles from './ProgressPart.modules.scss';

type Props = {
  className: string;
  height: number;
  count: number;
  countCompleted: number;
  offsetTop?: number;
};

const ProgressPart: React.FC<Props> = ({
  className,
  height,
  count,
  countCompleted,
  offsetTop,
}: Props) => {
  return (
    <div className={styles.root} style={{ height: `${height}px` }}>
      <div
        className={cn(styles.point, className)}
        style={{ top: `${offsetTop || 0}px` }}
      />
      <div className={styles.line}>
        <div
          className={cn(styles.lineCompleted, className)}
          style={{
            height: `${(countCompleted / count) * 100}%`,
          }}
        />
      </div>
    </div>
  );
};

ProgressPart.defaultProps = {};

export default React.memo(ProgressPart);
