export enum PaymentsType {
  yandex = 'yandex',
}

export enum FreeType {
  free = 'free',
}
export const PaymentsChooseType = {
  ...PaymentsType,
  free: 'free',
};

export const PaymentsName: Record<PaymentsType, string> = {
  [PaymentsType.yandex]: 'Яндекс.Касса',
};
