import { makeObservable, override } from 'mobx';

import { Form } from 'store/models/common/Form';
import request from 'utils/request';
import apiUrls from 'config/apiUrls';
import { ServerResponse } from 'store/models/common/types';
import { LessonModel } from 'store/models/lesson/LessonModel';
import { UnitModel } from 'store/models/unit/UnitModel';

import { UnitCreateResponseType, UnitTypeEnum } from './types';

export enum UnitFieldsEnum {
  type = 'type',
  required = 'required',
  passInTime = 'passInTime',
}

export type UnitFields = {
  type: UnitTypeEnum;
  required: boolean;
  passInTime: boolean;
};

export class UnitCreationFormModel extends Form<UnitFields> {
  lesson: LessonModel;
  unit: UnitModel | null = null;
  id?: number;

  initialValues: UnitFields = {
    required: false,
    passInTime: false,
    type: UnitTypeEnum.generic,
  };

  constructor(lesson: LessonModel, unit?: UnitModel) {
    super();
    makeObservable(this, {
      setInitialFrom: override,
      save: override,
    });

    this.lesson = lesson;

    if (unit) {
      this.unit = unit;
      this.id = unit.id;
    }

    this.clear(unit);
  }

  setInitialFrom(unit: UnitModel): void {
    super.setInitialFrom({
      type: unit.type,
      required: unit.required,
    });
  }

  async save(): Promise<boolean | UnitModel | null> {
    this.validate();
    if (this.hasErrors) {
      return false;
    }

    this.isSaving = true;

    const isNew = !this.unit;

    const { response }: ServerResponse<UnitCreateResponseType> = await request(
      isNew ? apiUrls.unit.create : apiUrls.unit.edit,
      'POST',
      {
        type: this.values.type,
        required: this.values.required,
        lesson_id: this.lesson.id,
        id: isNew ? undefined : this.unit?.id,
      }
    );

    this.isSaving = false;

    if (response) {
      this.id = response.id;

      if (this.unit) {
        this.unit.updateFrom(this);
      } else {
        this.unit = UnitModel.fromForm(this, this.lesson);
      }
      return this.unit;
    }

    return false;
  }
}
