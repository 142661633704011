import { observer } from 'mobx-react';
import { useEffect } from 'react';
import * as React from 'react';
import cn from 'classnames';
// eslint-disable-next-line import/named
import { Link, matchPath, useLocation } from 'react-router-dom';

import { CONFIG } from 'config/app';
import UsersIcon from 'components/icons/UsersIcon';
import IntegrationIcon from 'components/icons/IntegrationIcon';
import VerticalPointerLine from 'components/common/VerticalPointerLine';
import urls from 'config/urls';
import { useUiStore, useUserStore } from 'store/context';

import HomeworkSvg from './icons/HomeworkSvg';
import Profile from './icons/Profile';
import Courses from './icons/Courses';
import styles from './LeftBar.modules.scss';

const RouteIndex = [
  { path: urls.profile, index: 0 },
  { path: urls.courses, index: 1 },
  { path: urls.homework, index: 2 },
  { path: urls.students.pattern, index: 3 },
  { path: urls.integration, index: 4 },
];

const LeftBar: React.FC = () => {
  const location = useLocation();
  const user = useUserStore();
  const ui = useUiStore();

  const activeIndex = RouteIndex.find(({ path }) =>
    matchPath(location.pathname, {
      path,
    })
  )?.index;

  useEffect(() => {
    if (ui.showMenu) {
      ui.closeMenu();
    }
  }, [location.pathname]);

  return (
    <div
      className={cn(
        styles['left-bar'],
        ui.showMenu && styles['left-bar_opened'],
        styles[`left-bar_${ui.layout}`]
      )}
    >
      <VerticalPointerLine
        activeIndex={activeIndex}
        className={styles.pointerLine}
      >
        <Link
          className={cn(styles.tab, activeIndex === 0 && styles.tabActive)}
          to={urls.profile}
        >
          <Profile className={cn(styles.icon, styles.iconProfile)} />
          Мой профиль
        </Link>
        <Link
          className={cn(styles.tab, activeIndex === 1 && styles.tabActive)}
          to={urls.courses}
        >
          <Courses className={cn(styles.icon, styles.iconCourses)} />
          Курсы
        </Link>
        <Link
          className={cn(styles.tab, activeIndex === 2 && styles.tabActive)}
          to={urls.homework}
        >
          <HomeworkSvg className={styles.icon} />
          Задания
        </Link>
        {user.isAdmin && (
          <>
            <Link
              className={cn(styles.tab, activeIndex === 3 && styles.tabActive)}
              to={urls.students.pattern}
            >
              <UsersIcon className={styles.icon} />
              Студенты
            </Link>
            {CONFIG.paymentsEnabled && (
              <Link
                className={cn(
                  styles.tab,
                  activeIndex === 4 && styles.tabActive
                )}
                to={urls.integration}
              >
                <IntegrationIcon className={styles.icon} />
                Интеграция
              </Link>
            )}
          </>
        )}
      </VerticalPointerLine>
      <div>
        <a
          target="_blank"
          href={CONFIG.appStoreLink}
          className={styles.appStore}
        />
        <a
          target="_blank"
          href={CONFIG.googlePlayLink}
          className={styles.googlePlay}
        />
        <a
          target="_blank"
          href={CONFIG.huaweiStoreLink}
          className={styles.huaweiStore}
        />
      </div>
    </div>
  );
};

export default observer(LeftBar);
