export const TEST_CODE = `from unittest.case import TestCase
import unittest
from io import StringIO

# Пишите тесты тут
class CheckTestCase(TestCase):
    def testTrue(self):
        self.assertEqual(sum(2, 3), 5, 'FAIL')

# Запускаем тесты
stream = StringIO()
runner = unittest.TextTestRunner(stream=stream)
result = runner.run(unittest.makeSuite(CheckTestCase))
stream.seek(0)
res = f'Запущено тестов: {result.testsRun} \\n Ошибки: {result.failures} \\n {stream.read()}'

# Установите глобальную переменную SUCCESS, чтобы засчитать задание
SUCCESS = result.wasSuccessful()

# Печатаем вывод
res`;
