import * as propTypes from 'prop-types';
import * as React from 'react';

const OpenBookIcon = (props) => {
  const { className } = props;
  return (
    <svg
      className={className}
      width="20"
      height="16"
      viewBox="0 0 20 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="M3.308 0h-.002a.679.679 0 0 0-.49.211.74.74 0 0 0-.207.517v10.92c0 .4.315.726.702.727 1.632.004 4.367.358 6.254 2.408V3.356a.718.718 0 0 0-.096-.37C7.92.398 4.944.005 3.309 0zM17.391 11.647V.728a.74.74 0 0 0-.206-.517.679.679 0 0 0-.491-.211h-.002c-1.636.004-4.612.397-6.16 2.987a.718.718 0 0 0-.097.369v11.427c1.887-2.05 4.622-2.404 6.254-2.408a.718.718 0 0 0 .702-.728z" />
        <path d="M19.264 2.609h-.534v9.14a1.874 1.874 0 0 1-1.878 1.86c-1.453.003-3.85.285-5.548 1.876 2.936-.712 6.031-.25 7.795.149a.74.74 0 0 0 .624-.141.722.722 0 0 0 .277-.57V3.338a.733.733 0 0 0-.736-.73zM1.27 11.749v-9.14H.736A.733.733 0 0 0 0 3.338v11.585c0 .223.1.43.277.57a.74.74 0 0 0 .624.14c1.764-.397 4.859-.86 7.795-.148-1.698-1.591-4.095-1.872-5.548-1.876a1.874 1.874 0 0 1-1.878-1.86z" />
      </g>
    </svg>
  );
};

OpenBookIcon.propTypes = {
  className: propTypes.string.isRequired,
};

OpenBookIcon.defaultProps = {};

export default OpenBookIcon;
