import { action, makeObservable, observable, override, toJS } from 'mobx';

import { ChunkContainer } from 'components/Constructor/models/chunkContainer/ChunkContainer';
import { ChunkModel } from 'store/models/chunks/ChunkModel';
import {
  AnyChunkType,
  ChunkServerType,
  ChunkTypeEnum,
  IChunkModel,
} from 'store/models/chunks/types';
import { UnitModel } from 'store/models/unit/UnitModel';

import { ShiftPositionEnum } from '../../common/types';

import { QuizAnswerModel } from './QuizAnswerModel';
import { QuizGroupModel } from './QuizGroupModel';
import {
  ChunkQuizQuestionServerType,
  QuizAnswerRequestType,
  QuizStatus,
  QuizTypeEnum,
} from './types';

type QuizQuestionType = {
  plainTitle: string;
  quizType: QuizTypeEnum;
  status: QuizStatus;
  chunk: ChunkModel<QuizQuestionModel>;
  unit: UnitModel;
  chunks: ChunkModel<QuizAnswerModel>[];
  quizGroup: QuizGroupModel;
};

export class QuizQuestionModel
  extends ChunkContainer<QuizAnswerModel>
  implements IChunkModel, QuizQuestionType
{
  plainTitle = '';

  quizType: QuizTypeEnum;

  status: QuizStatus;

  chunk: ChunkModel<QuizQuestionModel>;
  unit: UnitModel;

  quizGroup: QuizGroupModel;

  constructor({
    quizType,
    chunks,
    unit,
    chunk,
    plainTitle,
    status,
    quizGroup,
  }: QuizQuestionType) {
    super();
    makeObservable(this, {
      // observable
      plainTitle: observable,
      quizType: observable,
      status: observable,
      // action
      setPlainTitle: action.bound,
      // override
      insertChunk: override,
    });
    this.status = status;
    this.quizType = quizType;
    this.unit = unit;
    this.chunk = chunk;
    this.chunks = chunks; // Ответы
    this.plainTitle = plainTitle;
    this.quizGroup = quizGroup;
  }

  removeFromParent() {
    this.quizGroup.deleteChunk(this.chunk.id);
  }

  /* Получаем все отмеченные ответы для проверка */
  get validationData(): QuizAnswerRequestType {
    return {
      quiz_id: this.chunk.id,
      answers_ids: this.chunks
        .filter((answer) => answer.data?.isChecked)
        .map((answer) => answer.id),
    };
  }

  validate(): string[] {
    if (this.chunks.length === 0) {
      return ['Не добавлен ни один ответ'];
    }

    return [];
  }

  setPlainTitle(title: string): void {
    this.plainTitle = title;
  }

  insertChunk(chunkId: string, position: ShiftPositionEnum): void {
    super.insertChunk(chunkId, position);
    this.addChunk(ChunkTypeEnum.quizAnswer);
    this.closeChunkCreationDrawer();
  }

  toJson(): object {
    return toJS({
      plain_title: this.plainTitle,
      quiz_type: this.quizType,
      answers: this.chunks.reduce(
        (acc, c) => [...acc, ...c.toJson()],
        [] as any[]
      ),
    });
  }

  generateChunksByType(chunkType: AnyChunkType): ChunkModel[] {
    const newChunks: ChunkModel[] = [];
    if (chunkType === ChunkTypeEnum.quizAnswer) {
      newChunks.push(ChunkModel.createDefault(this.unit, chunkType, this));
    }
    return newChunks;
  }

  static createDefault(
    unit: UnitModel,
    chunk: ChunkModel,
    quizGroup: QuizGroupModel
  ): QuizQuestionModel {
    return new QuizQuestionModel({
      plainTitle: 'Напишите вопрос',
      quizType: QuizTypeEnum.multipleAnswers,
      chunks: [],
      status: QuizStatus.notAnswered,
      chunk,
      unit,
      quizGroup,
    });
  }

  static fromJson(
    json: ChunkServerType<ChunkQuizQuestionServerType>,
    chunk: ChunkModel,
    quizGroup: QuizGroupModel
  ): QuizQuestionModel {
    const quizQuestion = new QuizQuestionModel({
      unit: chunk.unit,
      plainTitle: json.data.plain_title,
      quizType: json.data.quiz_type,
      status: json.data.status,
      chunk,
      chunks: [],
      quizGroup,
    });

    quizQuestion.chunks = json.data.answers.map((answer) =>
      ChunkModel.fromJson(answer, chunk.unit, quizQuestion)
    );

    return quizQuestion;
  }
}
