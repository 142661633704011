import * as React from 'react';
import { useCallback } from 'react';

import { CircleButtonTypeEnum } from './CircleButton/config/types';
import EditControl from './EditControl';
import styles from './BaseEdit.modules.scss';
import ArrowControls from './ArrowControls';

type Props = {
  isSelected: boolean;
  onEdit?: () => void;
  onAppendBefore?: () => void;
  onAppendAfter?: () => void;
  onShiftAfter?: () => void;
  onShiftBefore?: () => void;
  isEditOnly?: boolean;
  isHorizontalControls?: boolean;
  isHideTwinsControls?: boolean;
};

const BaseEdit: React.FC<Props> = ({
  isEditOnly,
  isSelected,
  isHorizontalControls,
  onAppendAfter,
  onAppendBefore,
  onShiftAfter,
  onShiftBefore,
  onEdit,
}: Props) => {
  const onClickHandler = useCallback(
    (handler: () => void) => (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      handler();
    },
    []
  );

  const arrowPropsBefore = {
    onAppend: onAppendBefore ? onClickHandler(onAppendBefore) : undefined,
    onShift: onShiftBefore ? onClickHandler(onShiftBefore) : undefined,
    isSelected: isSelected,
  };

  const arrowPropsAfter = {
    onAppend: onAppendAfter ? onClickHandler(onAppendAfter) : undefined,
    onShift: onShiftAfter ? onClickHandler(onShiftAfter) : undefined,
    isSelected: isSelected,
  };

  return (
    <div className={styles.root}>
      {onEdit && (
        <EditControl isSelected={isSelected} onClick={onClickHandler(onEdit)} />
      )}
      {!isEditOnly && (
        <>
          {isHorizontalControls ? (
            <>
              <ArrowControls
                type={CircleButtonTypeEnum.arrowLeft}
                {...arrowPropsBefore}
              />
              <ArrowControls
                type={CircleButtonTypeEnum.arrowRight}
                {...arrowPropsAfter}
              />
            </>
          ) : (
            <>
              <ArrowControls
                type={CircleButtonTypeEnum.arrowTop}
                {...arrowPropsBefore}
              />
              <ArrowControls
                type={CircleButtonTypeEnum.arrowBottom}
                {...arrowPropsAfter}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

BaseEdit.defaultProps = {
  isEditOnly: true,
  isHorizontalControls: false,
  isHideTwinsControls: false,
};

export default BaseEdit;
