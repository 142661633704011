import cn from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Title from 'components/common/Title';
import useField from 'components/form/hooks/useField';
import { InputField } from 'components/form';
import Button, { ButtonColor, ButtonSize } from 'components/common/Button';
import { useLocal } from 'utils/hooks/useLocal';

import styles from '../Password.modules.scss';

import { NewPasswordStore } from './NewPasswordStore';

const NewPassword: React.FC = () => {
  const { token } = useParams<{ token: string }>();

  const newPasswordStore = useLocal(() => new NewPasswordStore({ token }));

  const [password, setPassword, passwordErrors] = useField(
    newPasswordStore,
    'password'
  );
  const [passwordRepeat, setPasswordRepeat, passwordRepeatErrors] = useField(
    newPasswordStore,
    'passwordRepeat'
  );

  const onKeyPress = React.useCallback(
    async (event: React.KeyboardEvent): Promise<void> => {
      if (event.key === 'Enter') {
        await newPasswordStore.save();
      }
    },
    [newPasswordStore]
  );

  const history = useHistory();

  const onLogin = React.useCallback(() => {
    history.push('/');
  }, []);

  return (
    <>
      <Title index={1} className={styles.form__title}>
        Восстановить пароль
      </Title>
      {newPasswordStore.isSaved ? (
        <div>Пароль был успешно изменен</div>
      ) : (
        <>
          <InputField
            formElementClassName={styles.form__row}
            errors={passwordErrors}
            value={password}
            onChange={setPassword}
            type="password"
            placeholder="Введите новый пароль"
            noLabel
            onKeyPress={onKeyPress}
          />
          <InputField
            formElementClassName={styles.form__row}
            errors={passwordRepeatErrors}
            value={passwordRepeat}
            onChange={setPasswordRepeat}
            type="password"
            placeholder="Повторите пароль"
            noLabel
            onKeyPress={onKeyPress}
          />
          <Button
            className={cn(styles.form__row, styles.form__row_wide)}
            isLoading={newPasswordStore.isSaving}
            onClick={newPasswordStore.save}
          >
            Сохранить
          </Button>
        </>
      )}
      <Button
        className={cn(styles.form__row, styles.form__row_small)}
        color={ButtonColor.transparent}
        size={ButtonSize.small}
        onClick={onLogin}
      >
        Войти в аккаунт
      </Button>
    </>
  );
};

export default observer(NewPassword);
