import * as React from 'react';
import { observer } from 'mobx-react';
import YouTube from 'react-youtube';

import { WithChunk } from 'components/Constructor/Chunks/types';
import {
  EmbedChunkModel,
  EmbedType,
} from 'store/models/chunks/embed/EmbedChunkModel';
import UnsupportedChunk from 'components/Constructor/Chunks/UnsupportedChunk';
import { InputField } from 'components/form';

import styles from './EmbedChunk.modules.scss';

type Props = WithChunk<EmbedChunkModel>;

const EmbedChunk: React.FC<Props> = ({ isEditMode, isAdmin, chunk }: Props) => {
  // @ts-ignore
  if (chunk.data.type !== EmbedType.youtube) {
    return <UnsupportedChunk />;
  }

  return (
    <div>
      {isAdmin && isEditMode && (
        <div className={styles.urlInput}>
          <InputField
            label="Ссылка на youtube"
            onChange={chunk.data.setUrl}
            value={chunk.data.url}
          />
        </div>
      )}
      {chunk.data.youtubeVideoId ? (
        <YouTube
          className={styles.youtubeWrapper}
          iframeClassName={styles.youtubeFrame}
          videoId={chunk.data.youtubeVideoId}
          opts={{
            width: '100%',
          }}
        />
      ) : (
        <div className={styles.youtubeWrapper}>
          <iframe
            className={styles.youtubeFrame}
            src={chunk.data.url}
            allow="autoplay; fullscreen; picture-in-picture; encrypted-media;"
            allowFullScreen
            frameBorder={0}
          />
        </div>
      )}
    </div>
  );
};

export default observer(EmbedChunk);
