import {
  action,
  computed,
  makeObservable,
  observable,
  override,
  runInAction,
} from 'mobx';

import { Form } from 'store/models/common/Form';
import {
  composeValidators,
  greaterThanValidator,
  notEmptyValidator,
} from 'utils/validation';
import { ServerResponse } from 'store/models/common/types';
import request from 'utils/request';
import apiUrls from 'config/apiUrls';
import { PaymentsType } from 'config/payments';
import { PaymentsError } from 'store/models/payments/types';
import { YandexGroupPaymentApiResponse } from 'store/models/payments/yandex/types';
import { YandexPaymentModel } from 'store/models/payments/yandex/YandexPaymentModel';

export enum YandexGroupPaymentFieldsEnum {
  amount = 'amount',
  paymentUrl = 'paymentUrl',
}

export type YandexGroupPaymentFields = {
  amount: string;
  paymentUrl: string;
};

export class YandexGroupPaymentFormModel extends Form<YandexGroupPaymentFields> {
  initialValues: YandexGroupPaymentFields = {
    amount: '',
    paymentUrl: '',
  };

  validators = {
    amount: composeValidators(
      notEmptyValidator(),
      greaterThanValidator({
        min: 1,
        errorMessage: 'Цена не может быть меньше нуля',
      })
    ),
  };

  isLoading = false;
  isError = false;
  isSuccessLoaded = false;
  isEditable = false;
  isInitial = false;

  type = PaymentsType.yandex;

  constructor() {
    super();
    makeObservable(this, {
      // observable
      isError: observable,
      isLoading: observable,
      isSuccessLoaded: observable,
      isEditable: observable,
      isInitial: observable,
      // computed
      isDisabled: computed,
      // action
      setValues: action,
      load: action.bound,
      // override
      save: override,
      clear: override,
    });
  }

  get isDisabled(): boolean {
    return this.hasErrors || this.isError;
  }

  setValues(data: YandexGroupPaymentApiResponse): void {
    this.values = {
      amount: String(data.amount),
      paymentUrl: data.payment_url,
    };
  }

  async load(group: number): Promise<void> {
    this.isError = false;
    this.isLoading = true;
    this.isEditable = false;
    this.isInitial = false;

    try {
      const { response, error } = await request<YandexGroupPaymentApiResponse>(
        apiUrls.company.getGroupPayment,
        'GET',
        {
          kind: this.type,
          group,
        }
      );

      if (response) {
        this.isEditable = true;
        this.setValues(response);
      }

      if (error) {
        const notFoundError =
          error.data.status === PaymentsError.notFoundPaymentMethod;
        this.isError = !notFoundError;

        if (notFoundError) {
          this.isInitial = true;
        }
      }
    } catch (e) {
      this.isError = true;
    }

    this.isLoading = false;
  }

  async save(group: number): Promise<YandexPaymentModel | boolean> {
    this.validate();

    if (this.hasErrors) {
      return false;
    }

    this.isSaving = true;
    this.isSuccessLoaded = false;

    try {
      const { response }: ServerResponse<YandexGroupPaymentApiResponse> =
        await request(apiUrls.company.setGroupPayment, 'POST', {
          kind: this.type,
          amount: this.values.amount,
          group,
        });

      if (response) {
        runInAction(() => {
          this.isSaving = false;
          this.isSuccessLoaded = true;

          this.setField(
            YandexGroupPaymentFieldsEnum.paymentUrl,
            response.payment_url
          );
        });

        return new YandexPaymentModel(response.method);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }

    this.isSaving = false;
    return false;
  }

  clear(): void {
    this.values = { paymentUrl: '', amount: '' };
  }
}
