import { action, computed, makeObservable, observable } from 'mobx';

import { LessonModel } from 'store/models/lesson/LessonModel';
import { UnitModel } from 'store/models/unit/UnitModel';
import { RootStore } from 'store/RootStore';

export class LessonStore {
  rootStore: RootStore;

  lesson?: LessonModel;
  unit?: UnitModel;

  lessonUnavailable?: boolean;

  isEditMode = false;
  isLoading = false;
  isChunksSaving = false;
  isDeletingUnit = false;

  showUnitCreation = false;
  showChunkCreation = false;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      // observable
      lesson: observable,
      lessonUnavailable: observable,
      unit: observable,
      isLoading: observable,
      isChunksSaving: observable,
      isDeletingUnit: observable,
      isEditMode: observable,
      showChunkCreation: observable,
      showUnitCreation: observable,
      // computed
      isAdmin: computed,
      // action
      toggleChunkCreationDrawer: action.bound,
      toggleEditMode: action,
      setUnit: action,
      deleteUnit: action,
      load: action,
      save: action.bound,
    });
    this.rootStore = rootStore;
  }

  get isAdmin(): boolean {
    if (!this.lesson || !this.lesson.courseId) {
      return false;
    }

    return this.rootStore.userStore.isStaffInCourse(
      this.rootStore.courseStore.collection.items[this.lesson.courseId]
    );
  }

  toggleChunkCreationDrawer(): void {
    this.showChunkCreation = !this.showChunkCreation;
  }

  toggleEditMode(): void {
    this.isEditMode = !this.isEditMode;
  }

  async setUnit(unitId: number): Promise<void> {
    if (this.lesson) {
      const unit = this.lesson.getUnitById(unitId);
      if (unit) {
        this.unit = unit;
        await this.unit.loadChunks();
      }
    }
  }

  async deleteUnit(): Promise<[boolean, UnitModel | undefined | null]> {
    if (!this.unit || !this.lesson) {
      return [false, null];
    }

    this.isDeletingUnit = true;

    const unitIndex = this.lesson.getUnitIndexById(this.unit.id);
    const nextUnitIndex =
      unitIndex !== undefined && unitIndex + 1 < this.lesson.units.length
        ? unitIndex + 1
        : 0;
    const nextUnit = this.lesson.getUnitByIndex(nextUnitIndex);
    const result = await this.lesson?.deleteUnit(this.unit?.id);

    this.isDeletingUnit = false;

    return [Boolean(result), nextUnit];
  }

  async load(id: number, unitId?: number): Promise<void> {
    this.isLoading = true;

    const { lesson, unavailable } = await LessonModel.load(id);

    this.lessonUnavailable = unavailable;

    if (lesson) {
      this.lessonUnavailable = false;

      this.lesson = lesson;
      this.unit = unitId ? lesson.getUnitById(unitId) : lesson.getFirstUnit();

      if (this.unit) {
        await this.unit.loadChunks();
      }
    }

    this.isLoading = false;
  }

  async save(): Promise<boolean> {
    if (this.unit) {
      this.isChunksSaving = true;

      const result = await this.unit.saveChunks();

      this.isChunksSaving = false;

      return result;
    }

    return false;
  }
}
