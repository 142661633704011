import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import urls from 'config/urls';
import ContentLayout from 'components/common/ContentLayout';

import CoursePage from './CoursePage';
import CoursesList from './CoursesList';

const Courses: React.FC = () => {
  return (
    <ContentLayout>
      <Switch>
        <Route path={urls.courseIdPattern} component={CoursePage} />
        <Route path={urls.courses} component={CoursesList} />
      </Switch>
    </ContentLayout>
  );
};

export default React.memo(Courses);
