import * as React from 'react';

import apiUrls from 'config/apiUrls';
import request from 'utils/request';

import {
  AttachmentSourceEnum,
  AttachmentType,
  BunnyAttachmentStatusEnum,
} from '../models/attachment';
import { ServerResponse } from '../models/common/types';

export const useBunnyVideoPolling = (attachment: AttachmentType | null) => {
  const timeout = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    const poll = async () => {
      const {
        response,
      }: ServerResponse<{ status: BunnyAttachmentStatusEnum }> = await request(
        apiUrls.attachment.getStatus,
        'GET',
        {
          id: attachment?.id,
        }
      );

      if (response && attachment?.source === AttachmentSourceEnum.bunny) {
        attachment.status = response.status;
      }
    };

    const startPolling = async () => {
      if (
        attachment &&
        attachment.source === AttachmentSourceEnum.bunny &&
        attachment.status === BunnyAttachmentStatusEnum.uploaded
      ) {
        timeout.current = setTimeout(async () => {
          await poll();
          await startPolling();
        }, 5000);
      }
    };

    startPolling();

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
    };
  }, [attachment]);
};
