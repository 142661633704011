import * as React from 'react';
import * as ReactDOM from 'react-dom';
import cn from 'classnames';

import CloseIcon from 'components/icons/CloseIconV2';
import FullSideSpinner from 'components/common/Spinner/FullSideSpinner';

import styles from './Modal.modules.scss';
import defaultStyles from './defaultStyles.modules.scss';

type Props = {
  isOpen: boolean;
  children: React.ReactNode;
  onClose: () => void;
  isLoading?: boolean;
  className?: string;
  isWidthLimited?: boolean;
};

const Modal: React.FC<Props> = ({
  isOpen,
  children,
  onClose,
  isLoading,
  className,
  isWidthLimited,
}: Props) => {
  const modalRef = React.useRef(document.getElementById('modal'));

  if (!modalRef.current || !isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={cn(styles.root, isOpen && styles.root_shown)}>
      <div className={styles.background} onClick={onClose} />
      <div
        className={cn(
          styles.modal,
          isWidthLimited && styles.modalLimited,
          className
        )}
      >
        <div onClick={onClose} className={styles.iconContainer}>
          <CloseIcon className={styles.icon} />
        </div>
        {children}
        <FullSideSpinner isOpen={isLoading} />
      </div>
    </div>,
    modalRef.current
  );
};

Modal.defaultProps = {
  isLoading: false,
  className: defaultStyles.root,
  isWidthLimited: true,
};

export default React.memo(Modal);
