import * as React from 'react';
import { useCallback } from 'react';
import { observer } from 'mobx-react';

import { QuizGroupModel } from 'store/models/chunks/quiz/QuizGroupModel';
import ButtonPlus from 'components/common/Button/ButtonPlus/ButtonPlus';
import { ChunkTypeEnum } from 'store/models/chunks/types';
import { ChunkContainerContext } from 'components/Constructor/models/chunkContainer/context';

import { WithChunk } from '../types';
import Chunk from '../Chunk';

import QuizQuestion from './QuizQuestion/QuizQuestion';
import QuizValidation from './QuizValidation';
import styles from './QuizChunk.modules.scss';
import { QuizGroupContext } from './context';

type Props = WithChunk<QuizGroupModel>;

const QuizChunk: React.FC<Props> = ({
  isEditMode,
  isAdmin,
  chunk,
  index,
}: Props) => {
  const handleAddQuestion = useCallback(() => {
    chunk.data.addChunk(ChunkTypeEnum.quiz);
  }, [chunk, index]);

  const { chunks } = chunk.data;

  return (
    <div>
      <QuizGroupContext.Provider value={chunk.data}>
        <ChunkContainerContext.Provider value={chunk.data}>
          <div className={styles.questionsWrapper}>
            {chunks.map((chunk, index) => (
              <Chunk
                component={QuizQuestion}
                chunk={chunk}
                key={chunk.id}
                index={index}
                isEditMode={isEditMode}
                isAdmin={isAdmin}
              />
            ))}
          </div>
        </ChunkContainerContext.Provider>

        <QuizValidation quizChunk={chunk} isEditMode={isEditMode} />

        {isEditMode && (
          <ButtonPlus onClick={handleAddQuestion}>Добавить вопрос</ButtonPlus>
        )}
      </QuizGroupContext.Provider>
    </div>
  );
};

export default observer(QuizChunk);
