import { makeObservable, override } from 'mobx';

import apiUrls from 'config/apiUrls';
import { Form, FormValidators } from 'store/models/common/Form';
import request from 'utils/request';
import { composeValidators, emailValidator } from 'utils/validation';

import { ServerResponse } from '../common/types';

export enum InviteUserFormFields {
  email = 'email',
  accessLevel = 'accessLevel',
}

export type InviteUserForm = {
  email: string;
  accessLevel: number;
};

export class InviteUserFormModel extends Form<InviteUserForm> {
  companyId?: number;
  groupId: number;

  constructor(groupId: number, companyId?: number) {
    super({
      initialValues: {
        email: '',
        accessLevel: 0,
      },
      validators: {
        email: composeValidators(
          emailValidator({
            errorMessage: 'Некорректный email',
          })
        ),
      },
    });

    makeObservable(this, {
      save: override,
    });

    this.companyId = companyId;
    this.groupId = groupId;
  }

  async save(): Promise<boolean> {
    this.validate();

    if (this.hasErrors) {
      return false;
    }

    this.isSaving = true;

    const { response }: ServerResponse = await request(
      apiUrls.company.invitation.send,
      'POST',
      {
        company: this.companyId,
        group: this.groupId,
        emails: `["${this.values.email}"]`,
        access_level: this.values.accessLevel,
      }
    );

    this.isSaving = false;

    return Boolean(response);
  }
}
