import * as React from 'react';
import * as propTypes from 'prop-types';

const ProfileIcon = (props) => {
  const { className } = props;

  return (
    <svg
      width="14px"
      height="16px"
      viewBox="0 0 14 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-602.000000, -513.000000)" fill="currentColor">
          <g transform="translate(522.000000, 251.000000)">
            <g transform="translate(67.000000, 250.000000)">
              <g transform="translate(10.000000, 10.000000)">
                <path d="M17,15.8947368 C15.5715571,17.2982456 13.2382238,18 10,18 C6.76177619,18 4.42844286,17.2982456 3,15.8947368 C3,12.9760706 4.66766943,11.2949749 7.11764706,10.4210526 C7.9005147,10.7915936 8.77023495,11.2631579 10,11.2631579 C11.2297651,11.2631579 12.0397379,10.769994 12.8823529,10.4210526 C15.3003126,11.306871 17,12.9998553 17,15.8947368 Z M10,9.57894737 C7.95329769,9.57894737 6.29411765,7.88234221 6.29411765,5.78947368 C6.29411765,3.69660516 7.95329769,2 10,2 C12.0467023,2 13.7058824,3.69660516 13.7058824,5.78947368 C13.7058824,7.88234221 12.0467023,9.57894737 10,9.57894737 Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

ProfileIcon.propTypes = {
  className: propTypes.string,
};

export default ProfileIcon;
