import { ContentBlock, ContentState } from 'draft-js';
import * as React from 'react';
import { ComponentType, forwardRef } from 'react';

import { useConstructorContext } from '../../context';
import Chunk from '../Chunk';
import { WithChunk } from '../types';

import Layout from './Layout';

type Props = {
  contentState: ContentState;
  block: ContentBlock;
  [key: string]: any;
};

const withDraftJs = (
  Component: ComponentType<WithChunk>,
  isCustomChunk = false
) =>
  forwardRef(
    (
      { contentState, block, ...rest }: Props,
      ref: React.Ref<HTMLDivElement>
    ) => {
      const { chunk } = contentState.getEntity(block.getEntityAt(0)).getData();

      if (isCustomChunk) {
        return <Chunk chunk={chunk} component={Component} />;
      }

      const constructorContext = useConstructorContext();

      const {
        theme, // eslint-disable-line @typescript-eslint/no-unused-vars
        blockProps, // eslint-disable-line @typescript-eslint/no-unused-vars
        customStyleMap, // eslint-disable-line @typescript-eslint/no-unused-vars
        customStyleFn, // eslint-disable-line @typescript-eslint/no-unused-vars
        decorator, // eslint-disable-line @typescript-eslint/no-unused-vars
        forceSelection, // eslint-disable-line @typescript-eslint/no-unused-vars
        offsetKey, // eslint-disable-line @typescript-eslint/no-unused-vars
        selection, // eslint-disable-line @typescript-eslint/no-unused-vars
        tree, // eslint-disable-line @typescript-eslint/no-unused-vars
        blockStyleFn, // eslint-disable-line @typescript-eslint/no-unused-vars
        preventScroll, // eslint-disable-line @typescript-eslint/no-unused-vars
        className,
        ...elementProps
      } = rest;

      return (
        <Layout
          chunk={chunk}
          elementProps={elementProps}
          className={className}
          ref={ref}
          isEditMode={constructorContext.isEditMode}
        >
          <Component
            chunk={chunk}
            isEditMode={constructorContext.isEditMode}
            isAdmin={constructorContext.isAdmin}
            isFocused={blockProps.isFocused}
          />
        </Layout>
      );
    }
  );

export default withDraftJs;
