import * as propTypes from 'prop-types';
import * as React from 'react';

const ArrowRightIcon = (props) => {
  const { className } = props;
  return (
    <svg
      width="8px"
      height="12px"
      viewBox="0 0 8 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-793.000000, -756.000000)" fill="currentColor">
          <g transform="translate(166.000000, 440.000000)">
            <polygon
              transform="translate(630.705000, 322.000000) rotate(-90.000000) translate(-630.705000, -322.000000) "
              points="626.115 318.295 630.705 322.875 635.295 318.295 636.705 319.705 630.705 325.705 624.705 319.705"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

ArrowRightIcon.propTypes = {
  className: propTypes.string.isRequired,
};

ArrowRightIcon.defaultProps = {};

export default ArrowRightIcon;
