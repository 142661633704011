import * as React from 'react';
import cn from 'classnames';

import styles from './Badge.modules.scss';

type Props = {
  children: React.ReactNode;
  color?: string;
  className?: string;
};

const Badge: React.FC<Props> = ({ className, color, children }: Props) => {
  return (
    <div className={cn(styles.root, className)} style={{ background: color }}>
      {children}
    </div>
  );
};

Badge.defaultProps = {
  color: '',
};

export default React.memo(Badge);
