import cogoToast from 'cogo-toast';
import * as React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useCallback } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

import urls from 'config/urls';
import ButtonDelete from 'components/common/Button/ButtonDelete';
import SwitcherInput from 'components/form/SwitcherInput/SwitcherInput';
import Button from 'components/common/Button';
import ButtonPlus from 'components/common/Button/ButtonPlus';
import { useModal } from 'components/form/hooks/useModal';
import UnitCreationModal from 'components/containers/course/UnitCreationModal';

import { LessonStore } from '../../LessonStore';

import styles from './Admin.modules.scss';
import defaultStyles from './AdminDefault.modules.scss';

type Props = {
  className?: string;
  editStore: LessonStore;
};

const Admin: React.FC<Props> = ({ className, editStore }: Props) => {
  const [showCardModal, toggleCardModal] = useModal();
  const history = useHistory();

  const toggleEditMode = useCallback(() => {
    if (isMobileOnly) {
      return cogoToast.warn('Редактирование доступно только из веб-версии');
    }
    editStore.toggleEditMode();
  }, [editStore.toggleEditMode]);

  const deleteCard = useCallback(async () => {
    const [success, nextUnit] = await editStore.deleteUnit();
    if (success) {
      cogoToast.success('Карточка успешно удалена!');
      if (nextUnit) {
        history.replace(urls.lessonCard(editStore.lesson?.id, nextUnit.id));
      } else {
        history.replace(urls.lesson(editStore.lesson?.id));
      }
    } else {
      cogoToast.error('Произошла ошибка');
    }
  }, []);

  const handleSave = useCallback(async () => {
    const success = await editStore.save();
    if (success) {
      cogoToast.success('Карточка успешно сохранена!');
    } else {
      cogoToast.error('Произошла ошибка при сохранении');
    }
  }, []);

  if (!editStore.isAdmin) {
    return null;
  }

  return (
    <div className={cn(styles.root, className)}>
      {editStore.lesson && showCardModal && (
        <UnitCreationModal
          lesson={editStore.lesson}
          resetOnOpen
          onClose={toggleCardModal}
          isOpen={showCardModal}
          onSuccess={editStore.lesson.addUnit}
        />
      )}
      <div className={styles.switcherRow}>
        <SwitcherInput
          onChange={toggleEditMode}
          isActive={editStore.isEditMode}
        >
          Режим редактирования
        </SwitcherInput>
        {editStore.isEditMode && (
          <div>
            {(editStore.lesson?.units.length || 0) > 1 && (
              <ButtonDelete
                isLoading={editStore.isDeletingUnit}
                className={styles.deleteCardBtn}
                onClick={deleteCard}
              >
                Удалить карточку
              </ButtonDelete>
            )}
            <ButtonPlus className={styles.addCardBtn} onClick={toggleCardModal}>
              Добавить карточку
            </ButtonPlus>
          </div>
        )}
      </div>
      {editStore.isEditMode && (
        <Button
          className={styles.button}
          onClick={handleSave}
          isDisabled={editStore.isChunksSaving}
          isLoading={editStore.isChunksSaving}
        >
          Сохранить изменения в карточке
        </Button>
      )}
    </div>
  );
};

Admin.defaultProps = {
  className: defaultStyles.root,
};

export default observer(Admin);
