import * as React from 'react';
import cn from 'classnames';

import LockIcon from 'components/icons/LockIcon';

import styles from './CountLessons.modules.scss';

type Props = {
  className?: string;
  isLocked: boolean;
  countCompleted: number;
  count: number;
};

const CountLessons: React.FC<Props> = ({
  className,
  isLocked,
  countCompleted,
  count,
}: Props) => {
  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.countBorder}>
        <div className={styles.wrapper}>
          {!isLocked ? (
            <span className={styles.text}>
              {countCompleted}/{count}
            </span>
          ) : (
            <LockIcon className={styles.iconLock} />
          )}
        </div>
      </div>
      <div className={styles.line} />
    </div>
  );
};

CountLessons.defaultProps = {};

export default React.memo(CountLessons);
