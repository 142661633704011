import {
  PopperOptions,
  SideToolbarPluginStore,
  SideToolbarPosition,
} from '@draft-js-plugins/side-toolbar';
import { SideToolbarChildrenProps } from '@draft-js-plugins/side-toolbar/lib/components/Toolbar/Toolbar';
import React, {
  ReactElement,
  FC,
  useState,
  useCallback,
  useEffect,
} from 'react';
// @ts-ignore
import DraftOffsetKey from 'draft-js/lib/DraftOffsetKey';
import { EditorState } from 'draft-js';

import { IChunkContainer } from '../../models/chunkContainer/ChunkContainer';

import Popover from './Popover';
import SidebarButton from './SidebarButton';
import styles from './ConstructorSidebar.modules.scss';

interface ToolbarProps {
  children?: FC<SideToolbarChildrenProps>;
  store: SideToolbarPluginStore;
  position: SideToolbarPosition;
  popperOptions?: PopperOptions;
  unit: IChunkContainer;
}

function Toolbar({
  position,
  popperOptions,
  store,
  unit,
}: ToolbarProps): ReactElement | null {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  );

  const onEditorStateChange = useCallback((editorState?: EditorState) => {
    const selection = editorState!.getSelection();
    // if (!selection.getHasFocus()) {
    //   // setReferenceElement(null);
    //   return;
    // }

    const currentContent = editorState!.getCurrentContent();
    const currentBlock = currentContent.getBlockForKey(selection.getStartKey());

    // if (currentBlock.getType() === 'atomic' || currentBlock.getText() !== '') {
    //   setReferenceElement(null);
    //   return;
    // }

    // TODO verify that always a key-0-0 exists
    const offsetKey = DraftOffsetKey.encode(currentBlock.getKey(), 0, 0);
    // Note: need to wait on tick to make sure the DOM node has been create by Draft.js
    setTimeout(() => {
      // eslint-disable-next-line prefer-destructuring
      const node = document.querySelectorAll<HTMLDivElement>(
        `[data-offset-key="${offsetKey}"]`
      )[0];
      setReferenceElement(node);
    }, 0);
  }, []);

  useEffect(() => {
    store.subscribeToItem('editorState', onEditorStateChange);
    return () => {
      store.unsubscribeFromItem('editorState', onEditorStateChange);
    };
  }, [store]);

  if (referenceElement === null) {
    return null;
  }

  return (
    <Popover
      className={styles.wrapper}
      referenceElement={referenceElement}
      position={position}
      popperOptions={popperOptions}
    >
      <SidebarButton unit={unit} />
    </Popover>
  );
}

export default Toolbar;
