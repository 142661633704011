import cn from 'classnames';
import { observer } from 'mobx-react';
import { Resizable } from 're-resizable';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';

import { ChunkAlignEnum } from 'store/models/chunks/types';
import { ChunkModel } from 'store/models/chunks/ChunkModel';

import { CHUNK_ELEMENT_ID } from '../../../config';

import { getAlignClassNames } from './alignClassNames';
import stylesRoot from './styles/root.modules.scss';

type Props = {
  isEditMode: boolean;
  setRef: (node: any) => void;
  isSelected: boolean;
  verticalAlign: ChunkAlignEnum;
  horizontalAlign: ChunkAlignEnum | null;
  style: React.CSSProperties;
  children: React.ReactNode;
  chunk: ChunkModel;
  onClick: (e: React.MouseEvent) => void;
  onResize: (isResizing: boolean) => void;
};

const Wrapper: React.FC<Props> = React.forwardRef((props: Props, ref: any) => {
  const {
    onClick,
    setRef,
    isEditMode,
    isSelected,
    verticalAlign,
    horizontalAlign,
    style,
    children,
    chunk,
    onResize,
  } = props;

  const [isResizing, setIsResizing] = useState(false);

  const toggleResize = useCallback((e: any) => {
    // Стопаем событие, чтобы работали вложенные чанки
    e.stopPropagation();
    e.preventDefault();
    setIsResizing((s) => !s);
  }, []);

  useEffect(() => {
    onResize(isResizing);
  }, [isResizing]);

  const handleResize = useCallback(
    (e: any, direction: any, ref: HTMLElement, d: { width: number }) => {
      if (!ref.parentElement) {
        return;
      }

      chunk.layout.setWidth(
        chunk.layout.width + (d.width / ref.parentElement.clientWidth) * 100
      );
      toggleResize(e);
    },
    [chunk.layout]
  );

  let content = (
    <Resizable
      ref={(node): void => {
        if (node) {
          setRef(node.resizable);
          ref.current = node.resizable;
        }
      }}
      bounds="parent"
      enable={{
        top: false,
        right: true,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      handleWrapperClass={stylesRoot.resizerHandleWrapper}
      handleClasses={{ right: stylesRoot.resizerHandle }}
      size={{
        width: `${isMobileOnly ? 100 : chunk.layout.width}%`,
        height: 'auto',
      }}
      onResizeStart={toggleResize}
      onResizeStop={handleResize}
      className={cn(
        stylesRoot.root,
        isEditMode && stylesRoot.editing,
        isEditMode && (isSelected || isResizing) && stylesRoot.selected,

        getAlignClassNames(verticalAlign, undefined)
      )}
      style={style}
    >
      <div id={CHUNK_ELEMENT_ID} className={stylesRoot.inner} onClick={onClick}>
        {children}
      </div>
    </Resizable>
  );

  if (
    horizontalAlign &&
    [ChunkAlignEnum.center, ChunkAlignEnum.right].indexOf(horizontalAlign) > -1
  ) {
    content = (
      <div className={getAlignClassNames(undefined, horizontalAlign)}>
        {content}
      </div>
    );
  }

  return content;
});

export default observer(Wrapper);
