import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';

import { PaymentsStore } from 'store/stores';

import { RootStore } from './RootStore';
import { StudentsStore } from './stores/StudentsStore';
import { CourseStore } from './stores/CourseStore';
import { UiStore } from './stores/UiStore/UiStore';
import { UserStore } from './stores/UserStore';

export type StoreType = {
  rootStore: RootStore;
};

const useStores = (): StoreType => {
  return useContext(MobXProviderContext) as StoreType;
};

export const useRootStore = (): RootStore => {
  const store = useStores();

  return store.rootStore;
};

export const useUserStore = (): UserStore => {
  const store = useStores();

  return store.rootStore.userStore;
};

export const usePaymentsStore = (): PaymentsStore => {
  return useStores().rootStore.paymentsStore;
};

export const useCourseStore = (): CourseStore => {
  const store = useStores();

  return store.rootStore.courseStore;
};

export const useStudentsStore = (): StudentsStore => {
  const store = useStores();

  return store.rootStore.studentsStore;
};

export const useUiStore = (): UiStore => {
  const store = useStores();

  return store.rootStore.uiStore;
};
