//  https://github.com/typescript-cheatsheets/react-typescript-cheatsheet
import 'react-hot-loader';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import * as Sentry from '@sentry/browser';
import 'antd/dist/antd.css';

import stores from 'store';

import packageJson from '../package.json';

import App from './App';

import './styles/styles.scss';

Sentry.init({
  dsn: 'https://5bde3e02d647439297038395f54d3f4b@sentry.team.ktsstudio.ru/134',
  release: packageJson.version,
});

ReactDOM.render(
  <Provider {...stores}>
    <App />
  </Provider>,
  document.getElementById('root')
);
