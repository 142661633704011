import * as React from 'react';
import { useCallback, useRef } from 'react';
import cn from 'classnames';

import { AttachmentType } from 'store/models/attachment';
import Spinner from 'components/common/Spinner';

import commonStyles from '../styles/common.modules.scss';
import Label from '../Label';
import Error from '../Error';

import styles from './ImageInput.modules.scss';

type Props = {
  onChange: (file: File) => void;
  onDelete: () => void;
  attachment?: AttachmentType;
  fileName?: string;
  placeholder?: string;
  isLoading?: boolean;
  error?: string | null;
};

const ImageInput: React.FC<Props> = ({
  onChange,
  onDelete,
  fileName,
  isLoading,
  attachment,
  placeholder,
  error,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        const [file] = e.target.files;
        onChange(file);
      }
    },
    [onChange]
  );

  const handleDelete = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    onDelete();
  };

  return (
    <>
      <div
        className={cn(
          commonStyles.inputWrapper,
          commonStyles.input,
          styles.root
        )}
      >
        {attachment && !isLoading && (
          <div
            className={styles.preview}
            style={{ backgroundImage: `url(${attachment.url})` }}
          />
        )}
        {!attachment && !isLoading && (
          <span className={cn(styles.icon, styles.iconEmpty)} />
        )}
        {isLoading && (
          <span className={cn(styles.icon, styles.spinner)}>
            <Spinner />
          </span>
        )}
        <Label isStatic>{fileName || placeholder}</Label>
        {attachment && (
          <span className={styles.iconClose} onClick={handleDelete} />
        )}
        <input
          ref={inputRef}
          onChange={handleFileChange}
          accept=".gif,.jpg,.jpeg,.png"
          type="file"
          multiple={false}
          className={cn(styles.input)}
        />
      </div>
      {error && <Error>{error}</Error>}
    </>
  );
};

ImageInput.defaultProps = {
  placeholder: 'Загрузите изображение',
};

export default React.memo(ImageInput);
