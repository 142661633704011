import * as React from 'react';
import cn from 'classnames';

import CloseIcon from 'components/icons/CloseIconV2';

import styles from './Tag.modules.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
  onDelete?(): void;
  tag?: any;
  [key: string]: any;
};

const Tag: React.FC<Props> = ({
  tag,
  onDelete,
  className,
  children,
  ...props
}: Props) => {
  const Component = tag as any;

  return (
    <Component
      {...props}
      className={cn(styles.tag, onDelete && styles.canDelete, className)}
    >
      {children}
      {onDelete && (
        <div className={styles.deleteIcon} onClick={onDelete}>
          <CloseIcon />
        </div>
      )}
    </Component>
  );
};

Tag.defaultProps = {
  tag: 'div',
};

export default React.memo(Tag);
