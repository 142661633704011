import { action, computed, makeObservable, observable, toJS } from 'mobx';

export type DeadlineMixinType = {
  deadline: Date | null;
  isDeadline: boolean;
};

export class DeadlineMixin {
  deadline: Date | null = null;
  _isDeadline = false;

  constructor(deadline: Date | null, isDeadline: boolean) {
    makeObservable(this, {
      deadline: observable,
      _isDeadline: observable,

      isDeadline: computed,

      setDeadline: action.bound,
    });

    this.deadline = deadline;
    this._isDeadline = isDeadline;
  }

  get isDeadline(): boolean {
    if (this._isDeadline) {
      return true;
    } else if (!this.deadline) {
      return false;
    }

    // TODO Так работать не будет конечно, но я забиваю, не крит.
    return new Date().getTime() > this.deadline.getTime();
  }

  setDeadline(deadline: Date): void {
    this.deadline = deadline;
  }

  toJson(): { deadline: string | null } {
    return toJS({
      deadline: this.deadline?.toISOString() || null,
    });
  }
}
