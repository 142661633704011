import { ServerResponse } from 'store/models/common/types';
import request from 'utils/request';
import apiUrls from 'config/apiUrls';
import { PaymentsType } from 'config/payments';
import { BasePaymentUpdateApiRequest } from 'store/models/payments/types';

export const getPaymentCredentials = async <T = any>(
  company: number,
  payment: PaymentsType
): Promise<ServerResponse<T>> => {
  return request(apiUrls.company.getPaymentCredentials, 'GET', {
    kind: payment,
    company,
  });
};

export const updatePaymentCredentials = async <T = any>(
  data: BasePaymentUpdateApiRequest
): Promise<ServerResponse<T>> => {
  return request(apiUrls.company.updatePaymentCredentials, 'POST', data);
};
