import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { useCallback } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import Button from 'components/common/Button';
import { ButtonColor } from 'components/common/Button/Button';

import { complexChunks, typeToChunkEdit } from '../Chunks/config';
import { LESSON_EDIT_BAR_ELEMENT_ID } from '../config';
import { useConstructorContext } from '../context';

import LayoutEdit from './LayoutEdit';
import styles from './ChunkEdit.modules.scss';

type Props = {
  children?: React.ReactChildren;
};

const ChunkEdit: React.FC<Props> = () => {
  const { chunkToEdit: selectedChunk } = useConstructorContext();

  const deleteSelectedChunk = useCallback(() => {
    selectedChunk?.data?.removeFromParent();
  }, [selectedChunk]);

  const ChunkEdit = selectedChunk?.type
    ? typeToChunkEdit?.[selectedChunk?.type]
    : null;

  let content = (
    <div className={cn(styles.empty, styles.wrapper)}>
      Нажмите на&nbsp;блок, чтобы настроить отображение
    </div>
  );

  if (selectedChunk) {
    content = (
      <div className={styles.wrapper}>
        <LayoutEdit layout={selectedChunk.layout} />
        {ChunkEdit && (
          <ChunkEdit chunk={selectedChunk} isAdmin isFocused isEditMode />
        )}
        {complexChunks.indexOf(selectedChunk.type) > -1 && (
          <div className={styles.deleteBtn}>
            <Button color={ButtonColor.red} onClick={deleteSelectedChunk}>
              Удалить
            </Button>
          </div>
        )}
      </div>
    );
  }

  const root = document.getElementById(LESSON_EDIT_BAR_ELEMENT_ID);

  if (!root) {
    return null;
  }

  return ReactDOM.createPortal(content, root);
};

export default observer(ChunkEdit);
