import { useCallback } from 'react';

import { Form } from 'store/models/common/Form';

export type FieldType<T> = [T, (v: T) => void, string[]];

export const useField = <T, FormModel extends Form<T>>(
  form: FormModel,
  fieldName: keyof T
): FieldType<T[typeof fieldName]> => {
  const handleChange = useCallback(
    (v: T[typeof fieldName]) => {
      form.setField(fieldName, v);
    },
    [fieldName]
  );

  const value = form.getField(fieldName);
  const errors = (form.errors[fieldName] || []) as string[];

  return [value, handleChange, errors];
};

export type FieldBindType<T> = {
  value: T;
  onChange: (v: T) => void;
  errors: string[];
};

export const useFieldBind = <T, FormModel extends Form<T>>(
  form: FormModel,
  fieldName: keyof T
): FieldBindType<T[typeof fieldName]> => {
  const [value, onChange, errors] = useField(form, fieldName);

  return {
    value,
    onChange,
    errors,
  };
};

export default useField;
