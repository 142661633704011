import * as React from 'react';
import * as propTypes from 'prop-types';

const PencilIcon = (props) => {
  const { className } = props;

  return (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="currentColor" fillRule="nonzero">
          <path d="M11.1928446,3.0039681 L14.8544518,6.6834731 L5.5859129,15.997403 L1.9263817,12.317898 L11.1928446,3.0039681 Z M17.6329034,2.1165559 L15.9999675,0.4756229 C15.3688941,-0.158541 14.3441614,-0.158541 13.710944,0.4756229 L12.1467547,2.0474726 L15.8083619,5.7270118 L17.6329034,3.8935349 C18.1223655,3.4016415 18.1223655,2.6084151 17.6329034,2.1165559 Z M0.0101894,17.4897385 C-0.0564472,17.7911057 0.2143191,18.0611461 0.5142517,17.9878563 L4.5944984,16.9937069 L0.9349673,13.3142019 L0.0101894,17.4897385 Z" />
        </g>
      </g>
    </svg>
  );
};

PencilIcon.propTypes = {
  className: propTypes.string.isRequired,
};

export default PencilIcon;
