import toast from 'cogo-toast';
import { observer } from 'mobx-react';
import * as React from 'react';

import Modal from 'components/common/Modal/Modal';
import Button from 'components/common/Button';
import { InputField } from 'components/form';
import {
  InviteUserFormFields,
  InviteUserFormModel,
} from 'store/models/students/InviteUserFormModel';
import { useCurrentCompany } from 'store/stores';
import { useLocal } from 'utils/hooks/useLocal';

import useForm from '../../../form/hooks/useForm';

import styles from './InviteUserModal.modules.scss';

export type InviteUserModalProps = {
  groupId: number;
  isOpen: boolean;
  onClose(): void;
  onSuccess?(email: string): void;
};

const InviteUserModal: React.FC<InviteUserModalProps> = ({
  groupId,
  isOpen,
  onSuccess,
  onClose,
}: InviteUserModalProps) => {
  const companyId = useCurrentCompany()?.id;
  const store = useLocal(() => new InviteUserFormModel(groupId, companyId));
  const form = useForm(store, Object.values(InviteUserFormFields));

  const onSave = async (): Promise<void> => {
    const result = await store.save();
    if (result) {
      toast.success('Пользователь получит приглашение на почту');
      onSuccess?.(form.email.value);
      onClose();
    } else {
      toast.error('Не удалось отправить приглашение на почту пользователя');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className={styles.content}
      isLoading={store.isSaving}
    >
      <div className={styles.title}>Добавить студента</div>
      <InputField
        value={form.email.value}
        label="Email"
        noLabel
        onChange={form.email.setValue}
        placeholder="Введите email студента"
        errors={form.email.errors}
      />
      <InputField
        value={form.accessLevel.value}
        label="Уровень доступа"
        noLabel
        onChange={form.accessLevel.setValue}
        type="number"
        min="0"
        errors={form.accessLevel.errors}
      />
      <Button
        className={styles.inviteBtn}
        onClick={onSave}
        isDisabled={store.isSaving}
      >
        Отправить приглашение
      </Button>
    </Modal>
  );
};

export default observer(InviteUserModal);
