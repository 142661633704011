import * as React from 'react';
import * as propTypes from 'prop-types';

import noop from 'utils/noop';

const CloseIcon = (props) => {
  const { className, onClick } = props;

  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
      onClick={onClick}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-885.000000, -265.000000)" fill="currentColor">
          <g transform="translate(522.000000, 251.000000)">
            <g transform="translate(361.000000, 12.000000)">
              <path
                d="M11.1421356,9.14213562 L19.1421356,9.14213562 C19.6944204,9.14213562 20.1421356,9.58985087 20.1421356,10.1421356 C20.1421356,10.6944204 19.6944204,11.1421356 19.1421356,11.1421356 L11.1421356,11.1421356 L11.1421356,19.1421356 C11.1421356,19.6944204 10.6944204,20.1421356 10.1421356,20.1421356 C9.58985087,20.1421356 9.14213562,19.6944204 9.14213562,19.1421356 L9.14213562,11.1421356 L1.14213562,11.1421356 C0.589850874,11.1421356 0.142135624,10.6944204 0.142135624,10.1421356 C0.142135624,9.58985087 0.589850874,9.14213562 1.14213562,9.14213562 L9.14213562,9.14213562 L9.14213562,1.14213562 C9.14213562,0.589850874 9.58985087,0.142135624 10.1421356,0.142135624 C10.6944204,0.142135624 11.1421356,0.589850874 11.1421356,1.14213562 L11.1421356,9.14213562 Z"
                transform="translate(10.142136, 10.142136) rotate(45.000000) translate(-10.142136, -10.142136) "
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

CloseIcon.propTypes = {
  className: propTypes.string,
  onClick: propTypes.func,
};

CloseIcon.defaultProps = {
  onClick: noop,
};

export default CloseIcon;
