import * as propTypes from 'prop-types';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import styles from './Link.modules.scss';

const Link = (props) => {
  const { text, url } = props;

  return (
    <RouterLink className={styles.root} to={url}>
      {text}
    </RouterLink>
  );
};

Link.propTypes = {
  text: propTypes.string.isRequired,
  url: propTypes.string.isRequired,
};

Link.defaultProps = {};

export default Link;
