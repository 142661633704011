import * as React from 'react';
import cn from 'classnames';

import styles from './Row.modules.scss';

interface Props {
  className?: string;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
}

const Row: React.FC<Props> = ({
  className,
  leftContent,
  rightContent,
}: Props) => {
  return (
    <div className={cn(styles.row, className)}>
      {leftContent && (
        <div className={styles.rowLeftContent}>{leftContent}</div>
      )}
      {rightContent && (
        <div className={styles.rowRightContent}>{rightContent}</div>
      )}
    </div>
  );
};

export default Row;
