import * as React from 'react';

import styles from './UnsupportedChunk.modules.scss';

const UnsupportedChunk: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.img} />
      <div>Этот элемент пока не поддерживается</div>
    </div>
  );
};

export default React.memo(UnsupportedChunk);
