import * as React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import noop from 'utils/noop';

import styles from './BaseCard.modules.scss';

export type BaseCardProps = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  to?: string;
};

const BaseCard = React.forwardRef(
  ({ style, children, className, onClick, to }: BaseCardProps, ref: any) => {
    const Element = to ? Link : 'div';

    const props: any = {
      className: cn(styles.root, className),
      onClick,
      style,
    };

    if (to) {
      props.to = to;
    }

    return (
      <Element {...props} ref={ref}>
        {children}
      </Element>
    );
  }
);

BaseCard.defaultProps = {
  children: null,
  className: '',
  onClick: noop,
  style: {},
};

export default BaseCard;
