export function animateScroll(element, duration) {
  const start = element.scrollTop;
  const end = element.scrollHeight;
  const change = end - start;
  const increment = 20;

  function easeInOut(currentTime, start, change, duration) {
    currentTime /= duration / 2;
    if (currentTime < 1) {
      return (change / 2) * currentTime * currentTime + start;
    }
    currentTime -= 1;
    return (-change / 2) * (currentTime * (currentTime - 2) - 1) + start;
  }

  function animate(elapsedTime) {
    elapsedTime += increment;
    element.scrollTop = easeInOut(elapsedTime, start, change, duration);
    if (elapsedTime < duration) {
      setTimeout(() => {
        animate(elapsedTime);
      }, increment);
    }
  }

  animate(0);
}
