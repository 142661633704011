import { AtomicBlockUtils, EditorState } from 'draft-js';
import { action, computed, makeObservable, observable } from 'mobx';

import {
  ChunkContainer,
  IChunkContainer,
} from 'components/Constructor/models/chunkContainer/ChunkContainer';
import { generateComplexChunk } from 'components/Constructor/utils/complexChunkGenerator';
import {
  createDraftJsEntityFromChunk,
  draftJsToJson,
  jsonToDraftJs,
} from 'components/Constructor/utils/draftJsConverter';
import apiUrls from 'config/apiUrls';
import { ChunkModel } from 'store/models/chunks/ChunkModel';
import { QuizQuestionModel } from 'store/models/chunks/quiz/QuizQuestionModel';
import {
  AnyChunkType,
  ChunkTypeEnum,
  ComplexChunkEnum,
} from 'store/models/chunks/types';
import { ServerResponse } from 'store/models/common/types';
import { LessonModel } from 'store/models/lesson/LessonModel';
import { UnitCreationFormModel } from 'store/models/unit/UnitCreationFormModel';
import request from 'utils/request';

import { MarkupServerType, UnitServerType, UnitTypeEnum } from './types';

type UnitConstructorType = {
  id: number;
  finished: boolean;
  canUserOpen: boolean;
  creationDate: Date;
  type: UnitTypeEnum;
  lesson: LessonModel;
  ordinal: number;
  required: boolean;
  passInTime: boolean;
  maximumTimeOnUnit: number | null;
  // TODO
  markup: Array<any>;
  quizzes: Array<any>;

  creatorId?: number;
  courseId?: number;
  chapterId?: number;
};

export class UnitModel
  extends ChunkContainer
  implements UnitConstructorType, IChunkContainer
{
  isLoadingChunks = false;
  isChunksLoaded = false;

  // chunks: ChunkModel[] = [];

  editorState: EditorState = EditorState.createEmpty();

  finished: boolean;
  canUserOpen: boolean;
  id: number;
  creationDate: Date;
  type: UnitTypeEnum;
  lesson: LessonModel;
  ordinal: number;
  required: boolean;
  passInTime: boolean;
  maximumTimeOnUnit: number | null;
  // TODO
  markup: Array<any>;
  quizzes: Array<any>;

  creatorId?: number;
  courseId?: number;
  chapterId?: number;

  constructor({
    finished,
    canUserOpen,
    id,
    creationDate,
    type,
    creatorId,
    courseId,
    chapterId,
    lesson,
    ordinal,
    required,
    passInTime,
    maximumTimeOnUnit,
    markup,
    quizzes,
  }: UnitConstructorType) {
    super();
    makeObservable(this, {
      // observable
      isLoadingChunks: observable,
      isChunksLoaded: observable,
      editorState: observable,
      // chunks: observable,
      // computed
      disabledChunksToCreate: computed,
      topLevelChunks: computed,
      quizChunks: computed,
      //action
      updateFrom: action.bound,
      loadChunks: action,
      setEditorState: action.bound,
    });
    this.finished = finished;
    this.canUserOpen = canUserOpen;
    this.id = id;
    this.creationDate = creationDate;
    this.type = type;
    this.creatorId = creatorId;
    this.courseId = courseId;
    this.chapterId = chapterId;
    this.lesson = lesson;
    this.ordinal = ordinal;
    this.required = required;
    this.passInTime = passInTime;
    this.maximumTimeOnUnit = maximumTimeOnUnit;
    this.markup = markup;
    this.quizzes = quizzes;
  }

  setEditorState(editorState: EditorState): void {
    this.editorState = editorState;
  }

  addChunk(chunkType: AnyChunkType = ChunkTypeEnum.text): ChunkModel[] {
    // TODO Сохранение и парсинг chunk <-> draftjs

    const contentState = this.editorState.getCurrentContent();

    const newChunk = ChunkModel.createDefault(this, chunkType);
    // this.chunks.push(newChunk);

    const [contentStateWithEntity, entityKey] = createDraftJsEntityFromChunk(
      contentState,
      newChunk
    );

    const newEditorState = EditorState.set(this.editorState, {
      currentContent: contentStateWithEntity,
    });

    this.editorState = AtomicBlockUtils.insertAtomicBlock(
      newEditorState,
      entityKey,
      ' '
    );

    return [];
  }

  get disabledChunksToCreate(): ChunkTypeEnum[] {
    const disabled: ChunkTypeEnum[] = [];

    // TODO Брать из entityMap draftJS
    // if (this.chunks.findIndex((c) => c.type === ChunkTypeEnum.homework) > -1) {
    //   disabled.push(ChunkTypeEnum.homework, ChunkTypeEnum.quizGroup);
    // }
    //
    // if (this.chunks.findIndex((c) => c.type === ChunkTypeEnum.quizGroup) > -1) {
    //   disabled.push(ChunkTypeEnum.homework);
    // }

    return disabled;
  }

  /* Чанки верхнего уровня, без вопросов тестов */
  get topLevelChunks(): ChunkModel[] {
    return this.chunks.filter((c) => c.type !== ChunkTypeEnum.quiz);
  }

  get quizChunks(): ChunkModel<QuizQuestionModel>[] {
    return this.chunks.filter((c) => c.type === ChunkTypeEnum.quiz);
  }

  updateFrom(form: UnitCreationFormModel): void {
    this.required = form.values.required;
    this.passInTime = form.values.passInTime;
    this.type = form.values.type;
  }

  async loadChunks(): Promise<void> {
    if (this.isChunksLoaded) {
      return;
    }

    this.isLoadingChunks = true;

    const { response }: ServerResponse<{ markup: MarkupServerType }> =
      await request(apiUrls.unit.get, 'GET', {
        id: this.id,
      });

    if (response) {
      this.editorState = EditorState.createWithContent(
        jsonToDraftJs(response.markup, this)
      );
      // this.chunks = response.chunks.map((c) => ChunkModel.fromJson(c, this));
      this.isChunksLoaded = true;
    }

    this.isLoadingChunks = false;
  }

  generateChunksByType(chunkType: AnyChunkType): ChunkModel[] {
    const newChunks = [];
    if (chunkType in ChunkTypeEnum) {
      newChunks.push(ChunkModel.createDefault(this, chunkType));
    }
    if (chunkType in ComplexChunkEnum) {
      newChunks.push(
        ...generateComplexChunk(this, chunkType as ComplexChunkEnum)
      );
    }
    return newChunks;
  }

  async saveChunks(): Promise<boolean> {
    const { hasErrors, chunkMap, blocks, entityMap } = draftJsToJson(
      this.editorState
    );

    if (hasErrors) {
      return false;
    }

    const { response, error }: ServerResponse<any, { object_id: string }> =
      await request(apiUrls.chunks.saveMany, 'POST', {
        entityMap,
        blocks,
        unit_id: this.id,
      });

    if (error?.data?.object_id) {
      const errorChunk = chunkMap[error.data.object_id];

      if (errorChunk) {
        errorChunk.setError(['В этом элементе ошибка']);
      }
    }

    return Boolean(response);
  }

  static fromForm(
    form: UnitCreationFormModel,
    lesson: LessonModel
  ): UnitModel | null {
    if (!form.id) {
      return null;
    }

    return new UnitModel({
      id: form.id,
      required: form.values.required,
      type: form.values.type,
      passInTime: form.values.passInTime,
      lesson,

      finished: false,
      canUserOpen: false,
      creationDate: new Date(),
      courseId: lesson.courseId,
      chapterId: lesson.chapter?.id,
      creatorId: 0,
      ordinal: 0,
      maximumTimeOnUnit: null,
      // TODO
      markup: [],
      quizzes: [],
    });
  }

  static fromJson(json: UnitServerType, lesson: LessonModel): UnitModel {
    return new UnitModel({
      finished: json.is_finished,
      id: json.id,
      canUserOpen: json.can_user_open,
      creationDate: new Date(json.creation_date),
      type: json.type,
      creatorId: json.creator_id,
      courseId: json.course_id,
      chapterId: json.chapter_id,
      lesson,
      ordinal: json.ordinal,
      required: json.required,
      passInTime: json.pass_in_time,
      maximumTimeOnUnit: json.maximum_time_on_unit,

      markup: [],
      quizzes: [],
    });
  }
}
