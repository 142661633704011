import { makeObservable, observable, override } from 'mobx';

import { Form } from 'store/models/common/Form';
import {
  composeValidators,
  emailValidator,
  greaterThanValidator,
  notEmptyValidator,
} from 'utils/validation';
import { UserStore } from 'store/stores/UserStore';

type LoginFields = {
  email: string;
  password: string;
};

export class LoginForm extends Form<LoginFields> {
  initialValues = {
    email: '',
    password: '',
  };

  validators = {
    email: composeValidators(
      notEmptyValidator(),
      emailValidator(),
      greaterThanValidator({
        min: 5,
        errorMessage: 'Email должен быть длиннее 5 символов',
      })
    ),
    password: composeValidators(
      notEmptyValidator(),
      greaterThanValidator({
        min: 8,
        errorMessage: 'Пароль должен быть длиннее 8 символов',
      })
    ),
  };

  userStore: UserStore;

  isError = false;

  constructor(userStore: UserStore) {
    super();
    makeObservable(this, {
      //observable
      isError: observable,
      //override
      save: override,
    });
    this.userStore = userStore;
  }

  async save(): Promise<boolean> {
    this.isError = false;

    this.validate();
    if (this.hasErrors) {
      return false;
    }
    this.isSaving = true;

    const result = await this.userStore.login(
      this.values.email,
      this.values.password
    );

    this.isSaving = false;

    if (!result) {
      this.isError = true;
    }

    return result;
  }
}
