import { RawDraftContentBlock, RawDraftEntity } from 'draft-js';

import { DropdownOption } from 'components/form/Dropdown/Dropdown';

export enum UnitTypeEnum {
  generic = 'generic',
}

export type UnitCreateResponseType = {
  id: number;
};

export type MarkupServerType = {
  blocks: Array<RawDraftContentBlock>;
  entityMap: { [key: string]: RawDraftEntity };
};

export type UnitServerType = {
  is_finished: boolean;
  can_user_open: boolean;
  id: number;
  creation_date: string;
  type: UnitTypeEnum;
  creator_id: number;
  course_id: number;
  chapter_id: number;
  lesson_id: number;
  ordinal: number;
  required: boolean;
  pass_in_time: boolean;
  maximum_time_on_unit: number | null;
  // TODO
  markup: MarkupServerType;
  quizzes: Array<any>;
};

export const UnitTypeOptions: DropdownOption<UnitTypeEnum>[] = [
  { key: UnitTypeEnum.generic, label: 'Обычная' },
];
