import cn from 'classnames';
import * as React from 'react';
import { observer } from 'mobx-react';
import toast from 'cogo-toast';
import { useHistory } from 'react-router-dom';

import urls from 'config/urls';
import { useUserStore } from 'store';
import Title from 'components/common/Title';
import { InputField } from 'components/form';
import ProfileIcon from 'components/icons/ProfileIcon';
import LockIcon from 'components/icons/LockIcon';
import Button, { ButtonColor, ButtonSize } from 'components/common/Button';
import { useLocal } from 'utils/hooks/useLocal';
import useField from 'components/form/hooks/useField';

import { LoginForm } from './form';
import styles from './Login.modules.scss';

const Login: React.FC = () => {
  const userStore = useUserStore();

  const loginForm = useLocal(() => new LoginForm(userStore));

  const [email, setEmail, emailErrors] = useField(loginForm, 'email');
  const [password, setPassword, passwordErrors] = useField(
    loginForm,
    'password'
  );

  const onKeyPress = React.useCallback(
    async (event: React.KeyboardEvent): Promise<void> => {
      if (event.key === 'Enter') {
        await loginForm.save();
      }
    },
    [loginForm]
  );

  const history = useHistory();

  const onForgotPassword = React.useCallback(() => {
    history.push(urls.password.index);
  }, []);

  React.useEffect(() => {
    if (loginForm.isError) {
      toast.error('Проверьте правильность введенных данных');
    }
  }, [loginForm.isError]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <Title index={1} className={styles.form__title}>
          Войти в аккаунт
        </Title>
        <InputField
          formElementClassName={styles.form__row}
          errors={emailErrors}
          icon={<ProfileIcon className={styles['form__row-icon']} />}
          value={email}
          onChange={setEmail}
          type="email"
          placeholder="Email"
          noLabel
          onKeyPress={onKeyPress}
        />
        <InputField
          formElementClassName={styles.form__row}
          errors={passwordErrors}
          value={password}
          onChange={setPassword}
          icon={<LockIcon className={styles['form__row-icon']} />}
          type="password"
          placeholder="Пароль"
          noLabel
          onKeyPress={onKeyPress}
        />

        <Button
          className={cn(styles.form__row, styles.form__row_wide)}
          isLoading={loginForm.isSaving}
          onClick={loginForm.save}
        >
          Войти
        </Button>
        <Button
          className={styles.form__row}
          color={ButtonColor.transparent}
          size={ButtonSize.small}
          onClick={onForgotPassword}
        >
          Забыли пароль?
        </Button>
      </div>
    </div>
  );
};

export default observer(Login);
