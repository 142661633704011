import * as React from 'react';

import ContentLayout from 'components/common/ContentLayout';
import { DropdownField } from 'components/form';
import { PaymentsName, PaymentsType } from 'config/payments';
import { DropdownOption } from 'components/form/Dropdown';

import YandexPayment from './components/YandexPayment';
import Row from './components/Row';
import styles from './Payments.modules.scss';

const dropdownOptions: DropdownOption<PaymentsType>[] = [
  { key: PaymentsType.yandex, label: PaymentsName[PaymentsType.yandex] },
];

const PaymentComponents: Record<PaymentsType, React.ComponentType> = {
  [PaymentsType.yandex]: YandexPayment,
};

const Payments: React.FC = () => {
  const [selected, setSelected] = React.useState<React.ReactText | null>(null);

  const PaymentComponent = React.useMemo(
    () => selected && PaymentComponents[selected as PaymentsType],
    [selected]
  );

  return (
    <ContentLayout>
      <div className={styles.card}>
        <div className={styles.headContent}>
          <div className={styles.header}>Настройка платежной системы</div>
          <Row
            className={styles.headRow}
            leftContent={
              <DropdownField
                noLabel
                placeholder="Выберите ответ"
                label="Платежная система"
                options={dropdownOptions}
                selected={selected}
                onChange={setSelected}
              />
            }
            rightContent={
              <div className={styles.text}>
                Выберите платежную систему для подключения.
              </div>
            }
          />
        </div>
        {PaymentComponent && <PaymentComponent />}
      </div>
    </ContentLayout>
  );
};

export default Payments;
