import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from 'mobx';

import apiUrls from 'config/apiUrls';
import request from 'utils/request';

import { ChunkServerType, IChunkModel } from '../types';

enum MercuryTaskStatusEnum {
  notStarted = 'not_started',
  inProgress = 'in_progress',
  finished = 'finished',
}

type MercuryTaskChunkAdminServerType = {
  task_namespace: string;
};

type MercuryTaskChunkServerType = MercuryTaskChunkAdminServerType & {
  unit_id: number;
  status: MercuryTaskStatusEnum;
};

export class MercuryTaskChunkModel
  implements
    IChunkModel<MercuryTaskChunkServerType, MercuryTaskChunkAdminServerType>
{
  taskNamespace = '';
  status?: MercuryTaskStatusEnum;

  chunkId?: string;

  url?: string;
  password?: string;
  isLoadingUrl = false;

  constructor() {
    makeObservable(this, {
      taskNamespace: observable,
      status: observable,
      url: observable,
      password: observable,
      isLoadingUrl: observable,

      isPassed: computed,

      startTask: action.bound,
      setTaskNamespace: action.bound,
    });
  }

  get isPassed(): boolean {
    return this.status === MercuryTaskStatusEnum.finished;
  }

  setTaskNamespace = (v: string) => {
    this.taskNamespace = v;
  };

  async startTask() {
    this.isLoadingUrl = true;
    const { response } = await request<{ uri: string; password: string }>(
      apiUrls.chunks.startMercuryTask,
      'POST',
      {
        chunk_id: this.chunkId,
      }
    );

    runInAction(() => {
      if (response) {
        this.url = response.uri;
        this.password = response.password;
      }

      this.isLoadingUrl = false;
    });
  }

  toJson(): MercuryTaskChunkAdminServerType {
    return toJS({
      task_namespace: this.taskNamespace,
    });
  }

  static fromJson({
    id,
    data,
  }: ChunkServerType<MercuryTaskChunkServerType>): MercuryTaskChunkModel {
    const model = new MercuryTaskChunkModel();
    model.taskNamespace = data.task_namespace;
    model.chunkId = id;

    return model;
  }

  static createDefault(): MercuryTaskChunkModel {
    return new MercuryTaskChunkModel();
  }
}
