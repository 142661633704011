import * as React from 'react';
import cn from 'classnames';

import styles from './ProgressLine.modules.scss';

type Props = {
  className?: string;
  value: number;
};

const ProgressLine: React.FC<Props> = ({ className, value }: Props) => {
  return (
    <div className={cn(styles.progress, className)}>
      <div
        className={styles.progressCompleted}
        style={{ width: value * 100 + '%' }}
      />
    </div>
  );
};

export default ProgressLine;
