import * as React from 'react';
import cn from 'classnames';

import Spinner from 'components/common/Spinner';
import noop from 'utils/noop';

import styles from './Button.modules.scss';

export enum ButtonSize {
  small = 'small',
  normal = 'normal',
  big = 'big',
}

export enum ButtonColor {
  blue = 'blue',
  white = 'white',
  red = 'red',
  transparent = 'transparent',
}

export type ButtonProps = {
  children: React.ReactNode;
  size?: ButtonSize;
  color?: ButtonColor;
  className?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
};

const Button: React.FC<ButtonProps> = ({
  children,
  className,
  color,
  size,
  onClick,
  isDisabled,
  isLoading,
}: ButtonProps) => {
  const disabledOrLoading = isDisabled || isLoading;

  const rootClassName = cn(
    styles.wrapper,
    color === ButtonColor.blue && styles.blue,
    color === ButtonColor.white && styles.white,
    color === ButtonColor.red && styles.red,
    color === ButtonColor.transparent && styles.transparent,
    size === ButtonSize.normal && styles.normal,
    isDisabled && styles.disabled,
    isLoading && styles.loading,
    className
  );

  return (
    <button
      className={rootClassName}
      onClick={disabledOrLoading ? noop : onClick}
    >
      {isLoading && <Spinner className={styles.loader} />}
      <span className={styles.childrenWrapper}>{children}</span>
    </button>
  );
};

Button.defaultProps = {
  color: ButtonColor.blue,
  size: ButtonSize.normal,
  className: '',
  isDisabled: false,
  isLoading: false,
};

export default Button;
