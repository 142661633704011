import { action, computed, makeObservable, observable, toJS } from 'mobx';

import { ComposedValidator } from 'utils/validation';

export type FormFieldTypes = Record<string, any>;

export type FormValidators<T extends FormFieldTypes> = Partial<{
  [key in keyof T]: ComposedValidator<T[key]>;
}>;

export class Form<FT extends FormFieldTypes> {
  isSaving = false;

  errors: Partial<Record<keyof FT, string[]>> = {};

  initialValues: Partial<FT> = {};

  values: FT = {} as FT;

  validators: FormValidators<FT> = {} as FormValidators<FT>;

  constructor({
    initialValues,
    validators,
  }: {
    initialValues?: Partial<FT>;
    validators?: FormValidators<FT>;
  } = {}) {
    makeObservable(this, {
      //observable
      isSaving: observable,
      errors: observable,
      values: observable,
      //computed
      hasErrors: computed,
      //action
      setInitialFrom: action,
      clear: action.bound,
      setField: action,
      validate: action,
      save: action.bound,
    });

    if (initialValues) {
      this.initialValues = { ...initialValues };
    }

    if (validators) {
      this.validators = validators;
    }

    this.clear();
  }

  get hasErrors(): boolean {
    return (Object.values(this.errors || {}) as string[][]).some(
      (errors) => errors.length > 0
    );
  }

  getField(fieldName: keyof FT): FT[typeof fieldName] {
    return this.values[fieldName];
  }

  setInitialFrom(values: any): void {
    this.initialValues = Object.keys(values).reduce(
      (acc, key) => ({
        ...acc,
        [key]: toJS(values[key]),
      }),
      {}
    );
  }

  clear(values?: any): void {
    if (values) {
      this.setInitialFrom(values);
    }
    this.values = { ...this.initialValues } as FT;
  }

  setField(fieldName: keyof FT, value: FT[typeof fieldName]): void {
    this.values[fieldName] = value;
    this.errors[fieldName] = [];
  }

  validate(): void {
    this.errors = Object.keys(this.validators).reduce(
      (acc, nextKey) => ({
        ...acc,
        [nextKey]: this.validators[nextKey]?.(this.values[nextKey]),
      }),
      {}
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async save(...args: any[]): Promise<any> {
    throw new Error('not implemented!');
  }
}
