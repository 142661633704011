import * as React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { InputField } from 'components/form';
import Button from 'components/common/Button';
import { PaymentsType } from 'config/payments';
import { usePaymentsStore } from 'store/context';
import { isEmail } from 'utils/validation';
import { PaymentGroupStatus } from 'store/stores';
import urls from 'config/urls';

import styles from './PaymentForm.modules.scss';

interface Props {
  className?: string;
  courses: string[];
  amount: number;
  groupId: number;
  kind: PaymentsType;
}

const PaymentForm: React.FC<Props> = ({
  className,
  courses,
  amount,
  groupId,
  kind,
}: Props) => {
  const [email, setEmail] = React.useState('');
  const [disabled, setDisabled] = React.useState(true);
  const paymentsStore = usePaymentsStore();
  const history = useHistory();

  const handleOnClick = React.useCallback(async () => {
    const trimmedEmail = email.trim();
    const flag = Boolean(isEmail(trimmedEmail));

    setDisabled(flag);
    if (!flag) {
      const url = await paymentsStore.payGroup(trimmedEmail, groupId, kind);
      if (url) {
        if (paymentsStore.status == PaymentGroupStatus.success) {
          paymentsStore.groupUserId &&
            history.push(
              urls.payment.groupReturn.create(paymentsStore.groupUserId)
            );
          return;
        }
        window.location.href = url;
      }
    }
  }, [email, groupId, kind, paymentsStore.status, paymentsStore.groupUserId]);

  const handleOnChange = React.useCallback((value: string) => {
    setDisabled(false);
    setEmail(value);
  }, []);

  return (
    <div className={cn(styles.form, className)}>
      <div className={styles.header}>Оплата</div>
      <div className={styles.coursesContainer}>
        <div>
          <div className={styles.course}>Оплата доступ к курсам:</div>
          {courses.map((course) => (
            <div className={styles.course} key={course}>
              {course}
            </div>
          ))}
        </div>
        <div className={styles.courseCost}>{amount} ₽</div>
      </div>
      <div className={styles.info}>
        Оставьте электронную почту, чтобы мы выслали приглашение в&nbsp;сервис.
      </div>
      <InputField
        onChange={handleOnChange}
        value={email}
        noLabel
        label="Email"
        formElementClassName={styles.input}
        type="email"
        placeholder="Введите электронную почту"
      />
      <Button
        className={styles.button}
        onClick={handleOnClick}
        isDisabled={disabled}
        isLoading={paymentsStore.isLoading}
      >
        Перейти к оплате
      </Button>
    </div>
  );
};

export default observer(PaymentForm);
