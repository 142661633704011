import day from 'dayjs';
import { saveAs } from 'file-saver';
import * as React from 'react';
import { useCallback } from 'react';

import FileIcon from 'components/icons/FileIcon';
import { HomeworkMessageModel } from 'store/models/chunks/homework/chat/HomeworkMessageModel';
import { HomeworkMessageTagEnum } from 'store/models/chunks/homework/types';

import styles from './Message.modules.scss';
import SystemMessage from './SystemMessage';

type Props = {
  message: HomeworkMessageModel;
};

const Message: React.FC<Props> = ({ message }: Props) => {
  const saveAttachment = useCallback(() => {
    if (message.attachment) {
      saveAs(message.attachment.url, message.attachment.originalFileName);
    }
  }, [message.attachment?.url]);

  const date = day(message.sent);
  const isToday = date.isAfter(day(new Date()).startOf('day'));

  if (message.tag === HomeworkMessageTagEnum.statusChanged) {
    return <SystemMessage message={message} />;
  }

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.username}>
          {message.firstName} {message.lastName}
        </div>
        <div className={styles.date}>
          {isToday ? 'Сегодня' : date.format('DD.MM.YY')} в{' '}
          {date.format('HH:mm')}
        </div>
      </div>
      <div className={styles.text}>{message.message}</div>
      {message.attachment && (
        <div className={styles.attach} onClick={saveAttachment}>
          <div className={styles.attachIcon}>
            <FileIcon />
          </div>
          <div className={styles.fileName}>
            {message.attachment.originalFileName}
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(Message);
