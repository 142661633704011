import { action, makeObservable, observable, toJS } from 'mobx';

import { IChunkModel, ChunkServerType } from 'store/models/chunks/types';

import { ChunkTextServerType } from './types';

export class TextChunkModel implements IChunkModel {
  title: string;
  subtitle: string;

  constructor({ title, subtitle }: { title: string; subtitle: string }) {
    makeObservable(this, {
      // observable
      title: observable,
      subtitle: observable,
      // action
      setTitle: action,
    });
    this.title = title;
    this.subtitle = subtitle;
  }

  setTitle(v: string): void {
    this.title = v;
  }

  toJson(): object {
    return toJS({
      title: this.title,
      subtitle: this.subtitle,
    });
  }

  static createDefault(): TextChunkModel {
    return new TextChunkModel({
      title: '',
      subtitle: '',
    });
  }

  static fromJson(json: ChunkServerType<ChunkTextServerType>): TextChunkModel {
    const { data } = json;

    return new TextChunkModel({
      title: data.title,
      subtitle: data.subtitle,
    });
  }
}
