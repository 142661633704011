import { action, makeObservable, observable, toJS } from 'mobx';

import { ChunkServerType, IChunkModel } from '../types';

export enum CodeChunkLanguageEnum {
  python = 'python',
  javascript = 'javascript',
}

type CodeChunkServerType = {
  code: string;
  language: CodeChunkLanguageEnum;
};

export class CodeChunkModel implements IChunkModel<CodeChunkServerType> {
  code = '';
  language = CodeChunkLanguageEnum.python;

  constructor() {
    makeObservable(this, {
      code: observable,
      language: observable,

      setCode: action.bound,
      setLanguage: action.bound,
    });
  }

  setCode(v: string) {
    this.code = v;
  }

  setLanguage(v: CodeChunkLanguageEnum) {
    this.language = v;
  }

  toJson(): CodeChunkServerType {
    return toJS({
      code: this.code,
      language: this.language,
    });
  }

  static fromJson({
    data,
  }: ChunkServerType<CodeChunkServerType>): CodeChunkModel {
    const model = new CodeChunkModel();
    model.code = data.code;
    model.language = data.language;

    return model;
  }

  static createDefault(): CodeChunkModel {
    return new CodeChunkModel();
  }
}
