import { action, computed, makeObservable, observable, toJS } from 'mobx';
import uuid4 from 'uuid4';

import { UnitModel } from 'store/models/unit/UnitModel';

import { ChunkLayoutModel } from './ChunkLayoutModel';
import {
  ChunkDataModelType,
  ChunkServerType,
  ChunkTypeEnum,
  ChunkTypeToDataModel,
  ComplexChunkEnum,
  IChunkModel,
} from './types';

interface ChunkType<D extends IChunkModel = ChunkDataModelType> {
  unit: UnitModel;
  id: string;
  unitId: number;
  type: ChunkTypeEnum;
  data: D;
  layout: ChunkLayoutModel;
}

export class ChunkModel<D extends IChunkModel = ChunkDataModelType>
  implements ChunkType
{
  unit: UnitModel;
  id: string;
  unitId: number;
  type: ChunkTypeEnum;
  data: D;
  layout: ChunkLayoutModel;

  errors: string[] = [];

  // draftjs
  width = 100;
  alignment = 'center';

  constructor({ data, layout, unit, id, unitId, type }: ChunkType) {
    makeObservable(this, {
      // observable
      errors: observable,
      // computed
      hasErrors: computed,
      // action
      setError: action,
      validate: action,
    });
    this.unit = unit;
    this.unitId = unitId;
    this.id = id;
    this.type = type;
    this.layout = layout;
    this.data = data;
  }

  get hasErrors(): boolean {
    return this.errors.length > 0;
  }

  setError(errors: string[] = []): void {
    this.errors = errors;
  }

  validate(): void {
    this.errors = this.data.validate?.() || [];
  }

  afterSave(data: ChunkServerType): void {
    this.data.afterSave?.(data.data);
  }

  toJson(): any[] {
    const json = toJS({
      id: this.id,
      type: this.type,
      ...this.layout?.toJson(),
      data: this.data?.toJson() || {},
    });

    return [json];
  }

  static createDefault<T extends IChunkModel>(
    unit: UnitModel,
    type: ChunkTypeEnum | ComplexChunkEnum,
    parentChunkData?: ChunkDataModelType
  ): ChunkModel<T> {
    const chunk = new ChunkModel({
      unit,
      unitId: unit.id,
      id: uuid4(),
      type: type as ChunkTypeEnum,
      data: {},
      layout: {} as ChunkLayoutModel,
    });
    chunk.layout = ChunkLayoutModel.createDefault({ chunk });

    if (type === ChunkTypeEnum.image) {
      chunk.width = 50;
    }

    const DataModelCls = ChunkTypeToDataModel[type as ChunkTypeEnum];

    if (DataModelCls) {
      chunk.data = DataModelCls.createDefault(unit, chunk, parentChunkData);
    }

    return chunk;
  }

  static fromJson(
    json: ChunkServerType,
    unit: UnitModel,
    parentChunkDataModel?: ChunkDataModelType
  ): ChunkModel {
    const chunk = new ChunkModel({
      unit,
      id: json.id,
      type: json.type,
      unitId: json.unit_id,
      data: {},
      layout: {} as ChunkLayoutModel,
    });

    chunk.layout = ChunkLayoutModel.fromJson(json, chunk);

    const DataModelCls = ChunkTypeToDataModel[chunk.type];
    if (DataModelCls) {
      chunk.data = DataModelCls.fromJson(json, chunk, parentChunkDataModel);
    }

    return chunk;
  }
}
