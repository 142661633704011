import { observer } from 'mobx-react';
import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import urls from 'config/urls';
import { useUserStore } from 'store/context';

import NewPassword from './NewPassword';
import ResetPassword from './ResetPassword';
import styles from './Password.modules.scss';

const PasswordRoute: React.FC = () => {
  const userStore = useUserStore();

  if (userStore.isAuthorized) {
    return <Redirect to={urls.profile} />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <Switch>
          <Route path={urls.password.pattern} component={NewPassword} />
          <Route path={urls.password.index} component={ResetPassword} />
        </Switch>
      </div>
    </div>
  );
};

export default observer(PasswordRoute);
