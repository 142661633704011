import * as React from 'react';
import * as propTypes from 'prop-types';
import cn from 'classnames';

import { LessonTypeEnum as types } from 'store/models/lesson/types';
import range from 'utils/range';

import styles from './ProgressLine.modules.scss';

const ProgressLine = (props: any) => {
  const { currentIndex, total, onClickElement, type } = props;

  const arrIndex = currentIndex - 1;

  const indent = styles.indent.replace(/[^\d]+/, '');
  const countIndents = total - 1;

  return (
    <div className={styles.root}>
      {range(total).map((index) => {
        const isActive = index <= arrIndex;

        return (
          <div
            key={index}
            className={styles.tab}
            style={{
              width: `calc(${100 / total}% - ${
                (indent * countIndents) / total
              }px)`,
            }}
            onClick={() => onClickElement(index)}
          >
            <div
              className={cn(
                styles.tabBody,
                isActive && type === types.theory && styles.tabBodyTheoryActive,
                isActive && type === types.intro && styles.tabBodyIntroActive,
                isActive && type === types.test && styles.tabBodyTestActive,
                isActive && type === types.video && styles.tabBodyVideoActive,
                isActive &&
                  type === types.practice &&
                  styles.tabBodyPracticeActive,
                isActive && type === types.final && styles.tabBodyFinalActive
              )}
            />
          </div>
        );
      })}
    </div>
  );
};

ProgressLine.propTypes = {
  currentIndex: propTypes.number.isRequired,
  onClickElement: propTypes.func,
  total: propTypes.number.isRequired,
  type: propTypes.string.isRequired,
};

export default ProgressLine;
