import * as React from 'react';
import * as propTypes from 'prop-types';

const PlusIcon = (props) => {
  const { className } = props;

  return (
    <svg
      width="27px"
      height="27px"
      viewBox="0 0 27 27"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-1090.000000, -1320.000000)"
          fill="currentColor"
        >
          <g transform="translate(801.000000, 1208.000000)">
            <g transform="translate(272.000000, 95.000000)">
              <path d="M41.9375,28.59375 L32.40625,28.59375 L32.40625,19.0625 C32.40625,18.01025 31.55225,17.15625 30.5,17.15625 C29.44775,17.15625 28.59375,18.01025 28.59375,19.0625 L28.59375,28.59375 L19.0625,28.59375 C18.01025,28.59375 17.15625,29.44775 17.15625,30.5 C17.15625,31.55225 18.01025,32.40625 19.0625,32.40625 L28.59375,32.40625 L28.59375,41.9375 C28.59375,42.98975 29.44775,43.84375 30.5,43.84375 C31.55225,43.84375 32.40625,42.98975 32.40625,41.9375 L32.40625,32.40625 L41.9375,32.40625 C42.98975,32.40625 43.84375,31.55225 43.84375,30.5 C43.84375,29.44775 42.98975,28.59375 41.9375,28.59375 Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

PlusIcon.propTypes = {
  className: propTypes.string.isRequired,
};

PlusIcon.defaultProps = {};

export default PlusIcon;
