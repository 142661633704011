import * as React from 'react';
import cn from 'classnames';

import styles from './ContentLayout.modules.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const ContentLayout: React.FC<Props> = ({ children, className }: Props) => {
  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

ContentLayout.defaultProps = {
  className: styles.defaultWrapper,
};

export default React.memo(ContentLayout);
