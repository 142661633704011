import * as React from 'react';
import cn from 'classnames';

import CircleButton from '../CircleButton';
import { CircleButtonTypeEnum } from '../CircleButton/config/types';

import styles from './EditControl.modules.scss';

type Props = {
  isSelected: boolean;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
};

const EditControl: React.FC<Props> = (props: Props) => {
  const { isSelected, onClick } = props;

  return (
    <CircleButton
      type={CircleButtonTypeEnum.edit}
      className={cn(styles.root, isSelected && styles.rootSelected)}
      onClick={onClick}
    />
  );
};

EditControl.defaultProps = {};

export default EditControl;
