import createAlignmentPlugin from '@draft-js-plugins/alignment';
import '@draft-js-plugins/alignment/lib/plugin.css';
import createLinkPlugin from '@draft-js-plugins/anchor';
import '@draft-js-plugins/anchor/lib/plugin.css';
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';
import createDragNDropUploadPlugin from '@draft-js-plugins/drag-n-drop-upload';
import { composeDecorators } from '@draft-js-plugins/editor';
import createFocusPlugin from '@draft-js-plugins/focus';
import '@draft-js-plugins/focus/lib/plugin.css';
import createImagePlugin from '@draft-js-plugins/image';
import '@draft-js-plugins/image/lib/plugin.css';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import createResizablePlugin from '@draft-js-plugins/resizeable';
import '@draft-js-plugins/side-toolbar/lib/plugin.css';
import { DraftHandleValue, EditorState, RichUtils } from 'draft-js';
// @ts-ignore
import isSoftNewlineEvent from 'draft-js/lib/isSoftNewlineEvent';
import * as Immutable from 'immutable';

import { createChunkPlugin } from '../Chunks/DraftJsAdapter/createChunkPlugin';
import { DRAFTJS_BLOCK } from '../config';

import createSidebar from './Sidebar';
import alignmentToolStyles from './styles/AlignmentToolStyles.modules.scss';
import linkStyles from './styles/LinkTheme.modules.scss';
import buttonStyles from './styles/ToolbarButtonStyles.modules.scss';
import toolbarStyles from './styles/ToolbarStyles.modules.scss';

const alignmentPlugin = createAlignmentPlugin({
  theme: {
    buttonStyles,
    alignmentToolStyles,
  },
});
const resizeablePlugin = createResizablePlugin();
const focusPlugin = createFocusPlugin();
const blockDndPlugin = createBlockDndPlugin();

const imagePlugin = createImagePlugin({
  decorator: composeDecorators(
    resizeablePlugin.decorator,
    alignmentPlugin.decorator,
    focusPlugin.decorator,
    blockDndPlugin.decorator
  ),
});
const dragNDropFileUploadPlugin = createDragNDropUploadPlugin({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleUpload: (): any => '',
  // @ts-ignore
  addImage: imagePlugin.addImage,
});

const chunkPlugin = createChunkPlugin({
  resizeablePlugin,
  alignmentPlugin,
  focusPlugin,
});

const sidebarPlugin = createSidebar();
const inlineToolbarPlugin = createInlineToolbarPlugin({
  theme: {
    buttonStyles,
    toolbarStyles,
  },
});

export const linkPlugin = createLinkPlugin({
  theme: linkStyles,
  placeholder: 'https://...',
  linkTarget: '_blank',
});

export const { AlignmentTool } = alignmentPlugin;
export const { InlineToolbar } = inlineToolbarPlugin;
export const { SideToolbar } = sidebarPlugin;

export const plugins = [
  dragNDropFileUploadPlugin,
  blockDndPlugin,

  focusPlugin,
  alignmentPlugin,
  resizeablePlugin,
  inlineToolbarPlugin,
  sidebarPlugin,
  linkPlugin,

  imagePlugin,
  chunkPlugin,
];

export const blockRendererMap = Immutable.Map({
  // atomic: {
  //   element: 'div',
  // },
});

export function handleKeyCommand(
  command: any,
  editorState: EditorState,
  onChange: (s: EditorState) => void
) {
  // inline formatting key commands handles bold, italic, code, underline
  const updatedEditorState = RichUtils.handleKeyCommand(editorState, command);

  if (updatedEditorState) {
    onChange(updatedEditorState);
    return 'handled';
  }

  return 'not-handled';
}

export const handleReturn = (
  evt: any,
  editorState: EditorState,
  onChange: (s: EditorState) => void
): DraftHandleValue => {
  const blockType = RichUtils.getCurrentBlockType(editorState);
  if (
    [
      DRAFTJS_BLOCK.codeblock,
      DRAFTJS_BLOCK.blockquote,
      DRAFTJS_BLOCK.unstyled,
    ].indexOf(blockType as any) === -1 ||
    !isSoftNewlineEvent(evt)
  ) {
    return 'not-handled';
  }
  const newState = RichUtils.insertSoftNewline(editorState);
  onChange(newState);
  return 'handled';
};
