import * as React from 'react';
import cn from 'classnames';

import noop from 'utils/noop';

import styles from './FormElement.modules.scss';

type Props = {
  children: React.ReactNode;
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLDivElement>) => void;
  className?: string;
};

const FormElement: React.FC<Props> = ({
  onBlur,
  onFocus,
  children,
  className,
}: Props) => {
  return (
    <div
      onFocus={onFocus}
      onBlur={onBlur}
      className={cn(styles.element, className)}
    >
      {children}
    </div>
  );
};

FormElement.defaultProps = {
  onFocus: noop,
  onBlur: noop,
};

export default React.memo(FormElement);
