import CourseModel from './CourseModel';

export class CoursePreview {
  id: number;
  title: string;

  constructor(id: number, title: string) {
    this.id = id;
    this.title = title;
  }

  static fromCourseModel(model: CourseModel): CoursePreview | null {
    if (!model.id) {
      return null;
    }
    return new CoursePreview(model.id, model.title);
  }
}
