import { observer } from 'mobx-react';
import * as React from 'react';

import CodeInput from 'components/common/CodeInput/SimpleCodeInput';
import {
  CodeChunkLanguageEnum,
  CodeChunkModel,
} from 'store/models/chunks/code/CodeChunkModel';

import { Dropdown } from '../../../form';
import { DropdownOption } from '../../../form/Dropdown';
import { WithChunk } from '../types';

import styles from './CodeChunk.modules.scss';

type Props = WithChunk<CodeChunkModel>;

const LANGUAGE_OPTIONS: DropdownOption<CodeChunkLanguageEnum>[] = [
  { key: CodeChunkLanguageEnum.python, label: 'Python' },
  { key: CodeChunkLanguageEnum.javascript, label: 'Javascript' },
];

const CodeChunk: React.FC<Props> = ({
  chunk: { data, id },
  isEditMode,
}: Props) => {
  return (
    <div id={id}>
      {isEditMode && (
        <div className={styles.selectorWrapper}>
          <Dropdown
            selected={data.language}
            onChange={data.setLanguage}
            options={LANGUAGE_OPTIONS}
          />
        </div>
      )}
      <CodeInput
        readOnly={!isEditMode}
        language={data.language}
        code={data.code}
        onChange={data.setCode}
      />
    </div>
  );
};

export default observer(CodeChunk);
