import { makeObservable, observable, override } from 'mobx';

import apiUrls from 'config/apiUrls';
import { Form } from 'store/models/common/Form';
import { ServerResponse } from 'store/models/common/types';
import request from 'utils/request';
import {
  composeValidators,
  greaterThanValidator,
  notEmptyValidator,
} from 'utils/validation';

import { EXPIRED_TOKEN_STATUS } from './types';

export enum InviteFormFields {
  firstName = 'firstName',
  lastName = 'lastName',
  password = 'password',
  passwordRepeat = 'passwordRepeat',
}

type InviteFormType = {
  firstName: string;
  lastName: string;
  password: string;
  passwordRepeat: string;
};

export class InviteForm extends Form<InviteFormType> {
  initialValues: InviteFormType = {
    firstName: '',
    lastName: '',
    password: '',
    passwordRepeat: '',
  };

  validators = {
    firstName: composeValidators(notEmptyValidator()),
    lastName: composeValidators(notEmptyValidator()),
    password: composeValidators(
      notEmptyValidator(),
      greaterThanValidator({
        min: 8,
        errorMessage: 'Пароль должен быть длиннее 8 символов',
      })
    ),
  };

  isExpiredToken = false;

  constructor() {
    super();
    makeObservable(this, {
      isExpiredToken: observable,
      save: override,
    });
  }

  async save(token: string, needValidate: boolean): Promise<any> {
    if (needValidate) {
      this.validate();
      if (this.hasErrors) {
        return;
      }
    }

    if (this.values.password !== this.values.passwordRepeat) {
      this.errors.passwordRepeat = ['Пароли не совпадают'];
      return;
    }

    this.isSaving = true;

    const { response, error }: ServerResponse = await request(
      apiUrls.company.invitation.accept,
      'POST',
      {
        first_name: this.values.firstName,
        last_name: this.values.lastName,
        password: this.values.password,
        token,
      }
    );

    this.isSaving = false;

    if (error?.data?.status === EXPIRED_TOKEN_STATUS) {
      this.isExpiredToken = true;
    }

    return Boolean(response);
  }
}
