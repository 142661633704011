import * as React from 'react';

import TrashIcon from '../../../icons/TrashIcon';
import Button, { ButtonColor, ButtonProps } from '../index';

import styles from './ButtonDelete.modules.scss';

type Props = ButtonProps;

const ButtonDelete: React.FC<Props> = ({ children, ...props }: Props) => {
  return (
    <Button {...props} color={ButtonColor.red}>
      <TrashIcon className={styles.icon} />
      {children}
    </Button>
  );
};

export default React.memo(ButtonDelete);
