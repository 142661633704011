import * as React from 'react';

import './Title.modules.scss';

type Props = {
  index: number;
  className?: string;
  styleName?: string;
  children: React.ReactNode;
};

const Title: React.FC<Props> = ({ index, className, children }: Props) => {
  const Tag = `h${index}` as React.ElementType;
  return <Tag className={className}>{children}</Tag>;
};

Title.propTypes = {};

Title.defaultProps = {
  children: null,
};

export default Title;
