import * as React from 'react';

import ProfileContent from './components/ProfileContent';
import Header from './components/Header';
import Stats from './components/Stats';

const Profile: React.FC = () => {
  return (
    <ProfileContent>
      <Header />
      <Stats />
    </ProfileContent>
  );
};

export default Profile;
