import { makeObservable, observable } from 'mobx';

import { CourseInnerServerType } from 'store/models/course/types';

export type CourseInnerType = {
  maxAvailablePoints: number;
  chaptersCount: number;
  countCompleteLessons: number;
  points: number;
  unitCount: number;
  pointPerUnit: number;
  pointPerTest: number;
  lessonsCount: number;
};

class CourseInnerModel implements CourseInnerType {
  maxAvailablePoints: number;
  chaptersCount: number;
  countCompleteLessons: number;
  points: number;
  unitCount: number;
  pointPerUnit: number;
  pointPerTest: number;
  lessonsCount: number;

  constructor({
    maxAvailablePoints,
    chaptersCount,
    countCompleteLessons,
    points,
    unitCount,
    pointPerUnit,
    pointPerTest,
    lessonsCount,
  }: CourseInnerType) {
    makeObservable(this, {
      pointPerTest: observable,
      pointPerUnit: observable,
    });
    this.maxAvailablePoints = maxAvailablePoints;
    this.chaptersCount = chaptersCount;
    this.countCompleteLessons = countCompleteLessons;
    this.points = points;
    this.unitCount = unitCount;
    this.pointPerUnit = pointPerUnit;
    this.pointPerTest = pointPerTest;
    this.lessonsCount = lessonsCount;
  }

  static fromJson(json: CourseInnerServerType): CourseInnerModel {
    return new CourseInnerModel({
      maxAvailablePoints: json.max_available_points,
      chaptersCount: json.chapters_count,
      countCompleteLessons: json.count_complete_lessons,
      points: json.points,
      unitCount: json.unit_count,
      pointPerUnit: json.point_per_unit,
      pointPerTest: json.point_per_test,
      lessonsCount: json.lessons_count,
    });
  }

  static createDefault(): CourseInnerModel {
    return new CourseInnerModel({
      maxAvailablePoints: 0,
      chaptersCount: 0,
      countCompleteLessons: 0,
      points: 0,
      unitCount: 0,
      pointPerUnit: 0,
      pointPerTest: 0,
      lessonsCount: 0,
    });
  }
}

export default CourseInnerModel;
