import * as React from 'react';
import { useCallback, useState } from 'react';

export type UseFocusHook = [boolean, (e: React.FocusEvent) => void];

export default function useFocus(): UseFocusHook {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = useCallback(() => {
    setIsFocused((s) => !s);
  }, []);

  return [isFocused, handleFocus];
}
