import * as React from 'react';
import { isMobile } from 'react-device-detect';

import DoneCircleIcon from 'components/icons/DoneCirleIcon';
import ButtonArrow from 'components/common/Button/ButtonArrow';
import { ArrowDirectionEnum } from 'components/common/Button/ButtonArrow/ButtonArrow';
import urls from 'config/urls';
import { LessonTypeEnum } from 'store/models/lesson/types';
import { UnitModel } from 'store/models/unit/UnitModel';

import Navigation from './Navigation';
import Badge from './Badge';
import ProgressLine from './ProgressLine';
import styles from './Header.modules.scss';

type Props = {
  courseId: number;
  type: LessonTypeEnum;
  currentIndex?: number;
  total?: number;
  onGoToCard?: (index: number) => void;
  onPrevClick?: () => void;
  onNextClick?: () => void;
  backText?: string;
  backLink?: string;
  unit?: UnitModel;
};

const Header: React.FC<Props> = (props: Props) => {
  const {
    onPrevClick,
    onNextClick,
    courseId,
    currentIndex,
    total,
    type,
    onGoToCard,
    backText,
    backLink,
    unit,
  } = props;

  return (
    <div className={styles.root}>
      {currentIndex !== undefined && total !== undefined && (
        <Navigation
          className={styles.pager}
          value={currentIndex}
          totalValue={total}
          onPrevClick={onPrevClick}
          onNextClick={onNextClick}
        />
      )}

      <div className={styles.badge}>
        {unit?.finished && <DoneCircleIcon className={styles.doneIcon} />}
        <Badge type={type} />
      </div>

      {currentIndex !== undefined && total !== undefined && (
        <ProgressLine
          currentIndex={currentIndex}
          total={total}
          type={type}
          onClickElement={onGoToCard}
        />
      )}

      <ButtonArrow
        to={backLink || urls.courseId(courseId)}
        className={styles.backButton}
        direction={ArrowDirectionEnum.left}
      >
        {!isMobile && backText}
      </ButtonArrow>
    </div>
  );
};

Header.defaultProps = {
  backText: 'Вернуться к курсу',
};

export default React.memo(Header);
