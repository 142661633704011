import { makeObservable, observable, override, runInAction } from 'mobx';

import apiUrls from 'config/apiUrls';
import { Form } from 'store/models/common/Form';
import request from 'utils/request';
import {
  composeValidators,
  greaterThanValidator,
  notEmptyValidator,
} from 'utils/validation';

import type { SideApiStore } from '../../store/SideApiStore';
import { TokenModel } from '../../store/TokenModel';
import { SideApiTokenType } from '../../store/types';

type Fields = {
  name: string;
};

export class TokenCreationForm extends Form<Fields> {
  initialValues = {
    name: '',
  };

  validators = {
    name: composeValidators(
      notEmptyValidator(),
      greaterThanValidator({
        min: 5,
        errorMessage: 'Название должно быть длиннее 5 символов',
      })
    ),
  };

  token: TokenModel | null = null;

  sideApiStore: SideApiStore;

  isError = false;

  constructor(sideApiStore: SideApiStore) {
    super();
    makeObservable(this, {
      //observable
      isError: observable,
      //override
      save: override,
    });
    this.sideApiStore = sideApiStore;
  }

  async save(companyId: number): Promise<boolean> {
    this.isError = false;

    this.validate();
    if (this.hasErrors) {
      return false;
    }
    this.isSaving = true;

    const { response } = await request<SideApiTokenType>(
      apiUrls.integrationTokens.createToken,
      'POST',
      {
        company: companyId,
        name: this.values.name,
      }
    );

    runInAction(() => {
      this.isSaving = false;

      if (!response) {
        this.isError = true;
      } else {
        this.token = new TokenModel(this.sideApiStore, response);
        this.sideApiStore.addToken(this.token);
      }
    });

    return Boolean(response);
  }
}
