export enum CircleButtonTypeEnum {
  arrowTop = 'arrowTop',
  arrowBottom = 'arrowBottom',
  arrowLeft = 'arrowLeft',
  arrowRight = 'arrowRight',
  plus = 'plus',
  close = 'close',
  edit = 'edit',
  trash = 'trash',
  save = 'save',
}
