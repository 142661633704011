import * as propTypes from 'prop-types';
import * as React from 'react';

const EditIcon = (props) => {
  const { className } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="50px"
      height="50px"
      className={className}
    >
      <path
        fill="currentColor"
        d="M 18.414062 2 C 18.158188 2 17.902031 2.0974687 17.707031 2.2929688 L 16 4 L 20 8 L 21.707031 6.2929688 C 22.098031 5.9019687 22.098031 5.2689063 21.707031 4.8789062 L 19.121094 2.2929688 C 18.925594 2.0974687 18.669937 2 18.414062 2 z M 14.5 5.5 L 3 17 L 3 21 L 7 21 L 18.5 9.5 L 14.5 5.5 z"
      />
    </svg>
  );
};

EditIcon.propTypes = {
  className: propTypes.string.isRequired,
};

EditIcon.defaultProps = {};

export default EditIcon;
