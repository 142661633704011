import { useDrag, useDrop } from 'react-dnd';

type DraggableChunk = {
  type: 'unit';
  chunkId: string;
};

export const useDraggableChunk = (
  chunkId: string,
  onDrop: (chunkId1: string, chunkId2: string) => void,
  enabled = true
): [
  (ref: any) => void,
  (ref: any) => void,
  {
    isDragging: boolean;
    isOver: boolean;
    canDrop: boolean;
  }
] => {
  const [{ isDragging }, drag] = useDrag({
    canDrag: () => enabled,
    item: { type: 'unit', chunkId },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'unit',
    canDrop: (props: DraggableChunk) => props.chunkId !== chunkId,
    drop: (item: DraggableChunk) => onDrop(chunkId, item.chunkId),
    collect: (mon) => ({
      isOver: !!mon.isOver() && mon.canDrop(),
      canDrop: !!mon.canDrop(),
    }),
  });

  return [drag, drop, { isDragging, isOver, canDrop }];
};
