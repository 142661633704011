import * as React from 'react';

import NavigationPath from 'components/common/NavigationPath';
import urls from 'config/urls';
import { LessonModel } from 'store/models/lesson/LessonModel';

type Props = {
  lesson: LessonModel;
};

const Navigation: React.FC<Props> = ({ lesson }: Props) => {
  const pathItems = [
    {
      text: 'Курсы',
      url: urls.courses,
    },
    {
      text: lesson.courseName,
      url: urls.courseId(lesson.courseId),
    },
    {
      text: lesson.chapterName,
      url: urls.courseId(lesson.courseId),
    },
  ];

  return <NavigationPath pathItems={pathItems} />;
};

export default React.memo(Navigation);
