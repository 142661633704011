import * as React from 'react';
import cn from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import { TextareaHTMLAttributes, useCallback, useState } from 'react';

import styles from './SimpleTextEditor.modules.scss';

type Props = {
  text: string;
  isEditMode: boolean;
  onChange(text: string): void;
  className?: string;
} & Pick<TextareaHTMLAttributes<any>, 'onFocus' | 'onBlur'>;

const TextAreaEditor: React.FC<Props> = ({
  onChange,
  isEditMode,
  text,
  className,
  onFocus,
  onBlur,
}: Props) => {
  const [focused, setFocused] = useState(false);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  const classes = cn(
    className,
    styles.textarea,
    focused && styles.focused,
    !focused && text === '' && styles.empty
  );

  return (
    <TextareaAutosize
      className={classes}
      value={text}
      disabled={!isEditMode}
      onChange={handleChange}
      placeholder="Введите текст"
      onFocus={(e) => {
        setFocused(true);
        onFocus?.(e);
      }}
      onBlur={(e) => {
        setFocused(false);
        onBlur?.(e);
      }}
    />
  );
};

TextAreaEditor.defaultProps = {
  isEditMode: false,
};

export default React.memo(TextAreaEditor);
