import * as React from 'react';
import * as propTypes from 'prop-types';

const LockIcon = (props) => {
  const { className } = props;

  return (
    <svg
      width="14px"
      height="18px"
      viewBox="0 0 14 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-10.000000, -7.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <g transform="translate(10.000000, 7.000000)">
            <path d="M13.006843,7.26914756 L12.6519275,7.26914756 L12.6519275,5.62762336 C12.6519275,2.59149883 10.2138111,0.0552500036 7.1664555,0.00110943782 C7.08319033,-0.000369812608 6.91680967,-0.000369812608 6.83358193,0.00110943782 C3.78615152,0.0552500036 1.34807247,2.59149883 1.34807247,5.62762336 L1.34807247,7.26914756 L0.993119597,7.26914756 C0.446151625,7.26914756 0,7.82689894 0,8.51574888 L0,16.7489609 C0,17.4370343 0.446151625,18 0.99315702,18 L13.006843,18 C13.553811,18 14,17.4370343 14,16.7489609 L14,8.51574888 C14,7.82693592 13.5538484,7.26914756 13.006843,7.26914756 Z M8.12552057,12.6239971 L8.12552057,15.1117635 C8.12552057,15.3966302 7.88418256,15.637711 7.59569213,15.637711 L6.40438272,15.637711 C6.11585486,15.637711 5.87451685,15.3966302 5.87451685,15.1117635 L5.87451685,12.6239971 C5.59470845,12.3518151 5.4318081,11.9754568 5.4318081,11.5591217 C5.4318081,10.7701635 6.04894335,10.092223 6.83361935,10.0608999 C6.91677225,10.0575716 7.08334002,10.0575716 7.16649292,10.0608999 C7.95116892,10.092223 8.56830417,10.7701635 8.56830417,11.5591217 C8.56822933,11.9754568 8.40532897,12.3518151 8.12552057,12.6239971 Z M10.3126814,7.26914756 L7.1664555,7.26914756 L6.83358193,7.26914756 L3.68739341,7.26914756 L3.68739341,5.62762336 C3.68739341,3.8190548 5.17471519,2.32364356 7,2.32364356 C8.82528481,2.32364356 10.3126814,3.8190548 10.3126814,5.62762336 L10.3126814,7.26914756 Z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

LockIcon.propTypes = {
  className: propTypes.string,
};

export default LockIcon;
