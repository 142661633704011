import { ShiftPositionEnum } from 'store/models/common/types';

export function getSwapIndexes<T extends { id?: number }>(
  direction: ShiftPositionEnum,
  items: T[],
  itemToSwap: T
): [number, number] | null {
  const index = items.findIndex((c) => itemToSwap.id === c.id);
  const indexToSwap =
    direction === ShiftPositionEnum.up ? index - 1 : index + 1;
  if (
    items.length < 2 ||
    (direction === ShiftPositionEnum.up && index < 1) ||
    (direction === ShiftPositionEnum.down && index >= items.length - 1)
  ) {
    return null;
  }

  return [index, indexToSwap];
}
