import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

import urls from 'config/urls';
import { HomeworkListModel } from 'store/models/chunks/homework/HomeworkListModel';
import Modal from 'components/common/Modal';

import styles from './ConversationsList.modules.scss';

type Props = {
  courseId: number;
  lessonId: number;
  unitId: number;
  chunkId: string;
  isOpen: boolean;
  onClose(): void;
};

const ConversationsList: React.FC<Props> = ({
  courseId,
  lessonId,
  unitId,
  chunkId,
  isOpen,
  onClose,
}: Props) => {
  const [homeworkList] = useState(() => new HomeworkListModel({ courseId }));

  useEffect(() => {
    if (isOpen) {
      homeworkList.load();
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isLoading={homeworkList.isLoading}>
      {homeworkList.items.map((item: any) => (
        <Link
          className={styles.item}
          key={item.conversation_id}
          target="_blank"
          to={urls.lessonHomeworkChat(
            lessonId,
            unitId,
            chunkId,
            item.conversation_id
          )}
        >
          Диалог №{item.conversation_id}
        </Link>
      ))}
    </Modal>
  );
};

export default observer(ConversationsList);
