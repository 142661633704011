import { action, makeObservable, observable, toJS } from 'mobx';

import { ChunkServerType, IChunkModel } from 'store/models/chunks/types';

export enum EmbedType {
  youtube = 'youtube',
}

type EmbedChunkType = {
  type: EmbedType;
  url: string;
};

export class EmbedChunkModel implements IChunkModel, EmbedChunkType {
  type: EmbedType;
  url: string;

  constructor({ type, url }: EmbedChunkType) {
    makeObservable(this, {
      // observable
      type: observable,
      url: observable,
      // action
      setUrl: action.bound,
    });
    this.type = type;
    this.url = url;
  }

  setUrl(u: string): void {
    this.url = u;
  }

  get youtubeVideoId(): false | string {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = this.url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  }

  toJson(): object {
    return toJS({
      type: this.type,
      url: this.url,
    });
  }

  static createDefault(): EmbedChunkModel {
    return new EmbedChunkModel({
      type: EmbedType.youtube,
      url: 'https://www.youtube.com/embed/PajOjgoKzy8',
    });
  }

  static fromJson(json: ChunkServerType<EmbedChunkType>): EmbedChunkModel {
    const { data } = json;

    return new EmbedChunkModel({
      url: data.url,
      type: data.type,
    });
  }
}
