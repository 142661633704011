import * as React from 'react';
import { OutputData } from '@editorjs/editorjs';

import { mapTypeToComponent } from './view';

import './editor.global.scss';

type Props = {
  data: OutputData;
};

const TextViewer: React.FC<Props> = ({ data }: Props) => {
  return (
    <div className="editor-view">
      {data.blocks.map((b, index) => {
        const Component =
          mapTypeToComponent[b.type as keyof typeof mapTypeToComponent];

        if (Component) {
          return <Component key={index} {...(b.data as any)} />;
        }
      })}
    </div>
  );
};

export default React.memo(TextViewer);
