import { action, makeObservable, observable, toJS } from 'mobx';

import { AttachmentType, parseAttachment } from 'store/models/attachment';
import { ChunkServerType, IChunkModel } from 'store/models/chunks/types';

import { ChunkVideoServerType } from './types';

type VideoChunkType = {
  attachment: AttachmentType | null;
  attachmentId: number | null;
  canVideoSeek: boolean;
};

export class VideoChunkModel implements IChunkModel, VideoChunkType {
  attachment: AttachmentType | null;
  attachmentId: number | null;
  canVideoSeek: boolean;

  constructor({ canVideoSeek, attachment, attachmentId }: VideoChunkType) {
    makeObservable(this, {
      // observable
      attachment: observable,
      attachmentId: observable,
      canVideoSeek: observable,
      // action
      setAttachment: action.bound,
    });
    this.attachment = attachment;
    this.attachmentId = attachmentId;
    this.canVideoSeek = canVideoSeek;
  }

  setAttachment(attachment: AttachmentType): void {
    this.attachment = attachment;
    this.attachmentId = attachment.id;
  }

  toJson(): object {
    return toJS({
      attachment_id: this.attachmentId,
      can_video_seek: this.canVideoSeek,
    });
  }

  static createDefault(): VideoChunkModel {
    return new VideoChunkModel({
      attachment: null,
      attachmentId: null,
      canVideoSeek: true,
    });
  }

  static fromJson(
    json: ChunkServerType<ChunkVideoServerType>
  ): VideoChunkModel {
    const { data } = json;

    return new VideoChunkModel({
      attachment: data.attachment ? parseAttachment(data.attachment) : null,
      attachmentId: data.attachment_id,
      canVideoSeek: data.can_video_seek,
    });
  }
}
