import * as React from 'react';
import { useContext } from 'react';

import { QuizGroupModel } from 'store/models/chunks/quiz/QuizGroupModel';

export const QuizGroupContext = React.createContext<QuizGroupModel | null>(
  null
);

export const useQuizGroup = (): QuizGroupModel => {
  const data = useContext(QuizGroupContext);

  if (!data) {
    throw new Error('No QuizGroupContext Provider in tree!');
  }

  return data;
};
