import { makeAutoObservable } from 'mobx';

import { PaymentsType } from 'config/payments';

import { IBasePayment } from '../types';

import { YandexPaymentApiResponse } from './types';

export class YandexPaymentModel implements IBasePayment {
  shopID = '';
  secretKey = '';
  notificationUrl = '';
  id: number | null = null;

  isLoading = false;
  isEditable = false;

  isError = false;

  type: PaymentsType = PaymentsType.yandex;

  constructor(data: YandexPaymentApiResponse) {
    makeAutoObservable(this);
    this.normalizeData(data);
  }

  normalizeData({
    shop_id,
    notification_url,
    id,
    secret_key,
  }: YandexPaymentApiResponse): void {
    this.secretKey = secret_key;
    this.shopID = shop_id;
    this.notificationUrl = notification_url;
    this.id = id;
  }
}
