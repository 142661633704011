import { observer } from 'mobx-react';
import * as React from 'react';
import { useCallback } from 'react';

import SwitcherInput from 'components/form/SwitcherInput/SwitcherInput';
import { DropdownField } from 'components/form';
import { useCourseStore, useStudentsStore, useUserStore } from 'store/context';
import {
  HomeworkStatus,
  HomeworkStatusText,
} from 'store/models/chunks/homework/types';
import { getCoursesOptions } from 'store/stores';
import { getGroupsOptions } from 'store/stores/StudentsStore/utils';
import { Field } from 'components/form/Field';

import { HomeworkStore } from '../HomeworkStore';

import {
  convertDeadline,
  convertDeadlineValue,
  convertHomeworkToDropdownOptions,
  DEADLINE_OPTIONS,
  DEFAULT_OPTION,
  STATUS_OPTIONS,
} from './config';
import styles from './Filters.modules.scss';
import SelectedItem from './SelectedItem';

type Props = {
  homeworkStore: HomeworkStore;
};

const Filters: React.FC<Props> = ({ homeworkStore }: Props) => {
  const courseStore = useCourseStore();
  const studentsStore = useStudentsStore();
  const user = useUserStore();

  const coursesOptions = getCoursesOptions(courseStore.userCompanyCourses);

  const groupsOptions = getGroupsOptions(
    studentsStore.groupsList.filter((g) =>
      Boolean(homeworkStore.selectedCourses.find((c) => c.id === g.courseId))
    )
  );

  const handleSelectCourse = useCallback(
    (optionKey: string) => {
      homeworkStore.selectCourse(Number(optionKey));
    },
    [homeworkStore.selectCourse]
  );

  const handleSelectStatus = useCallback(
    (optionKey: string | number) => {
      homeworkStore.selectStatus(optionKey as HomeworkStatus);
    },
    [homeworkStore.selectStatus]
  );

  const handleSelectGroup = useCallback(
    (optionKey: string | number) => {
      homeworkStore.selectGroup(studentsStore.groups.items[Number(optionKey)]);
    },
    [homeworkStore.selectedGroup]
  );

  return (
    <div>
      <DropdownField
        noLabel
        label="Курс"
        selected={DEFAULT_OPTION.key}
        options={coursesOptions}
        placeholder="Выберите курс"
        onChange={handleSelectCourse}
      />
      {homeworkStore.selectedCourses.map((c) => (
        <div className={styles.item} key={c.id}>
          <SelectedItem
            id={c.id as number}
            onDelete={homeworkStore.unselectCourse}
          >
            {c.title}
          </SelectedItem>
        </div>
      ))}

      {groupsOptions.length > 0 && (
        <DropdownField
          noLabel
          label="Группа"
          selected={homeworkStore.selectedGroup?.id || DEFAULT_OPTION.key}
          options={groupsOptions}
          placeholder="Выберите группу"
          onChange={handleSelectGroup}
        />
      )}

      <DropdownField
        label="Статус"
        options={STATUS_OPTIONS.filter(
          (o) => o.key !== homeworkStore.selectedStatuses?.[0]
        )}
        noLabel
        placeholder="Выберите статус"
        selected={DEFAULT_OPTION.key}
        onChange={handleSelectStatus}
      />
      {homeworkStore.selectedStatuses.map((c) => (
        <div className={styles.item} key={c}>
          <SelectedItem id={c} onDelete={homeworkStore.unselectStatus}>
            {HomeworkStatusText[c]}
          </SelectedItem>
        </div>
      ))}
      {user.isAdmin && (
        <>
          {homeworkStore.selectedCourses?.[0] && (
            <DropdownField
              label="ДЗ"
              options={convertHomeworkToDropdownOptions(
                homeworkStore.homeworkOptions
              )}
              noLabel
              placeholder="Выберите ДЗ"
              selected={homeworkStore.selectedHomework}
              onChange={homeworkStore.selectHomeworkOption}
            />
          )}
          <DropdownField
            label="Дедлайн"
            options={DEADLINE_OPTIONS}
            noLabel
            selected={convertDeadlineValue(homeworkStore.isDeadline)}
            onChange={(k) => homeworkStore.setIsDeadline(convertDeadline(k))}
          />
          <Field>
            <SwitcherInput
              onChange={homeworkStore.toggleOnlyMine}
              isActive={homeworkStore.onlyMine}
            >
              Только мои
            </SwitcherInput>
          </Field>
        </>
      )}
    </div>
  );
};

export default observer(Filters);
