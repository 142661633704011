import * as React from 'react';

import ChunkEdit from 'components/Constructor/ChunkEdit/ChunkEdit';
import ChunkCreation from 'components/Constructor/ChunkCreation';

const UnitAdmin: React.FC = () => {
  return (
    <>
      <ChunkCreation />
      <ChunkEdit />
    </>
  );
};

export default React.memo(UnitAdmin);
