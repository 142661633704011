import * as React from 'react';

const FileIcon = (props) => {
  const { className, ...rest } = props;

  return (
    <svg
      width="48"
      height="58"
      viewBox="0 0 48 58"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...rest}
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="M45.574 14.073L33.928 2.426C32.683 1.182 31.04.373 29 0v19h19c-.373-2.041-1.183-3.683-2.426-4.927z" />
        <path d="M27.6 24.166c-1 0-1.85-.352-2.55-1.057-.7-.704-1.05-1.56-1.05-2.567V0H3.6c-1 0-1.85.352-2.55 1.057C.35 1.762 0 2.617 0 3.625v50.75c0 1.007.35 1.863 1.05 2.568C1.75 57.647 2.6 58 3.6 58h40.8c1 0 1.85-.353 2.55-1.057.7-.705 1.05-1.56 1.05-2.568V24.166H27.6z" />
      </g>
    </svg>
  );
};

export default FileIcon;
