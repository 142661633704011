import cn from 'classnames';
import * as React from 'react';
import { useCallback } from 'react';

import {
  AnyChunkType,
  ChunkTypeEnum,
  ComplexChunkEnum,
} from 'store/models/chunks/types';

import styles from './Element.modules.scss';

type Props = {
  type: AnyChunkType;
  label: string;
  onClick: (type: AnyChunkType) => void;
};

const Element: React.FC<Props> = ({ type, label, onClick }: Props) => {
  const handleClick = useCallback(() => {
    onClick(type);
  }, [type, onClick]);

  return (
    <div onClick={handleClick} className={styles.wrapper}>
      <div
        className={cn(styles.img, {
          [styles.typeText]: type === ChunkTypeEnum.editorjs,
          [styles.typeImg]: type === ChunkTypeEnum.image,
          [styles.typeImg2inRow]: type === ComplexChunkEnum.images2inRow,
          [styles.typeImgRightText]:
            type === ComplexChunkEnum.imageWithRightText,
          [styles.typeImgLeftText]: type === ComplexChunkEnum.imageWithLeftText,
          [styles.typeText2Columns]: type === ComplexChunkEnum.text2columns,
          [styles.typePortfolio]: type === ComplexChunkEnum.portfolio,
          [styles.typeQuiz]: type === ChunkTypeEnum.quizGroup,
          [styles.typeVideo]: type === ChunkTypeEnum.video,
          [styles.typeFile]: type === ChunkTypeEnum.attachments,
          [styles.typeHomework]: type === ChunkTypeEnum.homework,
          [styles.typeCallback]: type === ChunkTypeEnum.callbackTask,
          [styles.typeCodeTask]: type === ChunkTypeEnum.codeTask,
          [styles.typeMercury]: type === ChunkTypeEnum.mercuryTask,
          [styles.typeCode]: type === ChunkTypeEnum.code,
          [styles.typeEmbed]: type === ChunkTypeEnum.embed,
        })}
      />
      <div className={styles.label}>{label}</div>
    </div>
  );
};

export default React.memo(Element);
