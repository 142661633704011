import * as React from 'react';
import { useCallback } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import Button from 'components/common/Button';
import Badge from 'components/common/Badge';
import urls from 'config/urls';
import { useLessonStore } from 'pages/Lesson/context';
import { HomeworkChunkModel } from 'store/models/chunks/homework/HomeworkChunkModel';
import SimpleTextEditor from 'components/common/TextEditor/SimpleTextEditor';
import { useModal } from 'components/form/hooks/useModal';
import {
  HomeworkStatus,
  HomeworkStatusInfo,
  HomeworkStatusText,
} from 'store/models/chunks/homework/types';

import Deadline from '../common/Deadline';
import { WithChunk } from '../types';

import ConversationsList from './ConversationsList';
import styles from './HomeworkChunk.modules.scss';

type Props = WithChunk<HomeworkChunkModel>;

export const BADGE_COLOR = {
  [HomeworkStatus.notStarted]: '',
  [HomeworkStatus.checking]: '#F98511',
  [HomeworkStatus.rejected]: '#D1004A',
  [HomeworkStatus.finished]: '#20D771',
};

const HomeworkChunk: React.FC<Props> = ({ chunk, isEditMode }: Props) => {
  const { data } = chunk;
  const history = useHistory();
  const { lesson, isAdmin } = useLessonStore();
  const [showConversationsModal, toggleConversationsModal] = useModal();

  const handleGoToChat = useCallback(() => {
    if (isAdmin) {
      return history.push(urls.homework);
    }
    if (lesson) {
      history.push(
        urls.lessonHomeworkChat(
          lesson.id,
          chunk.unit.id,
          chunk.id,
          data?.conversationId
        )
      );
    }
  }, [isAdmin]);

  return (
    <>
      {isAdmin && lesson?.id && lesson?.courseId && showConversationsModal && (
        <ConversationsList
          isOpen={showConversationsModal}
          onClose={toggleConversationsModal}
          lessonId={lesson.id}
          courseId={lesson.courseId}
          chunkId={chunk.id}
          unitId={chunk.unit.id}
        />
      )}
      <div id={chunk.id} className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          <SimpleTextEditor
            isEditMode={isEditMode}
            onChange={data.setTitle}
            text={data.title}
            className={styles.title}
          />
          <Deadline
            value={data.deadlineMixin.deadline}
            onChange={data.deadlineMixin.setDeadline}
            isAdmin={isAdmin && isEditMode}
          />
          {data.status !== HomeworkStatus.notStarted && !isAdmin && (
            <Badge
              color={BADGE_COLOR[data.status]}
              className={styles.statusBadge}
            >
              {HomeworkStatusText[data.status]}
            </Badge>
          )}
        </div>
        {isAdmin ? (
          <>
            <div className={styles.statusTitle}>Домашнее задание</div>
            <div className={styles.statusInfo}>
              Принимать домашнее задание можно в&nbsp;чате со&nbsp;студентом.
              <br />
              Нажмите кнопку ниже, чтобы посмотреть студентов, которые отправили
              задание на&nbsp;проверку
            </div>
            <Button
              className={styles.buttonToChat}
              onClick={handleGoToChat}
              isDisabled={!data.isSaved}
            >
              Выбрать чат со студентом
            </Button>
          </>
        ) : (
          <>
            <div className={styles.statusTitle}>
              {HomeworkStatusText[data.status]}
            </div>
            <div className={styles.statusInfo}>
              {HomeworkStatusInfo[data.status]}
            </div>
            {data.canPass ? (
              <Button
                className={styles.buttonToChat}
                onClick={handleGoToChat}
                isDisabled={!data.isSaved}
              >
                Перейти в чат с преподавателем
              </Button>
            ) : (
              <Badge
                color={BADGE_COLOR[HomeworkStatus.rejected]}
                className={styles.deadlineBadge}
              >
                Срок сдачи этой задачи завершен или есть другие просроченные
                задания
              </Badge>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default observer(HomeworkChunk);
