import * as React from 'react';
import cn from 'classnames';

import { LessonTypeEnum } from 'store/models/lesson/types';

import styles from './Title.modules.scss';

type Props = {
  type: LessonTypeEnum;
  className?: string;
};

const Title: React.FC<Props> = ({ type, className }: Props) => {
  return (
    <span className={cn(styles.root, className)}>
      {type === LessonTypeEnum.intro && 'Введение'}
      {type === LessonTypeEnum.theory && 'Теория'}
      {type === LessonTypeEnum.test && 'Тест'}
      {type === LessonTypeEnum.video && 'Видео-урок'}
      {type === LessonTypeEnum.practice && 'Практическое задание'}
      {type === LessonTypeEnum.final && 'Итоговое задание'}
    </span>
  );
};

export default React.memo(Title);
