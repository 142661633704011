import * as React from 'react';
import { observer } from 'mobx-react';

import { ChunkModel } from 'store/models/chunks/ChunkModel';

import { useConstructorContext } from '../context';
import { useChunkContainer } from '../models/chunkContainer/context';

import { typeToChunk } from './config';
import Layout from './Layout';
import { WithChunk } from './types';
import UnsupportedChunk from './UnsupportedChunk';

type Props = {
  chunk: ChunkModel;
  component?: React.ComponentType<WithChunk>;
  [key: string]: any;
};

const Chunk: React.FC<Props> = ({ component, chunk, ...rest }: Props) => {
  const chunkContainer = useChunkContainer();

  const constructorContext = useConstructorContext();

  const Component = component || typeToChunk[chunk.type] || UnsupportedChunk;

  return (
    <Layout chunk={chunk} isEditMode={constructorContext.isEditMode}>
      <Component
        chunk={chunk}
        isEditMode={constructorContext.isEditMode}
        isAdmin={constructorContext.isAdmin}
        isFocused={chunkContainer.selectedChunkId === chunk.id}
        {...rest}
      />
    </Layout>
  );
};

export default observer(Chunk);
