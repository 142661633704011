import * as React from 'react';
import { useCallback } from 'react';
import toast from 'cogo-toast';

import ButtonPlus from 'components/common/Button/ButtonPlus';
import CourseCreationModal from 'components/containers/course/CourseCreationModal';
import CourseModel from 'store/models/course/CourseModel';
import { CourseStore } from 'store/stores/CourseStore';
import { useCourseStore } from 'store';

import styles from './AdminHeader.modules.scss';

const AdminHeader: React.FC = () => {
  const [showModal, setShowModal] = React.useState(false);

  const toggleModal = React.useCallback(() => {
    setShowModal((s) => !s);
  }, []);

  const coursesStore: CourseStore = useCourseStore();

  const handleAddCourse = useCallback((course: CourseModel) => {
    coursesStore.addCourse(course);
    toast.success('Курс успешно создан');
  }, []);

  return (
    <div className={styles.wrapper}>
      {showModal && (
        <CourseCreationModal
          resetOnOpen
          isOpen={showModal}
          onSuccess={handleAddCourse}
          onClose={toggleModal}
        />
      )}
      <ButtonPlus onClick={toggleModal}>Добавить курс</ButtonPlus>
    </div>
  );
};

export default React.memo(AdminHeader);
