import { action, computed, makeObservable, observable } from 'mobx';

import apiUrls from 'config/apiUrls';
import request from 'utils/request';

import {
  Collection,
  createDefaultCollection,
  linearizeCollection,
  parseCollection,
} from '../../models/collection';
import { ServerResponse } from '../../models/common/types';
import { GroupModel } from '../../models/students/GroupModel';
import { GroupServerType } from '../../models/students/types';
import { RootStore } from '../../RootStore';
import { GlobalStore } from '../types';

export class StudentsStore implements GlobalStore {
  initError = false;
  isInited = false;
  isIniting = false;

  rootStore: RootStore | null = null;

  isLoadingGroups = false;
  isLoadingGroup = false;
  isGroupsLoaded = false;
  groups: Collection<number, GroupModel> = createDefaultCollection();

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      // observable
      initError: observable,
      isInited: observable,
      isIniting: observable,
      isLoadingGroup: observable,
      isGroupsLoaded: observable,
      isLoadingGroups: observable,
      groups: observable,
      // computed
      noGroups: computed,
      groupsList: computed,
      // action
      loadGroups: action,
      addGroup: action,
    });
    this.rootStore = rootStore;
  }

  async init(): Promise<void> {
    this.isInited = false;
    this.isIniting = true;

    await this.loadGroups();

    this.isInited = true;
    this.isIniting = false;
  }

  get noGroups(): boolean {
    return Object.keys(this.groups).length === 0 && this.isGroupsLoaded;
  }

  get groupsList(): GroupModel[] {
    return linearizeCollection(this.groups);
  }

  async loadGroups(): Promise<any> {
    this.isLoadingGroups = true;

    const { response }: ServerResponse<{ groups: GroupServerType[] }> =
      await request(apiUrls.company.listGroups, 'GET', {
        company: this.rootStore?.userStore.selectedCompany?.id,
        limit: 100
      });

    if (response) {
      this.groups = parseCollection(response.groups, GroupModel.fromJson);
    }

    this.isLoadingGroups = false;
    this.isGroupsLoaded = true;
  }

  addGroup(group: GroupModel): void {
    this.groups.items[group.id] = group;
    this.groups.order.unshift(group.id);
  }
}
