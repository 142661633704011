import { action, makeObservable, observable, toJS } from 'mobx';

import apiUrls from 'config/apiUrls';
import { ChunkServerType, IChunkModel } from 'store/models/chunks/types';
import request from 'utils/request';

import { ServerResponse } from '../../common/types';

import { DeadlineMixin, DeadlineMixinType } from './DeadlineMixin';
import {
  ChunkHomeworkServerType,
  HomeworkApproveEnum,
  HomeworkStatus,
} from './types';

type HomeworkChunkType = {
  status: HomeworkStatus;
  title: string;
  isRead: boolean;
  isSaved: boolean;
  id: string | null;
  conversationId: string | null;
};

export class HomeworkChunkModel implements IChunkModel, HomeworkChunkType {
  id: string | null;

  status: HomeworkStatus;

  title: string;

  isRead: boolean;

  deadlineMixin: DeadlineMixin;

  conversationId: string | null;

  /* Только с сохраненного можно перейти на страницу чата */
  isSaved: boolean;

  isApproving = false;

  constructor({
    status,
    isSaved,
    title,
    isRead,
    deadline,
    isDeadline,
    id,
    conversationId,
  }: HomeworkChunkType & DeadlineMixinType) {
    makeObservable(this, {
      // observable
      status: observable,
      title: observable,
      isRead: observable,
      isSaved: observable,
      isApproving: observable,
      // action
      setTitle: action.bound,
      toJson: action,
      checkHomework: action,
    });
    this.isSaved = isSaved;
    this.isRead = isRead;
    this.title = title;
    this.status = status;
    this.id = id;
    this.deadlineMixin = new DeadlineMixin(deadline, isDeadline);
    this.conversationId = conversationId;
  }

  get canPass(): boolean {
    return (
      !this.deadlineMixin.isDeadline ||
      (this.deadlineMixin.isDeadline &&
        this.status !== HomeworkStatus.notStarted)
    );
  }

  setTitle(title: string): void {
    this.title = title;
  }

  toJson(): object {
    this.isSaved = true;

    return toJS({
      title: this.title,
      deadline: this.deadlineMixin.deadline,
    });
  }

  async checkHomework(
    approve: HomeworkApproveEnum,
    userId: number
  ): Promise<boolean> {
    if (this.isApproving) {
      return false;
    }

    this.isApproving = true;

    const { response }: ServerResponse = await request(
      apiUrls.homework.checkHomework,
      'POST',
      {
        homework_chunk: this.id,
        user: userId,
        status: approve,
      }
    );

    if (response) {
      this.status =
        approve === HomeworkApproveEnum.wrong
          ? HomeworkStatus.rejected
          : HomeworkStatus.finished;
    }

    this.isApproving = false;

    return Boolean(response);
  }

  approveHomework = this.checkHomework.bind(this, HomeworkApproveEnum.right);

  rejectHomework = this.checkHomework.bind(this, HomeworkApproveEnum.wrong);

  static createDefault(): HomeworkChunkModel {
    return new HomeworkChunkModel({
      title: 'Итоговое задание урока',
      status: HomeworkStatus.notStarted,
      isSaved: false,
      isRead: false,
      id: null,
      deadline: null,
      isDeadline: false,
      conversationId: null,
    });
  }

  static fromDataJson(
    data: ChunkHomeworkServerType,
    id: string
  ): HomeworkChunkModel {
    return new HomeworkChunkModel({
      isSaved: true,
      status: data.status,
      title: data.title,
      isRead: data.is_read,
      deadline: data.deadline ? new Date(data.deadline) : null,
      isDeadline: data.is_deadline,
      id,
      conversationId: data.conversation_id || null,
    });
  }

  static fromJson(
    json: ChunkServerType<ChunkHomeworkServerType>
  ): HomeworkChunkModel {
    const { data } = json;

    return HomeworkChunkModel.fromDataJson(data, json.id);
  }
}
