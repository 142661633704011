import { Form } from 'store/models/common/Form';

type FieldType = {
  value: any;
  setValue: (v: any) => void;
  errors: string[];
};

export const useForm = <T, F extends Form<T>>(
  form: F,
  fieldNames: Array<keyof T>
): Record<keyof T, FieldType> => {
  return fieldNames.reduce(
    (acc: Record<keyof T, FieldType>, field: keyof T) => ({
      ...acc,
      [field]: {
        value: form.values[field],
        errors: form.errors[field],
        setValue: (v: T[typeof field]): void => form.setField(field, v),
      },
    }),
    {} as Record<keyof T, FieldType>
  );
};

export default useForm;
