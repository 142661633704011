import * as React from 'react';
import classNames from 'classnames';
// @ts-ignore
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

import styles from './Datetime.modules.scss';

type Props = {
  onChange(d: Date): void;
  value: Date | null;
  className?: string;
  minDate?: Date;
};

const Deadline: React.FC<Props> = ({
  value,
  onChange,
  className,
  minDate,
}: Props) => {
  return (
    <DateTimePicker
      value={value}
      onChange={onChange}
      minDate={minDate}
      className={classNames(className, styles.wrapper)}
    />
  );
};

export default React.memo(Deadline);
