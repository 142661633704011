import { action, makeObservable, observable, toJS } from 'mobx';

import { AttachmentType, parseAttachment } from 'store/models/attachment';
import { IChunkModel } from 'store/models/chunks/types';

import { ChunkAttachmentServerType } from './types';

export class ImageChunkAttachmentModel implements IChunkModel {
  attachment: AttachmentType | null;
  attachmentId: number;
  title: object;
  subtitle: object;
  width: number;
  canFullScreen: boolean;

  constructor({
    attachment,
    attachmentId,
    title,
    subtitle,
    width,
    canFullScreen,
  }: any) {
    makeObservable(this, {
      // observable
      attachment: observable,
      attachmentId: observable,
      title: observable,
      subtitle: observable,
      width: observable,
      canFullScreen: observable,
      // action
      setAttachment: action.bound,
    });
    this.attachment = attachment;
    this.attachmentId = attachmentId;
    this.title = title;
    this.subtitle = subtitle;
    this.width = width;
    this.canFullScreen = canFullScreen;
  }

  setAttachment(attachment: AttachmentType): void {
    this.attachment = attachment;
    this.attachmentId = attachment.id;
  }

  toJson(): object {
    return toJS({
      attachment_id: this.attachmentId,
      title: this.title,
      subtitle: this.subtitle,
      width: this.width,
      can_full_screen: this.canFullScreen,
    });
  }

  static createDefault(): ImageChunkAttachmentModel {
    return new ImageChunkAttachmentModel({
      attachment: null,
      width: 100,
      title: {},
      subtitle: {},
      canFullScreen: false,
    });
  }

  static fromJson(json: ChunkAttachmentServerType): ImageChunkAttachmentModel {
    return new ImageChunkAttachmentModel({
      attachment: json.attachment ? parseAttachment(json.attachment) : null,
      attachmentId: json.attachment_id,
      title: json.title,
      subtitle: json.subtitle,
      width: json.width,
      canFullScreen: json.can_full_screen,
    });
  }
}
