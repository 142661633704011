import * as React from 'react';
import cn from 'classnames';

import styles from './Label.modules.scss';

type Props = {
  children: React.ReactNode;
  isStatic?: boolean;
  isUp?: boolean;
  isFocused?: boolean;
  className?: string;
  withIcon?: boolean;
};

const Label: React.FC<Props> = ({
  className,
  isFocused,
  isUp,
  isStatic,
  withIcon,
  children,
}: Props) => {
  return (
    <div
      className={cn(
        styles.label,
        isStatic && styles.labelStatic,
        withIcon && styles.labelWithIcon,
        isFocused && styles.labelFocused,
        (isFocused || isUp) && styles.labelUp,
        className
      )}
    >
      {children}
    </div>
  );
};

Label.defaultProps = {
  isUp: false,
  isFocused: false,
  isStatic: false,
  withIcon: false,
};

export default React.memo(Label);
