export enum AttachmentSourceEnum {
  bunny = 'bunny',
  s3 = 's3',
}

export enum BunnyAttachmentStatusEnum {
  uploaded = 'uploaded',
  playable = 'playable',
  finished = 'finished',
  failed = 'failed',
}

type BaseAttachmentServerType = {
  creator_id: number;
  name: string;
  original_file_name: string;
  is_deleted: boolean;
  base_id: number;
  id: number;
  creator_first_name: string;
  extension: string;
  url: string;
  creator_last_name: string;
};

export type AttachmentServerType =
  | (BaseAttachmentServerType & { source: AttachmentSourceEnum.s3 })
  | (BaseAttachmentServerType & {
      source: AttachmentSourceEnum.bunny;
      player_url: string;
      status: BunnyAttachmentStatusEnum;
    });

type BaseAttachmentType = {
  name: string;
  originalFileName: string;
  id: number;
  url: string;
};

export type AttachmentType =
  | (BaseAttachmentType & {
      source: AttachmentSourceEnum.s3;
    })
  | (BaseAttachmentType & {
      source: AttachmentSourceEnum.bunny;
      playerUrl: string;
      status: BunnyAttachmentStatusEnum;
    });
