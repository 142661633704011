import * as propTypes from 'prop-types';
import * as React from 'react';

const TrashIcon = (props) => {
  const { className } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="50px"
      height="50px"
      className={className}
    >
      <path
        fill="currentColor"
        d="M 10 2 L 9 3 L 3 3 L 3 5 L 21 5 L 21 3 L 15 3 L 14 2 L 10 2 z M 4.3457031 7 L 5.9023438 20.232422 C 6.0203437 21.240422 6.874625 22 7.890625 22 L 16.111328 22 C 17.127328 22 17.980656 21.239422 18.097656 20.232422 L 19.654297 7 L 4.3457031 7 z M 9 9 L 11 9 L 11 19 L 10 19 C 9.448 19 9 18.552 9 18 L 9 9 z M 13 9 L 15 9 L 15 18 C 15 18.552 14.552 19 14 19 L 13 19 L 13 9 z"
      />
    </svg>
  );
};

TrashIcon.propTypes = {
  className: propTypes.string.isRequired,
};

TrashIcon.defaultProps = {};

export default TrashIcon;
