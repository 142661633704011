import * as propTypes from 'prop-types';
import * as React from 'react';

const AlignLeft = (props) => {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor" fillRule="evenodd">
        <path d="M2,2H4V30H2ZM26,15H5V5H26ZM24,7H7v6H24Zm6,10V27H5V17Zm-2,2H7v6H28Z" />
      </g>
    </svg>
  );
};

AlignLeft.propTypes = {
  className: propTypes.string.isRequired,
};

AlignLeft.defaultProps = {};

export default AlignLeft;
