import * as React from 'react';
import { observer } from 'mobx-react';

import Constructor from 'components/Constructor';
import { UnitModel } from 'store/models/unit/UnitModel';
import { ChunkContainerContext } from 'components/Constructor/models/chunkContainer/context';

import styles from './UnitCard.modules.scss';

type Props = {
  unit: UnitModel;
  isEditMode: boolean;
  isAdmin: boolean;
};

const UnitCard: React.FC<Props> = ({ unit, isEditMode, isAdmin }: Props) => {
  return (
    <ChunkContainerContext.Provider value={unit}>
      <div className={styles.chunksWrapper}>
        {unit.editorState.isEmpty && isAdmin && !isEditMode && (
          <div className={styles.placeholder}>
            Включите режим редактирования и добавляйте блоки на карточку!
          </div>
        )}
        <Constructor
          editorState={unit.editorState}
          setEditorState={unit.setEditorState}
          unit={unit}
          isEditMode={isEditMode}
          isAdmin={isAdmin}
        />
      </div>
    </ChunkContainerContext.Provider>
  );
};

export default observer(UnitCard);
