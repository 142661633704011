import * as React from 'react';
import { useCallback, useState } from 'react';
import { observer } from 'mobx-react';

import { AttachmentUploadModel } from 'store/models/attachment/AttachmentUploadModel';
import { AttachmentType } from 'store/models/attachment';
import { WithFieldProps } from 'components/form/Field/types';

import ImageInput from './ImageInput';

interface Props extends WithFieldProps {
  attachment: AttachmentType;
  onChange: (attachment: AttachmentType | null) => void;
}

const ImageInputContainer: React.FC<Props> = ({
  onChange,
  attachment,
}: Props) => {
  const [uploadModel] = useState<AttachmentUploadModel>(
    new AttachmentUploadModel()
  );

  const handleChange = useCallback(
    async (file: File) => {
      const attachment = await uploadModel.uploadFile(file);

      if (attachment) {
        onChange(attachment);
      }
    },
    [onChange]
  );

  const handleDrop = useCallback(() => {
    uploadModel.dropFile();
    onChange(null);
  }, [onChange]);

  return (
    <ImageInput
      error={uploadModel.error}
      fileName={attachment?.originalFileName}
      onChange={handleChange}
      onDelete={handleDrop}
      attachment={attachment}
      isLoading={uploadModel.isLoading}
    />
  );
};

export default observer(ImageInputContainer);
