import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { ComponentType } from 'react';

import { usePaymentsStore } from 'store/context';
import { useCurrentCompany } from 'store/stores';
import FullSideSpinner from 'components/common/Spinner/FullSideSpinner';
import urls from 'config/urls';
import { DropdownField } from 'components/form';
import { DropdownOption } from 'components/form/Dropdown';
import {
  PaymentsChooseType,
  PaymentsName,
  PaymentsType,
} from 'config/payments';

import styles from './PaymentsGroupConfiguration.modules.scss';
import YandexGroupPayment from './components/YandexGroupPayment';

interface Props {
  className?: string;
  groupId: number;
}

export interface PaymentComponentProps {
  groupId: number;
}

const ComponentMap: Record<
  PaymentsType,
  ComponentType<PaymentComponentProps>
> = {
  [PaymentsType.yandex]: YandexGroupPayment,
};

const PaymentsGroupConfiguration: React.FC<Props> = ({ groupId }: Props) => {
  const paymentsStore = usePaymentsStore();
  const company = useCurrentCompany();

  const dropdownOptions: DropdownOption[] = React.useMemo(
    () => [
      {
        key: PaymentsChooseType.free,
        label: 'Бесплатно',
      },
      ...paymentsStore.paymentTypes.map((paymentType) => ({
        key: paymentType,
        label: PaymentsName[paymentType],
      })),
    ],
    [paymentsStore.paymentTypes]
  );

  React.useEffect(() => {
    if (company) {
      paymentsStore.fetchPaymentsType(company.id);
    }
  }, [company]);

  const [selectedPaymentType, setSelected] = React.useState<PaymentsType>(
    dropdownOptions[0].key
  );

  if (paymentsStore.isLoading) {
    return <FullSideSpinner isOpen />;
  }

  const PaymentComponent = ComponentMap[selectedPaymentType];

  return (
    <>
      {!paymentsStore.hasPayments ? (
        <div className={styles.hasNotPayments}>
          Чтобы подключить оплату к&nbsp;группе, добавьте платежную систему
          в&nbsp;<Link to={urls.integration}>разделе Интеграция</Link>.
        </div>
      ) : (
        <div className={styles.dropdown}>
          <DropdownField
            noLabel
            label="Способ оплаты"
            options={dropdownOptions}
            selected={selectedPaymentType}
            onChange={setSelected as (value: React.ReactText) => void}
          />
        </div>
      )}
      {PaymentComponent ? <PaymentComponent groupId={groupId} /> : null}
    </>
  );
};

export default observer(PaymentsGroupConfiguration);
