import * as propTypes from 'prop-types';
import * as React from 'react';

const SendIcon = (props) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="send_24px">
        <path
          id="icon/content/send_24px"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5 10L1.51 3L22.5 12L1.51 21L1.5 14L16.5 12L1.5 10ZM3.51 6.03L11.02 9.25L3.5 8.25L3.51 6.03ZM11.01 14.75L3.5 17.97V15.75L11.01 14.75Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

SendIcon.propTypes = {
  className: propTypes.string,
};

export default SendIcon;
