import * as React from 'react';
import { observer } from 'mobx-react';

import { useUserStore } from 'store';
import CourseList from 'pages/common/CourseList';

import AdminHeader from './admin/AdminHeader';

const Profile: React.FC = () => {
  const userStore = useUserStore();

  return (
    <>
      {userStore.isAdmin && <AdminHeader />}
      <CourseList />
    </>
  );
};

export default observer(Profile);
