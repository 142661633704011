import cogoToast from 'cogo-toast';
import copy from 'copy-to-clipboard';
import { observer } from 'mobx-react';
import * as React from 'react';

import { MercuryTaskChunkModel } from 'store/models/chunks/mercury/MercuryTaskChunkModel';
import noop from 'utils/noop';

import Badge from '../../../common/Badge/Badge';
import Button, { ButtonColor } from '../../../common/Button';
import { Input } from '../../../form';
import { WithChunk } from '../types';

import styles from './MercuryTaskChunk.modules.scss';

type Props = WithChunk<MercuryTaskChunkModel>;

const MercuryTaskChunk: React.FC<Props> = ({
  chunk: { data, id },
  isAdmin,
  isEditMode,
}: Props) => {
  const studentMode = !isEditMode && !isAdmin;

  const onCopy = React.useCallback(() => {
    if (data.password) {
      copy(data.password);
      cogoToast.success('Пароль скопирован');
    }
  }, [data.password]);

  const startBlock = (
    <div>
      {data.url ? (
        <div>
          Перейдите по ссылке{' '}
          <a href={data.url} target="_blank">
            {data.url}
          </a>{' '}
          и введите следующий пароль:
          <div className={styles.tokenField}>
            <div className={styles.tokenInput}>
              <Input readOnly onChange={noop} value={`${data.password}`} />
            </div>
            <div className={styles.tokenCopy}>
              <Button color={ButtonColor.transparent} onClick={onCopy}>
                Скопировать
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <Button onClick={data.startTask} isLoading={data.isLoadingUrl}>
          Запустить сервер
        </Button>
      )}
    </div>
  );

  return (
    <div id={id}>
      <div className={styles.title}>Задание на выделенном сервере</div>
      <div className={styles.description}>
        Вам будет выделен виртуальный сервер для решения задания.
        <br />
        <br />
        Чтобы начать задание, запустите сервер. Это может занять немного
        времени.
      </div>

      {studentMode && (
        <>
          {data.isPassed ? (
            <Badge className={styles.success}>Задание сдано</Badge>
          ) : (
            startBlock
          )}
        </>
      )}

      {!isEditMode && isAdmin && startBlock}

      {isEditMode && (
        <div className={styles.tokenInput}>
          <Input
            onChange={data.setTaskNamespace}
            placeholder="Введите название неймспейса"
            value={data.taskNamespace}
          />
        </div>
      )}
    </div>
  );
};

export default observer(MercuryTaskChunk);
