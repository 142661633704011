import cn from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { useModal } from 'components/form/hooks/useModal';
import UnitCreationModal from 'components/containers/course/UnitCreationModal';
import urls from 'config/urls';
import { HomeworkStatus } from 'store/models/chunks/homework/types';
import { LessonModel } from 'store/models/lesson/LessonModel';
import { LessonTypeEnum } from 'store/models/lesson/types';
import Badge from 'components/common/Badge/Badge';
import LockIcon from 'components/icons/LockIcon';

import { BADGE_COLOR } from '../../../../Constructor/Chunks/HomeworkChunk/HomeworkChunk';
import DoneCircleIcon from '../../../../icons/DoneCirleIcon';
import { useCourseContext } from '../../context';

import Corner from './Corner';
import LessonAdmin from './LessonAdmin';
import styles from './LessonElement.modules.scss';
import LessonIcon from './LessonIcon';
import Title from './Title';

type Props = {
  index: number;
  lesson: LessonModel;
  isAdmin: boolean;
  disabled?: boolean;
};

const LessonElement: React.FC<Props> = ({
  index,
  lesson,
  isAdmin,
  disabled,
}: Props) => {
  const { type, title } = lesson;

  const { isEditMode } = useCourseContext();

  const history = useHistory();
  const [showUnitModal, toggleUnitModal] = useModal();

  const redirectToLesson = React.useCallback(() => {
    history.push(urls.lesson(lesson.id));
  }, [lesson.id]);

  const onClick = React.useCallback(() => {
    if (disabled) {
      return;
    }

    if (!lesson.unitCount) {
      if (isAdmin) {
        toggleUnitModal();
      }
      return;
    }
    redirectToLesson();
  }, [lesson.id, lesson.unitCount, disabled]);

  return (
    <>
      {showUnitModal && (
        <UnitCreationModal
          isOpen={showUnitModal}
          onClose={toggleUnitModal}
          lesson={lesson}
          resetOnOpen
          onSuccess={redirectToLesson}
        />
      )}
      <div className={cn(styles.root, disabled && styles.root_disabled)}>
        <div className={styles.inner} onClick={onClick}>
          <Corner type={type} disabled={disabled} className={styles.corner} />

          <div className={styles.meta}>
            <span className={styles.meta__index}>{index + 1}</span>
            <LessonIcon
              className={cn(
                styles.meta__icon,
                type === LessonTypeEnum.video && styles.videoIcon
              )}
              type={type}
            />
            <Title className={styles.meta__title} type={type} />
          </div>

          <p className={styles.description}>{title}</p>

          <div className={styles.statuses}>
            {!lesson.hasEnoughAccessLevel && (
              <div
                className={cn(styles.accessLevel, styles.accessLevel_blocked)}
              >
                Недоступно на вашем треке
              </div>
            )}
            {isAdmin && isEditMode && lesson.accessLevel !== 0 && (
              <div className={cn(styles.accessLevel, styles.accessLevel_value)}>
                {lesson.accessLevel}
              </div>
            )}
            {isAdmin && isEditMode && lesson.isPublished && (
              <Badge
                color={BADGE_COLOR[HomeworkStatus.finished]}
                className={styles.badge}
              >
                Опубликован
              </Badge>
            )}
            {!isEditMode &&
              (disabled ? (
                <LockIcon className={cn(styles.status, styles.status_lock)} />
              ) : !!lesson.unitCount &&
                lesson.countCompletedUnits === lesson.unitCount ? (
                <DoneCircleIcon
                  className={cn(styles.status, styles.status_done)}
                />
              ) : (
                <span>
                  {lesson.countCompletedUnits} / {lesson.unitCount}
                </span>
              ))}
          </div>
        </div>
        {isAdmin && isEditMode && (
          <div className={styles.adminWrapper}>
            <LessonAdmin lesson={lesson} />
          </div>
        )}
      </div>
    </>
  );
};

export default observer(LessonElement);
