import * as React from 'react';
import { useCallback } from 'react';
import { observer } from 'mobx-react';

import { QuizAnswerModel } from 'store/models/chunks/quiz/QuizAnswerModel';
import { QuizQuestionModel } from 'store/models/chunks/quiz/QuizQuestionModel';
import SimpleTextEditor from 'components/common/TextEditor/SimpleTextEditor';
import ButtonPlus from 'components/common/Button/ButtonPlus/ButtonPlus';
import { ChunkTypeEnum } from 'store/models/chunks/types';
import { ChunkContainerContext } from 'components/Constructor/models/chunkContainer/context';

import { WithChunk } from '../../types';
import Chunk from '../../Chunk';

import QuizAnswer from './QuizAnswer/QuizAnswer';
import styles from './QuizQuestion.modules.scss';

type Props = WithChunk<QuizQuestionModel>;

const QuizQuestion: React.FC<Props> = ({
  isEditMode,
  isAdmin,
  chunk,
}: Props) => {
  const handleAddAnswer = useCallback(() => {
    chunk.data.addChunk(ChunkTypeEnum.quizAnswer);
  }, [chunk]);

  const handleAddImageAnswer = useCallback(() => {
    const [imageChunk] = chunk.data.addChunk(ChunkTypeEnum.quizAnswer);
    (imageChunk.data as QuizAnswerModel).setForceAttachment(true);
    imageChunk.layout.setWidth(100 / 3);
  }, [chunk]);

  return (
    <div>
      <SimpleTextEditor
        text={chunk.data.plainTitle}
        isEditMode={isEditMode}
        onChange={chunk.data.setPlainTitle}
        className={styles.questionTitle}
      />
      <ChunkContainerContext.Provider value={chunk.data}>
        <div className={styles.answersWrapper}>
          {chunk.data.chunks.map((chunk, index) => (
            <Chunk
              component={QuizAnswer}
              chunk={chunk}
              key={chunk.id}
              index={index}
              isEditMode={isEditMode}
              isAdmin={isAdmin}
            />
          ))}
        </div>
      </ChunkContainerContext.Provider>
      {isEditMode && (
        <div className={styles.addButtonWrapper}>
          <div className={styles.addButton}>
            <ButtonPlus onClick={handleAddAnswer}>Текстовый ответ</ButtonPlus>
          </div>
          <div className={styles.addButton}>
            <ButtonPlus onClick={handleAddImageAnswer}>
              Ответ с картинкой
            </ButtonPlus>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(QuizQuestion);
