import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { isMobile } from 'react-device-detect';

import { WhiteCard } from 'components/common/BaseCard';
import ButtonPlus from 'components/common/Button/ButtonPlus/ButtonPlus';
import { useModal } from 'components/form/hooks/useModal';
import Table from 'components/common/Table';
import ContentLayout from 'components/common/ContentLayout/ContentLayout';
import { useUserStore } from 'store/context';

import TokenCreationModal from './components/TokenCreationModal/TokenCreationModal';
import styles from './SideApi.modules.scss';
import { SideApiStore } from './store/SideApiStore';
import { columns } from './tableConfig';

const SideApi: React.FC = () => {
  const [store] = useState(() => new SideApiStore());
  const user = useUserStore();
  const [showCreation, toggleCreation] = useModal();

  useEffect(() => {
    if (user.selectedCompany) {
      store.load(user.selectedCompany?.id);
    }
  }, []);

  return (
    <ContentLayout>
      <TokenCreationModal
        isOpen={showCreation}
        onClose={toggleCreation}
        sideApiStore={store}
      />
      <WhiteCard className={styles.card}>
        <div className={styles.headContent}>
          <div className={styles.header}>Активные ключи</div>
          <div>
            <ButtonPlus onClick={toggleCreation}>Создать ключ</ButtonPlus>
          </div>
        </div>
        {store.tokens.length > 0 || store.isLoading ? (
          <Table
            size={isMobile ? 'small' : 'middle'}
            dataSource={store.tokens}
            columns={columns}
            pagination={false}
            loading={store.isLoading}
          />
        ) : (
          <div className={styles.listEmpty}>Пока нет активных ключей</div>
        )}
      </WhiteCard>
    </ContentLayout>
  );
};

export default observer(SideApi);
