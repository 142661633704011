import { makeObservable, override } from 'mobx';

import apiUrls from 'config/apiUrls';
import { Form, FormValidators } from 'store/models/common/Form';
import { ServerResponse } from 'store/models/common/types';
import request from 'utils/request';
import {
  composeValidators,
  greaterThanValidator,
  notEmptyValidator,
} from 'utils/validation';

import { GroupModel } from './GroupModel';
import { GroupCreationServerResponse } from './types';

export enum GroupCreationFieldsEnum {
  name = 'name',
  courseId = 'courseId',
}

export type GroupFields = {
  name: string;
  courseId: number | null;
};

export class GroupCreationFormModel extends Form<GroupFields> {
  validators: FormValidators<GroupFields> = {
    name: composeValidators(
      notEmptyValidator({
        errorMessage: 'Заполните название',
      }),
      greaterThanValidator({
        errorMessage: 'Название должно быть длиннее 5 символов',
        min: 5,
      })
    ),
    courseId: composeValidators(
      notEmptyValidator({
        errorMessage: 'Выберите курс',
      })
    ),
  };

  initialValues = {
    name: '',
    courseId: null,
  };

  constructor() {
    super();
    makeObservable(this, {
      save: override,
    });
  }

  async save(companyId: number): Promise<false | GroupModel> {
    this.validate();

    if (this.hasErrors) {
      return false;
    }

    this.isSaving = true;

    const { response }: ServerResponse<GroupCreationServerResponse> =
      await request(apiUrls.company.createGroup, 'POST', {
        name: this.values.name,
        courses: [this.values.courseId],
        company: companyId,
      });

    this.isSaving = false;

    if (response) {
      return GroupModel.fromJson(response);
    }

    return false;
  }
}
