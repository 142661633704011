export type ValidatorParams = {
  errorMessage: string;
  [key: string]: any;
};

export type Validator<T> = (value: T) => string;

export function notEmpty(
  value: any,
  params: ValidatorParams = { errorMessage: 'Поле не заполнено' }
): string {
  if (typeof value === 'string') {
    return value && Boolean(value.trim()) ? '' : params.errorMessage;
  }
  if (typeof value === 'number') {
    return '';
  }
  if (typeof value === 'object') {
    return value && Object.keys(value).length > 0 ? '' : params.errorMessage;
  }
  return value !== undefined && value !== null ? '' : params.errorMessage;
}

export function isEmail(
  value: string,
  params: ValidatorParams = { errorMessage: 'Некорректный email' }
): string {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/.test(value)
    ? ''
    : params?.errorMessage;
}

export function isPhone(
  value: string,
  params: ValidatorParams = { errorMessage: 'Некорректный телефон' }
): string {
  return /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(value)
    ? ''
    : params?.errorMessage;
}

export interface GreaterThanParams extends ValidatorParams {
  min: number;
}
export function greaterThan(value: string, params?: GreaterThanParams): string;
export function greaterThan(value: number, params?: GreaterThanParams): string;

export function greaterThan(
  value: any,
  params: GreaterThanParams = {
    errorMessage: 'Поле должно быть больше 5',
    min: 5,
  }
): string {
  if (typeof value === 'string') {
    return value.trim().length < params?.min ? params?.errorMessage : '';
  }
  if (typeof value === 'number') {
    return value < params?.min ? params?.errorMessage : '';
  }
  return params?.errorMessage;
}
