import * as React from 'react';
import cn from 'classnames';

import defaultStyles from './Spinner.modules.scss';

const Spinner = ({ className, local }) => {
  return (
    <svg
      width="200px"
      height="200px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      className={cn(className, local && defaultStyles.local)}
    >
      <circle
        cx="50"
        cy="50"
        r="22.6595"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
      >
        <animate
          attributeName="r"
          calcMode="spline"
          values="0;40"
          keyTimes="0;1"
          dur="1.6"
          keySplines="0 0.2 0.8 1"
          begin="-0.8s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="opacity"
          calcMode="spline"
          values="1;0"
          keyTimes="0;1"
          dur="1.6"
          keySplines="0.2 0 0.8 1"
          begin="-0.8s"
          repeatCount="indefinite"
        />
      </circle>
      <circle
        cx="50"
        cy="50"
        r="38.9441"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
      >
        <animate
          attributeName="r"
          calcMode="spline"
          values="0;40"
          keyTimes="0;1"
          dur="1.6"
          keySplines="0 0.2 0.8 1"
          begin="0s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="opacity"
          calcMode="spline"
          values="1;0"
          keyTimes="0;1"
          dur="1.6"
          keySplines="0.2 0 0.8 1"
          begin="0s"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  );
};

Spinner.defaultProps = {
  className: defaultStyles.spinner,
  local: false,
};

export default Spinner;
