import * as React from 'react';

type Props = React.SVGProps<any>;

const BurgerIcon: React.FC<Props> = (props: Props) => {
  return (
    <svg
      width="15px"
      height="13px"
      viewBox="0 0 15 13"
      version="1.1"
      {...props}
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Статьи"
          transform="translate(-486.000000, -8040.000000)"
          fill="currentColor"
        >
          <g id="Group-4" transform="translate(486.000000, 8040.000000)">
            <rect id="Rectangle" x="0" y="0" width="15" height="2"></rect>
            <rect id="Rectangle" x="0" y="5.5" width="15" height="2"></rect>
            <rect id="Rectangle" x="0" y="11" width="15" height="2"></rect>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default React.memo(BurgerIcon);
