import * as React from 'react';
import cn from 'classnames';

import { LessonTypeEnum } from 'store/models/lesson/types';

import styles from './Corner.modules.scss';

type Props = {
  type: LessonTypeEnum;
  className?: string;
  disabled?: boolean;
};

const Corner: React.FC<Props> = ({ type, className, disabled }: Props) => (
  <div
    className={cn(
      styles.root,
      className,
      type === LessonTypeEnum.intro && styles.intro,
      type === LessonTypeEnum.theory && styles.theory,
      type === LessonTypeEnum.test && styles.test,
      type === LessonTypeEnum.video && styles.video,
      type === LessonTypeEnum.practice && styles.practice,
      type === LessonTypeEnum.final && styles.finalTask,
      disabled && styles.disabled
    )}
  />
);

export default React.memo(Corner);
