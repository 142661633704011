import { observer } from 'mobx-react';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import BaseCard from 'components/common/BaseCard';
import Button, { ButtonColor } from 'components/common/Button';
import Spinner from 'components/common/Spinner';
import PageSpinner from 'components/common/Spinner/PageSpinner';
import urls from 'config/urls';
import Header from 'pages/Lesson/components/Header';
import { useLessonStore } from 'pages/Lesson/context';
import {
  HomeworkChatModel,
  LoadDirection,
  MAX_MESSAGES_LOAD,
} from 'store/models/chunks/homework/chat/HomeworkChatModel';
import { HomeworkStatus } from 'store/models/chunks/homework/types';
import { animateScroll } from 'utils/scroll';

import styles from './HomeworkChat.modules.scss';
import Input from './Input';
import Message from './Message';

const HomeworkChat: React.FC = () => {
  const chatContainer = useRef(null);
  const container = useRef<HTMLDivElement>(null);
  const [chatHeight, setChatHeight] = useState(500);

  const lessonStore = useLessonStore();
  const { lesson, unit, isAdmin } = lessonStore;

  const { chunkId, conversationId } = useParams<{
    chunkId: string;
    conversationId: string;
  }>();

  const [homeworkChat] = useState(
    () => new HomeworkChatModel({ chunkId, conversationId, unit })
  );

  useEffect(() => {
    homeworkChat.startPolling();

    if (container.current) {
      const { y } = container.current.getBoundingClientRect();

      setChatHeight(window.innerHeight - y - 16);
    }

    return (): void => {
      homeworkChat.stopPolling();
    };
  }, [chunkId, conversationId]);

  useEffect(() => {
    return (): void => {
      homeworkChat.stopPolling();
    };
  }, []);

  useEffect(() => {
    if (chatContainer.current) {
      animateScroll(chatContainer.current, 300);
    }
  }, [homeworkChat.lastMessageId]);

  const handleSendMessage = useCallback(async () => {
    await homeworkChat.sendMessage(!isAdmin);
  }, [isAdmin]);

  const handleScroll = useCallback(async (e: any) => {
    const { scrollHeight, scrollTop } = e.target;
    if (scrollTop / scrollHeight < 0.2) {
      await homeworkChat.loadMessages({
        direction: LoadDirection.desc,
        limit: MAX_MESSAGES_LOAD,
      });
    }
  }, []);

  const handleApprove = async (): Promise<void> => {
    homeworkChat.stopPolling();
    const result = await homeworkChat.homework?.approveHomework();

    if (result) {
      await homeworkChat.loadMessages();
    } else {
      homeworkChat.startPolling();
    }
  };

  const handleReject = async (): Promise<void> => {
    homeworkChat.stopPolling();
    const result = await homeworkChat.homework?.rejectHomework();

    if (result) {
      await homeworkChat.loadMessages();
    }

    homeworkChat.startPolling();
  };

  return (
    <BaseCard
      className={styles.card}
      ref={container}
      style={{ height: chatHeight }}
    >
      {lesson && lesson.courseId && (
        <Header
          type={lesson.type}
          courseId={lesson.courseId}
          backText="Вернуться к заданию"
          backLink={urls.lessonCard(lesson.id, unit?.id)}
        />
      )}
      {homeworkChat.isInitiallyLoaded && homeworkChat.messages.length === 0 && (
        <div className={styles.emptyStub}>
          Загрузите свое решение, оно попадет на проверку к преподавателю
        </div>
      )}
      <div className={styles.chat} ref={chatContainer} onScroll={handleScroll}>
        {((homeworkChat.isLoading && !homeworkChat.isInitiallyLoaded) ||
          homeworkChat.isLoadingHomework) && (
          <Spinner className={styles.chatLoader} />
        )}
        {homeworkChat.messages.map((msg) => (
          <div className={styles.message} key={msg.id}>
            <Message message={msg} />
          </div>
        ))}
        {homeworkChat.isLoadingOlderMessages &&
          homeworkChat.isInitiallyLoaded && (
            <PageSpinner className={styles.olderLoader} />
          )}
      </div>
      {isAdmin && homeworkChat?.homework?.status === HomeworkStatus.checking && (
        <div className={styles.acceptWrapper}>
          <Button
            className={styles.acceptBtn}
            onClick={handleApprove}
            isLoading={homeworkChat.homework?.isApproving}
          >
            Принять
          </Button>
          <Button
            color={ButtonColor.red}
            onClick={handleReject}
            isLoading={homeworkChat.homework?.isApproving}
          >
            Отклонить
          </Button>
        </div>
      )}
      {homeworkChat.homework?.status !== HomeworkStatus.finished && (
        <Input
          onSendMessage={handleSendMessage}
          message={homeworkChat.newMessage}
          isSending={homeworkChat.isSending}
        />
      )}
    </BaseCard>
  );
};

export default observer(HomeworkChat);
