import { action, makeObservable, observable } from 'mobx';

import request from 'utils/request';
import apiUrls from 'config/apiUrls';
import { ChapterCreationFormModel } from 'store/models/chapter/ChapterCreationFormModel';
import { ServerResponse, ShiftPositionEnum } from 'store/models/common/types';
import { getSwapIndexes } from 'utils/getSwapIndexes';
import { swapArrayElements } from 'utils/array';

import CourseModel from '../course/CourseModel';
import { LessonModel } from '../lesson/LessonModel';
import { LessonServerType } from '../lesson/types';

import { ChapterServerType, LessonListResponseType } from './types';

type ChapterType = {
  course: CourseModel | null;
  lessons: LessonModel[];
  description: string;
  title: string;
  id?: number;

  maxAvailablePoints: number;
  countLessons: number;
  countCompleteLessons: number;
  points: number;
  theme: string;
  required: boolean;
  isPublished: boolean;
  sendNotifications: boolean;

  courseId?: number;
};

export class ChapterModel implements ChapterType {
  id?: number;

  course: CourseModel | null = null;

  isLoadingLessons = false;
  isDeletingLesson = false;
  isLoadedLessons = false;
  lessons: LessonModel[] = [];
  description: string;
  title: string;
  isPublished: boolean;
  sendNotifications: boolean;

  maxAvailablePoints: number;
  countLessons: number;
  countCompleteLessons: number;
  points: number;
  theme: string;
  required: boolean;

  courseId?: number;

  constructor({
    course,
    maxAvailablePoints,
    countLessons,
    countCompleteLessons,
    points,
    theme,
    description,
    title,
    id,
    courseId,
    isPublished,
    sendNotifications,
    required,
  }: ChapterType) {
    makeObservable(this, {
      // observable
      id: observable,
      isLoadingLessons: observable,
      isDeletingLesson: observable,
      isLoadedLessons: observable,
      lessons: observable,
      description: observable,
      title: observable,
      isPublished: observable,
      sendNotifications: observable,

      //action
      loadLessons: action.bound,
      initLessons: action,
      addLesson: action.bound,
      deleteLesson: action.bound,
      updateFrom: action.bound,
      shiftLesson: action.bound,
    });

    this.course = course;

    this.maxAvailablePoints = maxAvailablePoints;
    this.countCompleteLessons = countCompleteLessons;
    this.points = points;
    this.countLessons = countLessons;
    this.theme = theme;
    this.description = description;
    this.title = title;
    this.id = id;
    this.courseId = courseId;
    this.required = required;
    this.isPublished = isPublished;
    this.sendNotifications = sendNotifications;
  }

  async loadLessons(): Promise<void> {
    this.isLoadingLessons = true;

    const { response }: ServerResponse<LessonListResponseType> = await request(
      apiUrls.lesson.list,
      'GET',
      { chapter_id: this.id }
    );

    if (response) {
      this.lessons = response.lessons.map((lesson) =>
        LessonModel.fromJson(lesson, this)
      );
    }

    this.isLoadedLessons = true;

    this.isLoadingLessons = false;
  }

  initLessons(lessons: LessonServerType[]): void {
    this.lessons = lessons.map((lesson) => LessonModel.fromJson(lesson, this));

    this.isLoadedLessons = true;
  }

  addLesson(lesson: LessonModel): void {
    this.lessons.push(lesson);
  }

  async deleteLesson(id: number): Promise<boolean> {
    this.isDeletingLesson = true;

    const { response }: ServerResponse = await request(
      apiUrls.lesson.delete,
      'POST',
      {
        id,
      }
    );

    if (response) {
      this.lessons = this.lessons.filter((l) => l.id !== id);
    }

    this.isDeletingLesson = false;

    return Boolean(response);
  }

  updateFrom(chapterForm: ChapterCreationFormModel): ChapterModel {
    this.description = chapterForm.values.description;
    this.title = chapterForm.values.title;
    this.isPublished = chapterForm.values.isPublished;
    this.sendNotifications = chapterForm.values.sendNotifications;

    if (chapterForm.id) {
      this.id = chapterForm.id;
    }

    return this;
  }

  async shiftLesson(
    direction: ShiftPositionEnum,
    lesson: LessonModel
  ): Promise<void> {
    const swapIndexes = getSwapIndexes(direction, this.lessons, lesson);
    if (!swapIndexes) {
      return;
    }

    const [index, indexToSwap] = swapIndexes;

    this.lessons = swapArrayElements(this.lessons, index, indexToSwap);

    const { error } = await request(apiUrls.lesson.swap, 'POST', {
      id1: this.lessons[index].id,
      id2: this.lessons[indexToSwap].id,
    });

    if (error) {
      this.lessons = swapArrayElements(this.lessons, index, indexToSwap);
    }
  }

  static fromForm(
    form: ChapterCreationFormModel,
    course: CourseModel
  ): ChapterModel {
    return new ChapterModel({
      countLessons: 0,
      countCompleteLessons: 0,
      maxAvailablePoints: 0,
      theme: '',
      id: form.id,
      course,
      courseId: course?.id,
      title: form.values.title,
      description: form.values.description,
      isPublished: form.values.isPublished,
      sendNotifications: form.values.sendNotifications,

      // defaults
      required: false,
      lessons: [],
      points: 0,
    });
  }

  static createDefault(course: CourseModel): ChapterModel {
    return new ChapterModel({
      course,
      maxAvailablePoints: 0,
      countLessons: 0,
      countCompleteLessons: 0,
      points: 0,
      theme: '',
      description: '',
      title: '',
      isPublished: false,
      courseId: course.id,
      required: false,
      lessons: [],
      sendNotifications: false,
    });
  }

  static fromJson(json: ChapterServerType, course: CourseModel): ChapterModel {
    return new ChapterModel({
      course,
      maxAvailablePoints: json.max_available_points,
      countLessons: json.count_lessons,
      countCompleteLessons: json.count_complete_lessons,
      points: json.points,
      theme: json.theme,
      description: json.description,
      title: json.title,
      id: json.id,
      courseId: json.course_id,
      required: json.required,
      lessons: [],
      isPublished: json.is_published,
      sendNotifications: json.send_notifications,
    });
  }
}
