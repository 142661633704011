import { useCallback } from 'react';
import * as React from 'react';
import CodeEditor from '@uiw/react-textarea-code-editor';

type Props = {
  code: string;
  onChange(code: string): void;
  language?: string;
  readOnly?: boolean;
};

const SimpleCodeInput: React.FC<Props> = ({
  code,
  onChange,
  language = 'python',
  readOnly = false,
}: Props) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value),
    [onChange]
  );

  return (
    <CodeEditor
      readOnly={readOnly}
      placeholder="# Тут можно писать код"
      language={language}
      value={code}
      onChange={handleChange}
      style={{
        fontSize: 12,
        backgroundColor: '#f5f5f5',
        fontFamily:
          'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
      }}
    />
  );
};

export default React.memo(SimpleCodeInput);
