import cn from 'classnames';
import { EditorState } from 'draft-js';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as React from 'react';
import Editor from '@draft-js-plugins/editor';
import Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import {
  BoldButton,
  ItalicButton,
  UnderlineButton,
  OrderedListButton,
  UnorderedListButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  CodeBlockButton,
  BlockquoteButton,
} from '@draft-js-plugins/buttons';
// eslint-disable-next-line import/named
import { Prompt } from 'react-router-dom';

import './Constructor.scss';
import { ChunkModel } from 'store/models/chunks/ChunkModel';
import { useUiStore } from 'store/context';
import { LayoutEnum } from 'store/stores';

import Admin from './Editor/Admin';
import {
  InlineToolbar,
  AlignmentTool,
  SideToolbar,
  linkPlugin,
  plugins,
  handleReturn,
  handleKeyCommand,
} from './Editor/plugins';
import { ConstructorContext, useWithCustomChunks } from './context';
import { blockStyleFn } from './Editor/styling';
import { IChunkContainer } from './models/chunkContainer/ChunkContainer';

type Props = {
  unit: IChunkContainer;
  isEditMode: boolean;
  isAdmin: boolean;
  editorState: EditorState;
  setEditorState(state: EditorState): void;
};

const Constructor: React.FC<Props> = ({
  unit,
  isAdmin,
  isEditMode,
  editorState,
  setEditorState,
}: Props) => {
  const editor = useRef<Editor>(null);
  const [readOnly, setReadonly] = useState(false);
  const [chunkToEdit, setChunkToEdit] = useState<ChunkModel | null>(null);
  const lastChunkToEditId = useRef<string | null>(null);

  useWithCustomChunks(setReadonly);

  const ui = useUiStore();

  useEffect(() => {
    if (isEditMode) {
      ui.setLayout(LayoutEnum.fixed);
      editor.current?.focus();
    }

    return (): void => {
      ui.setLayout(LayoutEnum.absolute);
    };
  }, [isEditMode]);

  const dropChunkToEdit = useCallback((chunkId: string) => {
    if (chunkId === lastChunkToEditId.current) {
      setChunkToEdit(null);
    }
  }, []);

  const contextValue = useMemo(
    () => ({
      isAdmin,
      isEditMode,
      readOnly,
      setReadonly,
      chunkToEdit,
      setChunkToEdit: (c: ChunkModel) => {
        lastChunkToEditId.current = c.id;
        setChunkToEdit(c);
      },
      dropChunkToEdit,
    }),
    [isAdmin, isEditMode, readOnly, chunkToEdit?.id]
  );

  return (
    <>
      {/*// @ts-ignore*/}
      <DndProvider backend={Backend}>
        <ConstructorContext.Provider value={contextValue}>
          {isAdmin && <Admin />}
          <div
            className={cn('constructor', readOnly && 'constructor_read-only')}
            id="constructor"
          >
            <Editor
              ref={editor}
              readOnly={!isEditMode || readOnly}
              editorState={editorState}
              onChange={setEditorState}
              plugins={plugins}
              blockStyleFn={blockStyleFn}
              handleKeyCommand={(e, state) =>
                handleKeyCommand(e, state, setEditorState)
              }
              handleReturn={(e, state) =>
                handleReturn(e, state, setEditorState)
              }
              placeholder={
                !isEditMode
                  ? 'Здесь можно писать. А еще кидать картинки прямо сюда. Задания и другие сложные блоки доступны в меню по нажатию на плюсик. Чтобы начать, включите режим редактирования выше'
                  : ''
              }
            />
            {isEditMode && (
              <>
                <AlignmentTool />
                <InlineToolbar>
                  {(externalProps) => (
                    <>
                      <BoldButton {...externalProps} />
                      <ItalicButton {...externalProps} />
                      <UnderlineButton {...externalProps} />
                      <HeadlineOneButton {...externalProps} />
                      <HeadlineTwoButton {...externalProps} />
                      <HeadlineThreeButton {...externalProps} />
                      <br />
                      <OrderedListButton {...externalProps} />
                      <UnorderedListButton {...externalProps} />
                      <CodeBlockButton {...externalProps} />
                      <BlockquoteButton {...externalProps} />
                      <linkPlugin.LinkButton {...externalProps} />
                    </>
                  )}
                </InlineToolbar>
                <SideToolbar unit={unit} />
              </>
            )}
          </div>
        </ConstructorContext.Provider>
      </DndProvider>
      <Prompt
        when={isEditMode}
        message="Вы уверены, что хотите покинуть страницу? Все несохраненные изменения удалятся"
      />
    </>
  );
};

export default observer(Constructor);
