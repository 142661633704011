import * as propTypes from 'prop-types';
import * as React from 'react';

const LongArrowRightIcon = (props) => {
  const { className } = props;
  return (
    <svg
      width="22px"
      height="15px"
      viewBox="0 0 22 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-1324.000000, -862.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <g transform="translate(170.000000, 818.000000)">
            <g transform="translate(981.000000, 29.000000)">
              <g transform="translate(184.000000, 22.500000) scale(-1, 1) translate(-184.000000, -22.500000) translate(173.000000, 15.000000)">
                <path d="M7.17651355,0.225599566 C7.48666048,-0.0751998555 7.97493233,-0.0751998555 8.28507926,0.225599566 C8.58474825,0.516236846 8.58474825,0.999954835 8.28507926,1.28991464 L2.66401543,6.74156542 L21.2127577,6.74156542 C21.6451472,6.74156542 22,7.0748837 22,7.49424145 C22,7.91359921 21.6451472,8.2577571 21.2127577,8.2577571 L2.66401543,8.2577571 L8.28507926,13.6992457 C8.58474825,14.0000452 8.58474825,14.4844406 8.28507926,14.7744004 C7.97493233,15.0751999 7.48666048,15.0751999 7.17651355,14.7744004 L0.224751744,8.03215754 C-0.0749172481,7.74152026 -0.0749172481,7.25780227 0.224751744,6.96784246 L7.17651355,0.225599566 Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

LongArrowRightIcon.propTypes = {
  className: propTypes.string,
};

export default LongArrowRightIcon;
