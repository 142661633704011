import * as React from 'react';
import * as propTypes from 'prop-types';

const CameraIcon = (props) => {
  const { className } = props;

  return (
    <svg
      width="21px"
      height="18px"
      viewBox="0 0 21 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="currentColor" fillRule="nonzero">
          <path d="M19.6875,7.71428571 L15.75,10.2857143 C15.75,9.50527731 15.38775,8.81485714 14.8273039,8.3429916 C16.1614559,7.5554958 17.0625,6.13670588 17.0625,4.5 C17.0625,2.01468908 15.0058039,0 12.46875,0 C9.93169608,0 7.875,2.01472269 7.875,4.5 C7.875,5.76063866 8.40720588,6.89721008 9.26034804,7.71428571 L6.85320588,7.71428571 C7.48190196,7.03092437 7.875,6.13542857 7.875,5.14285714 C7.875,3.01243697 6.11230392,1.28571429 3.9375,1.28571429 C1.76269608,1.28571429 0,3.01243697 0,5.14285714 C0,6.30514286 0.536151961,7.3349916 1.36630392,8.04215126 C0.555848039,8.47993277 0,9.3149916 0,10.2857143 L0,15.4285714 C0,16.8486387 1.17534804,18 2.625,18 L13.125,18 C14.574652,18 15.75,16.8486387 15.75,15.4285714 L19.6875,18 C20.412652,18 21,17.4246387 21,16.7142857 L21,9 C21,8.28964706 20.412652,7.71428571 19.6875,7.71428571 Z M3.9375,7.71428571 C2.48784804,7.71428571 1.3125,6.56292437 1.3125,5.14285714 C1.3125,3.72278992 2.48784804,2.57142857 3.9375,2.57142857 C5.38715196,2.57142857 6.5625,3.72278992 6.5625,5.14285714 C6.5625,6.56292437 5.38715196,7.71428571 3.9375,7.71428571 Z M12.46875,7.72134454 C10.65225,7.72134454 9.18029412,6.2787563 9.18029412,4.5 C9.18029412,2.72057143 10.6529363,1.27865546 12.46875,1.27865546 C14.2845637,1.27865546 15.7572059,2.72057143 15.7572059,4.5 C15.7572059,6.27942857 14.2852157,7.72134454 12.46875,7.72134454 Z" />
        </g>
      </g>
    </svg>
  );
};

CameraIcon.propTypes = {
  className: propTypes.string.isRequired,
};

export default CameraIcon;
