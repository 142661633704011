import cogoToast from 'cogo-toast';
import copy from 'copy-to-clipboard';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useState } from 'react';

import Button, { ButtonColor } from 'components/common/Button';
import EditModal from 'components/common/EditModal/EditModal';
import Modal from 'components/common/Modal/Modal';
import Title from 'components/common/Title';
import { FormElement, Input, InputField } from 'components/form';
import { useFieldBind } from 'components/form/hooks/useField';
import Label from 'components/form/Label/Label';
import { useUserStore } from 'store/context';
import noop from 'utils/noop';

import { SideApiStore } from '../../store/SideApiStore';

import { TokenCreationForm } from './form';
import styles from './TokenCreationModal.modules.scss';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  sideApiStore: SideApiStore;
};

const TokenCreationModal: React.FC<Props> = ({
  isOpen,
  onClose,
  sideApiStore,
}: Props) => {
  const [form, setForm] = useState(() => new TokenCreationForm(sideApiStore));
  const user = useUserStore();

  const bindField = useFieldBind(form, 'name');

  const clearFormAndClose = () => {
    setForm(new TokenCreationForm(sideApiStore));
    onClose();
  };

  const onSave = () => {
    if (user.selectedCompany?.id) {
      form.save(user.selectedCompany.id);
    }
  };

  const onClickTokenInput = React.useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      event.currentTarget.select();
    },
    []
  );

  const onCopy = React.useCallback(() => {
    if (form.token?.token) {
      copy(form.token.token);
      cogoToast.success('Токен  скопирован');
    }
  }, [form.token?.token]);

  if (form.token) {
    return (
      <Modal isOpen={isOpen} onClose={clearFormAndClose}>
        <FormElement>
          <Title index={3}>Создать ключ</Title>
        </FormElement>
        <Label isStatic>Токен</Label>
        <div className={styles.tokenField}>
          <div className={styles.tokenInput}>
            <Input
              onClick={onClickTokenInput}
              onChange={noop}
              value={form.token.token}
            />
          </div>
          <div className={styles.tokenCopy}>
            <Button color={ButtonColor.transparent} onClick={onCopy}>
              Скопировать
            </Button>
          </div>
        </div>

        <Button className={styles.closeBtn} onClick={clearFormAndClose}>
          Закрыть
        </Button>
      </Modal>
    );
  }

  return (
    <EditModal
      isSaving={form.isSaving}
      isOpen={isOpen}
      onClose={onClose}
      title="Создать ключ"
      onSave={onSave}
    >
      <InputField {...bindField} placeholder="Название" />
    </EditModal>
  );
};

export default observer(TokenCreationModal);
