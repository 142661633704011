import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import toast from 'cogo-toast';

import { SwitchField } from 'components/form';
import useForm from 'components/form/hooks/useForm';
import EditModal from 'components/common/EditModal/EditModal';
import noop from 'utils/noop';
import { LessonModel } from 'store/models/lesson/LessonModel';
import { UnitModel } from 'store/models/unit/UnitModel';
import {
  UnitCreationFormModel,
  UnitFieldsEnum,
} from 'store/models/unit/UnitCreationFormModel';

export type UnitCreationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: (unit: UnitModel) => void;
  resetOnOpen?: boolean;
  lesson: LessonModel;
  unitModel?: UnitModel;
  title?: string;
};

const UnitCreationModal: React.FC<UnitCreationModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  resetOnOpen,
  lesson,
  unitModel,
  title,
}: UnitCreationModalProps) => {
  const [formModel, setFormModel] = useState(
    () => new UnitCreationFormModel(lesson, unitModel)
  );

  const form = useForm(formModel, Object.values(UnitFieldsEnum));

  useEffect(() => {
    if (!isOpen && resetOnOpen) {
      setFormModel(new UnitCreationFormModel(lesson, unitModel));
    }
  }, [isOpen, resetOnOpen, lesson, unitModel]);

  const handleSave = useCallback(async () => {
    const unit = await formModel.save();
    if (unit) {
      toast.success('Изменения успешно сохранены!');

      if (onSuccess) {
        onSuccess(unit as UnitModel);
      }

      onClose();
    } else {
      toast.error('При сохранении произошла ошибка');
    }
  }, [onClose, onSuccess, formModel]);

  return (
    <EditModal
      isOpen={isOpen}
      onClose={onClose}
      isSaving={formModel.isSaving}
      title={title || 'Добавление карточки'}
      onSave={handleSave}
    >
      <SwitchField
        isActive={form.required.value}
        onChange={form.required.setValue}
        errors={form.required.errors}
      >
        Обязательная
      </SwitchField>
    </EditModal>
  );
};

UnitCreationModal.defaultProps = {
  onSuccess: noop,
};

export default observer(UnitCreationModal);
