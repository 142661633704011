import { action, makeObservable, observable } from 'mobx';

import { LessonCreationFormModel } from 'store/models/lesson/LessonCreationFormModel';
import { ServerResponse } from 'store/models/common/types';
import request from 'utils/request';
import apiUrls from 'config/apiUrls';

import { UnitModel } from '../unit/UnitModel';
import { ChapterModel } from '../chapter/ChapterModel';

import {
  LessonUnitType,
  LessonGetResponseType,
  LessonServerType,
  LessonTypeEnum,
} from './types';
import { normalizeLessonUnit } from './utils';

type LessonType = {
  accessLevel: number;
  title: string;
  type: LessonTypeEnum;
  courseName: string;
  maxAvailablePoints: number;
  points: number;
  unitCount: number;
  countCompletedUnits: number;
  required: boolean;
  isPublished: boolean;
  sendNotifications: boolean;
  chapterName: string;
  hasEnoughAccessLevel: boolean;

  id?: number;
  chapter?: ChapterModel;
  courseId?: number;
  firstUnit?: LessonUnitType;
  nextUnit?: LessonUnitType;
  prevUnit?: LessonUnitType;
};

export class LessonModel implements LessonType {
  isDeleting = false;

  accessLevel = 0;
  title: string;
  type: LessonTypeEnum;
  units: UnitModel[] = [];
  isPublished: boolean;
  sendNotifications: boolean;

  courseName: string;
  maxAvailablePoints: number;
  points: number;
  unitCount: number;
  countCompletedUnits: number;
  required: boolean;
  hasEnoughAccessLevel: boolean;

  courseId?: number;
  nextUnit?: LessonUnitType;
  prevUnit?: LessonUnitType;
  id?: number;
  chapter?: ChapterModel;
  chapterName: string;

  constructor({
    accessLevel,
    hasEnoughAccessLevel,
    chapter,
    maxAvailablePoints,
    points,
    unitCount,
    title,
    id,
    countCompletedUnits,
    type,
    courseName,
    required,
    courseId,
    isPublished,
    sendNotifications,
    chapterName,
  }: LessonType) {
    makeObservable(this, {
      // observable
      accessLevel: observable,
      hasEnoughAccessLevel: observable,
      isDeleting: observable,
      title: observable,
      type: observable,
      units: observable,
      isPublished: observable,
      sendNotifications: observable,
      unitCount: observable,
      // action
      updateFrom: action,
      addUnit: action.bound,
      deleteUnit: action.bound,
    });
    this.accessLevel = accessLevel;
    this.hasEnoughAccessLevel = hasEnoughAccessLevel;
    this.chapter = chapter;
    this.chapterName = chapterName;
    this.maxAvailablePoints = maxAvailablePoints;
    this.points = points;
    this.unitCount = unitCount;
    this.title = title;
    this.id = id;
    this.countCompletedUnits = countCompletedUnits;
    this.type = type;
    this.required = required;
    this.courseName = courseName;
    this.courseId = courseId;
    this.isPublished = isPublished;
    this.sendNotifications = sendNotifications;
  }

  updateFrom(form: LessonCreationFormModel): void {
    this.accessLevel = form.values.accessLevel;
    this.title = form.values.title;
    this.type = form.values.type;
    this.isPublished = form.values.isPublished;
    this.sendNotifications = form.values.sendNotifications;
  }

  addUnit(unit: UnitModel): void {
    this.units.push(unit);
  }

  async deleteUnit(unitId: number): Promise<boolean> {
    if (this.units.length < 2) {
      return false;
    }

    const { response }: ServerResponse = await request(
      apiUrls.unit.delete,
      'POST',
      {
        id: unitId,
      }
    );

    if (response) {
      this.units = this.units.filter((u) => u.id !== unitId);
      this.unitCount -= 1;
    }

    return Boolean(response);
  }

  getUnitById(id: number): UnitModel | undefined {
    return this.units.find((u) => u.id === id);
  }

  getUnitIndexById(id: number): number | undefined {
    const index = this.units.findIndex((u) => u.id === id);
    return index === -1 ? undefined : index;
  }

  getUnitByIndex(index: number): UnitModel | undefined {
    return this.units[index];
  }

  getFirstUnit(): UnitModel | undefined {
    return this.units.length > 0 ? this.units[0] : undefined;
  }

  static async load(
    id: number
  ): Promise<{ lesson: LessonModel | null; unavailable: boolean }> {
    const { response, error }: ServerResponse<LessonGetResponseType> =
      await request(apiUrls.lesson.get, 'GET', { id });

    if (error?.data?.status === 'forbidden') {
      return { lesson: null, unavailable: true };
    }

    if (response) {
      const lesson = LessonModel.fromJson(response.lesson);
      lesson.units = response.units.map((unit) =>
        UnitModel.fromJson(unit, lesson)
      );
      lesson.nextUnit = response.next_unit
        ? normalizeLessonUnit(response.next_unit)
        : undefined;
      lesson.prevUnit = response.prev_unit
        ? normalizeLessonUnit(response.prev_unit)
        : undefined;

      return { lesson, unavailable: false };
    }

    return { lesson: null, unavailable: false };
  }

  static fromForm(
    form: LessonCreationFormModel,
    chapter: ChapterModel
  ): LessonModel {
    return new LessonModel({
      chapter,
      accessLevel: form.values.accessLevel,
      title: form.values.title,
      type: form.values.type,
      id: form.id,
      isPublished: form.values.isPublished,
      sendNotifications: form.values.sendNotifications,

      courseId: chapter.courseId,
      courseName: chapter.course?.title || '',
      chapterName: chapter.title,

      // Дефолтные поля
      maxAvailablePoints: 0,
      points: 0,
      unitCount: 0,
      countCompletedUnits: 0,
      required: false,
      hasEnoughAccessLevel: true,
    });
  }

  static fromJson(json: LessonServerType, chapter?: ChapterModel): LessonModel {
    return new LessonModel({
      chapter,
      accessLevel: json.access_level,
      hasEnoughAccessLevel: json.has_user_enough_access_level,
      maxAvailablePoints: json.max_available_points,
      points: json.points,
      unitCount: json.unit_count,
      firstUnit: json.first_unit
        ? normalizeLessonUnit(json.first_unit)
        : undefined,
      title: json.title,
      id: json.id,
      countCompletedUnits: json.count_completed_units,
      type: json.type as LessonTypeEnum,
      required: json.required,
      courseName: json.course_name,
      courseId: json.course_id,
      chapterName: json.chapter_name,
      isPublished: json.is_published,
      sendNotifications: json.send_notifications,
    });
  }
}
