export const CONFIG = {
  appStoreLink:
    'https://apps.apple.com/ru/app/uclass/id1539986693?platform=iphone',
  googlePlayLink:
    'https://play.google.com/store/apps/details?id=online.uclass.app',
  huaweiStoreLink:
    'https://appgallery.huawei.com/app/C105776085?sharePrepath=ag&channelId=metaclass&id=ce9b639db645410882eadeaeedf4e717&s=08F4B36A0F1A6BB2BBC12D561E312E568556DEDC82D7430D53BBD02355C8138C&detailType=0&v=&callType=AGDLINK&installType=0000',
  landingLink: 'https://uclass.online/for-individuals',
  metaclassLink: 'https://metaclass.kts.studio',
  paymentsEnabled: true,
  aboutCallbackTask:
    'https://docs.google.com/document/d/1khJMI5RnAfy3tsXzQARzZzozaJF35dx3mTQzYxyNfN0/edit#heading=h.l7h6u6l9m86n',
};
