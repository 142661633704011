import * as React from 'react';
import { useState, useEffect } from 'react';
import { Switch, Route, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import cn from 'classnames';

import urls from 'config/urls';
import { LESSON_EDIT_BAR_ELEMENT_ID } from 'components/Constructor/config';
import PageSpinner from 'components/common/Spinner/PageSpinner';
import ContentLayout from 'components/common/ContentLayout';
import Title from 'components/common/Title';
import { useRootStore } from 'store/context';
import LockIcon from 'components/icons/LockIcon';

import Navigation from './components/Navigation';
import { LessonStore } from './LessonStore';
import HomeworkChat from './HomeworkChat';
import LessonPage from './LessonPage';
import { LessonContext } from './context';
import styles from './Lesson.modules.scss';

const LessonRoute: React.FC = () => {
  const rootStore = useRootStore();
  const [lessonStore] = useState(() => new LessonStore(rootStore));
  const { id, unitId } = useParams();

  useEffect(() => {
    lessonStore.load(Number(id), Number(unitId));
  }, [id, unitId]);

  useEffect(() => {
    if (unitId) {
      lessonStore.setUnit(Number(unitId));
    }
  }, [unitId]);

  const { lesson, lessonUnavailable, isLoading, isEditMode } = lessonStore;

  if (!isLoading && lessonUnavailable) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.mock}>
          <LockIcon className={styles.mock__icon} />
          <div>
            Этот урок не&nbsp;опубликован
            <br />
            или у&nbsp;вас нет к&nbsp;нему доступа
          </div>
        </div>
      </div>
    );
  }

  if (isLoading || !lesson) {
    return (
      <div className={styles.wrapper}>
        <PageSpinner />
      </div>
    );
  }

  return (
    <LessonContext.Provider value={lessonStore}>
      <ContentLayout
        className={cn(styles.content, isEditMode && styles.contentEditMode)}
      >
        <div className={styles.lesson}>
          <Navigation lesson={lesson} />
          <Title index={5} className={styles.title}>
            {lesson.title}
          </Title>
          <Switch>
            <Route path={urls.lessonHomeworkPattern} component={HomeworkChat} />
            <Route path={urls.lessonPagePattern} component={LessonPage} />
          </Switch>
        </div>
      </ContentLayout>
      <div
        className={cn(
          styles['edit-bar'],
          styles[`edit-bar_${rootStore.uiStore.layout}`],
          isEditMode && styles['edit-bar_visible']
        )}
        id={LESSON_EDIT_BAR_ELEMENT_ID}
      />
    </LessonContext.Provider>
  );
};

export default observer(LessonRoute);
