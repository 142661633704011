import axios from 'axios';

import iterate from 'utils/iterate';
import { ServerResponse } from 'store/models/common/types';

// TODO request V2
export default async <Response = any, Error = any>(
  url: string,
  methodType: 'GET' | 'POST' = 'GET',
  data: any = {},
  options: any = {}
): Promise<ServerResponse<Response, Error>> => {
  const {
    config = {},
    multipartFormData = false,
    progressCallback = undefined,
  } = options;
  const method = methodType.toLowerCase();
  const { headers = {} } = config;
  if (multipartFormData) {
    Object.assign(headers, { 'Content-Type': 'multipart/form-data' });
  }
  config.headers = headers;

  if (method === 'get') {
    config.params = data;
  } else if (multipartFormData) {
    const formData = new FormData();
    iterate(data, (dataValue: any, dataKey: any) => {
      formData.append(dataKey, dataValue);
    });
    config.data = formData;
  } else {
    config.data = data;
  }

  const result: ServerResponse<Response, Error> = {} as ServerResponse<
    Response,
    Error
  >;

  try {
    const response = await axios({
      method,
      url,
      onUploadProgress: progressCallback,
      ...config,
    });
    result.response = response?.data?.data;
    result.error = null;
  } catch (error) {
    result.error = error.response;
  }

  return result;
};
