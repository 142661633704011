import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import CourseModel from 'store/models/course/CourseModel';
import PageSpinner from 'components/common/Spinner/PageSpinner';
import SwitcherWithText from 'components/common/Switcher/SwitcherWithText';
import CourseCard from 'components/containers/course/CourseCard';
import AddChapter from 'pages/Courses/CoursePage/admin/AddChapter';
import { ChapterModel } from 'store/models/chapter/ChapterModel';
import ChapterBlock from 'components/containers/course/ChapterBlock';
import { CourseEditContext } from 'components/containers/course/context';
import Progress from 'components/containers/course/Progress';
import urls from 'config/urls';
import { useUserStore } from 'store/index';

import styles from './CoursePage.modules.scss';
import cn from 'classnames';

const CoursePage: React.FC = () => {
  const [course, setCourse] = useState<CourseModel | null>(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const { id } = useParams();
  const user = useUserStore();
  const history = useHistory();

  useEffect(() => {
    const fetchCourse = async (): Promise<void> => {
      const c = await CourseModel.load(Number(id));
      if (c) {
        setCourse(c);
      }
    };

    fetchCourse();
  }, []);

  const toggleEditMode = useCallback(() => {
    setIsEditMode((e) => !e);
  }, []);

  const handleContinue = useCallback(() => {
    if (course?.firstUnit) {
      const { lessonId, id } = course.firstUnit;
      return history.push(urls.lessonCard(lessonId, id));
    }
  }, [course]);

  if (!course) {
    return <PageSpinner />;
  }

  const isAdmin = user.isStaffInCourse(course);

  return (
    <CourseEditContext.Provider
      value={{
        course,
        isEditMode,
        isAdmin,
        resetCourse: setCourse,
      }}
    >
      {isAdmin && (
        <div className={styles.switcher}>
          <SwitcherWithText isActive={isEditMode} onToggle={toggleEditMode}>
            Режим редактирования
          </SwitcherWithText>
        </div>
      )}

      <CourseCard
        course={course}
        showLink={false}
        hasContinueButton
        handleContinue={handleContinue}
      />

      {isAdmin && isEditMode && (
        <div className={styles.addChapter}>
          <AddChapter course={course} />
        </div>
      )}

      <div className={styles.chaptersWrapper}>
        {course.chapters.length === 0 && isAdmin && !isEditMode && (
          <div className={styles.chaptersEmpty}>
            Кажется, вы&nbsp;пока не&nbsp;добавили главу.
            <br />
            Включите режим редактирования вверху, чтобы добавить главы!
          </div>
        )}
        {course.chapters.map((chapter: ChapterModel, index) => (
          <div
            key={chapter.id}
            className={cn(
              styles.chapter,
              index === course?.chapters.length - 1 && styles.chapter_last
            )}
          >
            <ChapterBlock
              chapter={chapter}
              isEditMode={isEditMode}
              isAdmin={isAdmin}
            />
          </div>
        ))}
        <Progress course={course} isEditMode={isEditMode} />
      </div>
    </CourseEditContext.Provider>
  );
};

export default observer(CoursePage);
