import * as propTypes from 'prop-types';
import * as React from 'react';

const WarningIcon = (props) => {
  const { className } = props;
  return (
    <svg
      width="28px"
      height="26px"
      viewBox="0 0 28 26"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-1162.000000, -597.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <g transform="translate(1142.000000, 440.000000)">
            <g transform="translate(20.000000, 154.000000)">
              <g transform="translate(0.000000, 3.000000)">
                <path d="M13.5175169,18 C12.6919877,18 12,18.684 12,19.5 C12,20.316 12.6919877,21 13.5175169,21 C14.3126957,21 15.0350338,20.316 14.9986134,19.536 C15.0350338,18.678 14.3491161,18 13.5175169,18 Z" />
                <path d="M27.318638,23.4508658 C28.2223034,21.8664268 28.2280593,19.9779627 27.3301497,18.3993704 L18.3165191,2.54328761 C17.4243654,0.947155386 15.8127329,0 14.0054021,0 C12.1980713,0 10.5864388,0.953002024 9.69428503,2.53744097 L0.669142793,18.4110636 C-0.228766767,20.0071959 -0.223010936,21.9073533 0.686410284,23.4917922 C1.58431984,25.0586913 3.19019656,26 4.98601567,26 L22.9902535,26 C24.7918285,26 26.4092168,25.046998 27.318638,23.4508658 Z M25.3616557,22.3049247 C24.8608984,23.1819204 23.9745005,23.7022712 22.9844977,23.7022712 L4.98025984,23.7022712 C4.00176866,23.7022712 3.12112659,23.1936137 2.631881,22.3341579 C2.13687957,21.4630088 2.13112374,20.4223072 2.62612517,19.5453114 L11.6512674,3.67753542 C12.140513,2.80638633 13.0153992,2.29188217 14.0054021,2.29188217 C14.9896491,2.29188217 15.8702912,2.81223297 16.3595368,3.68338206 L25.3789232,19.5511581 C25.8624129,20.4047673 25.8566571,21.4337756 25.3616557,22.3049247 Z" />
                <path d="M13.1465347,8.03992382 C12.439604,8.23224868 12,8.84316297 12,9.58417937 C12.0356436,10.0310519 12.0653465,10.483581 12.1009901,10.9304534 C12.2019802,12.6330942 12.3029703,14.3017952 12.4039604,16.004436 C12.439604,16.5814106 12.9089109,17 13.5148515,17 C14.1207921,17 14.5960396,16.5531275 14.6257426,15.9704963 C14.6257426,15.6197862 14.6257426,15.2973593 14.6613861,14.9409926 C14.7267327,13.8492661 14.7980198,12.7575397 14.8633663,11.6658132 C14.8990099,10.9587365 14.9643564,10.2516598 15,9.54458307 C15,9.29003545 14.9643564,9.0637709 14.8633663,8.83750635 C14.560396,8.20396561 13.8534653,7.88153863 13.1465347,8.03992382 Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

WarningIcon.propTypes = {
  className: propTypes.string.isRequired,
};

WarningIcon.defaultProps = {};

export default WarningIcon;
