import * as React from 'react';

import { errorStatuses, PaymentGroupStatus } from 'store/stores';
import ErrorWidget from 'pages/GroupPayment/GroupPaymentResult/components/ErrorWidget';
import PageSpinner, {
  PageSpinnerProps,
} from 'components/common/Spinner/PageSpinner/PageSpinner';
import SuccessWidget from 'pages/GroupPayment/GroupPaymentResult/components/SuccessWidget';

export const getStatusWidget = (
  status: PaymentGroupStatus
): React.NamedExoticComponent<PageSpinnerProps> | React.ComponentType => {
  if (errorStatuses.indexOf(status) > -1) {
    return ErrorWidget;
  }

  if (status === PaymentGroupStatus.success) {
    return SuccessWidget;
  }

  return PageSpinner;
};
