import { action, makeObservable, observable } from 'mobx';

import { AttachmentType, parseAttachment } from 'store/models/attachment';
import { ChunkServerType, IChunkModel } from 'store/models/chunks/types';

import { ChunkModel } from '../ChunkModel';

import { QuizQuestionModel } from './QuizQuestionModel';
import { ChunkQuizAnswerServerType } from './types';

type QuizAnswerType = {
  attachment: AttachmentType | null;
  isCorrect: boolean;
  isChecked: boolean;
  plainTitle: string;
  quizQuestion: QuizQuestionModel;
  chunk: ChunkModel<QuizAnswerModel>;
};

export class QuizAnswerModel implements IChunkModel, QuizAnswerType {
  attachment: AttachmentType | null;

  isCorrect: boolean;

  isChecked: boolean;

  plainTitle: string;

  quizQuestion: QuizQuestionModel;

  chunk: ChunkModel<QuizAnswerModel>;

  forceAttachment = false;

  constructor({
    attachment,
    isChecked,
    isCorrect,
    plainTitle,
    quizQuestion,
    chunk,
  }: QuizAnswerType) {
    makeObservable(this, {
      // observable
      attachment: observable,
      isChecked: observable,
      isCorrect: observable,
      plainTitle: observable,
      // action
      setPlainTitle: action.bound,
      toggleIsChecked: action.bound,
      toggleIsCorrect: action.bound,
      setAttachment: action.bound,
    });
    this.attachment = attachment;
    this.isCorrect = isCorrect;
    this.isChecked = isChecked;
    this.plainTitle = plainTitle;
    this.quizQuestion = quizQuestion;
    this.chunk = chunk;
  }

  removeFromParent(): void {
    this.quizQuestion.deleteChunk(this.chunk.id);
  }

  setPlainTitle(title: string): void {
    this.plainTitle = title;
  }

  toggleIsCorrect(): void {
    this.isCorrect = !this.isCorrect;
  }

  toggleIsChecked(): void {
    this.isChecked = !this.isChecked;
  }

  setAttachment(attachment: AttachmentType): void {
    this.attachment = attachment;
  }

  setForceAttachment(force: boolean): void {
    this.forceAttachment = force;
  }

  toJson(): object {
    return {
      attachment_id: this.attachment?.id || undefined,
      is_correct: this.isCorrect,
      plain_title: this.plainTitle,
    };
  }

  static createDefault(
    unit: any,
    chunk: ChunkModel<QuizAnswerModel>,
    quizQuestion: QuizQuestionModel
  ): QuizAnswerModel {
    return new QuizAnswerModel({
      plainTitle: 'Ответ',
      attachment: null,
      isCorrect: false,
      isChecked: false,
      quizQuestion,
      chunk,
    });
  }

  static fromJson(
    json: ChunkServerType<ChunkQuizAnswerServerType>,
    chunk: ChunkModel<QuizAnswerModel>,
    quizQuestion: QuizQuestionModel
  ): QuizAnswerModel {
    const { data } = json;
    return new QuizAnswerModel({
      plainTitle: data.plain_title,
      isCorrect: data.is_correct,
      isChecked: data.is_checked,
      attachment: data.attachment ? parseAttachment(data.attachment) : null,
      chunk,
      quizQuestion,
    });
  }
}
