import * as React from 'react';
import { useCallback } from 'react';
import cn from 'classnames';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
// @ts-ignore
import { NumberMaskOptions } from 'text-mask-addons';
import MaskedInput from 'react-text-mask';

import useFocus from 'components/form/hooks/useFocus';
import { FieldProps } from 'components/form/Field/Field';

import Label from '../Label';
import commonStyles from '../styles/common.modules.scss';

import styles from './Input.modules.scss';

type ExceptOwnProps = Omit<
  React.HTMLProps<HTMLInputElement>,
  'value' | 'onChange' | 'ref'
>;

export type NumberMask = Partial<NumberMaskOptions>;

export interface Props extends ExceptOwnProps, FieldProps {
  value: string | number;
  onChange: (v: any) => void;
  inputClassName?: string;
  icon?: React.ReactNode;
  noLabel?: boolean;
  numberMask?: NumberMask;
  onUnmask?: (text: string) => string;
}

const InputComponent: React.FC<Props> = ({
  icon,
  value,
  onChange,
  noLabel,
  inputClassName,
  numberMask,
  onUnmask,
  ...inputProps
}: Props) => {
  const { placeholder, type, ...rest } = inputProps;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = onUnmask ? onUnmask(e.target.value) : e.target.value;

      onChange(type === 'number' ? Number(value) : value);
    },
    [type, onUnmask]
  );

  const [isFocused, toggleFocus] = useFocus();

  const commonProps = {
    className: cn(styles.input, commonStyles.input, inputClassName),
    value: type === 'number' ? value : value || '',
    onChange: handleChange,
    placeholder: noLabel ? placeholder : undefined,
    ...rest,
  };

  return (
    <div
      styleName={cn('input-wrapper', icon && 'with-icon')}
      onBlur={toggleFocus}
      onFocus={toggleFocus}
    >
      {placeholder && !noLabel && (
        <Label
          withIcon={Boolean(icon)}
          isFocused={isFocused}
          isUp={Boolean(value) || value === 0}
        >
          {placeholder}
        </Label>
      )}
      {icon && (
        <div className={styles.icon}>
          <div className={styles.iconInner}>{icon}</div>
        </div>
      )}
      {!numberMask ? (
        <input type={type} {...commonProps} />
      ) : (
        <MaskedInput mask={createNumberMask(numberMask)} {...commonProps} />
      )}
    </div>
  );
};

InputComponent.defaultProps = {
  icon: null,
  noLabel: false,
};

export default InputComponent;
