import { DropdownOption } from 'components/form/Dropdown/Dropdown';

import { UnitServerType } from '../unit/types';

export type LessonCreateResponseType = {
  id: number;
};

export type LessonUnitServerType = {
  lesson_id: number;
  id: number;
};

export type LessonUnitType = {
  lessonId: number;
  id: number;
};

export type LessonGetResponseType = {
  lesson: LessonServerType;
  units: UnitServerType[];
  prev_unit: LessonUnitServerType;
  next_unit: LessonUnitServerType;
};

export type LessonServerType = {
  access_level: number;
  has_user_enough_access_level: boolean;
  course_id: number;
  max_available_points: number;
  points: number;
  unit_count: number;
  course_is_actual: boolean;
  course_type: string;
  first_unit: LessonUnitServerType;
  title: string;
  chapter_id: number;
  chapter_name: string;
  id: number;
  count_completed_units: number;
  type: string;
  ordinal: number;
  required: boolean;
  creator_id: number;
  course_name: string;
  is_published: boolean;
  send_notifications: boolean;
};

export enum LessonTypeEnum {
  intro = 'intro',
  theory = 'theory',
  test = 'test',
  video = 'video',
  practice = 'practice',
  final = 'final',
}

export const LessonTypeOptions: DropdownOption<LessonTypeEnum>[] = [
  { key: LessonTypeEnum.intro, label: 'Введение' },
  { key: LessonTypeEnum.theory, label: 'Теория' },
  { key: LessonTypeEnum.test, label: 'Тест' },
  { key: LessonTypeEnum.video, label: 'Видео' },
  { key: LessonTypeEnum.practice, label: 'Практика' },
  { key: LessonTypeEnum.final, label: 'Завершение' },
];
