import { makeObservable, observable, override, runInAction } from 'mobx';

import { Form } from 'store/models/common/Form';
import {
  composeValidators,
  greaterThanValidator,
  notEmptyValidator,
} from 'utils/validation';
import request from 'utils/request';
import apiUrls from 'config/apiUrls';

type ResetPasswordFields = {
  password: string;
  passwordRepeat: string;
};

export class NewPasswordStore extends Form<ResetPasswordFields> {
  private readonly _token: string;

  initialValues = {
    password: '',
    passwordRepeat: '',
  };

  validators = {
    password: composeValidators(
      notEmptyValidator(),
      greaterThanValidator({
        min: 8,
        errorMessage: 'Пароль должен быть длиннее 8 символов',
      })
    ),
    passwordRepeat: composeValidators(
      notEmptyValidator(),
      greaterThanValidator({
        min: 8,
        errorMessage: 'Пароль должен быть длиннее 8 символов',
      }),
      (value: string) =>
        this.values.password === value ? '' : 'Введенные пароли не совпадают'
    ),
  };

  isError = false;
  isSaved = false;

  constructor({ token }: { token: string }) {
    super();

    this._token = token;

    makeObservable(this, {
      //observable
      isError: observable,
      isSaved: observable,
      //override
      save: override,
    });
  }

  async save(): Promise<boolean> {
    if (this.isSaving) {
      return false;
    }

    this.isError = false;

    this.validate();

    if (this.hasErrors) {
      return false;
    }

    this.isSaving = true;

    const { error } = await request(apiUrls.user.recoverPassword, 'POST', {
      token: this._token,
      password: this.values.password,
    });

    runInAction(() => {
      if (error) {
        this.isSaved = false;
        this.isError = true;
        this.errors.passwordRepeat = ['Произошла ошибка'];
      } else {
        this.isSaved = true;
      }

      this.isSaving = false;
    });

    return !error;
  }
}
