import { observer } from 'mobx-react';
import { useEffect } from 'react';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useParams, useHistory } from 'react-router-dom';

import urls from 'config/urls';
import InviteUserModal from 'components/containers/students/InviteUserModal';
import { useModal } from 'components/form/hooks/useModal';
import FullSideSpinner from 'components/common/Spinner/FullSideSpinner';
import Table from 'components/common/Table';
import { WhiteCard } from 'components/common/BaseCard';
import ButtonPlus from 'components/common/Button/ButtonPlus';
import Button from 'components/common/Button';
import { useStudentsStore } from 'store/context';
import { useCurrentCompany } from 'store/stores';
import Tabs, { TabItem } from 'components/common/Tabs';

import Settings from './components/Settings';
import styles from './GroupPage.modules.scss';
import { columns } from './tableConfig';

const GroupPage: React.FC = () => {
  const groupId = useParams<{ id: string }>().id;
  const company = useCurrentCompany();
  const history = useHistory();
  const group = useStudentsStore().groups.items[Number(groupId)];

  const [showInvite, toggleInvite] = useModal();

  const tabs: TabItem[] = React.useMemo(
    (): TabItem[] => [
      {
        tabName: 'Участники',
        item: (
          <Table
            size="small"
            dataSource={group.students}
            columns={columns}
            pagination={{}}
            loading={group.isLoadingStudents}
            className={styles.tableIndent}
          />
        ),
      },
      {
        tabName: 'Настройки',
        item: <Settings className={styles.settings} group={group} />,
      },
    ],
    [isMobile, group.students, columns, group.isLoadingStudents, group.id]
  );

  const load = async () => {
    if (company?.id) {
      await group.loadStudents(company.id);
    }
  };

  useEffect(() => {
    if (!group) {
      history.replace(urls.students.groups.index);
    } else {
      load();
    }
  }, [group]);

  if (!group) {
    return <FullSideSpinner isOpen />;
  }

  return (
    <div>
      {showInvite && (
        <InviteUserModal
          isOpen={showInvite}
          onClose={toggleInvite}
          groupId={Number(groupId)}
          onSuccess={group.addPendingStudent}
        />
      )}
      <div className={styles.header}>
        <div className={styles.title}>Группа</div>
        <ButtonPlus onClick={toggleInvite}>Пригласить студента</ButtonPlus>
      </div>
      <div className={styles.updateBtn}>Всего студентов: {group.total}</div>
      <div className={styles.updateBtn}>
        <Button onClick={load} isLoading={group.isLoadingStudents}>
          Обновить
        </Button>
      </div>
      <WhiteCard className={styles.table}>
        <Tabs
          tabs={tabs}
          tabBarClassName={styles.tabBar}
          className={styles.tabs}
        />
      </WhiteCard>
    </div>
  );
};

export default observer(GroupPage);
