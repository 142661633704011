import { action, makeObservable, observable, toJS } from 'mobx';
import { OutputData } from '@editorjs/editorjs';

import { IChunkModel, ChunkServerType } from 'store/models/chunks/types';

const EDITOR_JS_VERSION = '2.17.0';

export class EditorJSModel implements IChunkModel {
  data: OutputData;

  constructor({ data }: { data: OutputData }) {
    makeObservable(this, {
      // observable
      data: observable,
      // action
      setData: action.bound,
    });
    this.data = data;
  }

  setData(d: OutputData): void {
    this.data = d;
  }

  toJson(): object {
    const data = toJS(this.data);
    delete data.time;
    return data;
  }

  static createDefault(): EditorJSModel {
    return new EditorJSModel({
      data: {
        blocks: [
          {
            data: { text: '' },
            type: 'paragraph',
          },
        ],
        version: EDITOR_JS_VERSION,
      },
    });
  }

  static fromJson(json: ChunkServerType<OutputData>): EditorJSModel {
    const { data } = json;

    return new EditorJSModel({
      data,
    });
  }
}
