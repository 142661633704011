import { action, makeObservable, observable, toJS } from 'mobx';

import { AttachmentType, parseAttachment } from 'store/models/attachment';
import { IChunkModel, ChunkServerType } from 'store/models/chunks/types';

import { ChunkFileServerType } from './types';

type AttachmentsChunkType = {
  attachment: AttachmentType | null;
  attachmentId: number | null;
};

export class AttachmentsChunkModel
  implements IChunkModel, AttachmentsChunkType
{
  attachment: AttachmentType | null;
  attachmentId: number | null;

  constructor({ attachment, attachmentId }: AttachmentsChunkType) {
    makeObservable(this, {
      //observable
      attachment: observable,
      attachmentId: observable,
      // action
      setAttachment: action.bound,
    });
    this.attachment = attachment;
    this.attachmentId = attachmentId;
  }

  setAttachment(attachment: AttachmentType): void {
    this.attachment = attachment;
    this.attachmentId = attachment.id;
  }

  toJson(): object {
    return toJS({
      attachment_id: this.attachmentId,
    });
  }

  static createDefault(): AttachmentsChunkModel {
    return new AttachmentsChunkModel({
      attachment: null,
      attachmentId: null,
    });
  }

  static fromJson(
    json: ChunkServerType<ChunkFileServerType>
  ): AttachmentsChunkModel {
    const { data } = json;

    return new AttachmentsChunkModel({
      attachment: data.attachment ? parseAttachment(data.attachment) : null,
      attachmentId: data.attachment_id,
    });
  }
}
