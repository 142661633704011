import * as React from 'react';
import { useCallback } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import BaseCard from 'components/common/BaseCard';
import ButtonArrow from 'components/common/Button/ButtonArrow';
import urls from 'config/urls';
import CourseModel from 'store/models/course/CourseModel';
import Title from 'components/common/Title';
import noop from 'utils/noop';

import CourseAdmin from './CourseAdmin';
import CourseInfo from './CourseInfo';
import ProgressLine from './ProgressLine/ProgressLine';
import styles from './CourseCard.modules.scss';

type Props = {
  course: CourseModel;
  showLink?: boolean;
  hasContinueButton?: boolean;
  isDevelopment?: boolean;
  handleContinue?: () => void;
};

const CourseCard: React.FC<Props> = ({
  course,
  handleContinue,
  showLink,
  hasContinueButton = true,
}: Props) => {
  const { title, description, id } = course;

  const history = useHistory();

  const gotoCourse = useCallback(() => {
    if (showLink) {
      history.push(urls.courseId(id));
    }
  }, [showLink, id]);

  const countCompleteLessons = course.inner?.countCompleteLessons || 0;
  const lessonsCount = course.totalLessonsCount;

  return (
    <div className={styles.root}>
      <CourseAdmin course={course} />
      <BaseCard
        onClick={gotoCourse}
        className={styles.card}
        style={{
          backgroundImage: `url(${course.background?.url})`,
        }}
      >
        <div className={cn(styles.substrate)} />

        <div className={styles.topContent}>
          <div className={cn(styles.info)}>
            Выполнено {countCompleteLessons} / {lessonsCount}
          </div>
          <br />
          <Title index={5} className={styles.title}>
            {title}
          </Title>
          <p className={styles.description}>{description}</p>
        </div>

        <ProgressLine value={countCompleteLessons / lessonsCount} />

        <CourseInfo
          isDevelopment={false}
          course={course}
          hasContinueButton={hasContinueButton}
          handleContinue={handleContinue}
        />

        {showLink && (
          <ButtonArrow
            className={cn(styles.button)}
            to={urls.courseId(course.id)}
          >
            Перейти к курсу
          </ButtonArrow>
        )}
      </BaseCard>
    </div>
  );
};

CourseCard.defaultProps = {
  showLink: true,
  isDevelopment: false,
  handleContinue: noop,
};

export default observer(CourseCard);
