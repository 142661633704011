import * as React from 'react';

import SuccessIcon from './img/success.svg';
import styles from './SuccessWidget.modules.scss';

const SuccessWidget: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <SuccessIcon className={styles.icon} />
      <div className={styles.header}>Оплата</div>
      <div className={styles.info}>
        Мы&nbsp;выслали приглашение в&nbsp;сервис вам на&nbsp;почту.
      </div>
    </div>
  );
};

export default React.memo(SuccessWidget);
