import * as React from 'react';
import { observer } from 'mobx-react';
import { useCallback } from 'react';

import { QuizAnswerModel } from 'store/models/chunks/quiz/QuizAnswerModel';

import { WithChunk } from '../../../types';
import { useQuizGroup } from '../../context';

import ImageAnswer from './ImageAnswer';
import TextAnswer from './TextAnswer';
import styles from './QuizAnswer.modules.scss';

type Props = WithChunk<QuizAnswerModel>;

const QuizAnswer: React.FC<Props> = ({ isEditMode, chunk }: Props) => {
  const answer = chunk.data;

  const quizChunk = useQuizGroup();

  const handleCheck = useCallback(() => {
    if (isEditMode) {
      answer.toggleIsCorrect();
    } else {
      if (quizChunk.attemptsLeft > 0) {
        answer.toggleIsChecked();
      }
    }
  }, [isEditMode, answer]);

  const isChecked = isEditMode ? answer.isCorrect : answer.isChecked;

  const Component =
    answer.forceAttachment || answer.attachment !== null
      ? ImageAnswer
      : TextAnswer;

  const props = {
    isChecked,
    handleCheck,
    answer,
    isEditMode,
  };

  return (
    <div className={styles.answerWrapper}>
      <Component {...props} />
    </div>
  );
};

export default observer(QuizAnswer);
