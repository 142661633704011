import * as React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { CONFIG } from 'config/app';
import urls from 'config/urls';
import BurgerIcon from 'components/icons/BurgerIcon';
import { useUiStore, useUserStore } from 'store';
import LogOut from 'components/icons/LogOut';

import CompanySelector from './CompanySelector';
import styles from './TopBar.modules.scss';

const TopBar: React.FC = () => {
  const user = useUserStore();
  const ui = useUiStore();

  return (
    <div className={cn(styles.root, styles[`root_${ui.layout}`])}>
      <div className={styles.logoWrapper}>
        <a
          href={CONFIG.metaclassLink}
          target="_blank"
          className={styles.logo}
        />
        <BurgerIcon
          className={cn(styles.burger, ui.showMenu && styles.burgerOpened)}
          onClick={ui.toggleMenu}
        />
      </div>

      <div className={styles.bar}>
        {user.companies.length > 0 && (
          <div className={styles.company}>
            <CompanySelector />
          </div>
        )}
        <div className={styles.menu}>
          <Link to={urls.profile}>
            <span className={styles.name}>{user.fullName}</span>
          </Link>
          <LogOut className={styles.logout} onClick={user.logout} />
        </div>
      </div>
    </div>
  );
};

export default observer(TopBar);
