import { action, makeObservable, observable } from 'mobx';

import apiUrls from 'config/apiUrls';
import { ServerResponse } from 'store/models/common/types';
import { normalizeCompanyUser } from 'store/stores/UserStore/normalizers';
import { CompanyModel, CompanyUserType } from 'store/stores/UserStore/types';
import request from 'utils/request';

import { InviteCheckServerType } from './types';

export class InviteStore {
  isLoadingInvite = false;
  isLoadedInvite = false;
  isInviteError = false;
  isSuccess = false;

  company?: CompanyModel;
  receiver?: CompanyUserType | null;
  sender?: CompanyUserType;

  constructor() {
    makeObservable(this, {
      // observable
      isLoadedInvite: observable,
      isInviteError: observable,
      isLoadingInvite: observable,
      isSuccess: observable,
      company: observable,
      receiver: observable,
      sender: observable,
      // action
      setSuccess: action,
      loadInvite: action,
    });
  }

  setSuccess(success: boolean): void {
    this.isSuccess = success;
  }

  async loadInvite(token: string): Promise<any> {
    this.isLoadingInvite = true;

    const { response }: ServerResponse<InviteCheckServerType> = await request(
      apiUrls.company.invitation.check,
      'GET',
      { token }
    );

    if (response) {
      this.company = response.company;
      this.receiver = response.receiver
        ? normalizeCompanyUser(response.receiver)
        : null;
      this.sender = normalizeCompanyUser(response.sender);
      this.isLoadedInvite = true;
    } else {
      this.isInviteError = true;
    }

    this.isLoadingInvite = false;
  }
}
