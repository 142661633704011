import * as React from 'react';

import Tabs from 'components/common/Tabs';
import { TabItem } from 'components/common/Tabs/Tabs';

import Payments from './components/Payments';
import SideApi from './components/SideApi';
import styles from './Integration.modules.scss';

const Integration: React.FC = () => {
  const tabs: TabItem[] = React.useMemo(
    () => [
      {
        item: <Payments />,
        tabName: 'Подключение платежей',
      },
      {
        item: <SideApi />,
        tabName: 'Стороннее API',
      },
    ],
    []
  );

  return (
    <div className={styles.wrapper}>
      <Tabs tabs={tabs} tabBarClassName={styles.tabs} />
    </div>
  );
};

export default Integration;
