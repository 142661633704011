import * as React from 'react';

import Badge from 'components/common/Badge';

import styles from './GroupPage.modules.scss';

const colors = {
  active: '#20d771',
  pending: '#f98511',
};

export const columns = [
  {
    title: 'Студент',
    dataIndex: 'fullName',
    key: 'fullName',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Уровень доступа',
    dataIndex: 'accessLevel',
  },
  {
    title: 'Прогресс',
    dataIndex: 'progress',
  },
  {
    title: 'Статус',
    dataIndex: 'isActive',
    render: (isActive: boolean) =>
      isActive ? (
        <Badge color={colors.active} className={styles.studentStatus}>
          Активен
        </Badge>
      ) : (
        <Badge color={colors.pending} className={styles.studentStatus}>
          Ожидает подтверждения
        </Badge>
      ),
  },
];
