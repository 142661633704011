import * as React from 'react';
import { useCallback } from 'react';
import { observer } from 'mobx-react';

import ChapterCreationModal from 'components/containers/course/ChapterCreationModal';
import ButtonPlus from 'components/common/Button/ButtonPlus';
import CourseModel from 'store/models/course/CourseModel';
import { ChapterModel } from 'store/models/chapter/ChapterModel';
import { useModal } from 'components/form/hooks/useModal';

type Props = {
  course: CourseModel;
};

const AddChapter: React.FC<Props> = ({ course }: Props) => {
  const [showModal, toggleModal] = useModal();

  const handleSuccess = useCallback(
    (chapter: ChapterModel) => {
      course.addChapter(chapter);
    },
    [course]
  );

  return (
    <>
      {showModal && (
        <ChapterCreationModal
          course={course}
          isOpen={showModal}
          title="Добавить главу"
          resetOnOpen
          onSuccess={handleSuccess}
          onClose={toggleModal}
        />
      )}
      <ButtonPlus onClick={toggleModal}>Добавить главу</ButtonPlus>
    </>
  );
};

export default observer(AddChapter);
