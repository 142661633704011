import { action, makeObservable, observable } from 'mobx';

import apiUrls from 'config/apiUrls';
import {
  AttachmentServerType,
  AttachmentType,
} from 'store/models/attachment/types';
import {
  parseAttachment,
  parseAttachmentError,
} from 'store/models/attachment/utils';
import request from 'utils/request';

import { ServerResponse } from '../common/types';

export class AttachmentUploadModel {
  file: File | null = null;
  isLoading = false;
  attachment: AttachmentType | null = null;

  statusPollingTimeout: NodeJS.Timeout | null = null;

  error?: string | null;

  constructor() {
    makeObservable(this, {
      file: observable,
      isLoading: observable,
      attachment: observable,
      error: observable,
      uploadFile: action.bound,
      dropFile: action.bound,
    });
  }

  async uploadFile(
    file: File,
    isVideo = false
  ): Promise<AttachmentType | undefined> {
    this.isLoading = true;
    this.error = null;

    this.file = file;

    const { response }: ServerResponse<AttachmentServerType[]> = await request(
      isVideo ? apiUrls.attachment.uploadBunny : apiUrls.attachment.upload,
      'POST',
      { file },
      {
        multipartFormData: true,
      }
    );

    this.isLoading = false;

    if (response) {
      this.attachment = parseAttachment(response[0]);

      return this.attachment;
    } else {
      this.error = parseAttachmentError();
    }

    this.isLoading = false;
  }

  dropFile(): void {
    this.error = null;
    this.file = null;
    this.attachment = null;
  }
}
