import { observable, action, computed, makeObservable } from 'mobx';

import request from 'utils/request';
import apiUrls from 'config/apiUrls';
import { CourseListResponseType } from 'store/models/course/types';
import { RootStore } from 'store/RootStore';
import { ServerResponse } from 'store/models/common/types';
import CourseModel from 'store/models/course/CourseModel';
import { CoursePreview } from 'store/models/course/CoursePreview';

import {
  Collection,
  createDefaultCollection,
  linearizeCollection,
  parseCollection,
} from '../../models/collection';
import { GlobalStore } from '../types';

export class CourseStore implements GlobalStore {
  rootStore: RootStore;

  isLoading = false;
  isInited = false;
  isIniting = false;
  initError = false;

  collection: Collection<number, CourseModel> = createDefaultCollection();

  previews: CoursePreview[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      // observable
      isLoading: observable,
      isInited: observable,
      isIniting: observable,
      initError: observable,
      collection: observable,
      previews: observable,
      // computed
      courses: computed,
      userCompanyCourses: computed,
      // action
      addCourse: action.bound,
      deleteCourse: action,
      loadList: action.bound,
      init: action.bound,
    });
  }

  get courses(): CourseModel[] {
    return linearizeCollection(this.collection);
  }

  get userCompanyCourses(): CourseModel[] {
    return this.courses.filter(
      (c) => c.company.id === this.rootStore.userStore.selectedCompany?.id
    );
  }

  addCourse(course: CourseModel): void {
    if (course.id) {
      this.collection.items[course.id] = course;
      this.collection.order.unshift(course.id);

      const coursePreview = CoursePreview.fromCourseModel(course);

      if (coursePreview && course) {
        this.previews.unshift(coursePreview);
      }
    }
  }

  async deleteCourse(courseId: number): Promise<boolean> {
    const { response }: ServerResponse = await request(
      apiUrls.course.delete,
      'POST',
      {
        id: courseId,
      }
    );

    if (response) {
      this.previews = this.previews.filter((p) => p.id !== courseId);
      this.collection.order = this.collection.order.filter(
        (id) => id !== courseId
      );
    }

    return Boolean(response);
  }

  async loadList(): Promise<void> {
    this.isLoading = true;

    const { response }: ServerResponse<CourseListResponseType> = await request(
      apiUrls.course.list
    );

    if (response) {
      this.collection = parseCollection(response.courses, CourseModel.fromJson);
    }

    this.isLoading = false;
  }

  async init(): Promise<void> {
    this.isInited = false;
    this.initError = false;
    this.isIniting = true;

    const { response }: ServerResponse<CourseListResponseType> = await request(
      apiUrls.course.list
    );

    if (response) {
      this.previews = response.courses.map(
        (c) => new CoursePreview(c.id, c.title)
      );
      this.collection = parseCollection(response.courses, CourseModel.fromJson);
      this.isInited = true;
    } else {
      this.initError = true;
    }

    this.isIniting = false;
  }
}
