const urls = {
  registration: '/registration',

  profile: '/profile',

  courses: '/courses',

  courseId: (id) => `/courses/${id}`,
  courseIdPattern: '/courses/:id',

  lesson: (lessonId) => `/lesson/${lessonId}/unit/`,
  lessonCard: (lessonId, unitId) => `/lesson/${lessonId}/unit/${unitId}`,
  lessonPagePattern: '/lesson/:id/unit/:unitId?',

  lessonHomeworkChat: (lessonId, unitId, chunkId, conversationId) =>
    `${urls.lessonCard(lessonId, unitId)}/chat/${chunkId}` +
    (conversationId ? `/${conversationId}` : ''),
  lessonHomeworkPattern:
    '/lesson/:id/unit/:unitId/chat/:chunkId/:conversationId?',

  homework: '/homework',

  company: '/company',

  backendInvite: '/invite',

  students: {
    pattern: '/students',
    groups: {
      index: '/students/groups/',
      pattern: '/students/groups/:id?',
      group: (id) => `/students/groups/${id}`,
    },
  },
  integration: '/integration',
  payment: {
    groupPay: '/payment/group_pay',
    groupReturn: {
      index: '/payment/group_return',
      create: (val) => `/payment/group_return?group_user_id=${val}`,
    },
  },

  password: {
    index: '/password',
    pattern: '/password/:token',
  },
};

export default urls;
