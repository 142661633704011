import * as React from 'react';

import UnitCreationModal from 'components/containers/course/UnitCreationModal';
import BaseEdit from 'components/common/BaseEdit';
import { useModal } from 'components/form/hooks/useModal';
import { useLessonStore } from 'pages/Lesson/context';
import noop from 'utils/noop';

const UnitAdmin: React.FC = () => {
  const { lesson, isEditMode, unit, isAdmin } = useLessonStore();

  const [showEditModal, toggleEditModal] = useModal();

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      {lesson && showEditModal && (
        <UnitCreationModal
          isOpen={showEditModal}
          onClose={toggleEditModal}
          lesson={lesson}
          title="Редактирование карточки"
          resetOnOpen
          unitModel={unit}
        />
      )}
      <BaseEdit
        isSelected={isEditMode}
        onEdit={toggleEditModal}
        onAppendBefore={noop}
        onAppendAfter={noop}
        onShiftBefore={noop}
        onShiftAfter={noop}
        isHorizontalControls
      />
    </>
  );
};

export default React.memo(UnitAdmin);
