import {
  action,
  computed,
  makeObservable,
  observable,
  override,
  toJS,
} from 'mobx';

import { ChunkContainer } from 'components/Constructor/models/chunkContainer/ChunkContainer';
import {
  IChunkModel,
  ChunkTypeEnum,
  AnyChunkType,
} from 'store/models/chunks/types';
import { ChunkModel } from 'store/models/chunks/ChunkModel';
import { UnitModel } from 'store/models/unit/UnitModel';
import { QuizQuestionModel } from 'store/models/chunks/quiz/QuizQuestionModel';
import apiUrls from 'config/apiUrls';
import request from 'utils/request';
import { ServerResponse } from 'store/models/common/types';

import { ChunkServerType } from '../types/dataTypes';

import { ChunkQuizGroupServerType, QuizGroupStatus, QuizStatus } from './types';

type QuizGroupType = {
  numberOfAttempts: number;
  numberOfAttemptsLeft: number;
  status: QuizGroupStatus;
  unit: UnitModel;
  chunkId: string;
  chunks: ChunkModel<QuizQuestionModel>[];
};

export class QuizGroupModel
  extends ChunkContainer<QuizQuestionModel>
  implements IChunkModel, QuizGroupType
{
  numberOfAttempts: number;

  numberOfAttemptsLeft: number;

  status: QuizGroupStatus;

  isValidating = false;

  unit: UnitModel;
  chunkId: string;

  constructor({
    chunkId,
    unit,
    chunks,
    numberOfAttempts,
    numberOfAttemptsLeft,
    status,
  }: QuizGroupType) {
    super();
    makeObservable(this, {
      // observable
      numberOfAttemptsLeft: observable,
      status: observable,
      isValidating: observable,
      // computed
      attemptsLeft: computed,
      quizStatus: computed,
      // override
      toggleChunkCreationDrawer: override,
      // action
      checkAnswers: action.bound,
    });
    this.status = status;
    this.chunkId = chunkId;
    this.unit = unit;
    this.chunks = chunks;
    this.numberOfAttempts = numberOfAttempts;
    this.numberOfAttemptsLeft = numberOfAttemptsLeft;
  }

  get attemptsLeft(): number {
    return isNaN(this.numberOfAttemptsLeft)
      ? this.numberOfAttempts
      : this.numberOfAttemptsLeft;
  }

  get quizStatus(): QuizStatus {
    if (this.chunks.every((c) => c.data?.status === QuizStatus.correct)) {
      return QuizStatus.correct;
    }

    if (this.chunks.some((c) => c.data?.status === QuizStatus.incorrect)) {
      return QuizStatus.incorrect;
    }

    return QuizStatus.notAnswered;
  }

  validate(): string[] {
    const errors = [];

    if (this.chunks.length === 0) {
      errors.push('Не добавлен ни один вопрос');
    }

    this.chunks.forEach((c) => {
      c.validate();
    });

    return errors;
  }

  toJson(): object {
    return toJS({
      number_of_attempts: this.numberOfAttempts,
      quizzes: this.chunks.reduce(
        (acc, c) => [...acc, ...c.toJson()],
        [] as any[]
      ),
    });
  }

  generateChunksByType(chunkType: AnyChunkType): ChunkModel[] {
    const newChunks: ChunkModel[] = [];
    if (chunkType === ChunkTypeEnum.quiz) {
      newChunks.push(ChunkModel.createDefault(this.unit, chunkType, this));
    }
    return newChunks;
  }

  toggleChunkCreationDrawer(isOpen: boolean): void {
    // Вместо открытия модалки сразу создаем вопрос
    if (isOpen) {
      this.addChunk(ChunkTypeEnum.quiz);
    }
  }

  async checkAnswers(): Promise<void> {
    this.isValidating = true;

    const quizzes = this.chunks.map(
      (question) => question.data?.validationData
    );

    const {
      response,
    }: ServerResponse<ChunkServerType<ChunkQuizGroupServerType>> =
      await request(apiUrls.chunks.checkAnswers, 'POST', {
        quizzes,
        quiz_group_id: this.chunkId,
      });

    if (response) {
      this.numberOfAttemptsLeft = response.data.number_of_attempts_left;
      this.status = response.data.status;
      this.chunks = response.data.quizzes.map((quiz) =>
        ChunkModel.fromJson(quiz, this.unit, this)
      );
    }

    this.isValidating = false;
  }

  static createDefault(unit: UnitModel, chunk: ChunkModel): QuizGroupModel {
    return new QuizGroupModel({
      numberOfAttempts: 3,
      numberOfAttemptsLeft: 3,
      status: QuizGroupStatus.inProgress,
      chunks: [],
      chunkId: chunk.id,
      unit,
    });
  }

  static fromJson(
    json: ChunkServerType<ChunkQuizGroupServerType>,
    chunk: ChunkModel<QuizGroupModel>
  ): QuizGroupModel {
    const quizGroup = new QuizGroupModel({
      unit: chunk.unit,
      numberOfAttempts: json.data.number_of_attempts,
      numberOfAttemptsLeft: json.data.number_of_attempts_left,
      status: json.data.status,
      chunkId: chunk.id,
      chunks: [],
    });

    quizGroup.chunks = json.data.quizzes.map((quiz) =>
      ChunkModel.fromJson(quiz, chunk.unit, quizGroup)
    );

    return quizGroup;
  }
}
