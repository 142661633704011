import * as React from 'react';
import { observer } from 'mobx-react';

import Button from 'components/common/Button';
import { usePaymentsStore } from 'store/context';
import { dropCookie } from 'utils/cookies';
import { PAID_KEY_COOKIE } from 'store/stores';

import ErrorIcon from './img/error.svg';
import styles from './ErrorWidget.modules.scss';

const ErrorWidget: React.FC = () => {
  const { paymentUrl } = usePaymentsStore();

  const handleOnBack = React.useCallback(() => {
    // Редиректим обратно на страницу ввода имейла

    if (paymentUrl) {
      dropCookie(PAID_KEY_COOKIE);
      window.location.href = paymentUrl;
    }
  }, [paymentUrl]);

  return (
    <div className={styles.wrapper}>
      <ErrorIcon className={styles.icon} />
      <div className={styles.header}>Платеж не&nbsp;прошел</div>
      <div className={styles.info}>
        Произошла ошибка при оплате, попробуйте еще раз или напишите нам
        в&nbsp;тех. поддержку.
      </div>
      <Button className={styles.button} onClick={handleOnBack}>
        Повторить
      </Button>
    </div>
  );
};

export default observer(ErrorWidget);
