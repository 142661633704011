import * as React from 'react';

import ArrowRightIcon from 'components/icons/ArrowRightIcon';

import styles from './Arrow.modules.scss';

const Arrow = () => {
  return <ArrowRightIcon className={styles.root} />;
};

Arrow.propTypes = {};

Arrow.defaultProps = {};

export default Arrow;
