import { hot } from 'react-hot-loader/root';
import * as React from 'react';
import { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';

import urls from 'config/urls';
import FullSideSpinner from 'components/common/Spinner/FullSideSpinner';
import { useRootStore } from 'store';
import Login from 'pages/Login';
import { Password } from 'pages/Password';
import Root from 'pages/Root/Root';
import Invite from 'pages/Invite';
import Registration from 'pages/Registration';
import GroupPayment from 'pages/GroupPayment';
import GroupPaymentResult from 'pages/GroupPayment/GroupPaymentResult/GroupPaymentResult';

export const App: React.FunctionComponent = () => {
  const rootStore = useRootStore();

  useEffect(() => {
    rootStore.init();
  }, []);

  if (rootStore.isIniting) {
    return <FullSideSpinner isOpen />;
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path={urls.payment.groupPay} component={GroupPayment} />
        <Route
          path={urls.payment.groupReturn.index}
          component={GroupPaymentResult}
        />
        <Route path={urls.backendInvite} exact component={Invite} />
        <Route path={urls.registration} exact component={Registration} />
        <Route path={urls.password.index} component={Password} />
        <Route component={!rootStore.userStore.isAuthorized ? Login : Root} />
      </Switch>
    </BrowserRouter>
  );
};

export default hot(observer(App));
