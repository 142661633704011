import { action, makeObservable, observable } from 'mobx';

import apiUrls from 'config/apiUrls';
import request from 'utils/request';

import { ServerResponse } from '../../common/types';
import { HomeworkListResponseType } from '../../homework/types';

export class HomeworkListModel {
  isLoading = false;

  items: { conversation_id: number }[] = [];

  courseId: number;

  constructor({ courseId }: { courseId: number }) {
    makeObservable(this, {
      // observable
      isLoading: observable,
      items: observable,
      // action
      load: action.bound,
    });
    this.courseId = courseId;
  }

  async load(): Promise<void> {
    // TODO типы
    this.isLoading = true;

    const { response }: ServerResponse<HomeworkListResponseType> =
      await request(apiUrls.homework.listHomework, 'GET', {
        course: this.courseId,
        limit: 100, // TODO
      });

    if (response) {
      this.items = response.homework;
    }

    this.isLoading = false;
  }
}
