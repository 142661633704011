import * as propTypes from 'prop-types';
import * as React from 'react';

const AlignRight = (props) => {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor" fillRule="evenodd">
        <path d="M30,2V30H28V2ZM6,5H27V15H6Zm2,8H25V7H8ZM2,17H27V27H2Zm2,8H25V19H4Z" />
      </g>
    </svg>
  );
};

AlignRight.propTypes = {
  className: propTypes.string.isRequired,
};

AlignRight.defaultProps = {};

export default AlignRight;
