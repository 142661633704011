type IdType = number;

export type Collection<I extends IdType, T extends { id: I }> = {
  order: I[];
  items: Record<I, T>;
};

export const createDefaultCollection = () => ({
  order: [],
  items: {},
});

export const linearizeCollection = <T extends { id: IdType }>(
  c: Collection<any, T>
): T[] => c.order.map((id) => c.items[id]);

export const parseCollection = <
  T extends { id: IdType },
  E extends { id: IdType }
>(
  items: T[],
  parseItem: (item: T) => E
): Collection<IdType, E> => ({
  items: items.reduce(
    (acc, next) => ({
      ...acc,
      [next.id]: parseItem(next),
    }),
    {}
  ),
  order: items.map((i) => i.id),
});
