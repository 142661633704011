import * as React from 'react';
import { useCallback } from 'react';

import BaseEdit from 'components/common/BaseEdit';
import ChapterCreationModal from 'components/containers/course/ChapterCreationModal';
import { useCourseContext } from 'components/containers/course/context';
import { useModal } from 'components/form/hooks/useModal';
import { ShiftPositionEnum } from 'store/models/common/types';
import { ChapterModel } from 'store/models/chapter/ChapterModel';

type Props = {
  chapter: ChapterModel;
};

const ChapterAdmin: React.FC<Props> = ({ chapter }: Props) => {
  const { isEditMode, course } = useCourseContext() || { isEditMode: false };

  const [showEditModal, toggleEditModal] = useModal();

  const handleShiftBefore = useCallback(() => {
    course?.shiftChapter(ShiftPositionEnum.up, chapter);
  }, []);

  const handleShiftAfter = useCallback(() => {
    course?.shiftChapter(ShiftPositionEnum.down, chapter);
  }, []);

  if (!course) {
    return null;
  }

  return (
    <>
      <BaseEdit
        isSelected={isEditMode}
        onEdit={toggleEditModal}
        isEditOnly={false}
        onShiftBefore={handleShiftBefore}
        onShiftAfter={handleShiftAfter}
      />
      {showEditModal && (
        <ChapterCreationModal
          resetOnOpen
          course={course}
          chapterModel={chapter}
          isOpen={showEditModal}
          onClose={toggleEditModal}
          title="Редактировать главу"
        />
      )}
    </>
  );
};

export default React.memo(ChapterAdmin);
