import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';

import apiUrls from 'config/apiUrls';
import { HomeworkStatus } from 'store/models/chunks/homework/types';
import { ServerResponse } from 'store/models/common/types';
import CourseModel from 'store/models/course/CourseModel';
import { HomeworkItemModel } from 'store/models/homework/HomeworkItemModel';
import { HomeworkListResponseType } from 'store/models/homework/types';
import { GroupModel } from 'store/models/students/GroupModel';
import request from 'utils/request';

const LOAD_LIMIT = 50;

export type HomeworkOption = {
  id: string;
  title: string;
};

export class HomeworkStore {
  homeworkList: HomeworkItemModel[] = [];

  count = 0;

  selectedCourses: CourseModel[] = [];

  selectedGroup: GroupModel | null = null;

  onlyMine = false;

  isDeadline: boolean | null = null;

  selectedStatuses: HomeworkStatus[] = [];

  courses: CourseModel[] = [];

  homeworkOptions: HomeworkOption[] = [];

  selectedHomework: string | null = null;

  isLoading = false;

  hasMore = false;

  isCoursesLoaded = false;

  constructor() {
    makeObservable(this, {
      // observable
      homeworkList: observable,
      selectedCourses: observable,
      selectedGroup: observable,
      selectedStatuses: observable,
      courses: observable,
      isLoading: observable,
      hasMore: observable,
      isCoursesLoaded: observable,
      onlyMine: observable,
      isDeadline: observable,
      count: observable,
      selectedHomework: observable,
      homeworkOptions: observable,
      // computed
      coursesOptions: computed,
      isEmpty: computed,
      // action
      clearList: action,
      initCourses: action,
      selectCourse: action.bound,
      selectGroup: action.bound,
      unselectCourse: action.bound,
      selectStatus: action.bound,
      unselectStatus: action.bound,
      loadMore: action.bound,
      toggleOnlyMine: action.bound,
      setIsDeadline: action.bound,
      fetchHomeworkOptions: action,
      selectHomeworkOption: action.bound,
    });
  }

  get coursesOptions(): CourseModel[] {
    return this.courses.filter(
      (c) => this.selectedCourses.findIndex((sc) => sc.id === c.id) < 0
    );
  }

  get isEmpty(): boolean {
    return this.homeworkList.length === 0 && !this.isLoading;
  }

  clearList(): void {
    this.homeworkList = [];
  }

  selectHomeworkOption(h: string) {
    this.selectedHomework = h;
  }

  async initCourses(courses: CourseModel[]): Promise<void> {
    this.courses = courses;
    // if (courses.length > 0) {
    //   this.selectCourse(courses[0].id);
    // }
    this.isCoursesLoaded = true;
  }

  async selectCourse(courseId: number): Promise<void> {
    const selectedCourse = this.courses.find((c) => c.id === courseId);
    if (selectedCourse) {
      this.selectedCourses = [selectedCourse];
      this.selectedGroup = null;
      this.selectedHomework = null;

      await this.fetchHomeworkOptions();
    }
  }

  async fetchHomeworkOptions() {
    const { response } = await request<{ homework: HomeworkOption[] }>(
      apiUrls.homework.listTitles,
      'GET',
      {
        course: this.selectedCourses[0].id,
      }
    );
    if (response) {
      this.homeworkOptions = response.homework;
    }
  }

  selectGroup(group: GroupModel): void {
    this.selectedGroup = group;
  }

  unselectCourse(courseId: number): void {
    this.selectedCourses = this.selectedCourses.filter(
      (c) => c.id !== courseId
    );
  }

  selectStatus(statusKey: HomeworkStatus): void {
    this.selectedStatuses = [statusKey];
  }

  unselectStatus(status: HomeworkStatus): void {
    this.selectedStatuses = this.selectedStatuses.filter((c) => c !== status);
  }

  toggleOnlyMine(): void {
    this.onlyMine = !this.onlyMine;
  }

  setIsDeadline(isDeadline: boolean | null): void {
    this.isDeadline = isDeadline;
  }

  async loadMore(): Promise<void> {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    const lastId = this.homeworkList?.[this.homeworkList.length - 1]?.id;

    const { response }: ServerResponse<HomeworkListResponseType> =
      await request(apiUrls.homework.listHomework, 'GET', {
        course: this.selectedCourses?.[0]?.id,
        group: this.selectedGroup?.id,
        status: this.selectedStatuses?.[0],
        assigned_to_me: this.onlyMine,
        is_deadline: this.isDeadline,
        homework_ids: this.selectedHomework,
        from_id: lastId,
        limit: LOAD_LIMIT,
      });

    runInAction(() => {
      if (response) {
        this.homeworkList.push(
          ...response.homework.map(HomeworkItemModel.fromJson)
        );
        this.hasMore = response.homework.length >= LOAD_LIMIT;
        this.count = response.count;
      }

      this.isLoading = false;
    });
  }
}
