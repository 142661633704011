import { action, computed, makeObservable, observable, toJS } from 'mobx';

import apiUrls from 'config/apiUrls';
import request from 'utils/request';

import { ChunkServerType, IChunkModel } from '../types';

import { TEST_CODE } from './config';

enum CodeTaskStatusEnum {
  notStarted = 'not_started',
  inProgress = 'in_progress',
  finished = 'finished',
}

type CodeTaskChunkAdminServerType = {
  test_code: string;
  example_code: string;

  language: 'python' | 'javascript';
};

type CodeTaskChunkServerType = CodeTaskChunkAdminServerType & {
  student_code: string;
  status: CodeTaskStatusEnum;
};

export class CodeTaskChunkModel
  implements IChunkModel<CodeTaskChunkServerType, CodeTaskChunkAdminServerType>
{
  studentCode? = '';
  status?: CodeTaskStatusEnum;
  chunkId?: string;

  exampleCode = `def sum(a, b):
  return a + b`;
  testCode = TEST_CODE;

  constructor() {
    makeObservable(this, {
      studentCode: observable,
      exampleCode: observable,
      testCode: observable,
      status: observable,

      fullCode: computed,
      isPassed: computed,
      isInProgress: computed,

      setStudentCode: action.bound,
      setTestCode: action.bound,
      setExampleCode: action.bound,
      codeTaskSuccess: action.bound,
    });
  }

  get isPassed(): boolean {
    return this.status === CodeTaskStatusEnum.finished;
  }

  get isInProgress(): boolean {
    return this.status === CodeTaskStatusEnum.inProgress;
  }

  get fullCode() {
    return `${this.studentCode}\n${this.testCode}`;
  }

  setExampleCode = (v: string) => {
    this.exampleCode = v;
  };

  setStudentCode = (v: string) => {
    this.studentCode = v;
  };

  setTestCode = (v: string) => {
    this.testCode = v;
  };

  saveStudentCode = async () => {
    await request(apiUrls.chunks.saveStudentCode, 'POST', {
      chunk_id: this.chunkId,
      student_code: this.studentCode,
    });
  };

  codeTaskSuccess = async () => {
    await request(apiUrls.chunks.checkCodeTask, 'POST', {
      chunk_id: this.chunkId,
    });
    this.status = CodeTaskStatusEnum.finished;
  };

  toJson(): CodeTaskChunkAdminServerType {
    return toJS({
      // student_code: this.studentCode,
      test_code: this.testCode,
      example_code: this.exampleCode,
      language: 'python',
    });
  }

  static fromJson({
    data,
    id,
  }: ChunkServerType<CodeTaskChunkServerType>): CodeTaskChunkModel {
    const CodeTaskModel = new CodeTaskChunkModel();
    CodeTaskModel.exampleCode = data.example_code;
    CodeTaskModel.testCode = data.test_code;
    CodeTaskModel.studentCode = data.student_code;
    CodeTaskModel.status = data.status;
    CodeTaskModel.chunkId = id;

    return CodeTaskModel;
  }

  static createDefault(): CodeTaskChunkModel {
    return new CodeTaskChunkModel();
  }
}
