import * as React from 'react';
import { useCallback } from 'react';

import CourseCreationModal from 'components/containers/course/CourseCreationModal';
import { useCourseContext } from 'components/containers/course/context';
import BaseEdit from 'components/common/BaseEdit';
import CourseModel from 'store/models/course/CourseModel';
import { useModal } from 'components/form/hooks/useModal';

type Props = {
  course: CourseModel;
};

const CoursePageHead: React.FC<Props> = ({ course }: Props) => {
  const [showModal, toggleModal] = useModal();

  const courseEditContext = useCourseContext();

  const handleSuccessEdit = useCallback((editedCourse: CourseModel) => {
    courseEditContext.resetCourse(editedCourse);
  }, []);

  if (!courseEditContext || !courseEditContext.isAdmin) {
    return null;
  }

  return (
    <>
      {showModal && (
        <CourseCreationModal
          resetOnOpen
          isOpen={showModal}
          title="Редактирование курса"
          onClose={toggleModal}
          courseModel={course}
          onSuccess={handleSuccessEdit}
        />
      )}
      <BaseEdit
        isSelected={courseEditContext.isEditMode}
        onEdit={toggleModal}
      />
    </>
  );
};

export default React.memo(CoursePageHead);
