import { makeObservable, observable } from 'mobx';

import { IChunkModel, ChunkServerType } from 'store/models/chunks/types';

import { ImageChunkAttachmentModel } from './common';

export class ImageChunkModel implements IChunkModel {
  image: ImageChunkAttachmentModel;

  constructor({ image }: { image: ImageChunkAttachmentModel }) {
    makeObservable(this, {
      // observable
      image: observable,
    });
    this.image = image;
  }

  toJson(): object {
    return [this.image.toJson()];
  }

  static createDefault(): ImageChunkModel {
    return new ImageChunkModel({
      image: ImageChunkAttachmentModel.createDefault(),
    });
  }

  static fromJson(json: ChunkServerType): ImageChunkModel {
    return new ImageChunkModel({
      image: ImageChunkAttachmentModel.fromJson(json.data[0]),
    });
  }
}
