export default (iterateTarget, callback) => {
  if (Array.isArray(iterateTarget)) {
    iterateTarget.forEach(callback);
    return;
  }

  if (typeof iterateTarget === 'object' && iterateTarget !== null) {
    Object.keys(iterateTarget).forEach((key, index) =>
      callback(iterateTarget[key], key, index)
    );
  }
};
