import * as React from 'react';
import cn from 'classnames';
import { Link, LinkProps } from 'react-router-dom';

import LongArrowRightIcon from 'components/icons/LongArrowRightIcon';
import LongArrowLeftIcon from 'components/icons/LongArrowLeftIcon';
import noop from 'utils/noop';

import styles from './ButtonArrow.modules.scss';

export enum ArrowDirectionEnum {
  left = 'left',
  right = 'right',
}

type Props = {
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  direction?: ArrowDirectionEnum;
  isDisabled?: boolean;
  to?: string;
};

const ButtonArrow: React.FC<Props> = ({
  className,
  onClick,
  children,
  direction,
  to,
  isDisabled,
}: Props) => {
  let Tag: React.ComponentType<LinkProps> | keyof React.ReactHTML = 'div';

  if (to) {
    Tag = Link;
  }

  return (
    <Tag
      className={cn(styles.root, className, isDisabled && styles.disabledRoot)}
      onClick={onClick}
      to={to as any}
    >
      {direction === ArrowDirectionEnum.left && (
        <LongArrowLeftIcon className={styles.iconLeft} />
      )}
      {children}
      {direction === ArrowDirectionEnum.right && (
        <LongArrowRightIcon className={styles.iconRight} />
      )}
    </Tag>
  );
};

ButtonArrow.propTypes = {};

ButtonArrow.defaultProps = {
  direction: ArrowDirectionEnum.right,
  isDisabled: false,
  onClick: noop,
};

export default React.memo(ButtonArrow);
