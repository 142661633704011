import { observer } from 'mobx-react';
import { useEffect } from 'react';
import * as React from 'react';
import toast from 'cogo-toast';
import { useHistory } from 'react-router-dom';

import urls from 'config/urls';
import useForm from 'components/form/hooks/useForm';
import LockIcon from 'components/icons/LockIcon';
import Button from 'components/common/Button';
import FullSideSpinner from 'components/common/Spinner/FullSideSpinner';
import { WhiteCard } from 'components/common/BaseCard';
import { useRootStore } from 'store/context';
import { useLocal } from 'utils/hooks/useLocal';
import { useQuery } from 'utils/hooks/useQuery';
import { InputField } from 'components/form';

import { InviteForm, InviteFormFields } from './store/InviteForm';
import { InviteStore } from './store/InviteStore';
import styles from './Invite.modules.scss';

const Invite: React.FC = () => {
  const store = useLocal(() => new InviteStore());
  const history = useHistory();
  const root = useRootStore();
  const { token } = useQuery();
  const formStore = useLocal(() => new InviteForm());
  const form = useForm(formStore, Object.values(InviteFormFields));

  useEffect(() => {
    if (token) {
      store.loadInvite(String(token));
    }
  }, [token]);

  const onSave = async (): Promise<any> => {
    if (token) {
      const result = await formStore.save(
        String(token),
        !store.receiver?.isActive
      );
      if (!result) {
        toast.error('Не удалось принять приглашение, попробуйте позже');
      } else {
        toast.success('Доступ успешно получен');
        store.setSuccess(true);
        history.replace(urls.courses);
        return root.init();
      }
    }
  };

  if (!store.isLoadedInvite || store.isLoadingInvite) {
    return <FullSideSpinner isOpen />;
  }

  if (store.isInviteError || !token) {
    return (
      <div className={styles.root}>
        <WhiteCard className={styles.modal}>
          <div className={styles.intro}>
            Возникла ошибка, попробуйте перезагрузить страницу
          </div>
        </WhiteCard>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <WhiteCard className={styles.modal}>
        <div className={styles.intro}>
          Присоединяйтесь к курсам компании <b>{store.company?.name}</b>
        </div>
        {!store.receiver?.isActive && (
          <div className={styles.form}>
            <div className={styles.formInfo}>
              Пожалуйста, зарегистрируйтесь на платформе, чтобы получить доступ:
            </div>
            <InputField
              placeholder="Имя"
              value={form.firstName.value}
              onChange={form.firstName.setValue}
              errors={form.firstName.errors}
            />
            <InputField
              placeholder="Фамилия"
              value={form.lastName.value}
              onChange={form.lastName.setValue}
              errors={form.lastName.errors}
            />
            <InputField
              placeholder="Пароль"
              noLabel
              icon={<LockIcon className={styles.inputIcon} />}
              value={form.password.value}
              onChange={form.password.setValue}
              errors={form.password.errors}
              type="password"
            />
            <InputField
              placeholder="Повторите пароль"
              noLabel
              icon={<LockIcon className={styles.inputIcon} />}
              value={form.passwordRepeat.value}
              onChange={form.passwordRepeat.setValue}
              errors={form.passwordRepeat.errors}
              type="password"
            />
          </div>
        )}
        {formStore.isExpiredToken ? (
          <div>
            <div className={styles.intro}>
              Вероятно, вы уже активировали аккаунт на платформе, попробуйте
              войти
            </div>
            <Button
              onClick={() => history.push(urls.courses)}
              className={styles.submit}
            >
              Войти
            </Button>
          </div>
        ) : (
          <Button
            className={styles.submit}
            onClick={onSave}
            isLoading={formStore.isSaving}
          >
            Получить доступ
          </Button>
        )}
      </WhiteCard>
    </div>
  );
};

export default observer(Invite);
