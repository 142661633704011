import * as React from 'react';
import cn from 'classnames';

import styles from './Disabled.modules.scss';

type Props = {
  text?: string;
  isDisabled?: boolean;
  className?: string;
  children: React.ReactNode;
};

const Disabled: React.FC<Props> = ({
  className,
  isDisabled,
  children,
  text,
}: Props) => {
  return (
    <div className={cn(styles.wrapper, className)}>
      {isDisabled && <div className={styles.content}>{text}</div>}
      <div className={cn(isDisabled && styles.disabled)}>{children}</div>
    </div>
  );
};

Disabled.defaultProps = {
  isDisabled: true,
  text: 'Недоступно',
};

export default Disabled;
