export default {
  user: {
    login: '/api/v2.user.login',
    logout: '/api/user.logout',
    current: '/api/v2.user.current',
    requestPasswordRecovering: '/api/user.request_password_recovering',
    recoverPassword: '/api/user.recover_password',
  },
  course: {
    list: '/api/v2.course.list',
    get: '/api/v2.course.get',
    create: '/api/v2.course.create',
    edit: '/api/v2.course.edit',
    delete: '/api/v2.course.delete',
  },
  chapter: {
    create: '/api/chapter.create',
    edit: '/api/chapter.edit',
    delete: '/api/v2.chapter.delete',
    swap: '/api/v2.chapter.swap',
  },
  lesson: {
    list: '/api/v2.lesson.list',
    get: '/api/v2.lesson.get',
    create: '/api/lesson.create',
    edit: '/api/lesson.edit',
    swap: '/api/v2.lesson.swap',
    delete: '/api/v2.lesson.delete',
  },
  unit: {
    get: '/api/v2.unit.get',
    create: '/api/v2.unit.create',
    edit: '/api/v2.unit.edit',
    delete: '/api/v2.unit.delete',
    swap: '/api/v2.unit.swap',
  },
  chunks: {
    saveMany: '/api/v2.chunk.save_many',
    checkAnswers: '/api/v2.chunk.check_answers',
    saveStudentCode: '/api/v2.chunk.save_student_code',
    checkCodeTask: '/api/v2.chunk.check_code_task',
    startMercuryTask: '/api/v2.chunk.start_mercury_task',
  },
  attachment: {
    upload: '/api/attachment.upload',
    uploadBunny: '/api/attachment.upload?target=bunny',
    getStatus: '/api/attachment.get_status',
  },
  homework: {
    get: '/api/homework.get_homework',
    checkHomework: '/api/homework.check_homework',
    sendHomework: '/api/homework.send_homework',
    listHomework: '/api/homework.list_homework',
    assign: '/api/homework.assign',
    listTitles: '/api/homework.list_titles',
  },
  conversation: {
    sendMessage: '/api/conversation.send_message',
    listMessages: '/api/conversation.list_messages',
  },
  company: {
    registerStaff: '/api/company.register_staff',
    createGroup: '/api/company.create_group',
    listGroups: '/api/company.list_groups',
    listStudents: '/api/company.list_users',
    invitation: {
      send: '/api/company.send_invitations',
      check: '/api/company.check_invitation',
      accept: '/api/company.accept_invitation',
    },
    getPaymentCredentials: '/api/company.get_payment_credentials',
    updatePaymentCredentials: '/api/company.update_payment_credentials',
    getPaymentMethods: '/api/company.get_payment_methods',
    getGroupPayment: '/api/company.get_group_payment',
    setGroupPayment: '/api/company.set_group_payment',
    getGroupPaymentInfo: '/api/company.get_group_payment_info',
  },
  payment: {
    groupPay: '/api/payment.group_pay',
    groupCheck: '/api/payment.group_check',
    groupReturn: '/api/payment.group_return',
  },
  integrationTokens: {
    listTokens: '/api/company.list_tokens',
    deleteToken: '/api/company.delete_token',
    createToken: '/api/company.create_token',
  },
};
