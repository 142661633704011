import {
  greaterThan,
  notEmpty,
  Validator,
  ValidatorParams,
  GreaterThanParams,
  isEmail,
  isPhone,
} from './validators';

export type ComposedValidator<T> = (value: T) => string[];

export const notEmptyValidator =
  (params?: ValidatorParams): Validator<any> =>
  (value: any): string =>
    notEmpty(value, params);

export const greaterThanValidator =
  (params?: GreaterThanParams): Validator<string | number> =>
  (value: any): string =>
    greaterThan(value, params);

export const emailValidator =
  (params?: ValidatorParams): Validator<string> =>
  (value: string): string =>
    isEmail(value, params);

export const phoneValidator =
  (params?: ValidatorParams): Validator<string> =>
  (value: string): string =>
    isPhone(value, params);

export const composeValidators =
  <T>(...validators: Validator<T>[]): ComposedValidator<T> =>
  (value: T): string[] => {
    return validators.reduce((acc: string[], next: Validator<T>) => {
      const error: string = next(value);
      if (error) {
        acc.push(error);
      }
      return acc;
    }, []);
  };
