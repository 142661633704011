import * as React from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import useFocus from 'components/form/hooks/useFocus';
import Label from 'components/form/Label';
import { WithFieldProps } from 'components/form/Field/types';

import inputStyles from '../styles/common.modules.scss';

type ExceptOwnProps = Omit<
  React.HTMLProps<HTMLTextAreaElement>,
  'value' | 'ref' | 'onChange'
>;

interface Props extends ExceptOwnProps, WithFieldProps {
  value: string;
  onChange: (value: string) => void;
}

const TextArea: React.FC<Props> = ({
  value,
  onChange,
  placeholder,
  ...textAreaProps
}: Props) => {
  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  const [isFocused, toggleFocus] = useFocus();

  return (
    <div className={inputStyles.inputWrapper}>
      {placeholder && (
        <Label isFocused={isFocused} isUp={Boolean(value)}>
          {placeholder}
        </Label>
      )}
      <TextareaAutosize
        onFocus={toggleFocus}
        onBlur={toggleFocus}
        className={inputStyles.input}
        value={value || ''}
        onChange={handleChange}
        {...textAreaProps}
      />
    </div>
  );
};

export default React.memo(TextArea);
