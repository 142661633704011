import { makeObservable, override, toJS } from 'mobx';

import { AttachmentType } from 'store/models/attachment';
import { Form, FormValidators } from 'store/models/common/Form';
import {
  composeValidators,
  greaterThanValidator,
  notEmptyValidator,
} from 'utils/validation';
import apiUrls from 'config/apiUrls';
import {
  CourseStatusEnum,
  CourseCreateResponseType,
} from 'store/models/course/types';
import { ServerResponse } from 'store/models/common/types';
import request from 'utils/request';
import CourseModel from 'store/models/course/CourseModel';

import { CompanyModel } from '../../stores/UserStore/types';

export enum CourseFieldsEnum {
  title = 'title',
  description = 'description',
  background = 'background',
  pointPerUnit = 'pointPerUnit',
  pointPerTest = 'pointPerTest',
  isPublished = 'isPublished',
  oneDeadline = 'oneDeadline',
}

export type CourseEditFields = {
  title: string;
  description: string;
  background: AttachmentType | null;
  pointPerUnit: number;
  pointPerTest: number;
  isPublished: boolean;
  oneDeadline: boolean;
};

export class CourseCreationFormModel extends Form<CourseEditFields> {
  validators: FormValidators<CourseEditFields> = {
    title: composeValidators(
      notEmptyValidator({
        errorMessage: 'Заполните название',
      }),
      greaterThanValidator({
        errorMessage: 'Название должно быть длиннее 5 символов',
        min: 5,
      })
    ),
    description: composeValidators(notEmptyValidator(), greaterThanValidator()),
    background: composeValidators(notEmptyValidator()),
    pointPerUnit: composeValidators(
      greaterThanValidator({
        errorMessage: 'Баллы не должны быть отрицательными',
        min: 0,
      })
    ),
    pointPerTest: composeValidators(
      greaterThanValidator({
        errorMessage: 'Баллы не должны быть отрицательными',
        min: 0,
      })
    ),
  };

  courseModel: CourseModel | null = null;

  id?: number;

  constructor(courseModel: CourseModel | null) {
    super({
      initialValues: {
        title: '',
        description: '',
        background: null,
        pointPerUnit: 0,
        pointPerTest: 0,
        isPublished: false,
        oneDeadline: false,
      },
    });

    makeObservable(this, {
      setInitialFrom: override,
      save: override,
    });

    if (courseModel) {
      this.courseModel = courseModel;
      this.id = this.courseModel.id;

      this.clear(courseModel);
    }
  }

  setInitialFrom(courseModel: CourseModel): void {
    this.initialValues = toJS({
      title: this.courseModel?.title || '',
      description: this.courseModel?.description || '',
      background: courseModel.background,
      pointPerUnit: courseModel.inner?.pointPerUnit || 0,
      pointPerTest: courseModel.inner?.pointPerTest || 0,
      isPublished: courseModel.isPublished,
      oneDeadline: courseModel.oneDeadline,
    });
  }

  async save(company: CompanyModel): Promise<boolean | CourseModel | null> {
    this.validate();

    if (this.hasErrors) {
      return false;
    }

    this.isSaving = true;

    const saveUrl = this.courseModel
      ? apiUrls.course.edit
      : apiUrls.course.create;

    const status = CourseStatusEnum.draft;

    const data = toJS({
      title: this.values.title,
      description: this.values.description,
      background_image: (this.values.background as AttachmentType)?.id,
      point_per_unit: this.values.pointPerUnit,
      point_per_test: this.values.pointPerTest,
      status,
      id: this.courseModel ? this.courseModel.id : undefined,
      company: company.id,
      is_published: this.values.isPublished,
      one_deadline: this.values.oneDeadline,
    });

    const { response }: ServerResponse<CourseCreateResponseType> =
      await request(saveUrl, 'POST', data);

    this.isSaving = false;

    if (response) {
      this.id = response.id;
      if (this.courseModel) {
        this.courseModel.updateFrom(this);
      } else {
        this.courseModel = CourseModel.fromForm(this, company);
      }
      return this.courseModel;
    }

    return false;
  }
}
