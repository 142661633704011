import { observer } from 'mobx-react';
import { forwardRef } from 'react';
import * as React from 'react';

import { Error } from 'components/form';
import { ChunkModel } from 'store/models/chunks/ChunkModel';

import { CHUNK_ELEMENT_ID } from '../../config';

type Props = {
  children: React.ReactNode;
  isEditMode: boolean;
  chunk: ChunkModel;
  elementProps: { [key: string]: any };
  className?: string;
};

const Layout = forwardRef(
  (
    { children, elementProps, className, isEditMode, chunk }: Props,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { style, ...rest } = elementProps;

    if (!isEditMode) {
      return <div style={style}>{children}</div>;
    }

    return (
      <div
        id={CHUNK_ELEMENT_ID}
        ref={ref}
        style={style}
        className={className}
        {...rest}
      >
        {children}
        {chunk.hasErrors && (
          <Error>
            {chunk.errors.map((err, i) => (
              <div key={i}>{err}</div>
            ))}
          </Error>
        )}
      </div>
    );
  }
);

export default observer(Layout);
