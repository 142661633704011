import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';

import request from 'utils/request';
import apiUrls from 'config/apiUrls';
import { ServerResponse } from 'store/models/common/types';

import { UnitModel } from '../../../unit/UnitModel';
import { HomeworkMessagesResponseType } from '../types';

import { HomeworkMessageModel } from './HomeworkMessageModel';
import { HomeworkModel } from './HomeworkModel';

export const MAX_MESSAGES_LOAD = 20;

const POLLING_INTERVAL = 10 * 1000;

export enum LoadDirection {
  asc = 'asc', // Более новые
  desc = 'desc', // Более старые
}

export class HomeworkChatModel {
  chunkId: string;

  conversationId: string;

  unit?: UnitModel;

  homework: HomeworkModel | null = null;

  isSending = false;

  isLoading = false;

  isLoadingHomework = false;

  isAllLoaded = false;

  isLoadingOlderMessages = false;

  isInitiallyLoaded = false;

  // Идут в обратном порядке 100 99 98
  messages: HomeworkMessageModel[] = [];

  newMessage: HomeworkMessageModel = HomeworkMessageModel.createDefault();

  pollingInterval: any = null;

  constructor({
    chunkId,
    conversationId,
    unit,
  }: {
    chunkId: string;
    conversationId: string;
    unit?: UnitModel;
  }) {
    makeObservable(this, {
      // observable
      isSending: observable,
      isLoading: observable,
      isAllLoaded: observable,
      isLoadingOlderMessages: observable,
      isLoadingHomework: observable,
      isInitiallyLoaded: observable,
      messages: observable,
      newMessage: observable,
      // computed
      firstMessageId: computed,
      lastMessageId: computed,
      // action
      loadMessages: action.bound,
      sendMessage: action.bound,
      stopPolling: action.bound,
      startPolling: action.bound,
    });
    this.chunkId = chunkId;
    this.conversationId = conversationId;
    this.unit = unit;
  }

  get firstMessageId(): number | undefined {
    // Самое старое
    return this.messages.length > 0
      ? this.messages[this.messages.length - 1].id
      : undefined;
  }

  get lastMessageId(): number | undefined {
    // Самое новое
    return this.messages.length > 0 ? this.messages[0]?.id : undefined;
  }

  // get homework(): HomeworkChunkModel | undefined {
  //   return this.unit?.editorState
  //     .getCurrentContent()
  //     .getAllEntities()
  //     .filter((c) => c?.getType() === CHUNK_ENTITY_TYPE)
  //     .find(
  //       (c) =>
  //         c?.getData().type === ChunkTypeEnum.homework &&
  //         c?.getData().chunk.id === this.chunkId // Почему-то DraftJS глобально запоминает entityMap и добавляет туда сущности при переходах междуу страницами
  //     )
  //     ?.getData()?.chunk?.data;
  // }

  stopPolling(): void {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
      this.pollingInterval = null;
    }
  }

  async startPolling(): Promise<void> {
    this.stopPolling();
    await this.loadMessages({
      direction: LoadDirection.desc,
      limit: MAX_MESSAGES_LOAD,
    });
    this.pollingInterval = setInterval(this.loadMessages, POLLING_INTERVAL);
  }

  async loadMessages(
    { direction, limit } = {
      direction: LoadDirection.asc,
      limit: MAX_MESSAGES_LOAD,
    }
  ): Promise<void> {
    if (
      this.isLoading ||
      this.isSending ||
      (direction === LoadDirection.desc && this.isAllLoaded)
    ) {
      return;
    }

    this.isLoading = true;
    this.isLoadingOlderMessages = direction === LoadDirection.desc;

    const { response }: ServerResponse<HomeworkMessagesResponseType> =
      await request(apiUrls.conversation.listMessages, 'GET', {
        conversation: this.conversationId,
        limit,
        from_id:
          direction === LoadDirection.asc
            ? this.lastMessageId
            : this.firstMessageId,
        homework_chunk: this.chunkId,
        direction,
      });

    runInAction(() => {
      if (response) {
        this.homework = HomeworkModel.fromJson(response.homework_progress);

        // Сообщения приходят в прямом порядке -> 1 2 3 4 5
        const newMessages = response.messages.map((m) =>
          HomeworkMessageModel.fromJson(m)
        );

        if (direction === LoadDirection.asc) {
          this.messages.unshift(...newMessages.reverse());
        } else {
          this.messages.push(...newMessages);
          if (newMessages.length === 0) {
            this.isAllLoaded = true;
          }
        }
        this.isInitiallyLoaded = true;
      }

      this.isLoading = false;
      this.isLoadingOlderMessages = false;
    });
  }

  async sendMessage(isSolution = false): Promise<boolean> {
    this.isSending = true;

    await this.loadMessages({ direction: LoadDirection.asc, limit: 50 });

    const data = this.newMessage.toJson() as any;
    data.homework_chunk = this.chunkId;
    data.conversation = this.conversationId;

    const { response }: ServerResponse = await request(
      isSolution
        ? apiUrls.homework.sendHomework
        : apiUrls.conversation.sendMessage,
      'POST',
      data
    );

    if (response) {
      this.messages.unshift(HomeworkMessageModel.fromJson(response));
      this.newMessage = HomeworkMessageModel.createDefault();
    }

    this.isSending = false;

    return Boolean(response);
  }
}
