import * as React from 'react';
import { useCallback } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';

import BaseCard from 'components/common/BaseCard';
import urls from 'config/urls';

import { useLessonStore } from '../../context';
import Header from '../Header';

import UnitAdmin from './Admin';
import styles from './LessonSlide.modules.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const LessonSlide: React.FC<Props> = (props: Props) => {
  const { children, className } = props;
  const { lesson, unit } = useLessonStore();

  const currentIndex = unit?.id ? lesson?.getUnitIndexById(unit?.id) || 0 : 0;
  const totalUnitsCount = lesson?.units.length;

  const history = useHistory();

  const gotoCard = useCallback(
    (index: number) => {
      if (!lesson) {
        return;
      }

      const unit = lesson.getUnitByIndex(index);
      if (unit) {
        return history.push(urls.lessonCard(lesson.id, unit.id));
      }
      const { lessonId: nextLessonId, id: nextUnitId } =
        (index < 0 ? lesson.prevUnit : lesson.nextUnit) || {};
      if (nextLessonId) {
        return history.push(urls.lessonCard(nextLessonId, nextUnitId));
      } else {
        return history.push(urls.courseId(lesson.courseId));
      }
    },
    [lesson]
  );

  return (
    <BaseCard className={cn(styles.root, className)}>
      <UnitAdmin />
      {lesson && lesson.courseId && (
        <Header
          unit={unit}
          currentIndex={currentIndex === -1 ? 0 : currentIndex + 1}
          total={totalUnitsCount}
          type={lesson.type}
          courseId={lesson.courseId}
          onPrevClick={(): void => gotoCard(currentIndex - 1)}
          onNextClick={(): void => gotoCard(currentIndex + 1)}
          onGoToCard={gotoCard}
        />
      )}
      <div className={styles.content}>{children}</div>
      {lesson && lesson.courseId && (
        <Header
          unit={unit}
          currentIndex={currentIndex === -1 ? 0 : currentIndex + 1}
          total={totalUnitsCount}
          type={lesson.type}
          courseId={lesson.courseId}
          onPrevClick={(): void => gotoCard(currentIndex - 1)}
          onNextClick={(): void => gotoCard(currentIndex + 1)}
          onGoToCard={gotoCard}
        />
      )}
    </BaseCard>
  );
};

LessonSlide.defaultProps = {
  className: '',
};

export default observer(LessonSlide);
