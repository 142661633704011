import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import * as React from 'react';

import urls from 'config/urls';
import Button from 'components/common/Button';
import BaseCard from 'components/common/BaseCard';
import Progress from 'components/common/Progress';
import Spinner from 'components/common/Spinner';
import { useCourseStore } from 'store/index';

import styles from './Stats.modules.scss';

const Stats: React.FC = () => {
  const courses = useCourseStore();
  const history = useHistory();

  const startedCourses = courses.courses.filter((c) => c.isStarted);

  return (
    <div>
      <div className={styles.title}>Статистика</div>
      <BaseCard>
        <div className={styles.cardTitle}>Прохождение курсов</div>
        <div className={styles.courses}>
          {startedCourses.length === 0 && (
            <>
              <div className={styles.coursesEmpty}>
                Вы&nbsp;еще не&nbsp;начали проходить курсы
              </div>
              <Button onClick={() => history.push(urls.courses)}>
                Посмотреть курсы
              </Button>
            </>
          )}
          {courses.isLoading && <Spinner local />}
          {startedCourses.map((c) => (
            <Progress
              className={styles.course}
              progress={c.inner?.countCompleteLessons || 0}
              total={c.totalLessonsCount}
              key={c.id}
            >
              {c.title}
            </Progress>
          ))}
        </div>
      </BaseCard>
    </div>
  );
};

export default observer(Stats);
