import { useCallback } from 'react';
import * as React from 'react';

import Tag from 'components/common/Tag';

import styles from './SelectedItem.modules.scss';

type Props = {
  id: string | number;
  onDelete(id: string | number): void;
  children: React.ReactNode;
};

const SelectedItem: React.FC<Props> = ({ children, onDelete, id }: Props) => {
  const handleDelete = useCallback(() => {
    onDelete(id);
  }, [onDelete, id]);

  return (
    <Tag className={styles.tag} onDelete={handleDelete}>
      {children}
    </Tag>
  );
};

export default React.memo(SelectedItem);
