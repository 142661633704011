import { action, makeObservable, observable, toJS } from 'mobx';

import { ChunkModel } from 'store/models/chunks/ChunkModel';

import {
  ChunkAlignEnum,
  ChunkServerType,
  ChunkTypeEnum,
  IChunkModel,
} from './types';

type ChunkLayoutType = {
  chunk: ChunkModel;

  width: number;
  offsetTop: number;
  offsetLeft: number;
  offsetRight: number;
  offsetBottom: number;
  verticalAlign: ChunkAlignEnum;
  horizontalAlign: ChunkAlignEnum | null;
};

export class ChunkLayoutModel implements IChunkModel, ChunkLayoutType {
  chunk: ChunkModel;

  width: number;
  offsetTop: number;
  offsetLeft: number;
  offsetRight: number;
  offsetBottom: number;
  verticalAlign: ChunkAlignEnum;
  horizontalAlign: ChunkAlignEnum | null;

  constructor({
    chunk,
    width,
    offsetTop,
    offsetLeft,
    offsetRight,
    offsetBottom,
    verticalAlign,
    horizontalAlign,
  }: ChunkLayoutType) {
    makeObservable(this, {
      // observable
      width: observable,
      offsetRight: observable,
      offsetLeft: observable,
      offsetTop: observable,
      offsetBottom: observable,
      verticalAlign: observable,
      horizontalAlign: observable,
      // action
      setWidth: action.bound,
      setOffsetTop: action.bound,
      setOffsetBottom: action.bound,
      setOffsetLeft: action.bound,
      setOffsetRight: action.bound,
      setHorizontalAlign: action.bound,
      setVerticalAlign: action.bound,
    });
    this.chunk = chunk;

    this.width = width;
    this.offsetTop = offsetTop;
    this.offsetBottom = offsetBottom;
    this.offsetLeft = offsetLeft;
    this.offsetRight = offsetRight;
    this.verticalAlign = verticalAlign;
    this.horizontalAlign = horizontalAlign;
  }

  setWidth(width: number): void {
    if (width > 0 && width <= 100) {
      this.width = width;
    }
  }

  setOffsetTop(v: number): void {
    this.offsetTop = v;
  }

  setOffsetBottom(v: number): void {
    this.offsetBottom = v;
  }

  setOffsetLeft(v: number): void {
    this.offsetLeft = v;
  }

  setOffsetRight(v: number): void {
    this.offsetRight = v;
  }

  setVerticalAlign(va: ChunkAlignEnum): void {
    this.verticalAlign = va;
  }

  setHorizontalAlign(ha: ChunkAlignEnum): void {
    this.horizontalAlign = ha;
  }

  toJson(): object {
    return toJS({
      layout_width: this.width,
      layout_offset_top: this.offsetTop,
      layout_offset_left: this.offsetLeft,
      layout_offset_right: this.offsetRight,
      layout_offset_bottom: this.offsetBottom,
      layout_vertical_align: this.verticalAlign,
      layout_horizontal_align: this.horizontalAlign,
    });
  }

  public static createDefault({
    chunk,
  }: {
    chunk: ChunkModel;
  }): ChunkLayoutModel {
    const isMedia = [ChunkTypeEnum.image, ChunkTypeEnum.video].includes(
      chunk.type
    );

    const isComplexChunk = [
      ChunkTypeEnum.quiz,
      ChunkTypeEnum.quizAnswer,
    ].includes(chunk.type);

    return new ChunkLayoutModel({
      chunk,

      width: isMedia ? 50 : 100,
      offsetTop: isComplexChunk ? 20 : 0,
      offsetLeft: isComplexChunk ? 20 : 0,
      offsetRight: isComplexChunk ? 20 : 0,
      offsetBottom: isComplexChunk ? 20 : 0,
      verticalAlign: ChunkAlignEnum.top,
      horizontalAlign: isMedia ? ChunkAlignEnum.center : ChunkAlignEnum.left,
    });
  }

  public static fromJson(
    json: ChunkServerType,
    chunk: ChunkModel
  ): ChunkLayoutModel {
    return new ChunkLayoutModel({
      chunk,
      width: json.layout_width,
      offsetTop: json.layout_offset_top,
      offsetLeft: json.layout_offset_left,
      offsetRight: json.layout_offset_right,
      offsetBottom: json.layout_offset_bottom,
      verticalAlign: json.layout_vertical_align,
      horizontalAlign: json.layout_horizontal_align,
    });
  }
}
