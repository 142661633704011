import cn from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';

import SuccessIcon from 'components/icons/lesson/SuccessIcon';
import WarningIcon from 'components/icons/WarningIcon';
import { HomeworkMessageModel } from 'store/models/chunks/homework/chat/HomeworkMessageModel';
import { HomeworkSystemMessageEnum } from 'store/models/chunks/homework/types';

import { systemMessageText } from './config';
import styles from './SystemMessage.modules.scss';

type Props = {
  message: HomeworkMessageModel;
};

const SystemMessage: React.FC<Props> = ({ message }: Props) => {
  return (
    <div
      className={cn(styles.alert, {
        [styles.alertChecking]:
          message.message === HomeworkSystemMessageEnum.checking,
        [styles.alertFinished]:
          message.message === HomeworkSystemMessageEnum.finished,
      })}
    >
      {message.message === HomeworkSystemMessageEnum.finished ? (
        <SuccessIcon className={styles.alertIcon} />
      ) : (
        <WarningIcon className={styles.alertIcon} />
      )}
      <div>
        Задача переведена в&nbsp;статус &laquo;
        <b>{systemMessageText[message.message as HomeworkSystemMessageEnum]}</b>
        &raquo;
      </div>
    </div>
  );
};

export default observer(SystemMessage);
