import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';

import { RootStore } from 'store/RootStore';
import { PaymentsType } from 'config/payments';
import request from 'utils/request';
import apiUrls from 'config/apiUrls';
import { ServerResponse } from 'store/models/common/types';
import {
  CheckPaymentGroupResponse,
  errorStatuses,
  GetPaymentGroupInfoResponse,
  GetPaymentMethodsApiResponse,
  GroupReturnResponse,
  PAID_KEY_COOKIE,
  PayGroupResponse,
  PaymentGroupStatus,
} from 'store/stores';
import { getCookieValue } from 'utils/cookies';

export class PaymentsStore {
  rootStore: RootStore | null = null;
  isPaymentFormIsError = false;
  isPaymentFormIsLoading = false;

  isLoading = false;
  isError = false;

  paymentTypes: PaymentsType[] = [];
  status: PaymentGroupStatus = PaymentGroupStatus.pending;
  groupUserId: number | null = null;
  paymentUrl: string | null = null;

  amount = 0;

  courses: string[] = [];

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      // observable
      isLoading: observable,
      isError: observable,
      isPaymentFormIsError: observable,
      isPaymentFormIsLoading: observable,
      paymentTypes: observable,
      amount: observable,
      courses: observable,
      status: observable,
      groupUserId: observable,
      paymentUrl: observable,
      // computed
      hasPayments: computed,
      // action
      fetchPaymentsType: action.bound,
      fetchPaymentGroupInfo: action.bound,
      paymentGroupCheck: action.bound,
      payGroup: action.bound,
      fetchGroupReturn: action.bound,
      setPaymentStatus: action.bound,
      initPaymentForm: action.bound,
    });
    this.rootStore = rootStore;
  }

  async initPaymentForm(
    kind: PaymentsType,
    groupId: number
  ): Promise<void | boolean> {
    this.isPaymentFormIsLoading = true;
    this.isPaymentFormIsError = false;

    if (!(await this.paymentGroupCheck(Number(groupId)))) {
      const result = await this.fetchPaymentGroupInfo(
        Number(groupId),
        kind as PaymentsType
      );
      this.isPaymentFormIsError = !result;
    } else {
      this.isPaymentFormIsError = this.isError;
      this.isPaymentFormIsLoading = false;
      return Boolean(!this.isError && this.groupUserId);
    }
    this.isPaymentFormIsLoading = false;
  }

  get hasPayments(): boolean {
    return !!this.paymentTypes.length;
  }

  setPaymentStatus(status: PaymentGroupStatus): void {
    this.status = status;
  }

  async fetchPaymentsType(company: number): Promise<void> {
    this.isLoading = true;
    this.isError = false;

    try {
      const { response }: ServerResponse<GetPaymentMethodsApiResponse> =
        await request(apiUrls.company.getPaymentMethods, 'GET', {
          company,
        });

      if (!response) {
        this.isError = true;
      } else {
        this.paymentTypes = response.methods;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      this.isError = true;
    }

    this.isLoading = false;
  }

  async fetchPaymentGroupInfo(
    groupId: number,
    kind: PaymentsType
  ): Promise<boolean> {
    try {
      const { response, error } = await request<GetPaymentGroupInfoResponse>(
        apiUrls.company.getGroupPaymentInfo,
        'GET',
        {
          kind,
          group: groupId,
        }
      );

      if (error) {
        return false;
      } else if (response) {
        runInAction(() => {
          this.amount = response.amount;
          this.courses = response.courses;
        });
        return true;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }

    return false;
  }

  // Метод для проверки пользователя, чтобы он второй раз не оплатил
  // true - если пользователь уже оплатил группу
  // false - если не оплатил группу
  async paymentGroupCheck(groupId: number): Promise<boolean> {
    this.isError = false;
    const cookie = getCookieValue(PAID_KEY_COOKIE);

    if (!cookie) {
      return false;
    }

    try {
      const { response, error } = await request<CheckPaymentGroupResponse>(
        apiUrls.payment.groupCheck,
        'GET',
        {
          token: cookie,
        }
      );

      if (error) {
        return false;
      } else if (response) {
        this.groupUserId = response.group_user_id;
        return response.group_id === groupId;
      }
    } catch (e) {
      this.isError = true;
      // eslint-disable-next-line no-console
      console.log(e);
    }

    return false;
  }

  async payGroup(
    email: string,
    groupId: number,
    kind: PaymentsType
  ): Promise<string | undefined> {
    this.isLoading = true;
    this.isError = false;

    try {
      const { response } = await request<PayGroupResponse>(
        apiUrls.payment.groupPay,
        'POST',
        {
          email,
          kind,
          group: groupId,
        }
      );

      if (response) {
        runInAction(() => {
          this.status = response.status;
          this.groupUserId = response.group_user_id;
          this.isLoading = false;
        });

        return response.confirmation_url;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }

    this.isError = true;
    this.isLoading = false;
  }

  async fetchGroupReturn(groupUserId: number): Promise<boolean> {
    try {
      const { response } = await request<GroupReturnResponse>(
        apiUrls.payment.groupReturn,
        'GET',
        {
          group_user_id: groupUserId,
        }
      );

      if (response) {
        this.status = response.status;
        if (errorStatuses.indexOf(response.status) > -1) {
          this.paymentUrl = response.payment_url;
        }
        return true;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }

    return false;
  }
}
