import * as React from 'react';
import cn from 'classnames';

import BaseCard, { BaseCardProps } from '../BaseCard';

import styles from './WhiteCard.modules.scss';

type Props = BaseCardProps;

const WhiteCard: React.FC<Props> = ({ className, ...rest }: Props) => {
  return <BaseCard className={cn(className, styles.whiteCard)} {...rest} />;
};

export default React.memo(WhiteCard);
