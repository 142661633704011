import { makeObservable, observable, override, runInAction } from 'mobx';

import { Form } from 'store/models/common/Form';
import {
  composeValidators,
  emailValidator,
  greaterThanValidator,
  notEmptyValidator,
} from 'utils/validation';
import request from 'utils/request';
import apiUrls from 'config/apiUrls';
import { ServerResponse } from 'store/models/common/types';

type ResetPasswordFields = {
  email: string;
};

export class ResetPasswordStore extends Form<ResetPasswordFields> {
  initialValues = {
    email: '',
  };

  validators = {
    email: composeValidators(
      notEmptyValidator(),
      emailValidator(),
      greaterThanValidator({
        min: 5,
        errorMessage: 'Email должен быть длиннее 5 символов',
      })
    ),
  };

  isError = false;
  isSaved = false;

  constructor() {
    super();

    makeObservable(this, {
      //observable
      isError: observable,
      isSaved: observable,
      //override
      save: override,
    });
  }

  async save(): Promise<boolean> {
    if (this.isSaving || !this.values.email) {
      return false;
    }

    this.isError = false;

    this.validate();

    if (this.hasErrors) {
      return false;
    }

    this.isSaving = true;

    const { error } = await request(
      apiUrls.user.requestPasswordRecovering,
      'POST',
      {
        email: this.values.email,
      }
    );

    runInAction(() => {
      if (error) {
        this.isSaved = false;
        this.isError = true;
        this.errors.email = [
          'Произошла ошибка - возможно, пользователя с таким email не существует',
        ];
      } else {
        this.isSaved = true;
      }

      this.isSaving = false;
    });

    return !error;
  }
}
