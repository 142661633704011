import * as React from 'react';

import OpenBookItem from 'components/icons/OpenBookIcon';
import AcademicCapIconV2 from 'components/icons/AcademicCapIconV2';
import FileIcon from 'components/icons/FileIcon';

import styles from './InfoItem.modules.scss';

export enum CourseIconTypeEnum {
  book = 'book',
  academicCap = 'academicCap',
  file = 'file',
}

type Props = {
  iconType: CourseIconTypeEnum;
  children: React.ReactNode;
};

const InfoItem: React.FC<Props> = (props: Props) => {
  const { iconType, children } = props;

  return (
    <div className={styles.root}>
      {iconType === CourseIconTypeEnum.book && (
        <OpenBookItem className={styles.iconOpenBook} />
      )}
      {iconType === CourseIconTypeEnum.academicCap && (
        <AcademicCapIconV2 className={styles.iconAcademicCap} />
      )}
      {iconType === CourseIconTypeEnum.file && (
        <FileIcon className={styles.iconFile} />
      )}
      {children}
    </div>
  );
};

export default InfoItem;
