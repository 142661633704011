import * as React from 'react';

import Switcher, { SwitcherProps } from 'components/common/Switcher/Switcher';

import styles from './SwitcherWithText.modules.scss';

type Props = SwitcherProps & {
  children: React.ReactNode;
};

const SwitcherWithText: React.FC<Props> = ({ children, ...rest }: Props) => {
  return (
    <div className={styles.switchWrapper}>
      <span className={styles.switchText}>{children}</span>
      <span>
        <Switcher {...rest} />
      </span>
    </div>
  );
};

export default React.memo(SwitcherWithText);
