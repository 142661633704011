import * as React from 'react';
import { observer } from 'mobx-react';
import cogoToast from 'cogo-toast';

import { usePaymentsStore } from 'store/context';
import { PaymentGroupStatus } from 'store/stores';
import { usePolling } from 'utils/hooks/usePolling';
import { useQuery } from 'utils/hooks/useQuery';
import { getStatusWidget } from 'pages/GroupPayment/GroupPaymentResult/utils';

import GroupPaymentLayout from '../common/GroupPaymentLayout';

import styles from './GroupPaymentResult.modules.scss';

const GroupPaymentResult: React.FC = () => {
  const paymentsStore = usePaymentsStore();

  const { group_user_id: groupUserId } = useQuery();
  const [success, setSuccess] = React.useState(true);
  const [isInit, setIsInit] = React.useState(true);

  const handlePolling = React.useCallback(async () => {
    setSuccess(await paymentsStore.fetchGroupReturn(Number(groupUserId)));
  }, [groupUserId]);

  React.useEffect(() => {
    // Начальный статус , для того чтобы показался лоадер
    paymentsStore.setPaymentStatus(PaymentGroupStatus.pending);
    setIsInit(false);
  }, []);

  const condition =
    paymentsStore.status === PaymentGroupStatus.pending && success;

  usePolling(handlePolling, condition);

  React.useEffect(() => {
    !success &&
      cogoToast.error('Что-то пошло не так, обратитесь в тех поддержку');
  }, [success]);

  const Component = React.useMemo(
    () => getStatusWidget(paymentsStore.status),
    [paymentsStore.status]
  );

  return (
    <GroupPaymentLayout>
      <div className={styles.content}>{!isInit && <Component />}</div>
    </GroupPaymentLayout>
  );
};

export default observer(GroupPaymentResult);
