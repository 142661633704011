import * as React from 'react';
import { useCallback } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import pluralizer from 'utils/pluralizer';
import { QuizStatus, QuizStatusText } from 'store/models/chunks/quiz/types';
import { ChunkModel } from 'store/models/chunks/ChunkModel';
import { QuizGroupModel } from 'store/models/chunks/quiz/QuizGroupModel';
import Button from 'components/common/Button';

import styles from './QuizValidation.modules.scss';

type Props = {
  quizChunk: ChunkModel<QuizGroupModel>;
  isEditMode: boolean;
};

const QuizValidation: React.FC<Props> = ({ quizChunk, isEditMode }: Props) => {
  const handleAnswer = useCallback(async () => {
    await quizChunk.data.checkAnswers();
  }, [quizChunk.data.checkAnswers]);

  const { quizStatus, isValidating, attemptsLeft } = quizChunk.data;

  const isCorrect = quizStatus === QuizStatus.correct;

  if (isEditMode) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div
        className={cn(styles.validationStatus, {
          [styles.validationCorrect]: isCorrect,
          [styles.validationIncorrect]: quizStatus === QuizStatus.incorrect,
        })}
      >
        {QuizStatusText[quizStatus]}
      </div>
      {!isCorrect && (
        <div className={styles.validationStatus}>
          У вас осталось <b>{attemptsLeft}</b>{' '}
          {pluralizer(attemptsLeft, 'попытка', 'попытки', 'попыток')}
        </div>
      )}
      <Button
        onClick={handleAnswer}
        className={styles.quizBtn}
        isLoading={isValidating}
        isDisabled={attemptsLeft <= 0 || isCorrect || isValidating}
      >
        Проверить
      </Button>
    </div>
  );
};

export default observer(QuizValidation);
