import { useCallback } from 'react';
import * as React from 'react';
// import CodeEditor from '@uiw/react-textarea-code-editor';
import Editor from '@monaco-editor/react';

type Props = {
  code: string;
  onChange(code: string): void;
  language?: string;
  readonly?: boolean;
};

const LINE_HEIGHT = 19;
const MIN_LINES = 3;

const SimpleCodeInput: React.FC<Props> = ({
  code,
  onChange,
  readonly = false,
  language = 'python',
}: Props) => {
  const [height, setHeight] = React.useState(LINE_HEIGHT * MIN_LINES);
  const editor = React.useRef<any>();

  const handleEditorChange = useCallback(() => {
    const v = editor.current.getValue();
    const countOfLines = v.split('\n').length;
    onChange(v);

    const currentHeight = countOfLines * LINE_HEIGHT;
    setHeight(currentHeight);
    editor.current.layout();
  }, [onChange]);

  const handleEditorDidMount = useCallback(
    (_valueGetter: any) => {
      editor.current = _valueGetter;
      handleEditorChange();
    },
    [handleEditorChange]
  );

  return (
    <Editor
      height={height}
      onMount={handleEditorDidMount}
      language={language}
      onChange={handleEditorChange}
      value={code}
      options={{
        readOnly: readonly,
        overviewRulerBorder: false,
        scrollBeyondLastLine: false,
        scrollbar: {
          vertical: 'hidden',
          // handleMouseWheel: false,
          alwaysConsumeMouseWheel: false,
        },
        minimap: {
          enabled: false,
        },
        lineHeight: LINE_HEIGHT,
      }}
    />
  );
};

export default React.memo(SimpleCodeInput);
