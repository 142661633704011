import { PaymentsType } from 'config/payments';

export enum PaymentGroupStatus {
  new = 'new',
  pending = 'pending',
  success = 'success',
  cancelled = 'cancelled',
  failed = 'failed',
}

export type GetPaymentMethodsApiResponse = {
  methods: PaymentsType[];
};

export type GetPaymentGroupInfoResponse = {
  amount: number;
  courses: string[];
};

export type GroupReturnResponse = {
  status: PaymentGroupStatus;
  is_new_user: boolean;
} & {
  status: PaymentGroupStatus.failed | PaymentGroupStatus.cancelled;
  payment_url: string;
};

export type CheckPaymentGroupResponse = {
  group_user_id: number;
  group_id: number;
  id: number;
} & GroupReturnResponse;

export type PayGroupResponse = {
  amount: number;
  confirmation_url: string;
  id: number;
  status: PaymentGroupStatus;
  type: 'group_payment';
  // new field
  group_user_id: number | null;
};
