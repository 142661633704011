import Input from './Input';
import FormElement from './FormElement';
import Error from './Error';
import TextArea from './TextArea';
import Dropdown from './Dropdown';
import ImageInput from './ImageInput';
import SwitcherInput from './SwitcherInput';
import { FieldHOC } from './Field';

const InputField = FieldHOC(Input);
const TextAreaField = FieldHOC(TextArea);
const ImageField = FieldHOC(ImageInput);
const DropdownField = FieldHOC(Dropdown);
const SwitchField = FieldHOC(SwitcherInput);

export {
  Input,
  TextArea,
  FormElement,
  Error,
  Dropdown,
  InputField,
  TextAreaField,
  ImageField,
  DropdownField,
  SwitchField,
};
