import * as React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { CourseProgressStatus } from 'store/models/course/types';
import noop from 'utils/noop';
import pluralizer from 'utils/pluralizer';
import ButtonArrow from 'components/common/Button/ButtonArrow';
import CourseModel from 'store/models/course/CourseModel';

import InfoItem from './InfoItem';
import { CourseIconTypeEnum } from './InfoItem/InfoItem';
import styles from './CourseInfo.modules.scss';

type Props = {
  hasContinueButton: boolean;
  isDevelopment: boolean;
  course: CourseModel;
  handleContinue?: () => void;
};

const CONTINUE_TEXT = {
  [CourseProgressStatus.notStarted]: 'Начать',
  [CourseProgressStatus.inProgress]: 'Продолжить',
  [CourseProgressStatus.finished]: 'Посмотреть',
};

const CourseInfo: React.FC<Props> = ({
  isDevelopment,
  hasContinueButton,
  course,
  handleContinue,
}: Props) => {
  const lessonsCount = course.totalLessonsCount;
  const chaptersCount = course.totalChaptersCount;

  return (
    <div className={styles.root}>
      <div
        className={cn(
          styles.itemsContainer,
          isDevelopment && styles.itemsContainerNoActive
        )}
      >
        <InfoItem iconType={CourseIconTypeEnum.book}>
          {chaptersCount} {pluralizer(chaptersCount, 'глава', 'главы', 'глав')}
        </InfoItem>
        <InfoItem iconType={CourseIconTypeEnum.academicCap}>
          {lessonsCount} {pluralizer(lessonsCount, 'урок', 'урока', 'уроков')}
        </InfoItem>
      </div>
      {hasContinueButton && (
        <ButtonArrow className={styles.button} onClick={handleContinue}>
          {CONTINUE_TEXT[course.progressStatus] || 'Перейти'}
        </ButtonArrow>
      )}
    </div>
  );
};

CourseInfo.defaultProps = {
  hasContinueButton: false,
  isDevelopment: false,
  handleContinue: noop,
};

export default observer(CourseInfo);
