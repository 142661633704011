import * as React from 'react';
import * as ReactDOM from 'react-dom';
import cn from 'classnames';

import styles from './Drawer.modules.scss';

type Props = {
  isOpen?: boolean;
  children: React.ReactNode;
  onClose: () => void;
};

const Drawer: React.FC<Props> = ({ onClose, isOpen, children }: Props) => {
  const container = document.getElementById('drawer');

  if (!container) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={cn(styles.container, isOpen && styles.open)}>
      <div className={styles.background} onClick={onClose} />
      <div className={styles.drawer}>{children}</div>
    </div>,
    container
  );
};

Drawer.defaultProps = {
  isOpen: false,
};

export default React.memo(Drawer);
