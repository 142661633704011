import * as React from 'react';
import { observer } from 'mobx-react';
import copy from 'copy-to-clipboard';
import cogoToast from 'cogo-toast';

import { useCurrentCompany } from 'store/stores';
import { useLocal } from 'utils/hooks/useLocal';
import {
  YandexGroupPaymentFieldsEnum,
  YandexGroupPaymentFormModel,
} from 'store/models/payments/yandex/YandexGroupPaymentFormModel';
import useForm from 'components/form/hooks/useForm';
import FullSideSpinner from 'components/common/Spinner/FullSideSpinner';
import { InputField } from 'components/form';
import noop from 'utils/noop';
import Button from 'components/common/Button';
import PaymentSuccess from 'components/common/PaymentSuccess/PaymentSuccess';

import { PaymentComponentProps } from '../../PaymentsGroupConfiguration';

import styles from './YandexGroupPayment.modules.scss';

const onUnmask = (text: string) => text && (text.match(/\d+/)?.[0] || '');

const YandexGroupPayment: React.FC<PaymentComponentProps> = ({
  groupId,
}: PaymentComponentProps) => {
  const company = useCurrentCompany();

  const yandexGroupPaymentFormModel: YandexGroupPaymentFormModel = useLocal(
    () => new YandexGroupPaymentFormModel()
  );

  const yandexForm = useForm(
    yandexGroupPaymentFormModel,
    Object.values(YandexGroupPaymentFieldsEnum)
  );

  // Cостояние для показа кнопок (по-умолчанию кнопки не показывается, они показываются когда одно из полей меняется)
  const [hasControls, setHasControls] = React.useState(false);

  const save = React.useCallback(() => {
    yandexGroupPaymentFormModel.save(groupId);
  }, [groupId]);

  const onChangeAmount = React.useCallback((value: string) => {
    yandexForm.amount.setValue(value);
    setHasControls(true);
  }, []);

  const onClickPaymentUrlInput = React.useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      event.currentTarget.select();
    },
    []
  );

  const onCopy = React.useCallback(() => {
    copy(yandexForm.paymentUrl.value);
    cogoToast.success('Ссылка  скопирована');
  }, [yandexForm.paymentUrl.value]);

  React.useEffect(() => {
    if (company) {
      yandexGroupPaymentFormModel.load(groupId);
    }
  }, [groupId]);

  React.useEffect(() => {
    yandexGroupPaymentFormModel.validate();
  }, [yandexForm.amount.value]);

  if (yandexGroupPaymentFormModel.isLoading) {
    return <FullSideSpinner isOpen />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.amountInput}>
        <InputField
          noLabel
          label="Цена"
          value={yandexForm.amount.value}
          onUnmask={onUnmask}
          numberMask={{
            suffix: ' ₽',
            prefix: '',
            includeThousandsSeparator: false,
            allowLeadingZeroes: false,
            allowNegative: false,
          }}
          onChange={onChangeAmount}
        />
      </div>
      {yandexForm.paymentUrl.value && (
        <>
          <div className={styles.paymentUrlHeader}>Скопируйте ссылку</div>
          <div className={styles.paymentContainer}>
            <div className={styles.paymentUrlWrapper}>
              <div className={styles.amountInput}>
                <InputField
                  onClick={onClickPaymentUrlInput}
                  formElementClassName={styles.paymentUrlFormElement}
                  onChange={noop}
                  value={yandexForm.paymentUrl.value}
                  inputClassName={styles.paymentUrl}
                />
              </div>
              <div className={styles.copyButton} onClick={onCopy}>
                Копировать ссылку
              </div>
            </div>
            {!hasControls && (
              <div className={styles.paymentComment}>
                После оплаты, студенту высылается ссылка на&nbsp; регистрацию.
              </div>
            )}
          </div>
        </>
      )}
      <div className={styles.footer}>
        {!yandexGroupPaymentFormModel.isSuccessLoaded ? (
          hasControls && (
            <>
              <Button
                onClick={save}
                className={styles.button}
                isDisabled={yandexGroupPaymentFormModel.isDisabled}
              >
                Сохранить
              </Button>
              {!yandexGroupPaymentFormModel.isInitial && (
                <div
                  onClick={yandexGroupPaymentFormModel.clear}
                  className={styles.dropButton}
                >
                  Сбросить изменения
                </div>
              )}
            </>
          )
        ) : (
          <PaymentSuccess />
        )}
      </div>
    </div>
  );
};

export default observer(YandexGroupPayment);
