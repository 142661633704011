import * as React from 'react';
import cn from 'classnames';

import styles from './Error.modules.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const Error: React.FC<Props> = ({ children, className }: Props) => {
  return <div className={cn(styles.error, className)}>{children}</div>;
};

export default React.memo(Error);
