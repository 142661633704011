import * as propTypes from 'prop-types';
import * as React from 'react';

const AcademicCapIconV2 = (props) => {
  const { className } = props;
  return (
    <svg
      width="29"
      height="24"
      viewBox="0 0 29 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <defs>
        <path
          d="M5 21v5.115L14.5 31l9.5-4.885V21l-9.5 4.885L5 21zm9.5-14L0 15.143l14.5 8.143 11.864-6.664V26H29V15.143L14.5 7z"
          id="a"
        />
      </defs>
      <use
        fill="currentColor"
        href="#a"
        transform="translate(0 -7)"
        fillRule="evenodd"
      />
    </svg>
  );
};

AcademicCapIconV2.propTypes = {
  className: propTypes.string.isRequired,
};

AcademicCapIconV2.defaultProps = {};

export default AcademicCapIconV2;
