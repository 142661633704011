import { makeObservable, override } from 'mobx';

import { ChapterModel } from 'store/models/chapter/ChapterModel';
import { Form } from 'store/models/common/Form';
import request from 'utils/request';
import apiUrls from 'config/apiUrls';
import { ServerResponse } from 'store/models/common/types';
import { LessonModel } from 'store/models/lesson/LessonModel';

import { LessonCreateResponseType, LessonTypeEnum } from './types';

export enum LessonFieldsEnum {
  accessLevel = 'accessLevel',
  title = 'title',
  type = 'type',
  isPublished = 'isPublished',
  sendNotifications = 'sendNotifications',
}

export type LessonFields = {
  accessLevel: number;
  title: string;
  type: LessonTypeEnum;
  isPublished: boolean;
  sendNotifications: boolean;
};

export class LessonCreationFormModel extends Form<LessonFields> {
  chapter: ChapterModel;
  lesson: LessonModel | null = null;
  id?: number;

  constructor(chapter: ChapterModel, lesson?: LessonModel) {
    super({
      initialValues: {
        accessLevel: 0,
        title: '',
        type: LessonTypeEnum.theory,
        isPublished: false,
        sendNotifications: false,
      },
    });

    makeObservable(this, {
      setInitialFrom: override,
      save: override,
    });

    this.chapter = chapter;

    if (lesson) {
      this.lesson = lesson;
      this.id = lesson.id;

      this.clear(lesson);
    }
  }

  setInitialFrom(lesson: LessonModel): void {
    super.setInitialFrom({
      accessLevel: lesson.accessLevel,
      title: lesson.title,
      type: lesson.type,
      isPublished: lesson.isPublished,
      sendNotifications: lesson.sendNotifications,
    });
  }

  async save(): Promise<boolean | LessonModel | null> {
    this.validate();
    if (this.hasErrors) {
      return false;
    }

    this.isSaving = true;

    const isNew = !this.lesson;

    const { response }: ServerResponse<LessonCreateResponseType> =
      await request(
        isNew ? apiUrls.lesson.create : apiUrls.lesson.edit,
        'POST',
        {
          access_level: this.values.accessLevel,
          title: this.values.title,
          type: this.values.type,
          chapter_id: this.chapter.id,
          is_published: this.values.isPublished,
          send_notifications: this.values.sendNotifications,
          id: isNew ? undefined : this.lesson?.id,
        }
      );

    this.isSaving = false;

    if (response) {
      this.id = response.id;

      let { lesson } = this;

      if (this.lesson) {
        this.lesson.updateFrom(this);
      } else {
        lesson = LessonModel.fromForm(this, this.chapter);
      }
      return lesson;
    }

    return false;
  }
}
