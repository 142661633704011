import { makeObservable, observable, override } from 'mobx';

import apiUrls from 'config/apiUrls';
import { Form } from 'store/models/common/Form';
import { ServerResponse } from 'store/models/common/types';
import request from 'utils/request';
import {
  composeValidators,
  emailValidator,
  greaterThanValidator,
  notEmptyValidator,
} from 'utils/validation';

export enum RegistrationFormFields {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phone = 'phone',
  companyName = 'companyName',
}

type RegistrationFormType = {
  email: string;
  phone: string;
  companyName: string;
  firstName: string;
  lastName: string;
};

export class RegistrationForm extends Form<RegistrationFormType> {
  registrationSuccess = false;

  initialValues: RegistrationFormType = {
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    companyName: '',
  };

  validators = {
    firstName: composeValidators(notEmptyValidator()),
    lastName: composeValidators(notEmptyValidator()),
    // phone: composeValidators(notEmptyValidator(), phoneValidator()),
    companyName: composeValidators(
      notEmptyValidator(),
      greaterThanValidator({
        min: 5,
        errorMessage: 'Название компании должно быть больше 5',
      })
    ),
    email: composeValidators(notEmptyValidator(), emailValidator()),
  };

  constructor() {
    super();
    makeObservable(this, {
      registrationSuccess: observable,
      save: override,
    });
  }

  async save(): Promise<any> {
    this.validate();
    if (this.hasErrors) {
      return;
    }

    this.isSaving = true;

    const { response }: ServerResponse = await request(
      apiUrls.company.registerStaff,
      'POST',
      {
        first_name: this.values.firstName,
        last_name: this.values.lastName,
        email: this.values.email,
        company_name: this.values.companyName,
        phone: this.values.phone,
      }
    );

    if (response) {
      this.registrationSuccess = true;
    }

    this.isSaving = false;

    return Boolean(response);
  }
}
