import * as React from 'react';
import { useCallback } from 'react';

import Switcher, { SwitcherProps } from 'components/common/Switcher/Switcher';
import { FieldProps } from 'components/form/Field/Field';

import styles from './SwitcherInput.modules.scss';

type Props = FieldProps &
  Omit<SwitcherProps, 'onToggle' | 'className'> & {
    onChange: (isActive: boolean) => void;
  };

const SwitcherInput: React.FC<Props> = ({
  onChange,
  children,
  ...switchProps
}: Props) => {
  const handleChange = useCallback(() => {
    onChange(!switchProps.isActive);
  }, [onChange, switchProps.isActive]);

  return (
    <div>
      <div className={styles.label}>{children}</div>
      <Switcher
        {...switchProps}
        onToggle={handleChange}
        className={styles.switcher}
      />
    </div>
  );
};

export default React.memo(SwitcherInput);
