import cogoToast from 'cogo-toast';
import copy from 'copy-to-clipboard';
import * as React from 'react';
import { observer } from 'mobx-react';

import { CONFIG } from 'config/app';
import Badge from 'components/common/Badge';
import Button, { ButtonColor } from 'components/common/Button';
import { Input } from 'components/form';
import SwitcherInput from 'components/form/SwitcherInput/SwitcherInput';
import { CallbackTaskChunkModel } from 'store/models/chunks/callbackTask/CallbackTaskChunkModel';
import noop from 'utils/noop';

import Deadline from '../common/Deadline/Deadline';
import { WithChunk } from '../types';

import styles from './CallbackTaskChunk.modules.scss';

type Props = WithChunk<CallbackTaskChunkModel>;

const CallbackTaskChunk: React.FC<Props> = ({ chunk, isEditMode }: Props) => {
  const { data } = chunk;

  const onClickTokenInput = React.useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      event.currentTarget.select();
    },
    []
  );

  const onCopy = React.useCallback(() => {
    if (data.userToken) {
      copy(data.userToken);
      cogoToast.success('Ключ скопирован');
    }
  }, [data.userToken]);

  const onCopyChunkId = React.useCallback(() => {
    if (chunk.id) {
      copy(chunk.id);
      cogoToast.success('Токен скопирован');
    }
  }, [chunk.id]);

  return (
    <div id={chunk.id}>
      <div className={styles.title}>Задание с автопроверкой</div>
      <div className={styles.description}>
        Задание с автоматической проверкой.
        {isEditMode && (
          <a href={CONFIG.aboutCallbackTask} target="_blank">
            Подробнее о настройке
          </a>
        )}
        {!isEditMode && !data.isDone && (
          <> Скопируйте личный ключ в указанное заданием место.</>
        )}
      </div>
      <Deadline
        value={data.deadlineMixin.deadline}
        onChange={data.deadlineMixin.setDeadline}
        isAdmin={isEditMode}
      />
      {!isEditMode && data.userToken && (
        <div className={styles.tokenField}>
          {!data.isDone && data.canPass && (
            <>
              <div className={styles.tokenInput}>
                <Input
                  onClick={onClickTokenInput}
                  onChange={noop}
                  value={data.userToken}
                />
              </div>
              <div className={styles.tokenCopy}>
                <Button color={ButtonColor.transparent} onClick={onCopy}>
                  Скопировать
                </Button>
              </div>
            </>
          )}

          {!data.isDone && !data.canPass && (
            <Badge className={styles.deadlineIcon}>
              Срок сдачи этой задачи завершен или есть другие просроченные
              задания
            </Badge>
          )}
          {data.isDone && (
            <Badge className={styles.doneIcon}>Задание выполнено</Badge>
          )}
        </div>
      )}

      {isEditMode && (
        <>
          <div className={styles.tokenField}>
            <div className={styles.tokenInput}>
              <Input
                onClick={onClickTokenInput}
                onChange={noop}
                disabled
                value={chunk.id}
              />
            </div>
            <div className={styles.tokenCopy}>
              <Button color={ButtonColor.transparent} onClick={onCopyChunkId}>
                Скопировать
              </Button>
            </div>
          </div>
          <div>
            <SwitcherInput
              isActive={data.isSecure}
              onChange={data.toggleIsSecure}
            >
              Включить защищенную сдачу с ключом компании
            </SwitcherInput>
            <div className={styles.description}>
              После сохранения и обновления карточки (страницы) здесь будет
              пользовательский ключ
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default observer(CallbackTaskChunk);
