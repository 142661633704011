import * as React from 'react';
import { useContext } from 'react';

import { LessonStore } from './LessonStore';

export const LessonContext = React.createContext<LessonStore | null>(null);

export const useLessonStore = (): LessonStore => {
  const data = useContext(LessonContext);
  if (!data) {
    throw new Error('No LessonStore Provider in tree!');
  }

  return data;
};
