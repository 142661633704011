import * as propTypes from 'prop-types';
import * as React from 'react';

const AlignCenter = (props) => {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor" fillRule="evenodd">
        <path d="M29,28v2H3V28Zm-3-1H17V6h9ZM24,8H19V25h5ZM15,27H6V2h9ZM13,4H8V25h5Z" />
      </g>
    </svg>
  );
};

AlignCenter.propTypes = {
  onClick: propTypes.func,
  className: propTypes.string.isRequired,
};

AlignCenter.defaultProps = {
  onClick: () => null,
};

export default AlignCenter;
