import cn from 'classnames';
import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';

import urls from 'config/urls';
import Profile from 'pages/Profile';
import Courses from 'pages/Courses';
import Lesson from 'pages/Lesson';
import Homework from 'pages/Homework';
import Students from 'pages/Students';
import { useUiStore, useUserStore } from 'store/context';
import Integration from 'pages/Integration';

import TopBar from './TopBar';
import LeftBar from './LeftBar';

import './Root.modules.scss';

const Root: React.FC = () => {
  const userStore = useUserStore();
  const ui = useUiStore();

  return (
    <>
      <TopBar />
      <LeftBar />
      <div styleName={cn('content', ui && `content_${ui.layout}`)}>
        <Switch>
          <Route path={urls.profile} component={Profile} />
          <Route path={urls.lessonPagePattern} component={Lesson} />
          <Route path={urls.courses} component={Courses} />
          <Route path={urls.homework} component={Homework} />
          <Route
            path={urls.students.pattern}
            render={() =>
              userStore.isAdmin ? <Students /> : <Redirect to={urls.profile} />
            }
          />
          <Route
            path={urls.integration}
            render={() =>
              userStore.isAdmin ? (
                <Integration />
              ) : (
                <Redirect to={urls.profile} />
              )
            }
          />
          <Redirect to={urls.profile} />
        </Switch>
      </div>
    </>
  );
};

export default observer(Root);
