import * as React from 'react';

type Props = React.SVGProps<any>;

const UsersIcon: React.FC<Props> = (props: Props) => {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="supervisor_account_24px">
        <path
          id="icon/action/supervisor_account_24px"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5002 14.7502C12.7518 14.7502 14.5835 12.9185 14.5835 10.6668C14.5835 8.41516 12.7518 6.5835 10.5002 6.5835C8.24852 6.5835 6.41685 8.41516 6.41685 10.6668C6.41685 12.9185 8.24852 14.7502 10.5002 14.7502ZM10.5001 8.91683C11.4685 8.91683 12.2501 9.69849 12.2501 10.6668C12.2501 11.6352 11.4685 12.4168 10.5001 12.4168C9.53181 12.4168 8.75014 11.6352 8.75014 10.6668C8.75014 9.69849 9.53181 8.91683 10.5001 8.91683ZM5.56516 20.5835H10.5585C10.5235 20.7702 10.5002 20.9685 10.5002 21.1668V22.9168H2.3335V21.1668C2.3335 18.4485 7.77016 17.0835 10.5002 17.0835C11.1535 17.0835 11.9585 17.1652 12.8102 17.3168C11.9818 17.8768 11.2935 18.5768 10.8968 19.4285C10.8327 19.4285 10.7656 19.4256 10.6986 19.4227L10.6985 19.4227C10.6314 19.4197 10.5643 19.4168 10.5002 19.4168C8.71516 19.4168 6.72016 20.0002 5.56516 20.5835ZM19.2502 17.6668C17.1035 17.6668 12.8335 18.8452 12.8335 21.1668V22.9168H25.6668V21.1668C25.6668 18.8452 21.3968 17.6668 19.2502 17.6668ZM22.1668 13.0002C22.1668 14.0968 21.5485 15.0418 20.6618 15.5435C20.2418 15.7768 19.7635 15.9168 19.2502 15.9168C18.7368 15.9168 18.2585 15.7768 17.8385 15.5435C16.9518 15.0418 16.3335 14.0968 16.3335 13.0002C16.3335 11.3902 17.6402 10.0835 19.2502 10.0835C20.8602 10.0835 22.1668 11.3902 22.1668 13.0002Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default React.memo(UsersIcon);
