import deepcopy from 'deepcopy';
import {
  ContentState,
  convertFromRaw,
  convertToRaw,
  EditorState,
} from 'draft-js';

import { ChunkModel } from 'store/models/chunks/ChunkModel';
import { ChunkServerType } from 'store/models/chunks/types';
import { MarkupServerType } from 'store/models/unit/types';

export const CHUNK_ENTITY_TYPE = 'CHUNK';

export const createDraftJsEntityFromChunk = (
  contentState: ContentState,
  chunk: ChunkModel
): [ContentState, string] => {
  const contentStateWithEntity = contentState.createEntity(
    CHUNK_ENTITY_TYPE,
    'IMMUTABLE',
    { chunk, type: chunk.type }
  );
  return [
    contentStateWithEntity,
    contentStateWithEntity.getLastCreatedEntityKey(),
  ];
};

export const draftJsToJson = (editorState: EditorState) => {
  let hasErrors = false;
  const chunkMap: Record<string, ChunkModel> = {};

  const { blocks, entityMap: baseEntityMap } = convertToRaw(
    editorState.getCurrentContent()
  );

  const entityMap = { ...baseEntityMap };

  for (const key of Object.keys(entityMap)) {
    const entity = entityMap[key];

    if (entity.type === CHUNK_ENTITY_TYPE) {
      const chunk = entity.data.chunk as ChunkModel;

      chunk.validate();
      hasErrors = hasErrors || chunk.hasErrors;

      entity.data = {
        ...entity.data,
        chunk: chunk.toJson()[0],
      };

      chunkMap[chunk.id] = chunk;
    }
  }

  return { blocks, entityMap, hasErrors, chunkMap };
};

export const jsonToDraftJs = (
  json: MarkupServerType,
  unit: any
): ContentState => {
  const { blocks, entityMap } = deepcopy(json);

  for (const key of Object.keys(entityMap)) {
    const entity = entityMap[key];

    if (entity.type === CHUNK_ENTITY_TYPE) {
      entityMap[key].data.chunk = ChunkModel.fromJson(
        entity.data.chunk as ChunkServerType,
        unit
      );
    }
  }

  return convertFromRaw({ blocks, entityMap });
};
