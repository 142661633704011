import * as propTypes from 'prop-types';
import * as React from 'react';

const AlignCenter = (props) => {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor" fillRule="evenodd" rotate={90}>
        <path d="M30,15H27V6H17v9H15V4H5V15H2v2H5V28H15V17h2V27H27V17h3ZM13,26H7V6h6Zm12-1H19V8h6V25Z" />
      </g>
    </svg>
  );
};

AlignCenter.propTypes = {
  className: propTypes.string.isRequired,
};

AlignCenter.defaultProps = {};

export default AlignCenter;
