import * as React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import CircleButton from 'components/common/BaseEdit/CircleButton';
import { CircleButtonColorEnum } from 'components/common/BaseEdit/CircleButton/config/colors';
import { CircleButtonTypeEnum } from 'components/common/BaseEdit/CircleButton/config/types';
import Checkbox from 'components/common/Checkbox';
import Spinner from 'components/common/Spinner';
import Switcher from 'components/common/Switcher';
import { useAttachmentUpload } from 'store/hooks/useAttachmentUpload';
import { QuizAnswerModel } from 'store/models/chunks/quiz/QuizAnswerModel';
import noop from 'utils/noop';

import commonStyles from '../../../../common/styles/MediaChunk.modules.scss';
import defaultImg from '../../../../ImageChunk/img/defaultImg.svg';

import styles from './ImageAnswer.modules.scss';

type Props = {
  isEditMode: boolean;
  isChecked: boolean;
  handleCheck: () => void;
  answer: QuizAnswerModel;
};

const TextAnswer: React.FC<Props> = ({
  isChecked,
  isEditMode,
  handleCheck,
  answer,
}: Props) => {
  const [uploadModel, handleFileChange] = useAttachmentUpload(
    answer.setAttachment
  );

  const { attachment } = answer;

  return (
    <div className={styles.wrapper}>
      <img
        src={attachment?.url || defaultImg}
        className={commonStyles.preview}
      />

      <div className={styles.switch}>
        {isEditMode ? (
          <Switcher isActive={isChecked} onToggle={handleCheck} />
        ) : (
          <Checkbox isActive={isChecked} onToggle={handleCheck} />
        )}
      </div>

      {!uploadModel.isLoading && isEditMode && (
        <CircleButton
          className={styles.editIcon}
          onClick={noop}
          type={CircleButtonTypeEnum.plus}
          color={CircleButtonColorEnum.red}
        >
          <input
            accept=".gif,.jpg,.jpeg,.png"
            onChange={handleFileChange}
            type="file"
            multiple={false}
            className={cn(commonStyles.imgInput)}
          />
        </CircleButton>
      )}

      <div
        className={cn(
          styles.shadowBlock,
          commonStyles.selectedWrapper,
          uploadModel.isLoading && commonStyles.chunkSelected
        )}
      >
        {uploadModel.isLoading && (
          <Spinner className={commonStyles.inputIcon} />
        )}
      </div>
    </div>
  );
};

export default observer(TextAnswer);
