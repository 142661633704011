import sanitizeHtml from 'sanitize-html';

const config: any = {
  b: [],
  strong: [],
  em: [],
  i: [],
  u: [],
  s: [],
  br: [],
  a: [
    'href',
    { name: 'target', values: ['_blank'] },
    { name: 'rel', values: ['noopener nofollow', 'noopener', 'nofollow'] },
  ],
  mark: [{ name: 'class', values: ['cdx-marker'] }],
  code: [{ name: 'class', values: ['inline-code'] }],
};

export const sanitize = (html: string, origin = 'example.com') =>
  sanitizeHtml(html, {
    allowedTags: Object.keys(config),
    allowedAttributes: config,
    transformTags: {
      a: (tagName, attribs) => {
        // always rel noopener
        const rel = ['noopener'];
        // target blank by default
        attribs.target = '_blank';
        // for external links rel nofollow
        if (origin && attribs.href.indexOf(origin) === -1) {
          rel.push('nofollow');
        }
        // all internal links must be https
        if (origin && attribs.href.startsWith('http://' + origin)) {
          attribs.href = attribs.href.replace(
            'http://' + origin,
            'https://' + origin
          );
        }
        // remove target for internal links
        if (origin && attribs.href.startsWith('https://' + origin)) {
          delete attribs.target;
        }
        return {
          tagName: 'a',
          attribs: {
            ...attribs,
            rel: rel.join(' '),
          },
        };
      },
    },
  });
