import { ChunkServerType } from 'store/models/chunks/types';

import { AttachmentServerType } from '../../attachment';

export enum QuizTypeEnum {
  oneAnswer = 'one_answer',
  multipleAnswers = 'multiple_answers',
}

export type ChunkQuizAnswerServerType = {
  id: string;
  attachment_id: string | null;
  attachment: AttachmentServerType;
  is_correct: boolean;
  is_checked: boolean;
  plain_title: string;
  title: string;
};

export type ChunkQuizQuestionServerType = {
  plain_title: string;
  quiz_type: QuizTypeEnum;
  status: QuizStatus;
  answers: ChunkServerType<ChunkQuizAnswerServerType>[];
};

export type ChunkQuizGroupServerType = {
  number_of_attempts: number;
  number_of_attempts_left: number;
  quizzes: ChunkServerType<ChunkQuizQuestionServerType>[];
  status: QuizGroupStatus;
};

export type QuizAnswerRequestType = { quiz_id: string; answers_ids: string[] };

export enum QuizStatus {
  notAnswered = 'not_answered',
  incorrect = 'incorrect',
  correct = 'correct',
}

export enum QuizAnswerType {
  text = 'text',
  image = 'image',
}

export enum QuizGroupStatus {
  inProgress = 'in_progress',
  finished = 'finished',
}

export const QuizStatusText: Record<QuizStatus, string> = {
  [QuizStatus.notAnswered]: 'Проверка теста',
  [QuizStatus.incorrect]: 'Тест не пройден',
  [QuizStatus.correct]: 'Тест успешно пройден',
};
