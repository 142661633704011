import * as React from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';

import Label from '../Label';

import { WithFieldProps } from './types';
import { Field } from './Field';

const FieldHOC = <P extends WithFieldProps>(
  Component: React.ComponentType<P>
): React.FC<P & WithFieldProps> => {
  const FieldComponent: React.FC<P & WithFieldProps> = ({
    errors,
    label,
    formElementClassName,
    ...rest
  }: WithFieldProps) => {
    return (
      <Field errors={errors} formElementClassName={formElementClassName}>
        {label && <Label isStatic>{label}</Label>}
        <Component errors={errors} {...(rest as P)} />
      </Field>
    );
  };

  FieldComponent.displayName = `Field(${
    Component.displayName || Component.name || 'FormElement'
  })`;

  hoistNonReactStatic(FieldComponent, Component);

  return FieldComponent;
};

export default FieldHOC;
