import * as React from 'react';
import { observer } from 'mobx-react';

import PageSpinner from 'components/common/Spinner/PageSpinner/PageSpinner';

import { useLessonStore } from '../context';
import LessonSlide from '../components/LessonSlide';

import UnitCard from './UnitCard';
import Admin from './Admin';

const LessonPage: React.FC = () => {
  const lessonStore = useLessonStore();

  const { unit, isEditMode, isAdmin } = lessonStore;

  return (
    <>
      {isAdmin && <Admin editStore={lessonStore} />}
      {unit && (
        <LessonSlide>
          {unit.isLoadingChunks ? (
            <PageSpinner />
          ) : (
            <UnitCard isEditMode={isEditMode} unit={unit} isAdmin={isAdmin} />
          )}
        </LessonSlide>
      )}
    </>
  );
};

export default observer(LessonPage);
