import * as propTypes from 'prop-types';
import * as React from 'react';

const AlignCenter = (props) => {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor" fillRule="evenodd">
        <path d="M29,2V4H3V2ZM17,5H27V26H17Zm2,19h6V7H19ZM5,5H15V30H5ZM7,28h6V7H7Z" />
      </g>
    </svg>
  );
};

AlignCenter.propTypes = {
  className: propTypes.string.isRequired,
};

AlignCenter.defaultProps = {};

export default AlignCenter;
