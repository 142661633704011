import * as React from 'react';
import * as propTypes from 'prop-types';
import cn from 'classnames';

import styles from './VerticalPoinerLine.modules.scss';

class VerticalPointerLine extends React.Component {
  constructor(props) {
    super(props);

    this.rootRef = null;

    this.state = {
      isMounted: false,
    };
  }

  update = () => {
    this.forceUpdate();
  };

  reCalc = () => {
    requestAnimationFrame(this.update);
  };

  componentDidMount = () => {
    window.addEventListener('resize', this.onResizeWindow);
    this.setState({ isMounted: true }, this.reCalc);
  };

  onResizeWindow = () => {
    this.reCalc();
  };

  setRootRef = (ref) => (this.rootRef = ref);

  render() {
    const { children, className, activeIndex } = this.props;

    const { isMounted } = this.state;
    let activeChildOffset = 0;
    let activeChildHeight = 0;

    if (isMounted && activeIndex !== undefined) {
      const activeChildNode = this.rootRef.children[activeIndex];

      const { top: topRoot } = this.rootRef.getBoundingClientRect();
      const { top: topActiveChild, height } =
        activeChildNode.getBoundingClientRect();

      activeChildOffset = topActiveChild - topRoot;
      activeChildHeight = height;
    }

    return (
      <div className={cn(styles.root, className)} ref={this.setRootRef}>
        {children}
        <div
          className={styles.line}
          style={{
            transform: `translateY(${activeChildOffset}px) scaleY(${activeChildHeight})`,
          }}
        />
      </div>
    );
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.onResizeWindow);
  };
}

VerticalPointerLine.propTypes = {
  children: propTypes.any.isRequired,

  activeIndex: propTypes.number,

  className: propTypes.string,
};

VerticalPointerLine.defaultProps = {
  className: styles.root,
};

export default VerticalPointerLine;
