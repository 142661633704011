import * as React from 'react';
import { useContext } from 'react';

import CourseModel from 'store/models/course/CourseModel';
import noop from 'utils/noop';

type CourseEditContextValue = {
  isEditMode: boolean;
  isAdmin: boolean;
  course?: CourseModel;
  resetCourse: (newCourse: CourseModel) => void;
};

export const CourseEditContext =
  React.createContext<CourseEditContextValue | null>(null);

export const useCourseContext = (): CourseEditContextValue => {
  return (
    useContext(CourseEditContext) || {
      isEditMode: false,
      isAdmin: false,
      resetCourse: noop,
    }
  );
};
