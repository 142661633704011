import { CompanyUserServerType, CompanyUserType } from './types';

export const normalizeCompanyUser = (
  data: CompanyUserServerType
): CompanyUserType => ({
  id: data.id,
  accessLevel: data.access_level,
  created: new Date(data.created),
  lastName: data.last_name,
  firstName: data.first_name,
  type: data.type,
  isActive: data.is_active,
  role: data.role,
  email: data.email,
  companyUserId: data.company_user_id,
});
