import * as React from 'react';
import cn from 'classnames';

import styles from './PaymentSuccess.modules.scss';

interface Props {
  className?: string;
}

const PaymentSuccess: React.FC<Props> = ({ className }: Props) => {
  return (
    <div className={cn(styles.successWrapper, className)}>
      <div className={styles.successIcon} />
      <div className={styles.successText}>Данные сохранены.</div>
    </div>
  );
};

export default PaymentSuccess;
