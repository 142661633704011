// TODO Рефакторинг
import * as propTypes from 'prop-types';
import * as React from 'react';
import cn from 'classnames';

import Spinner from '../index';

import stylesDefault from './stylesDefault.modules.scss';
import styles from './styles.modules.scss';

class FullSideSpinner extends React.Component {
  constructor(props) {
    super(props);

    const { isOpen } = this.props;

    this.state = {
      isInDOM: isOpen,
      isHidden: true,
    };

    this.isNeedFade = isOpen;

    this.refRoot = null;
  }

  setRootRef = (ref) => (this.refRoot = ref);

  componentDidMount = () => {
    this.componentUpdated();
  };

  componentDidUpdate = () => {
    this.componentUpdated();
  };

  setIsVisible = () => {
    this.setState({ isHidden: false });
  };

  componentUpdated = () => {
    if (this.isNeedFade) {
      requestAnimationFrame(this.setIsVisible);
      this.isNeedFade = false;
    }
  };

  checkIsHidden = () => {
    return window.getComputedStyle(this.refRoot).opacity === '0';
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isOpen } = this.props;
    const { isOpen: isOpenNext } = nextProps;

    if (!isOpen && isOpenNext) {
      this.setState({ isInDOM: true, isHidden: true });
      this.isNeedFade = true;
    }

    if (isOpen && !isOpenNext) {
      const isHidden = this.checkIsHidden();

      if (isHidden) {
        this.setState({
          isInDOM: false,
          isHidden: true,
        });
        this.isNeedFade = false;
        return;
      }

      this.setState({
        isInDOM: true,
        isHidden: true,
      });
      this.isNeedFade = false;
    }
  }

  onTransitionEnd = () => {
    const isHidden = this.checkIsHidden();

    if (!isHidden) {
      return;
    }

    this.setState({
      isInDOM: false,
      isHidden: true,
    });
    this.isNeedFade = false;
  };

  render() {
    const { className, openOpacity } = this.props;

    const { isInDOM, isHidden } = this.state;

    if (!isInDOM) {
      return null;
    }

    return (
      <div
        ref={this.setRootRef}
        className={cn(
          styles.root,
          isHidden ? styles.hidden : styles.show,
          className
        )}
        style={{
          opacity: isHidden ? 0 : openOpacity,
        }}
        onTransitionEnd={this.onTransitionEnd}
      >
        <Spinner className={styles.spinner} />
      </div>
    );
  }
}

FullSideSpinner.propTypes = {
  isOpen: propTypes.bool.isRequired,
  className: propTypes.string,
  openOpacity: propTypes.number,
};

FullSideSpinner.defaultProps = {
  className: stylesDefault.root,
  openOpacity: 1,
};

export default FullSideSpinner;
