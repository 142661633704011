import * as React from 'react';
import * as propTypes from 'prop-types';

const LogoIcon = (props) => {
  const { className } = props;

  return (
    <svg
      width="22px"
      height="18px"
      viewBox="0 0 22 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-54.000000, -258.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <g>
            <g transform="translate(25.000000, 258.000000)">
              <path d="M29,11.5391061 L34.1284916,11.5391061 L34.1284916,6.41061453 L29,6.41061453 L29,11.5391061 Z M29,17.9497207 L34.1284916,17.9497207 L34.1284916,12.8212291 L29,12.8212291 L29,17.9497207 Z M29,5.12849162 L34.1284916,5.12849162 L34.1284916,0 L29,0 L29,5.12849162 Z M35.4106145,11.5391061 L50.7960894,11.5391061 L50.7960894,6.41061453 L35.4106145,6.41061453 L35.4106145,11.5391061 Z M35.4106145,17.9497207 L50.7960894,17.9497207 L50.7960894,12.8212291 L35.4106145,12.8212291 L35.4106145,17.9497207 Z M35.4106145,0 L35.4106145,5.12849162 L50.7960894,5.12849162 L50.7960894,0 L35.4106145,0 Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

LogoIcon.propTypes = {
  className: propTypes.string.isRequired,
};

export default LogoIcon;
