import * as React from 'react';
import cn from 'classnames';

import styles from './Tabs.modules.scss';

export interface TabItem {
  tabName: string;
  item: React.ReactNode;
}

interface Props {
  className?: string;
  tabBarClassName?: string;
  contentClassName?: string;
  tabs: TabItem[];
}

const Tabs: React.FC<Props> = ({
  className,
  tabs,
  tabBarClassName,
  contentClassName,
}: Props) => {
  const [activeTab, setActiveTab] = React.useState(0);

  return (
    <div className={cn(styles.tabs, className)}>
      <div className={cn(styles.tabBar, tabBarClassName)}>
        {tabs.map(({ tabName }, index) => (
          <div
            key={tabName}
            className={cn(styles.tab, index === activeTab && styles.activeTab)}
            onClick={() => setActiveTab(index)}
          >
            {tabName}
          </div>
        ))}
      </div>
      <div className={cn(styles.content, contentClassName)}>
        {tabs[activeTab].item}
      </div>
    </div>
  );
};

export default React.memo(Tabs);
