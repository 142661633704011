import { action, makeObservable, observable } from 'mobx';

import apiUrls from 'config/apiUrls';
import request from 'utils/request';

import type { SideApiStore } from './SideApiStore';
import { SideApiTokenType } from './types';

export class TokenModel {
  sideApiStore: SideApiStore;
  name: string;
  id: number;
  expires: string | null;
  created: string;
  token: string;

  isRemoving = false;

  constructor(sideApiStore: SideApiStore, token: SideApiTokenType) {
    this.sideApiStore = sideApiStore;
    this.token = token.token;
    this.expires = token.expires;
    this.id = token.id;
    this.name = token.name;
    this.created = token.created;

    makeObservable(this, {
      isRemoving: observable,
      remove: action.bound,
    });
  }

  async remove() {
    this.isRemoving = true;

    const { response } = await request(
      apiUrls.integrationTokens.deleteToken,
      'POST',
      {
        id: this.id,
      }
    );

    if (response) {
      this.sideApiStore.dropToken(this.id);
    }
  }
}
