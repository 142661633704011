import * as React from 'react';
import { observer } from 'mobx-react';
import { saveAs } from 'file-saver';
import { useCallback } from 'react';

import { useAttachmentUpload } from 'store/hooks/useAttachmentUpload';
import { VideoChunkModel } from 'store/models/chunks/video/VideoChunkModel';
import FileIcon from 'components/icons/FileIcon';
import Spinner from 'components/common/Spinner';
import DownloadIcon from 'components/icons/LongArrowRightIcon';

import { WithChunk } from '../types';

import styles from './AttachmentsChunk.modules.scss';

type Props = WithChunk<VideoChunkModel>;

const AttachmentsChunk: React.FC<Props> = ({ chunk }: Props) => {
  const { attachment, setAttachment } = chunk.data;

  const [uploadModel, handleFileChange] = useAttachmentUpload(setAttachment);

  const saveAttachment = useCallback(() => {
    if (attachment) {
      saveAs(attachment.url, attachment.originalFileName);
    }
  }, [attachment?.url]);

  return (
    <div id={chunk.id} className={styles.wrapper}>
      <div className={styles.info}>
        {uploadModel.isLoading ? (
          <Spinner className={styles.infoFile} />
        ) : (
          <FileIcon className={styles.infoFile} />
        )}
        <div className={styles.infoTitle}>
          {attachment ? attachment.originalFileName : 'Выберите файл'}
        </div>
        <div>
          <input
            onChange={handleFileChange}
            type="file"
            multiple={false}
            className={styles.input}
          />
        </div>
      </div>
      {attachment && (
        <div onClick={saveAttachment} className={styles.downloadBtn}>
          <DownloadIcon />
        </div>
      )}
    </div>
  );
};

export default observer(AttachmentsChunk);
