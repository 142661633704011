import * as React from 'react';

import Modal from 'components/common/Modal';
import Title from 'components/common/Title';
import ControlsBar from 'components/common/ControlsBar';
import { FormElement } from 'components/form';

import styles from './EditModal.modules.scss';

export type EditModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  onSave: () => void;
  isSaving: boolean;
  title?: string;
};

const EditModal: React.FC<EditModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  onSave,
  isSaving,
}: EditModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isLoading={isSaving}>
      <FormElement>
        <Title index={3}>{title}</Title>
      </FormElement>
      {children}
      <div className={styles.controls}>
        <ControlsBar onClose={onClose} onOk={onSave} isLoading={isSaving} />
      </div>
    </Modal>
  );
};

export default React.memo(EditModal);
