import * as queryString from 'querystring';

import { useLocation } from 'react-router-dom';

export const useQuery = () => {
  const { search } = useLocation();

  const searchQuery = search.charAt(0) === '?' ? search.slice(1) : search;
  return queryString.parse(searchQuery);
};
