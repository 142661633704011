class Generator {
  constructor(prefix) {
    this.counter = -1;
    this.prefix = prefix;
  }

  generate = () => `${this.prefix}-${++this.counter}`;
}

export default new Generator('unique-string').generate;
