import * as React from 'react';
import { useCallback } from 'react';
import cn from 'classnames';

import { ChunkGroupTypeEnum } from 'store/models/chunks/types';

import styles from './Group.modules.scss';

type Props = {
  onClick: (key: ChunkGroupTypeEnum) => void;
  id: ChunkGroupTypeEnum;
  isSelected?: boolean;
  label: string;
};

const Group: React.FC<Props> = ({ onClick, id, isSelected, label }: Props) => {
  const handleClick = useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  return (
    <div
      onClick={handleClick}
      className={cn(styles.typeItem, isSelected && styles.active)}
    >
      {label}
    </div>
  );
};

Group.defaultProps = {
  isSelected: false,
};

export default React.memo(Group);
