import { Saver } from '@editorjs/editorjs/types/api';
import * as React from 'react';
import cn from 'classnames';
import { useCallback, useState } from 'react';
import EditorJs from 'react-editor-js';
import { OutputData } from '@editorjs/editorjs';

import generateUniqueString from 'utils/generateUniqueString';

import './editor.global.scss';

import TextViewer from './TextViewer';
import { EDITOR_JS_TOOLS } from './tools';

type Props = {
  data: OutputData;
  onSave?: (d: OutputData) => void;
  viewMode?: boolean;
};

const TextEditor: React.FC<Props> = ({ data, onSave, viewMode }: Props) => {
  const [id] = useState(() => generateUniqueString());
  const [ready, setReady] = useState(false);

  const handleSave = useCallback(
    async ({ saver }: { saver: Saver }) => {
      if (onSave) {
        onSave(await saver.save());
      }
    },
    [onSave]
  );

  const handleReady = useCallback(() => {
    setReady(true);
  }, []);

  if (viewMode) {
    return <TextViewer data={data} />;
  }

  return (
    /* @ts-ignore */
    <EditorJs
      tools={EDITOR_JS_TOOLS}
      minHeight={0}
      autofocus
      data={data}
      onChange={handleSave}
      holder={id}
      onReady={handleReady}
    >
      <div id={id} className={cn(!ready && 'editor-loading')} />
      {!ready && <TextViewer data={data} />}
    </EditorJs>
  );
};

TextEditor.defaultProps = {
  viewMode: false,
};

export default React.memo(TextEditor);
