import * as React from 'react';
import { useContext } from 'react';

import { ChunkContainer } from './ChunkContainer';

export const ChunkContainerContext = React.createContext<ChunkContainer | null>(
  null
);

export const useChunkContainer = (): ChunkContainer => {
  const data = useContext(ChunkContainerContext);

  if (!data) {
    throw new Error('No ChunkContainer Provider in tree!');
  }

  return data;
};
