import * as React from 'react';
import cn from 'classnames';

import CircleButton from '../CircleButton';
import { CircleButtonTypeEnum } from '../CircleButton/config/types';

import styles from './ArrowControls.modules.scss';

type Props = {
  type: CircleButtonTypeEnum;
  onShift?: (e: React.MouseEvent<HTMLElement>) => void;
  onAppend?: (e: React.MouseEvent<HTMLElement>) => void;
  isSelected: boolean;
};

const ArrowControls: React.FC<Props> = ({
  isSelected,
  onAppend,
  onShift,
  type,
}: Props) => {
  const isVertical =
    type === CircleButtonTypeEnum.arrowTop ||
    type === CircleButtonTypeEnum.arrowBottom;

  const isHorizontal =
    type === CircleButtonTypeEnum.arrowLeft ||
    type === CircleButtonTypeEnum.arrowRight;

  return (
    <div
      className={cn(
        styles.arrows,
        styles[type],
        isVertical && styles.vertical,
        isHorizontal && styles.horizontal,
        isSelected && styles.active
      )}
    >
      {onShift && (
        <CircleButton type={type} className={styles.button} onClick={onShift} />
      )}
      {onAppend && (
        <CircleButton
          type={CircleButtonTypeEnum.plus}
          className={styles.button}
          onClick={onAppend}
        />
      )}
    </div>
  );
};

ArrowControls.defaultProps = {};

export default ArrowControls;
