import { observer } from 'mobx-react';
import * as React from 'react';

import Checkbox from 'components/common/Checkbox';
import Switcher from 'components/common/Switcher';
import SimpleTextEditor from 'components/common/TextEditor/SimpleTextEditor';
import { QuizAnswerModel } from 'store/models/chunks/quiz/QuizAnswerModel';

import styles from './TextAnswer.modules.scss';

type Props = {
  isEditMode: boolean;
  isChecked: boolean;
  handleCheck: () => void;
  answer: QuizAnswerModel;
};

const TextAnswer: React.FC<Props> = ({
  isChecked,
  isEditMode,
  handleCheck,
  answer,
}: Props) => {
  return (
    <>
      {isEditMode ? (
        <Switcher
          isActive={isChecked}
          onToggle={handleCheck}
          className={styles.isCorrect}
        />
      ) : (
        <Checkbox
          isActive={isChecked}
          onToggle={handleCheck}
          className={styles.isCorrect}
        />
      )}
      <SimpleTextEditor
        text={answer.plainTitle}
        isEditMode={isEditMode}
        onChange={answer.setPlainTitle}
      />
    </>
  );
};

export default observer(TextAnswer);
