import * as propTypes from 'prop-types';
import * as React from 'react';

const DoneCircleIcon = (props) => {
  const { className } = props;
  return (
    <svg
      width="21px"
      height="21px"
      viewBox="0 0 21 21"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-1038.000000, -923.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <g transform="translate(166.000000, 607.000000)">
            <g transform="translate(0.000000, 290.000000)">
              <path d="M882.5,26 C876.701284,26 872,30.701087 872,36.5 C872,42.298913 876.701284,47 882.5,47 C888.299356,47 893,42.298913 893,36.5 C893,30.701087 888.299159,26 882.5,26 Z M880.57387,41.8936451 L875.9505,37.2704715 L877.491492,35.7294793 L880.57387,38.8116606 L887.508508,31.8768264 L889.0495,33.4178186 L880.57387,41.8936451 Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

DoneCircleIcon.propTypes = {
  className: propTypes.string,
};

DoneCircleIcon.defaultProps = {
  className: '',
};

export default DoneCircleIcon;
