import * as React from 'react';
import * as propTypes from 'prop-types';

const LogOut = (props) => {
  const { className, onClick } = props;

  return (
    <svg
      className={className}
      onClick={onClick}
      width="342"
      height="357"
      viewBox="0 0 342 357"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-43 -28)" fill="none" fillRule="evenodd">
        <path
          d="M107.227 107.227c-58.97 58.969-58.97 154.577 0 213.546 58.969 58.97 154.577 58.97 213.546 0 58.97-58.969 58.97-154.577 0-213.546"
          stroke="currentColor"
          strokeWidth="40"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          fill="currentColor"
          x="190"
          y="28"
          width="48"
          height="230"
          rx="24"
        />
      </g>
    </svg>
  );
};

LogOut.propTypes = {
  className: propTypes.string.isRequired,
  onClick: propTypes.func,
};

LogOut.defaultProps = {
  onClick: null,
};

export default LogOut;
