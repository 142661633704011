import { observer } from 'mobx-react';
import * as React from 'react';

import urls from 'config/urls';
import { WhiteCard } from 'components/common/BaseCard';
import { GroupModel } from 'store/models/students/GroupModel';

import styles from './GroupItem.modules.scss';

type Props = {
  group: GroupModel;
  className?: string;
};

const GroupItem: React.FC<Props> = ({ group, className }: Props) => {
  return (
    <WhiteCard className={className} to={urls.students.groups.group(group.id)}>
      <div className={styles.name}>{group.name}</div>
      <div className={styles.courses}>{group.courseTitle}</div>
    </WhiteCard>
  );
};

export default observer(GroupItem);
