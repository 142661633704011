import { useEffect } from 'react';

const POLLING_TIME = 1500;

export const usePolling = (
  callback: () => void | Promise<void>,
  stop: boolean
): void => {
  useEffect(() => {
    let polling: null | NodeJS.Timeout = null;
    if (stop) {
      polling = setInterval(callback, POLLING_TIME);
    }

    return () => {
      polling && clearInterval(polling);
    };
  }, [stop]);
};
