import { action, makeObservable, observable, runInAction } from 'mobx';

import apiUrls from 'config/apiUrls';
import request from 'utils/request';

import { TokenModel } from './TokenModel';
import { SideApiTokenType } from './types';

export class SideApiStore {
  isLoading = false;

  tokens: TokenModel[] = [];

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      tokens: observable,
      load: action,
      dropToken: action,
      addToken: action,
    });
  }

  dropToken(id: number) {
    this.tokens = this.tokens.filter((t) => t.id !== id);
  }

  addToken(token: TokenModel) {
    this.tokens = [...this.tokens, token];
  }

  async load(companyId: number): Promise<void> {
    this.isLoading = true;

    const { response } = await request<{ tokens: SideApiTokenType[] }>(
      apiUrls.integrationTokens.listTokens,
      'GET',
      {
        company: companyId,
      }
    );

    runInAction(() => {
      if (response) {
        this.tokens = response.tokens.map((t) => new TokenModel(this, t));
      }

      this.isLoading = false;
    });
  }
}
