import * as React from 'react';

import { useModal } from 'components/form/hooks/useModal';
import ButtonPlus from 'components/common/Button/ButtonPlus';
import AddGroupModal from 'components/containers/students/AddGroupModal';
import BaseCard from 'components/common/BaseCard';

import styles from './GroupsEmpty.modules.scss';

const GroupsEmpty: React.FC = () => {
  const [show, toggle] = useModal();

  return (
    <>
      <AddGroupModal isOpen={show} onClose={toggle} />
      <div className={styles.wrapper}>
        <BaseCard className={styles.card}>
          <div className={styles.content}>
            <div className={styles.emptyIcon} />
            <div className={styles.title}>
              У&nbsp;вас нет ни&nbsp;одной группы
            </div>
            <div className={styles.info}>
              Для начала работы необходимо создать учебную группу.
            </div>
            <ButtonPlus onClick={toggle}>Создать группу</ButtonPlus>
          </div>
        </BaseCard>
      </div>
    </>
  );
};

export default React.memo(GroupsEmpty);
