import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import toast from 'cogo-toast';

import { InputField, TextAreaField } from 'components/form';
import CourseModel from 'store/models/course/CourseModel';
import useForm from 'components/form/hooks/useForm';
import Button from 'components/common/Button';
import EditModal from 'components/common/EditModal/EditModal';
import { ChapterModel } from 'store/models/chapter/ChapterModel';
import noop from 'utils/noop';
import { useLocal } from 'utils/hooks/useLocal';
import {
  ChapterCreationFormModel,
  ChapterFieldsEnum,
} from 'store/models/chapter/ChapterCreationFormModel';
import { ButtonColor } from 'components/common/Button/Button';
import { Field } from 'components/form/Field';
import SwitcherInput from 'components/form/SwitcherInput';

export type CourseCreationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: (chapter: ChapterModel) => void;
  resetOnOpen?: boolean;
  course: CourseModel;
  chapterModel?: ChapterModel;
  title?: string;
};

const ChapterCreationModal: React.FC<CourseCreationModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  resetOnOpen,
  course,
  chapterModel,
  title,
}: CourseCreationModalProps) => {
  const chapterForm = useLocal(
    () => new ChapterCreationFormModel(course, chapterModel)
  );

  const form = useForm(chapterForm, Object.values(ChapterFieldsEnum));

  useEffect(() => {
    if (!isOpen && resetOnOpen) {
      chapterForm.clear(chapterModel);
    }
  }, [isOpen, resetOnOpen, chapterModel]);

  const handleSave = useCallback(async () => {
    const newChapter = await chapterForm.save();
    if (newChapter) {
      toast.success('Изменения успешно сохранены!');
      if (onSuccess) {
        onSuccess(newChapter as ChapterModel);
      }
      onClose();
    }
  }, [onClose, onSuccess]);

  const handleDelete = useCallback(async () => {
    if (!chapterModel?.id) {
      return;
    }

    const result = await course.deleteChapter(chapterModel.id);
    if (result) {
      toast.success('Глава удалена');
      onClose();
    }
  }, [course, chapterModel]);

  return (
    <EditModal
      isOpen={isOpen}
      onClose={onClose}
      isSaving={chapterForm.isSaving}
      title={title || 'Добавление главы'}
      onSave={handleSave}
    >
      <InputField
        errors={form.title.errors}
        placeholder="Название:"
        value={form.title.value}
        onChange={form.title.setValue}
      />
      <TextAreaField
        errors={form.description.errors}
        placeholder="Описание:"
        value={form.description.value}
        onChange={form.description.setValue}
      />
      <Field>
        <SwitcherInput
          isActive={form.isPublished.value}
          onChange={form.isPublished.setValue}
        >
          Глава опубликована
        </SwitcherInput>
      </Field>
      {form.isPublished.value && (
        <Field>
          <SwitcherInput
            isActive={form.sendNotifications.value}
            onChange={form.sendNotifications.setValue}
          >
            Отправить уведомление о публикации
          </SwitcherInput>
        </Field>
      )}
      {chapterModel && (
        <Button
          onClick={handleDelete}
          color={ButtonColor.red}
          isLoading={course.isDeletingChapter}
        >
          Удалить
        </Button>
      )}
    </EditModal>
  );
};

ChapterCreationModal.defaultProps = {
  onSuccess: noop,
};

export default observer(ChapterCreationModal);
