import { observer } from 'mobx-react';
import * as React from 'react';

import Button, { ButtonColor, ButtonSize } from 'components/common/Button';
import InputComponent from 'components/form/Input';
import { ChunkLayoutModel } from 'store/models/chunks/ChunkLayoutModel';
import { ChunkAlignEnum } from 'store/models/chunks/types';

import AlignIcon from './AlignIcon';
import styles from './LayoutEdit.modules.scss';

type Props = {
  layout: ChunkLayoutModel;
};

const LayoutEdit: React.FC<Props> = ({ layout }: Props) => {
  return (
    <>
      <div className={styles.layoutTitle}>Размер и отступы</div>
      <div className={styles.box}>
        <div className={styles.offsetTop}>
          <InputComponent
            placeholder="Сверху"
            min={0}
            type="number"
            value={layout.offsetTop}
            onChange={layout.setOffsetTop}
          />
          <span>px</span>
        </div>
        <div className={styles.middle}>
          <div className={styles.middleInput}>
            <InputComponent
              placeholder="Слева"
              min={0}
              type="number"
              value={layout.offsetLeft}
              onChange={layout.setOffsetLeft}
            />
            <span>px</span>
          </div>
          <div className={styles.middleInput}>
            <InputComponent
              placeholder="Справа"
              min={0}
              type="number"
              value={layout.offsetRight}
              onChange={layout.setOffsetRight}
            />
            <span>px</span>
          </div>
        </div>
        <div className={styles.offsetTop}>
          <InputComponent
            placeholder="Снизу"
            min={0}
            type="number"
            value={layout.offsetBottom}
            onChange={layout.setOffsetBottom}
          />
          <span>px</span>
        </div>
        <div className={styles.width}>
          <InputComponent
            min={0}
            max={100}
            placeholder="Ширина"
            type="number"
            value={layout.width.toFixed()}
            onChange={layout.setWidth}
          />
          <span>%</span>
        </div>
        <div className={styles.widthButtons}>
          <Button
            color={ButtonColor.white}
            size={ButtonSize.small}
            onClick={() => layout.setWidth(25)}
          >
            25%
          </Button>
          <Button
            color={ButtonColor.white}
            size={ButtonSize.small}
            onClick={() => layout.setWidth(33.3)}
          >
            33%
          </Button>
          <Button
            color={ButtonColor.white}
            size={ButtonSize.small}
            onClick={() => layout.setWidth(50)}
          >
            50%
          </Button>
          <Button
            color={ButtonColor.white}
            size={ButtonSize.small}
            onClick={() => layout.setWidth(100)}
          >
            100%
          </Button>
        </div>
      </div>
      <div className={styles.layoutTitle}>Выравнивание</div>
      <div className={styles.alignType}>
        <AlignIcon
          align={ChunkAlignEnum.top}
          onSelect={layout.setVerticalAlign}
          selectedAlign={layout.verticalAlign}
        />
        <AlignIcon
          align={ChunkAlignEnum.center}
          onSelect={layout.setVerticalAlign}
          selectedAlign={layout.verticalAlign}
        />
        <AlignIcon
          align={ChunkAlignEnum.bottom}
          onSelect={layout.setVerticalAlign}
          selectedAlign={layout.verticalAlign}
        />
      </div>
      <div className={styles.alignType}>
        <AlignIcon
          align={ChunkAlignEnum.left}
          onSelect={layout.setHorizontalAlign}
          selectedAlign={layout.horizontalAlign}
        />
        <AlignIcon
          align={ChunkAlignEnum.center}
          icon={ChunkAlignEnum.centerHorizontal}
          onSelect={layout.setHorizontalAlign}
          selectedAlign={layout.horizontalAlign}
        />
        <AlignIcon
          align={ChunkAlignEnum.right}
          onSelect={layout.setHorizontalAlign}
          selectedAlign={layout.horizontalAlign}
        />
      </div>
    </>
  );
};

export default observer(LayoutEdit);
