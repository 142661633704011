import * as React from 'react';

import { sanitize } from './utils';

export type PProps = { text: string };

export type HProps = PProps & { level: number };

export type ListProps = {
  style: 'ordered' | 'unordered';
  items: string[];
};

export const H: React.FC<HProps> = ({ level, text }: HProps) => {
  const Component = `h${level}` as any;
  const html = sanitize(text);

  return <Component dangerouslySetInnerHTML={{ __html: html }} />;
};

export const P: React.FC<PProps> = ({ text }: PProps) => {
  const html = sanitize(text);

  return <p dangerouslySetInnerHTML={{ __html: html }} />;
};

export const List: React.FC<ListProps> = ({ style, items }: ListProps) => {
  const Component: any = style === 'ordered' ? 'ol' : 'ul';

  return (
    <Component>
      {items.map((item, i) => (
        <li key={i}>{item}</li>
      ))}
    </Component>
  );
};

type EmbedProps = {
  caption: string;
  embed: string;
  height: number;
  service: 'youtube' | string;
  source: string;
  width: number;
};

export const Embed: React.FC<EmbedProps> = ({
  caption,
  embed,
  service,
  height,
}: EmbedProps) => {
  return (
    <div>
      <iframe
        width="100%"
        height={height}
        allowFullScreen={service === 'youtube'}
        frameBorder={0}
        src={embed}
      />
      <div className="caption">{caption}</div>
    </div>
  );
};

type TableProps = {
  content: string[][];
};

export const Table: React.FC<TableProps> = ({ content }: TableProps) => {
  return (
    <div className="tc-table__wrap">
      <table className="tc-table">
        <tbody>
          {content.map((row, i) => (
            <tr key={i}>
              {row.map((cell, i) => (
                <td className="tc-table__cell" key={i}>
                  <div className="tc-table__area">{cell}</div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const mapTypeToComponent = {
  header: H,
  paragraph: P,
  list: List,
  embed: Embed,
  table: Table,
};
