import * as React from 'react';
import * as propTypes from 'prop-types';

const SuccessIcon = (props) => {
  const { className } = props;

  return (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-23.000000, -18.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <g transform="translate(23.000000, 18.000000)">
            <path d="M9,0 C4.00909091,0 0,4.00909091 0,9 C0,13.9909091 4.00909091,18 9,18 C13.9909091,18 18,13.9909091 18,9 C18,4.00909091 13.9909091,0 9,0 Z M14.1954545,6.17727273 L7.65,12.9681818 C7.56818182,13.05 7.48636364,13.0909091 7.36363636,13.0909091 C7.24090909,13.0909091 7.11818182,13.05 7.07727273,12.9681818 L3.88636364,9.53181818 L3.80454545,9.45 C3.72272727,9.36818182 3.68181818,9.24545455 3.68181818,9.16363636 C3.68181818,9.08181818 3.72272727,8.95909091 3.80454545,8.87727273 L4.37727273,8.30454545 C4.54090909,8.14090909 4.78636364,8.14090909 4.95,8.30454545 L4.99090909,8.34545455 L7.24090909,10.7590909 C7.32272727,10.8409091 7.44545455,10.8409091 7.52727273,10.7590909 L13.0090909,5.07272727 L13.05,5.07272727 C13.2136364,4.90909091 13.4590909,4.90909091 13.6227273,5.07272727 L14.1954545,5.64545455 C14.3590909,5.76818182 14.3590909,6.01363636 14.1954545,6.17727273 Z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

SuccessIcon.propTypes = {
  className: propTypes.string.isRequired,
};

export default SuccessIcon;
