import { observer } from 'mobx-react';
import { useEffect } from 'react';
import * as React from 'react';

import ButtonPlus from 'components/common/Button/ButtonPlus/ButtonPlus';
import AddGroupModal from 'components/containers/students/AddGroupModal';
import { useModal } from 'components/form/hooks/useModal';
import Spinner from 'components/common/Spinner';
import { useStudentsStore } from 'store/context';

import GroupItem from './GroupItem';
import GroupsEmpty from './GroupsEmpty';
import styles from './Groups.modules.scss';

const Groups: React.FC = () => {
  const students = useStudentsStore();
  const [showModal, toggleModal] = useModal();

  useEffect(() => {
    students.loadGroups();
  }, []);

  if (students.noGroups) {
    return <GroupsEmpty />;
  }

  return (
    <div>
      <AddGroupModal onClose={toggleModal} isOpen={showModal} />
      {students.isLoadingGroups && <Spinner local />}
      <div className={styles.filters}>
        <ButtonPlus onClick={toggleModal}>Создать группу</ButtonPlus>
      </div>
      {students.groupsList.map((g) => (
        <GroupItem className={styles.card} key={g.id} group={g} />
      ))}
    </div>
  );
};

export default observer(Groups);
