import * as React from 'react';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { ChunkModel } from 'store/models/chunks/ChunkModel';
import { throttle } from 'utils/throttle';

import { CHUNK_ELEMENT_ID, LESSON_EDIT_BAR_ELEMENT_ID } from './config';

export type ConstructorContextType = {
  isEditMode: boolean;
  isAdmin: boolean;
  readOnly: boolean;
  chunkToEdit: ChunkModel | null;
  setChunkToEdit(chunk: ChunkModel): void;
  dropChunkToEdit(chunkId: string): void;
  setReadonly(isReadonly: boolean): void;
};

export const ConstructorContext =
  React.createContext<ConstructorContextType | null>(null);

export const useConstructorContext = (): ConstructorContextType => {
  const data = useContext(ConstructorContext);
  if (!data) {
    throw new Error('No ConstructorContext Provider in tree!');
  }

  return data;
};

/*
Хук для включения ридонли режима редактора в случае взаимодействия с элементами кастомных чанков:
https://draftjs.org/docs/advanced-topics-block-components#recommendations-and-other-notes
 */
export const useConstructorReadonly = () => {
  const ctx = useConstructorContext();

  const [isOpenFileDialog, setIsOpenFileDialog] = useState(false);

  const readOnlyEnable = (fromFileInput = false) => {
    ctx.setReadonly(true);

    if (fromFileInput) {
      setIsOpenFileDialog(fromFileInput);
    }
  };

  const readOnlyDisable = useCallback(
    (e?: ChangeEvent) => {
      e?.stopPropagation();
      e?.preventDefault();

      ctx.setReadonly(false);
    },
    [ctx.setReadonly]
  );

  useEffect(() => {
    // Если открыт диалог с файлом, то дожидаемся либо onChange либо закрытия
    const handle = throttle(() => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      disable();
      ctx.setReadonly(false);
      setIsOpenFileDialog(false);
    }, 500);

    function disable() {
      // document.removeEventListener('mousemove', handle);
      // document.removeEventListener('touchstart', handle);
      // document.removeEventListener('keypress', handle);
      document.removeEventListener('change', handle);
      window.removeEventListener('focus', handle);
    }

    function enable() {
      // document.addEventListener('mousemove', handle);
      // document.addEventListener('touchstart', handle);
      // document.addEventListener('keypress', handle);
      window.addEventListener('focus', handle);
      document.addEventListener('change', handle);
    }

    if (isOpenFileDialog) {
      enable();
    }

    return () => {
      disable();
    };
  }, [isOpenFileDialog]);

  return { readOnlyEnable, readOnlyDisable };
};

/*
При кликах на кастомных чанках включает ридонли режим редактора:
https://draftjs.org/docs/advanced-topics-block-components#recommendations-and-other-notes

Немного неоптимально (ловим все клики), но зато удобно: 1 раз на все чанки.
Другое решение см выше.
 */
export const useWithCustomChunks = (setReadonly: (ro: boolean) => void) => {
  const focus = useCallback((e: Event) => {
    let parent: Element | null = e.target as Element;
    while (parent && parent.id !== 'constructor') {
      if (
        parent.id === CHUNK_ELEMENT_ID ||
        parent.id === LESSON_EDIT_BAR_ELEMENT_ID
      ) {
        setReadonly(true);
        return;
      }
      parent = parent.parentElement;
    }

    setReadonly(false);
  }, []);

  useEffect(() => {
    document.addEventListener('click', focus);

    return () => {
      document.removeEventListener('click', focus);
    };
  }, []);

  return { focus };
};
