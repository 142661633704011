import { ImageChunkModel } from 'store/models/chunks/images/ImageChunkModel';
import { TextChunkModel } from 'store/models/chunks/text/TextChunkModel';
import { EditorJSModel } from 'store/models/chunks/text/EditorJsModel';
import { QuizGroupModel } from 'store/models/chunks/quiz/QuizGroupModel';
import { QuizQuestionModel } from 'store/models/chunks/quiz/QuizQuestionModel';
import { QuizAnswerModel } from 'store/models/chunks/quiz/QuizAnswerModel';
import { VideoChunkModel } from 'store/models/chunks/video/VideoChunkModel';
import { AttachmentsChunkModel } from 'store/models/chunks/files/AttachmentsChunkModel';
import { HomeworkChunkModel } from 'store/models/chunks/homework/HomeworkChunkModel';
import { CallbackTaskChunkModel } from 'store/models/chunks/callbackTask/CallbackTaskChunkModel';
import { EmbedChunkModel } from 'store/models/chunks/embed/EmbedChunkModel';

import { CodeChunkModel } from '../code/CodeChunkModel';
import { CodeTaskChunkModel } from '../codeTask/CodeTaskChunkModel';
import { MercuryTaskChunkModel } from '../mercury/MercuryTaskChunkModel';

import { ChunkAlignEnum, ChunkTypeEnum } from './chunkTypes';

export type ChunkDataModelType =
  | TextChunkModel
  | ImageChunkModel
  | EditorJSModel
  | QuizGroupModel
  | QuizQuestionModel
  | QuizAnswerModel
  | VideoChunkModel
  | AttachmentsChunkModel
  | HomeworkChunkModel
  | CallbackTaskChunkModel
  | CodeTaskChunkModel
  | MercuryTaskChunkModel
  | CodeChunkModel
  | EmbedChunkModel
  | any;

export type LayoutServerType = {
  layout_width: number;
  layout_offset_top: number;
  layout_offset_left: number;
  layout_offset_right: number;
  layout_offset_bottom: number;
  layout_vertical_align: ChunkAlignEnum;
  layout_horizontal_align: ChunkAlignEnum;
};

export type ChunkServerType<D extends any = any> = LayoutServerType & {
  id: string;
  unit_id: number;
  type: ChunkTypeEnum;
  data: D;

  creator_id?: number;
  creation_date?: string;
};

export type ChunksServerResponseType = {
  chunks: ChunkServerType<any>[];
};

export const ChunkTypeToDataModel: Record<ChunkTypeEnum, ChunkDataModelType> = {
  [ChunkTypeEnum.text]: TextChunkModel,
  [ChunkTypeEnum.editorjs]: EditorJSModel,
  [ChunkTypeEnum.image]: ImageChunkModel,
  [ChunkTypeEnum.quizGroup]: QuizGroupModel,
  [ChunkTypeEnum.quiz]: QuizQuestionModel,
  [ChunkTypeEnum.quizAnswer]: QuizAnswerModel,
  [ChunkTypeEnum.video]: VideoChunkModel,
  [ChunkTypeEnum.attachments]: AttachmentsChunkModel,
  [ChunkTypeEnum.homework]: HomeworkChunkModel,
  [ChunkTypeEnum.callbackTask]: CallbackTaskChunkModel,
  [ChunkTypeEnum.codeTask]: CodeTaskChunkModel,
  [ChunkTypeEnum.mercuryTask]: MercuryTaskChunkModel,
  [ChunkTypeEnum.code]: CodeChunkModel,
  [ChunkTypeEnum.embed]: EmbedChunkModel,
};
