import { useRef, useState } from 'react';

export const useRunCode = () => {
  const pyodide = useRef<any>(null);
  const [output, setOutput] = useState('');
  const [isRunning, setIsRunning] = useState(false);

  const loadPyodide = async () => {
    if (pyodide.current) {
      return null;
    }
    try {
      pyodide.current = await (window as any).loadPyodide({
        indexURL: 'https://cdn.jsdelivr.net/pyodide/v0.18.1/full/',
      });
    } catch (e) {
      //
    }

    await pyodide.current?.loadPackage('micropip');
    await pyodide.current?.loadPackage('pytest');
  };

  const runCode = async (codeToRun: string) => {
    let isSuccess = false;
    setIsRunning(true);
    setOutput('');
    await loadPyodide();
    if (pyodide.current) {
      try {
        const result = await pyodide.current.runPythonAsync(codeToRun);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        isSuccess = pyodide.current.globals.get('SUCCESS');
        setOutput(result);
      } catch (e) {
        setOutput(e.message);
      }
    }
    setIsRunning(false);
    return isSuccess;
  };

  return {
    runCode,
    output,
    isRunning,
  };
};
