import * as React from 'react';
import { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import { isMobileOnly } from 'react-device-detect';

import { Error } from 'components/form';
import { ChunkModel } from 'store/models/chunks/ChunkModel';
import BaseEdit from 'components/common/BaseEdit';
import { ChunkAlignEnum } from 'store/models/chunks/types';
import { ShiftPositionEnum } from 'store/models/common/types';
import Blurable from 'components/common/Blurable';

import { LESSON_EDIT_BAR_ELEMENT_ID } from '../../config';
import { useConstructorContext } from '../../context';
import { useChunkContainer } from '../../models/chunkContainer/context';
import { complexChunks } from '../config';
import { useDraggableChunk } from '../hooks';

import Wrapper from './Wrapper';
import { getAlignClassNames } from './Wrapper/alignClassNames';
import styles from './Layout.modules.scss';

type Props = {
  chunk: ChunkModel;
  isEditMode: boolean;
  children: React.ReactNode;
};

const Layout: React.FC<Props> = ({ children, isEditMode, chunk }: Props) => {
  const {
    width,
    offsetTop,
    offsetBottom,
    offsetLeft,
    offsetRight,
    horizontalAlign,
    verticalAlign,
  } = chunk.layout;

  const chunkContainer = useChunkContainer();
  const constructorCtx = useConstructorContext();

  const [canDrag, setCanDrag] = useState(true);

  const handleResize = useCallback((isResizing: boolean) => {
    setCanDrag(!isResizing);
  }, []);

  const handleInsertBefore = useCallback(() => {
    chunkContainer.insertChunk(chunk.id, ShiftPositionEnum.left);
  }, [chunk.id, chunkContainer.insertChunk]);

  const handleInsertAfter = useCallback(() => {
    chunkContainer.insertChunk(chunk.id, ShiftPositionEnum.right);
  }, [chunk.id, chunkContainer.insertChunk]);

  const handleSelect = useCallback(
    (e: any) => {
      e.stopPropagation(); // Если кликаем по вложенным чанкам
      chunkContainer.selectChunk(chunk);
      constructorCtx.setChunkToEdit(chunk);
    },
    [chunk, chunkContainer.selectChunk]
  );

  const handleShiftRight = useCallback(() => {
    chunkContainer.shiftChunk(chunk.id, ShiftPositionEnum.right);
  }, [chunkContainer.shiftChunk, chunk.id]);

  const handleShiftLeft = useCallback(() => {
    chunkContainer.shiftChunk(chunk.id, ShiftPositionEnum.left);
  }, [chunkContainer.shiftChunk, chunk.id]);

  const handleBlur = useCallback(() => {
    chunkContainer.dropChunkSelection(chunk.id);
    constructorCtx.dropChunkToEdit(chunk.id);
  }, [chunk.id, chunkContainer.dropChunkSelection]);

  const [dragRef, dropRef, { isOver }] = useDraggableChunk(
    chunk.id,
    (chunkId1, chunkId2) => chunkContainer.swapChunks(chunkId1, chunkId2),
    canDrag
  );

  const style = {
    border: 'none',
    width: `${isMobileOnly ? 100 : width}%`,
    paddingTop: `${offsetTop}px`,
    paddingBottom: `${offsetBottom}px`,
    paddingLeft: `${isMobileOnly ? Math.min(10, offsetLeft) : offsetLeft}px`,
    paddingRight: `${isMobileOnly ? Math.min(10, offsetRight) : offsetRight}px`,
  };

  if (!isEditMode) {
    let content = (
      <div
        style={style}
        className={cn(
          styles.root,
          getAlignClassNames(verticalAlign, undefined)
        )}
      >
        {children}
      </div>
    );

    if (
      horizontalAlign &&
      [ChunkAlignEnum.center, ChunkAlignEnum.right].indexOf(horizontalAlign) >
        -1
    ) {
      content = (
        <div className={getAlignClassNames(undefined, horizontalAlign)}>
          {content}
        </div>
      );
    }

    return content;
  }

  delete style.width;

  const isSelected = chunkContainer.selectedChunkId === chunk.id;

  if (chunk.hasErrors) {
    style.border = '1px dashed red';
  }

  return (
    <Blurable
      onBlur={handleBlur}
      excludedRoots={[document.getElementById(LESSON_EDIT_BAR_ELEMENT_ID)]}
    >
      <Wrapper
        onResize={handleResize}
        onClick={handleSelect}
        chunk={chunk}
        setRef={(node: React.ReactNode): void => {
          dragRef(node);
          dropRef(node);
        }}
        isEditMode={isEditMode}
        isSelected={isSelected}
        horizontalAlign={horizontalAlign}
        verticalAlign={verticalAlign}
        style={style}
      >
        <BaseEdit
          isEditOnly={complexChunks.indexOf(chunk.type) === -1}
          isSelected={isSelected}
          isHorizontalControls
          onShiftAfter={handleShiftRight}
          onShiftBefore={handleShiftLeft}
          onAppendAfter={handleInsertAfter}
          onAppendBefore={handleInsertBefore}
        />
        {isOver && <div className={styles.unitOver} />}
        <div className={cn(styles.root, isSelected && styles.editing)}>
          {children}
        </div>
        {chunk.hasErrors && (
          <Error>
            {chunk.errors.map((err, i) => (
              <div key={i}>{err}</div>
            ))}
          </Error>
        )}
      </Wrapper>
    </Blurable>
  );
};

export default observer(Layout);
