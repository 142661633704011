import { action, makeObservable, observable, runInAction } from 'mobx';

import apiUrls from 'config/apiUrls';
import request from 'utils/request';

import { HomeworkChunkModel } from '../chunks/homework/HomeworkChunkModel';
import { HomeworkStatus } from '../chunks/homework/types';

import { AssignedUser, HomeworkItemServerType } from './types';

type HomeworkItemType = {
  conversationId: number;
  homeworkId: string;
  lessonId: number;
  unitId: number;
  lastName: string;
  firstName: string;
  userId: number;
  email: string;
  id: number;
  lastAnswerMessageId: number;
  status: HomeworkStatus;
  courseTitle: string;
  chapterTitle: string;
  lessonTitle: string;
  assignedId: number | null;
  assigned: AssignedUser | null;

  homeworkChunk: HomeworkChunkModel;
};

export class HomeworkItemModel implements HomeworkItemType {
  isAssigning = false;

  assignedId: number | null;
  assigned: AssignedUser | null;

  conversationId: number;
  homeworkId: string;
  lessonId: number;
  unitId: number;
  lastName: string;
  firstName: string;
  email: string;
  userId: number;
  id: number;
  lastAnswerMessageId: number;
  status: HomeworkStatus;
  courseTitle: string;
  chapterTitle: string;
  lessonTitle: string;

  homeworkChunk: HomeworkChunkModel;

  constructor({
    conversationId,
    homeworkChunk,
    lastName,
    lessonId,
    unitId,
    homeworkId,
    firstName,
    userId,
    id,
    lastAnswerMessageId,
    status,
    courseTitle,
    chapterTitle,
    lessonTitle,
    assigned,
    assignedId,
    email,
  }: HomeworkItemType) {
    this.conversationId = conversationId;
    this.homeworkChunk = homeworkChunk;
    this.lastAnswerMessageId = lastAnswerMessageId;
    this.lastName = lastName;
    this.firstName = firstName;
    this.userId = userId;
    this.id = id;
    this.status = status;
    this.homeworkId = homeworkId;
    this.lessonId = lessonId;
    this.unitId = unitId;
    this.courseTitle = courseTitle;
    this.chapterTitle = chapterTitle;
    this.lessonTitle = lessonTitle;
    this.assigned = assigned;
    this.assignedId = assignedId;
    this.email = email;

    makeObservable(this, {
      isAssigning: observable,
      assigned: observable,
      assignedId: observable,
      takeForMe: action.bound,
    });
  }

  async takeForMe(user: AssignedUser): Promise<boolean> {
    this.isAssigning = true;

    const { response } = await request(apiUrls.homework.assign, 'POST', {
      homework_chunk: this.homeworkChunk.id,
      user: this.userId,
    });

    runInAction(() => {
      this.isAssigning = false;
      this.assigned = user;
      this.assignedId = user.id;
    });

    return Boolean(response);
  }

  static fromJson(data: HomeworkItemServerType): HomeworkItemModel {
    return new HomeworkItemModel({
      conversationId: data.conversation_id,
      homeworkChunk: HomeworkChunkModel.fromDataJson(
        data.homework,
        data.homework_id
      ),
      lastName: data.last_name,
      homeworkId: data.homework_id,
      firstName: data.first_name,
      userId: data.user_id,
      id: data.id,
      lastAnswerMessageId: data.last_answer_message_id,
      status: data.status,
      lessonId: data.lesson_id,
      unitId: data.unit_id,
      courseTitle: data.course_title,
      chapterTitle: data.chapter_title,
      lessonTitle: data.lesson_title,
      assigned: data.assigned,
      assignedId: data.assigned_id,
      email: data.email,
    });
  }
}
