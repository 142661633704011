import * as React from 'react';
import { observer } from 'mobx-react';

import { EditorJSModel } from 'store/models/chunks/text/EditorJsModel';
import TextEditor from 'components/common/TextEditor';

import { WithChunk } from '../types';

type Props = WithChunk<EditorJSModel>;

const TextChunk: React.FC<Props> = ({ chunk, isEditMode }: Props) => {
  const { data } = chunk;

  return (
    <div id={chunk.id}>
      <TextEditor
        data={data.data}
        onSave={data.setData}
        viewMode={!isEditMode}
      />
    </div>
  );
};

export default observer(TextChunk);
