import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import urls from 'config/urls';
import ContentLayout from 'components/common/ContentLayout';

import GroupPage from './GroupPage';
import Groups from './Groups';

const Students: React.FC = () => {
  return (
    <ContentLayout>
      <Switch>
        <Route path={urls.students.groups.index} exact component={Groups} />
        <Route path={urls.students.groups.pattern} component={GroupPage} />
        <Redirect to={urls.students.groups.index} />
      </Switch>
    </ContentLayout>
  );
};

export default Students;
