import cn from 'classnames';
import * as React from 'react';

import { CONFIG } from 'config/app';
import { GroupModel } from 'store/models/students/GroupModel';

import PaymentsGroupConfiguration from './PaymentsGroupConfiguration';
import styles from './Settings.modules.scss';

type Props = {
  group: GroupModel;
  className?: string;
};

const Settings: React.FC<Props> = ({ group, className }: Props) => {
  return (
    <div className={cn(styles.wrapper, className)}>
      <div className={styles.setting}>
        <div className={styles.header}>Курс</div>
        <div className={styles.text}>{group.courseTitle}</div>
      </div>
      {CONFIG.paymentsEnabled && (
        <div className={styles.setting}>
          <div className={styles.header}>Оплата</div>
          <PaymentsGroupConfiguration groupId={group.id} />
        </div>
      )}
    </div>
  );
};

export default React.memo(Settings);
