import React, { ComponentType } from 'react';
import { EditorState } from 'draft-js';
import { createStore, Store } from '@draft-js-plugins/utils';
import { EditorPlugin } from '@draft-js-plugins/editor';
import * as PopperJS from '@popperjs/core';
import { Modifier } from 'react-popper';

import { IChunkContainer } from '../../models/chunkContainer/ChunkContainer';

import Toolbar from './Toolbar';

export type SideToolbarPosition = 'left' | 'right';

export interface SideToolbarPluginConfig {
  position?: SideToolbarPosition;
  popperOptions?: PopperOptions;
}

export type PopperOptions = Omit<Partial<PopperJS.Options>, 'modifiers'> & {
  createPopper?: typeof PopperJS.createPopper;
  modifiers?: ReadonlyArray<Modifier<unknown>>;
};

export type SideToolbarPlugin = EditorPlugin & {
  SideToolbar: ComponentType<{ unit: IChunkContainer }>;
};

interface StoreItemMap {
  isVisible?: boolean;
  getEditorState?(): EditorState;
  setEditorState?(state: EditorState): void;
  editorState?: EditorState;
  getEditorRef?(): {
    refs?: { editor: HTMLElement };
    editor: HTMLElement;
  };
}

export type SideToolbarPluginStore = Store<StoreItemMap>;

export default (config: SideToolbarPluginConfig = {}): SideToolbarPlugin => {
  const defaultPostion = 'left';

  const store = createStore<StoreItemMap>({
    isVisible: true,
  });

  const { position = defaultPostion, popperOptions } = config;

  const SideToolbar = ({ unit }: { unit: IChunkContainer }) => (
    <Toolbar
      unit={unit}
      store={store}
      position={position}
      popperOptions={popperOptions}
    />
  );

  return {
    initialize: ({ setEditorState, getEditorState, getEditorRef }) => {
      store.updateItem('getEditorState', getEditorState);
      store.updateItem('setEditorState', setEditorState);
      store.updateItem('getEditorRef', getEditorRef);
    },
    onChange: (editorState) => {
      store.updateItem('editorState', editorState);
      return editorState;
    },
    SideToolbar,
  };
};
