import * as React from 'react';
import { useCallback, useState } from 'react';

import { AttachmentUploadModel } from 'store/models/attachment/AttachmentUploadModel';
import { AttachmentType } from 'store/models/attachment';

export const useAttachmentUpload = (
  setAttachment: (a: AttachmentType) => void,
  isVideo = false
): [
  AttachmentUploadModel,
  (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>,
  (model: AttachmentUploadModel) => void
] => {
  const [uploadModel, setUploadModel] = useState<AttachmentUploadModel>(
    new AttachmentUploadModel()
  );

  const handleFileChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (!e.target.files?.length) {
        return;
      }

      const file = e.target.files?.item(0);
      if (file) {
        const uploadedAttach = await uploadModel.uploadFile(file, isVideo);

        if (uploadedAttach) {
          setAttachment(uploadedAttach);
        }
      }
    },
    [uploadModel, setAttachment]
  );

  return [uploadModel, handleFileChange, setUploadModel];
};
