import {
  CompanyModel,
  CompanyUserServerType,
} from 'store/stores/UserStore/types';

export type InviteCheckServerType = {
  company: CompanyModel;
  receiver: CompanyUserServerType | null;
  sender: CompanyUserServerType;
};

export const EXPIRED_TOKEN_STATUS = 'expired_invitation_token';
