export enum ShiftPositionEnum {
  up = 'up',
  down = 'down',
  left = 'left',
  right = 'right',
}

export type ServerResponse<T = any, E = any> = {
  response?: T;
  error: { data: E } | null;
};
