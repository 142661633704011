import * as React from 'react';
import cn from 'classnames';

import Spinner from 'components/common/Spinner';

import styles from './PageSpinner.modules.scss';

export type PageSpinnerProps = {
  className?: string;
};

const PageSpinner: React.FC<PageSpinnerProps> = ({
  className,
}: PageSpinnerProps) => {
  return <Spinner className={cn(styles.pageSpinner, className)} />;
};

export default React.memo(PageSpinner);
