import * as React from 'react';

type Props = React.SVGProps<any>;

const CompanyIcon: React.FC<Props> = (props: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="business_center_24px">
        <path
          id="icon/places/business_center_24px"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 7H16V5L14 3H10L8 5V7H4C2.9 7 2 7.9 2 9V14C2 14.75 2.4 15.38 3 15.73V19C3 20.11 3.89 21 5 21H19C20.11 21 21 20.11 21 19V15.72C21.59 15.37 22 14.73 22 14V9C22 7.9 21.1 7 20 7ZM10 5.00004H14V7.00004H10V5.00004ZM20 9H4.00001V14H9.00001V11H15V14H20V9ZM13 15H11V13H13V15ZM5 19H19V16H15V17H9V16H5V19Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default React.memo(CompanyIcon);
