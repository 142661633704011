import { makeObservable, override } from 'mobx';

import { ChapterModel } from 'store/models/chapter/ChapterModel';
import { Form } from 'store/models/common/Form';
import CourseModel from 'store/models/course/CourseModel';
import request from 'utils/request';
import apiUrls from 'config/apiUrls';
import { ServerResponse } from 'store/models/common/types';

import { ChapterCreateResponseType } from './types';
import { LessonTypeEnum } from 'store/models/lesson/types';

export enum ChapterFieldsEnum {
  title = 'title',
  description = 'description',
  isPublished = 'isPublished',
  sendNotifications = 'sendNotifications',
}

export type ChapterFields = {
  title: string;
  description: string;
  isPublished: boolean;
  sendNotifications: boolean;
};

export class ChapterCreationFormModel extends Form<ChapterFields> {
  id?: number;

  course: CourseModel;
  chapter: ChapterModel | null = null;

  initialValues: ChapterFields = {
    title: '',
    description: '',
    isPublished: false,
    sendNotifications: false,
  };

  constructor(course: CourseModel, chapter?: ChapterModel) {
    super();

    makeObservable(this, {
      //override
      setInitialFrom: override,
      save: override,
    });

    this.course = course;

    if (chapter) {
      this.chapter = chapter;
      this.id = chapter.id;

      this.clear(chapter);
    }
  }

  setInitialFrom(chapter: ChapterModel): void {
    super.setInitialFrom({
      title: chapter.title,
      description: chapter.description,
      isPublished: chapter.isPublished,
      sendNotifications: chapter.sendNotifications,
    });
  }

  async save(): Promise<boolean | ChapterModel | null> {
    this.validate();
    if (this.hasErrors) {
      return false;
    }

    this.isSaving = true;

    const isNew = !this.chapter;

    const { response }: ServerResponse<ChapterCreateResponseType> =
      await request(
        isNew ? apiUrls.chapter.create : apiUrls.chapter.edit,
        'POST',
        {
          title: this.values.title,
          description: this.values.description,
          course_id: this.course.id,
          is_published: this.values.isPublished,
          send_notifications: this.values.sendNotifications,
          id: isNew ? undefined : this.chapter?.id,
        }
      );

    this.isSaving = false;

    if (response) {
      this.id = response.id;
      let { chapter } = this;

      if (this.chapter) {
        this.chapter.updateFrom(this);
      } else {
        chapter = ChapterModel.fromForm(this, this.course);
      }
      return chapter;
    }

    return false;
  }
}
