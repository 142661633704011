import * as React from 'react';
import * as propTypes from 'prop-types';
import cn from 'classnames';

import BaseBadge from 'components/common/Badge';
import { LessonTypeEnum as types } from 'store/models/lesson/types';

import styles from './Badge.modules.scss';

const Badge = (props: any) => {
  const { type, className } = props;

  const isTheory = type === types.theory;
  const isIntro = type === types.intro;
  const isTest = type === types.test;
  const isVideo = type === types.video;
  const isPractice = type === types.practice;
  const isFinal = type === types.final;

  return (
    <BaseBadge
      className={cn(
        className,
        isIntro && styles.intro,
        isTheory && styles.theory,
        isTest && styles.test,
        isVideo && styles.video,
        isPractice && styles.practice,
        isFinal && styles.finalTask
      )}
    >
      {isIntro && 'Введение'}
      {isTheory && 'Теория'}
      {isTest && 'Тест'}
      {isVideo && 'Видео-урок'}
      {isPractice && 'Практическое задание'}
      {isFinal && 'Итоговое задание'}
    </BaseBadge>
  );
};

Badge.propTypes = {
  type: propTypes.oneOf([
    types.intro,
    types.theory,
    types.test,
    types.video,
    types.practice,
    types.final,
  ]).isRequired,
  className: propTypes.string,
};

export default Badge;
