import * as React from 'react';

function IntegrationIcon(props) {
  return (
    <svg
      width={22}
      height={20}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="currentColor" d="M0 0h22v20H0z" />
      <g clipPath="url(#prefix__clip0)" fill="#fff">
        <path d="M16.342 5.726a2.249 2.249 0 00-3.182-.001L10.255 8.63a2.253 2.253 0 00-.575 2.2.429.429 0 10.826-.233c-.136-.484 0-1.004.356-1.36l2.905-2.905a1.391 1.391 0 011.967 1.967l-2.905 2.905c-.19.19-.432.322-.696.377a.429.429 0 10.178.84c.426-.09.817-.302 1.125-.61l2.905-2.905a2.249 2.249 0 000-3.18z" />
        <path d="M12.307 9.182a.429.429 0 10-.825.233c.135.484-.001 1.004-.356 1.36L8.22 13.68a1.391 1.391 0 01-1.968-1.967l2.905-2.905c.19-.19.432-.322.695-.377a.429.429 0 10-.168-.842l-.008.002c-.426.09-.817.302-1.125.61l-2.905 2.905a2.25 2.25 0 003.18 3.181l2.905-2.905a2.253 2.253 0 00.575-2.2z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" transform="translate(5 4)" d="M0 0h12v12H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IntegrationIcon;
