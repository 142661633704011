import * as React from 'react';

import { AttachmentServerType } from 'store/models/attachment';

import { HomeworkServerType } from './chat/types';

export enum HomeworkMessageTagEnum {
  solution = 'hw_solution',
  statusChanged = 'hw_status_changed',
}

export enum HomeworkApproveEnum {
  right = 'right',
  wrong = 'wrong',
}

export enum HomeworkSystemMessageEnum {
  checking = 'checking',
  rejected = 'rejected',
  finished = 'finished',
}

export type DeadlineServerType = {
  deadline: string | null;
};

export type DeadlineGetServerType = DeadlineServerType & {
  is_deadline: boolean;
};

export type ChunkHomeworkServerType = DeadlineGetServerType & {
  title: string;
  is_read: boolean;
  status: HomeworkStatus; // TODO
  conversation_id: string | null;
};

export type HomeworkMessageServerType = {
  last_name: string;
  user_id: number;
  message: string | HomeworkSystemMessageEnum;
  conversation_id: number;
  tag: HomeworkMessageTagEnum | null;
  is_mine: boolean;
  id: number;
  first_name: string;
  attachment: AttachmentServerType;
  sent: string;
};

export type HomeworkMessagesResponseType = {
  messages: HomeworkMessageServerType[];
  homework_progress: HomeworkServerType;
};

export enum HomeworkStatus {
  notStarted = 'not_started',
  checking = 'checking',
  rejected = 'rejected',
  finished = 'finished',
}

export const HomeworkStatusText: Record<HomeworkStatus, string> = {
  [HomeworkStatus.notStarted]: 'Не начато',
  [HomeworkStatus.checking]: 'На проверке',
  [HomeworkStatus.rejected]: 'Возвращено на доработку',
  [HomeworkStatus.finished]: 'Принято',
};

export const HomeworkStatusInfo: Record<HomeworkStatus, React.ReactElement> = {
  [HomeworkStatus.notStarted]: (
    <span>
      Для того чтобы отправить задание, перейдите в&nbsp;чат
      с&nbsp;преподавателем для обсуждения.
    </span>
  ),
  [HomeworkStatus.checking]: (
    <span>
      Ваше задание на&nbsp;проверке. Если вы&nbsp;хотите загрузить новую версию
      своего результата, то&nbsp;можете сделать это в&nbsp;любой момент
    </span>
  ),
  [HomeworkStatus.rejected]: (
    <span>
      Для того чтобы отправить исправления по&nbsp;заданию, перейдите в&nbsp;чат
      с&nbsp;преподавателем для обсуждения.
    </span>
  ),
  [HomeworkStatus.finished]: (
    <span>
      Ваше задание успешно принято! Для того чтобы посмотреть обсуждение задания
      перейдите в&nbsp;чат с&nbsp;преподавателем
    </span>
  ),
};
