import { observer } from 'mobx-react';
import * as React from 'react';

import {
  GroupCreationFieldsEnum,
  GroupCreationFormModel,
} from 'store/models/students/GroupCreationFormModel';
import useForm from 'components/form/hooks/useForm';
import Modal from 'components/common/Modal';
import Button from 'components/common/Button';
import { DropdownField, InputField } from 'components/form';
import { useCourseStore, useCurrentCompany } from 'store/index';
import { GroupModel } from 'store/models/students/GroupModel';
import { getCoursesOptions } from 'store/stores/CourseStore';
import { useLocal } from 'utils/hooks/useLocal';
import noop from 'utils/noop';

import styles from './AddGroupModal.modules.scss';

export type AddGroupModalProps = {
  isOpen: boolean;
  onClose(): void;
  onSuccess?(group: GroupModel): void;
};

const AddGroupModal: React.FC<AddGroupModalProps> = ({
  isOpen,
  onClose,
  onSuccess = noop,
}: AddGroupModalProps) => {
  const courseStore = useCourseStore();
  const companyId = useCurrentCompany()?.id;
  const formModel = useLocal(() => new GroupCreationFormModel());
  const form = useForm(formModel, Object.values(GroupCreationFieldsEnum));

  const onSave = async () => {
    if (companyId) {
      const group = await formModel.save(companyId);
      if (group) {
        onClose();
        onSuccess(group);
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className={styles.content}
      isLoading={formModel.isSaving}
    >
      <div className={styles.title}>Добавить группу</div>
      <InputField
        noLabel
        label="Название"
        placeholder="Введите название группы"
        value={form.name.value}
        onChange={form.name.setValue}
        errors={form.name.errors}
      />
      <DropdownField
        label="Курс"
        placeholder="Выберите курс"
        noLabel
        selected={form.courseId.value}
        options={getCoursesOptions(courseStore.userCompanyCourses)}
        onChange={form.courseId.setValue}
        errors={form.courseId.errors}
      />
      <Button onClick={onSave} className={styles.button}>
        Создать
      </Button>
    </Modal>
  );
};

export default observer(AddGroupModal);
