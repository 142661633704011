import * as React from 'react';
import { observer } from 'mobx-react';

import { useCourseStore } from 'store';
import PageSpinner from 'components/common/Spinner/PageSpinner';
import CourseModel from 'store/models/course/CourseModel';
import CourseCard from 'components/containers/course/CourseCard';

import styles from './CourseList.modules.scss';

const CourseList: React.FC = () => {
  const courseStore = useCourseStore();

  return (
    <div>
      {courseStore.isLoading && <PageSpinner />}
      {courseStore.courses.length === 0 && !courseStore.isLoading && (
        <div className={styles.courseEmpty}>
          Пока нет доступных для вас курсов
        </div>
      )}
      {courseStore.courses.map((course: CourseModel) => (
        <div key={course.id} className={styles.course}>
          <CourseCard course={course} />
        </div>
      ))}
    </div>
  );
};

export default observer(CourseList);
