import { action, makeObservable, observable } from 'mobx';

import apiUrls from 'config/apiUrls';
import request from 'utils/request';

import { PendingCompanyUserType } from '../../stores/UserStore/types';
import { ServerResponse } from '../common/types';

import { StudentModel } from './StudentModel';
import { GroupServerType, StudentServerType } from './types';

type GroupType = {
  id: number;
  name: string;
  courseId?: number;
  courseTitle?: string;
};

export class GroupModel implements GroupType {
  id: number;
  name: string;
  courseId?: number;
  courseTitle?: string;

  isLoadingStudents = false;

  students: (StudentModel | PendingCompanyUserType)[] = [];
  total: number | null = null;

  constructor({ id, name, courseId, courseTitle }: GroupType) {
    makeObservable(this, {
      // observable
      isLoadingStudents: observable,
      students: observable,
      total: observable,
      // action
      loadStudents: action,
      addPendingStudent: action.bound,
    });
    this.id = id;
    this.name = name;
    this.courseId = courseId;
    this.courseTitle = courseTitle;
  }

  async loadStudents(companyId: number): Promise<any> {
    this.isLoadingStudents = true;

    const {
      response,
    }: ServerResponse<{
      users: StudentServerType[];
      total: number;
    }> = await request(apiUrls.company.listStudents, 'GET', {
      company: companyId,
      group: this.id,
      course: this.courseId,
      limit: 100,
    });

    if (response) {
      this.students = response.users.map(StudentModel.fromJson);
      this.total = response.total;
    }

    this.isLoadingStudents = false;
  }

  addPendingStudent(email: string): void {
    if (!this.students.find((s) => s.email === email)) {
      this.students.unshift({
        email,
        isActive: false,
      });
    }
  }

  static fromJson(data: GroupServerType): GroupModel {
    const course = data.courses?.[0];

    return new GroupModel({
      id: data.id,
      name: data.name,
      courseId: course?.id,
      courseTitle: course?.title,
    });
  }
}
