import { composeDecorators } from '@draft-js-plugins/editor';
import { ComponentType } from 'react';

import { ChunkTypeEnum } from 'store/models/chunks/types';
import EmbedChunk from 'components/Constructor/Chunks/EmbedChunk';
// import { decoratorAtomicInteraction } from '../../utils/decoratorAtomicInteraction';
// import { decoratorSelectOnFocus } from '../../utils/decoratorSelectOnFocus';

import AttachmentsChunk from '../AttachmentsChunk/AttachmentsChunk';
import CallbackTaskChunk from '../CallbackTaskChunk';
import CodeEditorChunk from '../CodeEditorChunk';
import HomeworkChunk from '../HomeworkChunk/HomeworkChunk';
import ImageChunk from '../ImageChunk/ImageChunk';
import MercuryTaskChunk from '../MercuryTaskChunk';
import QuizChunk from '../QuizChunk/QuizChunk';
import VideoChunk from '../VideoChunk/VideoChunk';
import CodeChunk from '../CodeChunk';

import withDraftJs from './withDraftJs';

export type DraftJsChunkCreatorConfig = {
  focusPlugin: any;
  alignmentPlugin: any;
  resizeablePlugin: any;
};

export const createDraftJsChunks = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  config: DraftJsChunkCreatorConfig
): Partial<Record<ChunkTypeEnum, ComponentType>> => ({
  [ChunkTypeEnum.image]: composeDecorators()(withDraftJs(ImageChunk, true)),
  // resizeablePlugin.decorator,
  // alignmentPlugin.decorator,
  // focusPlugin.decorator,
  // decoratorSelectOnFocus
  // decoratorAtomicInteraction
  [ChunkTypeEnum.video]: composeDecorators()(withDraftJs(VideoChunk, true)),
  // resizeablePlugin.decorator,
  // alignmentPlugin.decorator,
  // focusPlugin.decorator,
  // decoratorSelectOnFocus
  // decoratorAtomicInteraction
  [ChunkTypeEnum.attachments]: composeDecorators()(
    // resizeablePlugin.decorator,
    // alignmentPlugin.decorator,
    // focusPlugin.decorator,
    // decoratorSelectOnFocus
    // decoratorAtomicInteraction
    withDraftJs(AttachmentsChunk, true)
  ),
  [ChunkTypeEnum.homework]: composeDecorators()(withDraftJs(HomeworkChunk)),
  [ChunkTypeEnum.callbackTask]: composeDecorators()(
    withDraftJs(CallbackTaskChunk)
  ),
  [ChunkTypeEnum.quizGroup]: composeDecorators()(withDraftJs(QuizChunk, true)),
  [ChunkTypeEnum.codeTask]: composeDecorators()(withDraftJs(CodeEditorChunk)),
  [ChunkTypeEnum.mercuryTask]: composeDecorators()(
    withDraftJs(MercuryTaskChunk)
  ),
  [ChunkTypeEnum.code]: composeDecorators()(withDraftJs(CodeChunk)),
  [ChunkTypeEnum.embed]: composeDecorators()(withDraftJs(EmbedChunk, true)),
});
