import classNames from 'classnames';
import { observer } from 'mobx-react';
import { forwardRef } from 'react';
import * as React from 'react';

import CircleButton from 'components/common/BaseEdit/CircleButton';
import { CircleButtonTypeEnum } from 'components/common/BaseEdit/CircleButton/config/types';

import { IChunkContainer } from '../../models/chunkContainer/ChunkContainer';

import styles from './ConstructorSidebar.modules.scss';

type Props = {
  className?: string;
  unit: IChunkContainer;
};

const SidebarButton = forwardRef(
  ({ className, unit }: Props, ref: React.Ref<HTMLLabelElement>) => {
    if (!unit) {
      return null;
    }

    const openChunkCreation = () => {
      unit.toggleChunkCreationDrawer(true);
    };

    return (
      <CircleButton
        ref={ref}
        className={classNames(
          styles.plusButton,
          className,
          unit.showChunkCreation && styles.plusButtonOpened
        )}
        type={CircleButtonTypeEnum.plus}
        onClick={openChunkCreation}
      />
    );
  }
);

export default observer(SidebarButton);
