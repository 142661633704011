import { action, makeObservable, observable, toJS } from 'mobx';

import { AttachmentType, parseAttachment } from 'store/models/attachment';

import {
  HomeworkMessageServerType,
  HomeworkMessageTagEnum,
  HomeworkSystemMessageEnum,
} from '../types';

type HomeworkMessageType = {
  message: string | HomeworkSystemMessageEnum;
  tag: HomeworkMessageTagEnum | null;
  id?: number;
  userId?: number;
  attachment?: AttachmentType | null;
  sent?: Date;
  lastName?: string;
  firstName?: string;
  isMine?: boolean;
};

export class HomeworkMessageModel implements HomeworkMessageType {
  id?: number;

  userId?: number;

  attachment?: AttachmentType | null;

  message: string | HomeworkSystemMessageEnum;

  sent?: Date;

  tag: HomeworkMessageTagEnum | null;

  lastName?: string;

  firstName?: string;

  isMine?: boolean;

  constructor({
    id,
    attachment,
    message,
    lastName,
    firstName,
    sent,
    isMine,
    tag,
    userId,
  }: HomeworkMessageType) {
    makeObservable(this, {
      // observable
      attachment: observable,
      message: observable,
      lastName: observable,
      firstName: observable,
      isMine: observable,
      // action
      setMessage: action.bound,
      setAttachment: action.bound,
    });
    this.id = id;
    this.attachment = attachment;
    this.message = message;
    this.lastName = lastName;
    this.firstName = firstName;
    this.isMine = isMine;
    this.sent = sent;
    this.tag = tag;
    this.userId = userId;
  }

  setMessage(message: string): void {
    this.message = message;
  }

  setAttachment(attachment: AttachmentType | null): void {
    this.attachment = attachment;
  }

  toJson(): object {
    return toJS({
      message: this.message,
      attachment: this.attachment?.id,
    });
  }

  static createDefault(): HomeworkMessageModel {
    return new HomeworkMessageModel({
      message: '',
      tag: null,
    });
  }

  static fromJson(json: HomeworkMessageServerType): HomeworkMessageModel {
    return new HomeworkMessageModel({
      id: json.id,
      attachment: json.attachment ? parseAttachment(json.attachment) : null,
      message: json.message,
      lastName: json.last_name,
      firstName: json.first_name,
      isMine: json.is_mine,
      sent: new Date(json.sent),
      tag: json.tag,
      userId: json.user_id,
    });
  }
}
