import * as React from 'react';
import cn from 'classnames';

import styles from './Switcher.modules.scss';

export interface SwitcherProps {
  onToggle: () => void;
  isActive: boolean;
  className?: string;
  isDisabled?: boolean;
}

const Switcher: React.FC<SwitcherProps> = (props: SwitcherProps) => {
  const { onToggle, isActive, className, isDisabled } = props;

  return (
    <div
      className={cn(styles.root, isDisabled && styles.rootDisabled, className)}
      onClick={onToggle}
    >
      <div
        className={cn(
          styles.container,
          isActive && styles.containerActive,
          isDisabled && styles.containerDisabled
        )}
      />
      <div className={cn(styles.point, isActive && styles.pointActive)} />
    </div>
  );
};

Switcher.defaultProps = {
  isDisabled: false,
};

export default Switcher;
