import { action, computed, makeObservable } from 'mobx';

import { StudentsStore } from './stores/StudentsStore';
import { GlobalStore } from './stores/types';
import { UiStore } from './stores/UiStore/UiStore';
import { UserStore } from './stores/UserStore';
import { CourseStore } from './stores/CourseStore';
import { PaymentsStore } from './stores/PaymentsStore';

export class RootStore implements GlobalStore {
  userStore = new UserStore(this);
  courseStore = new CourseStore(this);
  studentsStore = new StudentsStore(this);
  paymentsStore = new PaymentsStore(this);
  uiStore = new UiStore();

  constructor() {
    makeObservable(this, {
      // computed
      isInited: computed,
      isIniting: computed,
      initError: computed,
      // action
      init: action,
      onLogin: action,
    });
  }

  get isInited(): boolean {
    return (
      this.userStore.isInited &&
      this.courseStore.isInited &&
      this.studentsStore.isInited
    );
  }

  get isIniting(): boolean {
    return !this.isInited && !this.initError;
  }

  get initError(): boolean {
    return (
      this.userStore.initError ||
      this.courseStore.initError ||
      this.studentsStore.initError
    );
  }

  async init(): Promise<any> {
    await this.userStore.init();
    await this.courseStore.init();
    await this.studentsStore.init();
  }

  async onLogin(): Promise<any> {
    await this.courseStore.init();
    await this.studentsStore.init();
  }
}

export default new RootStore();
