import * as React from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import { usePaymentsStore } from 'store/context';
import { useQuery } from 'utils/hooks/useQuery';
import { PaymentsType } from 'config/payments';
import PageSpinner from 'components/common/Spinner/PageSpinner/PageSpinner';
import GroupPaymentLayout from 'pages/GroupPayment/common/GroupPaymentLayout/GroupPaymentLayout';
import urls from 'config/urls';

import PaymentForm from './components/PaymentForm';
import styles from './GroupPayment.modules.scss';

const GroupPayment: React.FC = () => {
  const paymentsStore = usePaymentsStore();

  const { kind, group_id: groupId } = useQuery();
  const history = useHistory();

  React.useEffect(() => {
    const init = async () => {
      if (
        await paymentsStore.initPaymentForm(
          kind as PaymentsType,
          Number(groupId)
        )
      ) {
        history.push(
          urls.payment.groupReturn.create(paymentsStore.groupUserId)
        );
      } else {
        cogoToast.error(
          'Что-то пошло не так, проверьте ссылку на корректность или обратитесь в техническую поддержку'
        );
      }
    };

    init();
  }, [kind, groupId]);

  return (
    <GroupPaymentLayout>
      <div className={styles.wrapper}>
        {paymentsStore.isPaymentFormIsLoading ||
        paymentsStore.isPaymentFormIsError ? (
          <PageSpinner />
        ) : (
          <PaymentForm
            groupId={Number(groupId)}
            kind={kind as PaymentsType}
            amount={paymentsStore.amount}
            courses={paymentsStore.courses}
          />
        )}
      </div>
    </GroupPaymentLayout>
  );
};

export default observer(GroupPayment);
