import * as React from 'react';
import cn from 'classnames';

import CameraIcon from 'components/icons/lesson/CameraIcon';
import DocumentIcon from 'components/icons/lesson/DocumentIcon';
import CheckboxIcon from 'components/icons/lesson/CheckboxIcon';
import VideoIcon from 'components/icons/lesson/VideoIcon';
import PencilIcon from 'components/icons/lesson/PencilIcon';
import ExamIcon from 'components/icons/lesson/ExamIcon';
import { LessonTypeEnum } from 'store/models/lesson/types';

import styles from './LessonIcon.modules.scss';

const LessonTypeToComponent: {
  [type: string]: React.ElementType;
} = {
  [LessonTypeEnum.intro]: CameraIcon,
  [LessonTypeEnum.theory]: DocumentIcon,
  [LessonTypeEnum.test]: CheckboxIcon,
  [LessonTypeEnum.video]: VideoIcon,
  [LessonTypeEnum.practice]: PencilIcon,
  [LessonTypeEnum.final]: ExamIcon,
};

type Props = {
  type: LessonTypeEnum;
  className?: string;
};

const LessonIcon: React.FC<Props> = (props: Props) => {
  const { type, className } = props;

  const componentProps = {
    className: cn(styles.root, className),
  };

  const Component: React.ElementType = LessonTypeToComponent[type];

  return <Component {...componentProps} />;
};

export default React.memo(LessonIcon);
