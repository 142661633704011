import { action, computed, makeObservable, observable } from 'mobx';
import arrayMove from 'array-move';

import { ChunkModel } from 'store/models/chunks/ChunkModel';
import {
  AnyChunkType,
  ChunkTypeEnum,
  IChunkModel,
} from 'store/models/chunks/types/chunkTypes';
import { ShiftPositionEnum } from 'store/models/common/types';
import { swapArrayElements } from 'utils/array';

export interface IChunkContainer {
  chunks: ChunkModel[];
  isEditMode: boolean;
  showChunkCreation: boolean;
  selectedChunkId: string | null;
  disabledChunksToCreate: ChunkTypeEnum[];

  toggleEditMode(): void;
  addChunk(chunkType: AnyChunkType): void;
  selectChunk(chunk: ChunkModel): void;
  dropChunkSelection(chunkId: string): void;
  deleteSelectedChunk(): void;
  swapChunks(chunkId1: string, chunkId2: string): void;
  shiftChunk(chunkId: string, position: ShiftPositionEnum): void;
  insertChunk(chunkId: string, position: ShiftPositionEnum): void;
  // toggleChunkEditDrawer(): void;
  toggleChunkCreationDrawer(isOpen: boolean): void;
}

export abstract class ChunkContainer<C extends IChunkModel = any>
  implements IChunkContainer
{
  selectedChunk: ChunkModel | null = null;
  chunkCreationIndex: number | null = null;
  showChunkCreation = false;
  isEditMode = true;

  chunks: ChunkModel<C>[] = [];

  constructor() {
    makeObservable(this, {
      //observable
      selectedChunk: observable,
      chunkCreationIndex: observable,
      showChunkCreation: observable,
      isEditMode: observable,
      chunks: observable,
      //computed
      selectedChunkId: computed,
      //action
      toggleChunkCreationDrawer: action,
      closeChunkCreationDrawer: action,
      toggleEditMode: action,
      onSortChunks: action,
      swapChunks: action,
      addChunk: action,
      shiftChunk: action,
      selectChunk: action,
      insertChunk: action,
      // toggleChunkEditDrawer: action,
      closeChunkEditDrawer: action,
      dropChunkSelection: action,
      deleteSelectedChunk: action,
      deleteChunk: action,
    });
  }

  /* Чанки, которые нельзя создать, например ДЗ + тест одновременно */
  get disabledChunksToCreate(): ChunkTypeEnum[] {
    return [];
  }

  get selectedChunkId(): string | null {
    return this.selectedChunk?.id || null;
  }

  toggleChunkCreationDrawer(isOpen: boolean): void {
    this.showChunkCreation = isOpen;
  }

  closeChunkCreationDrawer(): void {
    this.toggleChunkCreationDrawer(false);
    this.chunkCreationIndex = null;
  }

  toggleEditMode(): void {
    this.isEditMode = !this.isEditMode;
  }

  onSortChunks({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }): void {
    this.chunks = arrayMove(this.chunks, oldIndex, newIndex);
  }

  swapChunks(chunkId1: string, chunkId2: string): void {
    const indexToInsert = this.chunks.findIndex((c) => c.id === chunkId1);
    const chunkToMove = this.chunks.find((c) => c.id === chunkId2);
    const indexToDelete = this.chunks.findIndex((c) => c.id === chunkId2);
    if (indexToInsert >= 0 && chunkToMove) {
      this.chunks.splice(indexToInsert, 0, chunkToMove);
      if (indexToDelete > indexToInsert) {
        this.chunks.splice(indexToDelete + 1, 1);
      } else {
        this.chunks.splice(indexToDelete, 1);
      }
    }
  }

  abstract generateChunksByType(chunkType: AnyChunkType): ChunkModel[];

  addChunk(chunkType: AnyChunkType = ChunkTypeEnum.text): ChunkModel[] {
    if (this.disabledChunksToCreate.includes(chunkType as ChunkTypeEnum)) {
      return [];
    }

    const newChunks = this.generateChunksByType(chunkType);

    if (this.chunkCreationIndex !== null && this.chunkCreationIndex >= 0) {
      this.chunks.splice(this.chunkCreationIndex, 0, ...newChunks);
      this.chunkCreationIndex = null;
    } else {
      this.chunks.push(...newChunks);
    }

    return newChunks;
  }

  shiftChunk(chunkId: string, position: ShiftPositionEnum): void {
    const chunkIndex = this.chunks.findIndex((c) => c.id === chunkId);
    if (chunkIndex >= 0) {
      this.chunks = swapArrayElements(
        this.chunks,
        chunkIndex,
        position === ShiftPositionEnum.left ? chunkIndex - 1 : chunkIndex + 1
      );
    }
  }

  selectChunk(chunk: ChunkModel): void {
    this.selectedChunk = chunk;
  }

  /* Обработка клика на + у чанка */

  insertChunk(chunkId: string, position: ShiftPositionEnum): void {
    let index = this.chunks.findIndex((c) => c.id === chunkId);
    if (index === -1) {
      return;
    }

    if (position === ShiftPositionEnum.right) {
      index += 1;
    }

    this.chunkCreationIndex = index;
    this.toggleChunkCreationDrawer(true);
  }

  // toggleChunkEditDrawer(): void {}

  closeChunkEditDrawer(): void {
    this.dropChunkSelection(this.selectedChunkId);
  }

  dropChunkSelection(chunkId: string | null): void {
    if (chunkId === this.selectedChunkId) {
      this.selectedChunk = null;
    }
  }

  deleteSelectedChunk(): void {
    if (this.selectedChunkId) {
      this.chunks = this.chunks.filter((c) => c.id !== this.selectedChunkId);
      this.closeChunkEditDrawer();
      this.dropChunkSelection(this.selectedChunkId);
    }
  }

  deleteChunk(id: string): void {
    this.chunks = this.chunks.filter((c) => c.id !== id);
  }
}
