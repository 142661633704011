import * as propTypes from 'prop-types';
import * as React from 'react';

const AlignCenterHorizontal = (props) => {
  return (
    <svg
      width="24px"
      height="28px"
      viewBox="0 0 24 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="s"
          transform="translate(12.000000, 14.000000) rotate(-90.000000) translate(-12.000000, -14.000000) translate(-2.000000, 2.000000)"
          fill="currentColor"
        >
          <path
            d="M28,11 L25,11 L25,2 L15,2 L15,11 L13,11 L13,0 L3,0 L3,11 L0,11 L0,13 L3,13 L3,24 L13,24 L13,13 L15,13 L15,23 L25,23 L25,13 L28,13 L28,11 Z M11,22 L5,22 L5,2 L11,2 L11,22 Z M23,21 L17,21 L17,4 L23,4 L23,21 Z"
            id="Shape"
          />
        </g>
      </g>
    </svg>
  );
};

AlignCenterHorizontal.propTypes = {
  className: propTypes.string.isRequired,
};

AlignCenterHorizontal.defaultProps = {};

export default AlignCenterHorizontal;
