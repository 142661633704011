import { action, computed, makeObservable, observable } from 'mobx';

import apiUrls from 'config/apiUrls';
import { AttachmentType, parseAttachment } from 'store/models/attachment';
import {
  ChapterServerType,
  LessonListResponseType,
} from 'store/models/chapter/types';
import { ServerResponse, ShiftPositionEnum } from 'store/models/common/types';
import { CourseCreationFormModel } from 'store/models/course/CourseCreationFormModel';
import {
  CourseInnerServerType,
  CourseProgressStatus,
  CourseServerType,
} from 'store/models/course/types';
import { getSwapIndexes } from 'utils/getSwapIndexes';
import { swapArrayElements } from 'utils/array';
import request from 'utils/request';

import { ChapterModel } from '../chapter/ChapterModel';
import { CompanyModel } from '../../stores/UserStore/types';
import { LessonUnitType } from '../lesson/types';
import { normalizeLessonUnit } from '../lesson/utils';

import CourseInnerModel, { CourseInnerType } from './CourseInnerModel';

export type CourseModelType = {
  id: number;
  title: string;
  description: string;
  background: AttachmentType | null;
  progressStatus: CourseProgressStatus;
  chapters: ChapterModel[];
  company: CompanyModel;
  isPublished: boolean;
  oneDeadline: boolean;

  firstUnit?: LessonUnitType;
  inner?: CourseInnerType;
};

class CourseModel implements CourseModelType {
  id: number;

  isDeletingChapter = false;
  title: string;
  description: string;
  background: AttachmentType | null = null;
  progressStatus: CourseProgressStatus;
  chapters: ChapterModel[];
  company: CompanyModel;
  isPublished: boolean;
  oneDeadline: boolean;

  firstUnit?: LessonUnitType;
  inner?: CourseInnerModel;

  constructor({
    id,
    title,
    description,
    background,
    inner,
    chapters,
    firstUnit,
    isPublished,
    progressStatus,
    company,
    oneDeadline,
  }: CourseModelType) {
    makeObservable(this, {
      // observable
      id: observable,
      isDeletingChapter: observable,
      title: observable,
      description: observable,
      background: observable,
      progressStatus: observable,
      chapters: observable,
      company: observable,
      isPublished: observable,
      firstUnit: observable,
      inner: observable,
      oneDeadline: observable,
      // computed
      isStarted: computed,
      totalLessonsCount: computed,
      totalChaptersCount: computed,
      // action
      addChapter: action.bound,
      deleteChapter: action,
      shiftChapter: action.bound,
      updateFrom: action,
    });
    this.id = id;
    this.title = title;
    this.description = description;
    this.background = background;
    this.inner = inner;
    this.chapters = chapters;
    this.firstUnit = firstUnit;
    this.progressStatus = progressStatus;
    this.company = company;
    this.isPublished = isPublished;
    this.oneDeadline = oneDeadline;
  }

  get isStarted(): boolean {
    return (
      [CourseProgressStatus.inProgress, CourseProgressStatus.finished].indexOf(
        this.progressStatus
      ) > -1
    );
  }

  get totalLessonsCount(): number {
    return (
      this.chapters?.reduce((acc, c) => acc + c.lessons.length, 0) ||
      this.inner?.lessonsCount ||
      0
    );
  }

  get totalChaptersCount(): number {
    return this.chapters?.length || this.inner?.chaptersCount || 0;
  }

  addChapter(chapter: ChapterModel): void {
    if (this.chapters) {
      this.chapters.push(chapter);
    }
  }

  async deleteChapter(id: number): Promise<boolean> {
    this.isDeletingChapter = true;

    const { response }: ServerResponse = await request(
      apiUrls.chapter.delete,
      'POST',
      {
        id,
      }
    );

    if (response) {
      this.chapters = this.chapters?.filter((c) => c.id !== id);
    }

    this.isDeletingChapter = false;

    return Boolean(response);
  }

  async shiftChapter(
    direction: ShiftPositionEnum,
    chapter: ChapterModel
  ): Promise<void> {
    const swapIndexes = getSwapIndexes(direction, this.chapters, chapter);
    if (!swapIndexes) {
      return;
    }

    const [index, indexToSwap] = swapIndexes;

    this.chapters = swapArrayElements(this.chapters, index, indexToSwap);

    const { error } = await request(apiUrls.chapter.swap, 'POST', {
      id1: this.chapters[index].id,
      id2: this.chapters[indexToSwap].id,
    });

    if (error) {
      this.chapters = swapArrayElements(this.chapters, index, indexToSwap);
    }
  }

  static async load(id: number): Promise<CourseModel | null> {
    const { response }: ServerResponse<CourseServerType> = await request(
      apiUrls.course.get,
      'GET',
      { id }
    );

    if (!response) {
      return null;
    }

    const course: CourseModel = CourseModel.fromJson(response);

    const lessonsListResult: ServerResponse<LessonListResponseType> =
      await request(apiUrls.lesson.list, 'GET', {
        course_id: id,
      });
    if (lessonsListResult.response) {
      const { lessons } = lessonsListResult.response;
      course.chapters.forEach((chapter) => {
        chapter.initLessons(lessons.filter((l) => l.chapter_id === chapter.id));
      });
    }

    return course;
  }

  updateFrom(form: CourseCreationFormModel): void {
    this.title = form.values.title;
    this.description = form.values.description;
    this.background = form.values.background;
    this.isPublished = form.values.isPublished;
    this.oneDeadline = form.values.oneDeadline;

    if (this.inner) {
      this.inner.pointPerTest = form.values.pointPerTest;
      this.inner.pointPerUnit = form.values.pointPerUnit;
    }
  }

  static fromForm(
    form: CourseCreationFormModel,
    company: CompanyModel
  ): CourseModel | null {
    const { title, description, background, isPublished, oneDeadline } =
      form.values;

    return new CourseModel({
      id: form.id as number,
      title,
      description,
      background,
      progressStatus: CourseProgressStatus.notStarted,
      chapters: [],
      company,
      isPublished,
      oneDeadline,
    });
  }

  static fromJson(json: CourseServerType): CourseModel {
    const course = new CourseModel({
      id: json.id,
      title: json.title,
      description: json.description,
      progressStatus: json.progress_status,
      firstUnit: json.first_unit
        ? normalizeLessonUnit(json.first_unit)
        : undefined,
      background: json.background_image
        ? parseAttachment(json.background_image)
        : null,
      inner: CourseInnerModel.fromJson(
        json.inner || ({} as CourseInnerServerType)
      ),
      chapters: [],
      company: json.companies[0],
      isPublished: json.is_published,
      oneDeadline: json.one_deadline,
    });

    course.chapters = (json.chapters || []).map(
      (chapterJson: ChapterServerType) =>
        ChapterModel.fromJson(chapterJson, course)
    );

    return course;
  }
}

export default CourseModel;
