import classNames from 'classnames';

import { ChunkAlignEnum } from 'store/models/chunks/types';

import stylesVerticalAligns from './styles/verticalAligns.modules.scss';
import stylesHorizontalAligns from './styles/horizontalAligns.modules.scss';

export const getAlignClassNames = (
  verticalAlign?: ChunkAlignEnum,
  horizontalAlign?: ChunkAlignEnum | null
): string =>
  classNames([
    verticalAlign === ChunkAlignEnum.top && stylesVerticalAligns.top,
    verticalAlign === ChunkAlignEnum.center && stylesVerticalAligns.center,
    verticalAlign === ChunkAlignEnum.bottom && stylesVerticalAligns.bottom,

    horizontalAlign === ChunkAlignEnum.left && stylesHorizontalAligns.left,
    horizontalAlign === ChunkAlignEnum.center && stylesHorizontalAligns.center,
    horizontalAlign === ChunkAlignEnum.right && stylesHorizontalAligns.right,

    horizontalAlign && stylesHorizontalAligns.horizontalAlignWrapper,
  ]);
