import * as React from 'react';
import cn from 'classnames';

import Link from './Link';
import Arrow from './Arrow';
import styles from './NavigationPath.modules.scss';

type Props = {
  pathItems: Array<{
    text: string;
    url: string;
  }>;
  className?: string;
};

const NavigationPath: React.FC<Props> = (props: Props) => {
  const { pathItems, className } = props;

  return (
    <div className={cn(styles.root, className)}>
      {pathItems.map((pathItemData, index) => (
        <React.Fragment key={index}>
          <Link text={pathItemData.text} url={pathItemData.url} />
          {index < pathItems.length - 1 && <Arrow />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default React.memo(NavigationPath);
