import cn from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';

import urls from 'config/urls';
import BaseCard from 'components/common/BaseCard';
import Tag from 'components/common/Tag/Tag';
import { useUserStore } from 'store/context';
import {
  HomeworkStatus,
  HomeworkStatusText,
} from 'store/models/chunks/homework/types';
import { HomeworkItemModel } from 'store/models/homework/HomeworkItemModel';
import { AssignedUser } from 'store/models/homework/types';

import styles from './HomeworkItem.modules.scss';

type Props = {
  item: HomeworkItemModel;
};

const HomeworkItem: React.FC<Props> = ({ item }: Props) => {
  const user = useUserStore();

  return (
    <BaseCard className={styles.wrapper}>
      <div
        className={cn(styles.status, {
          [styles.statusNotStarted]:
            item.status === HomeworkStatus.notStarted ||
            item.status === HomeworkStatus.checking,
          [styles.statusRejected]: item.status === HomeworkStatus.rejected,
          [styles.statusAccepted]: item.status === HomeworkStatus.finished,
        })}
      >
        {HomeworkStatusText[item.status]}
      </div>
      <div className={styles.title}>
        <Link
          to={urls.lessonCard(item.lessonId, item.unitId)}
          className={styles.title}
        >
          {item.courseTitle}. {item.lessonTitle}: {item.homeworkChunk.title}
        </Link>
      </div>
      <div className={styles.user}>
        <div>
          <div className={styles.userName}>
            {item.email} <br />
            {item.firstName} {item.lastName}
          </div>
          {item.assigned && (
            <div className={styles.assigned}>
              назначено на {item.assigned.first_name} {item.assigned.last_name}
            </div>
          )}
        </div>
        <div>
          {user.isAdmin && user.id !== item.assigned?.id && (
            <Tag
              onClick={() => item.takeForMe(user.userData as AssignedUser)}
              className={styles.takeForMe}
            >
              Назначить мне
            </Tag>
          )}
          <Tag
            tag={Link}
            to={urls.lessonHomeworkChat(
              item.lessonId,
              item.unitId,
              item.homeworkId,
              item.conversationId
            )}
            className={styles.link}
          >
            {isMobile ? 'чат' : 'Перейти в чат'}
          </Tag>
        </div>
      </div>
    </BaseCard>
  );
};

export default observer(HomeworkItem);
