import { observable, action, override, makeObservable } from 'mobx';

import { Form } from 'store/models/common/Form';
import { YandexPaymentModel } from 'store/models/payments/yandex/YandexPaymentModel';
import {
  getPaymentCredentials,
  updatePaymentCredentials,
} from 'store/models/payments/utils';
import { composeValidators, notEmptyValidator } from 'utils/validation';
import { PaymentsType } from 'config/payments';
import { ServerResponse } from 'store/models/common/types';

import { BasePaymentUpdateApiRequest, PaymentsError } from '../types';

import { YandexPaymentApiResponse } from './types';

export enum YandexPaymentFieldsEnum {
  shopID = 'shopID',
  secretKey = 'secretKey',
}

export type YandexPaymentFields = {
  shopID: string;
  secretKey: string;
};

export class YandexPaymentCreationFormModel extends Form<YandexPaymentFields> {
  initialValues: YandexPaymentFields = {
    shopID: '',
    secretKey: '',
  };

  type = PaymentsType.yandex;

  isLoading = false;
  isError = false;
  isEditable = false;
  isSuccessLoaded = false;
  isInitial = false;

  validators = {
    shopID: composeValidators(notEmptyValidator()),
    secretKey: composeValidators(notEmptyValidator()),
  };

  constructor() {
    super();
    makeObservable(this, {
      // observable
      isError: observable,
      isLoading: observable,
      isSuccessLoaded: observable,
      isEditable: observable,
      isInitial: observable,
      // action
      setValues: action,
      load: action.bound,
      // override
      save: override,
      clear: override,
    });
  }

  async save(company: number): Promise<YandexPaymentModel | boolean> {
    this.validate();

    if (this.hasErrors) {
      return false;
    }

    this.isSaving = true;
    this.isSuccessLoaded = false;

    try {
      const { response }: ServerResponse<YandexPaymentApiResponse> =
        await updatePaymentCredentials<YandexPaymentApiResponse>({
          company,
          kind: this.type,
          shop_id: this.values.shopID,
          secret_key: this.values.secretKey,
        } as BasePaymentUpdateApiRequest);

      if (response) {
        this.isSaving = false;
        this.isSuccessLoaded = true;
        return new YandexPaymentModel(response);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }

    this.isSaving = false;
    return false;
  }

  clear(): void {
    this.values = { shopID: '', secretKey: '' };
  }

  setValues(data: YandexPaymentApiResponse): void {
    this.values = {
      shopID: data.shop_id,
      secretKey: data.secret_key,
    };
  }

  async load(company: number): Promise<void> {
    this.isError = false;
    this.isLoading = true;
    this.isInitial = false;

    try {
      const { response, error }: ServerResponse<YandexPaymentApiResponse> =
        await getPaymentCredentials<YandexPaymentApiResponse>(
          company,
          this.type
        );
      if (error) {
        const notFoundMethodError =
          error.data.status === PaymentsError.notFoundPaymentMethod;
        this.isError = !notFoundMethodError;

        if (notFoundMethodError) {
          this.isInitial = true;
        }
      } else if (response) {
        this.isEditable = true;
        this.setValues(response);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      this.isError = true;
    }

    this.isLoading = false;
  }
}
