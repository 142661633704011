import * as React from 'react';

import ContentLayout from 'components/common/ContentLayout';

import styles from './ProfileContent.modules.scss';

type Props = {
  children: React.ReactNode;
};

const ProfileContent: React.FC<Props> = ({ children }: Props) => {
  return <ContentLayout className={styles.root}>{children}</ContentLayout>;
};

export default React.memo(ProfileContent);
