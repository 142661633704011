import * as React from 'react';
import { useCallback } from 'react';
import cn from 'classnames';

import AlignCenterHorizontal from 'components/icons/BlockAlign/AlignCenterHorizontal';
import AlignCenter from 'components/icons/BlockAlign/AlignCenter';
import AlignBottom from 'components/icons/BlockAlign/AlignBottom';
import AlignTop from 'components/icons/BlockAlign/AlignTop';
import AlignRight from 'components/icons/BlockAlign/AlignRight';
import AlignLeft from 'components/icons/BlockAlign/AlignLeft';
import { ChunkAlignEnum } from 'store/models/chunks/types';

import styles from './LayoutEdit.modules.scss';

type Props = {
  align: ChunkAlignEnum;
  icon?: ChunkAlignEnum;
  selectedAlign: ChunkAlignEnum | null;
  onSelect: (align: ChunkAlignEnum) => void;
};

const mapAlignToIcon: Partial<Record<ChunkAlignEnum, React.ComponentType>> = {
  [ChunkAlignEnum.top]: AlignTop,
  [ChunkAlignEnum.center]: AlignCenter,
  [ChunkAlignEnum.centerHorizontal]: AlignCenterHorizontal,
  [ChunkAlignEnum.bottom]: AlignBottom,
  [ChunkAlignEnum.left]: AlignLeft,
  [ChunkAlignEnum.right]: AlignRight,
};

const AlignIcon: React.FC<Props> = ({
  align,
  icon,
  onSelect,
  selectedAlign,
}: Props) => {
  const handleSelect = useCallback(() => {
    onSelect(align);
  }, [align, onSelect]);

  const Component = (
    icon !== undefined ? mapAlignToIcon[icon] : mapAlignToIcon[align]
  ) as any;

  const isActive = selectedAlign === align;

  return (
    <Component
      onClick={handleSelect}
      className={cn(styles.alignIcon, isActive && styles.alignActive)}
    />
  );
};

export default React.memo(AlignIcon);
