import { CSSProperties, forwardRef, Ref } from 'react';
import * as React from 'react';
import cn from 'classnames';

import ArrowRightIcon from 'components/icons/ArrowRightIcon';
import PlusIcon from 'components/icons/PlusIcon';
import CloseIcon from 'components/icons/CloseIconV2';
import EditIcon from 'components/icons/EditIcon';
import TrashIcon from 'components/icons/TrashIcon';
import SaveIcon from 'components/icons/SaveIcon';
import noop from 'utils/noop';

import { CircleButtonTypeEnum } from './config/types';
import { CircleButtonColorEnum } from './config/colors';
import styles from './styles/CircleButton.modules.scss';
import backgroundStyles from './styles/CircleButtonBackground.modules.scss';

type Props = {
  className?: string;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  type: CircleButtonTypeEnum;
  color?: CircleButtonColorEnum;
  children?: React.ReactNode;
  style?: CSSProperties;
  [key: string]: any;
};

const CircleButton = forwardRef((props: Props, ref: Ref<HTMLLabelElement>) => {
  const { className, onClick, type, children, color, style, ...rest } = props;

  return (
    <label
      ref={ref}
      style={style}
      className={cn(
        styles.root,
        className,
        color === CircleButtonColorEnum.blue && backgroundStyles.blue,
        color === CircleButtonColorEnum.red && backgroundStyles.red,
        color === CircleButtonColorEnum.green && backgroundStyles.green,
        color === CircleButtonColorEnum.purple && backgroundStyles.purple
      )}
      onClick={onClick}
      {...rest}
    >
      {children}
      {type === CircleButtonTypeEnum.arrowLeft && (
        <ArrowRightIcon className={cn(styles.icon, styles.iconLeft)} />
      )}
      {type === CircleButtonTypeEnum.arrowRight && (
        <ArrowRightIcon className={styles.icon} />
      )}
      {type === CircleButtonTypeEnum.arrowTop && (
        <ArrowRightIcon className={cn(styles.icon, styles.iconTop)} />
      )}
      {type === CircleButtonTypeEnum.arrowBottom && (
        <ArrowRightIcon className={cn(styles.icon, styles.iconBottom)} />
      )}
      {type === CircleButtonTypeEnum.plus && (
        <PlusIcon className={cn(styles.icon)} />
      )}
      {type === CircleButtonTypeEnum.close && (
        <CloseIcon className={cn(styles.icon)} />
      )}
      {type === CircleButtonTypeEnum.edit && (
        <EditIcon className={cn(styles.iconBig)} />
      )}
      {type === CircleButtonTypeEnum.trash && (
        <TrashIcon className={cn(styles.iconBig)} />
      )}
      {type === CircleButtonTypeEnum.save && (
        <SaveIcon className={cn(styles.iconBig)} />
      )}
    </label>
  );
});

CircleButton.defaultProps = {
  color: CircleButtonColorEnum.blue,
  children: null,
  onClick: noop,
};

export default CircleButton;
