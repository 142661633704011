import { observer } from 'mobx-react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import urls from 'config/urls';
import useForm from 'components/form/hooks/useForm';
import Button from 'components/common/Button';
import { WhiteCard } from 'components/common/BaseCard';
import { useRootStore } from 'store/context';
import { useLocal } from 'utils/hooks/useLocal';
import { InputField } from 'components/form';

import {
  RegistrationForm,
  RegistrationFormFields,
} from './store/RegistrationForm';
import styles from './Registration.modules.scss';

const Registration: React.FC = () => {
  const rootStore = useRootStore();
  const history = useHistory();
  const formStore = useLocal(() => new RegistrationForm());
  const form = useForm(formStore, Object.values(RegistrationFormFields));

  const login = async () => {
    history.push(urls.courses);
    await rootStore.init();
  };

  return (
    <div className={styles.root}>
      <WhiteCard className={styles.modal}>
        {formStore.registrationSuccess ? (
          <div className={styles.success}>
            Спасибо за&nbsp;регистрацию, на&nbsp;вашу почту{' '}
            <b>{form.email.value}</b> отправлено письмо с&nbsp;данными для
            авторизации на&nbsp;платформе.
            <Button className={styles.submit} onClick={login}>
              Начать создавать курсы
            </Button>
          </div>
        ) : (
          <div className={styles.form}>
            <div className={styles.formInfo}>
              Пожалуйста, зарегистрируйтесь на&nbsp;платформе, чтобы получить
              доступ к&nbsp;созданию курсов:
            </div>
            <InputField
              placeholder="Имя"
              value={form.firstName.value}
              onChange={form.firstName.setValue}
              errors={form.firstName.errors}
            />
            <InputField
              placeholder="Фамилия"
              value={form.lastName.value}
              onChange={form.lastName.setValue}
              errors={form.lastName.errors}
            />
            <InputField
              placeholder="Email"
              value={form.email.value}
              onChange={form.email.setValue}
              errors={form.email.errors}
              type="email"
            />
            <InputField
              placeholder="Телефон"
              value={form.phone.value}
              onChange={form.phone.setValue}
              errors={form.phone.errors}
              type="phone"
            />
            <InputField
              placeholder="Название вашей компании"
              value={form.companyName.value}
              onChange={form.companyName.setValue}
              errors={form.companyName.errors}
            />
            <Button
              className={styles.submit}
              onClick={formStore.save}
              isLoading={formStore.isSaving}
            >
              Получить доступ
            </Button>
          </div>
        )}
      </WhiteCard>
    </div>
  );
};

export default observer(Registration);
