import * as React from 'react';
import { observer } from 'mobx-react';

import BaseEdit from 'components/common/BaseEdit';
import { useCourseContext } from 'components/containers/course/context';
import { useModal } from 'components/form/hooks/useModal';
import { ShiftPositionEnum } from 'store/models/common/types';
import { LessonModel } from 'store/models/lesson/LessonModel';
import LessonCreationModal from 'components/containers/course/LessonCreationModal/LessonCreationModal';

type Props = {
  lesson: LessonModel;
};

const LessonAdmin: React.FC<Props> = ({ lesson }: Props) => {
  const { isEditMode, isAdmin } = useCourseContext();

  const [showEditModal, toggleEditModal] = useModal();

  const handleShiftBefore = () =>
    lesson.chapter?.shiftLesson(ShiftPositionEnum.up, lesson);

  const handleShiftAfter = () =>
    lesson.chapter?.shiftLesson(ShiftPositionEnum.down, lesson);

  if (!isEditMode || !isAdmin) {
    return null;
  }

  return (
    <>
      <BaseEdit
        isSelected={isEditMode}
        onEdit={toggleEditModal}
        isEditOnly={false}
        onShiftBefore={handleShiftBefore}
        onShiftAfter={handleShiftAfter}
      />
      {lesson.chapter && showEditModal && (
        <LessonCreationModal
          resetOnOpen
          lessonModel={lesson}
          chapter={lesson.chapter}
          isOpen={showEditModal}
          onClose={toggleEditModal}
          title="Редактировать урок"
        />
      )}
    </>
  );
};

export default observer(LessonAdmin);
