import * as React from 'react';

type Props = {
  className?: string;
};

const HomeworkSvg: React.FC<Props> = ({ className }: Props) => {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H22V18H0V13.7149L12 13.7149V12.2149L0 12.2149V9.71495L12 9.71495V8.21495L0 8.21495V5.71495L12 5.71495V4.21495L0 4.21495V0ZM19.3536 3.35355L16.8536 5.85355L16.5 6.20711L16.1464 5.85355L14.6464 4.35355L15.3536 3.64645L16.5 4.79289L18.6464 2.64645L19.3536 3.35355ZM16.8205 10.2496L19.3205 7.74961L18.6134 7.0425L16.467 9.18895L15.3205 8.0425L14.6134 8.74961L16.1134 10.2496L16.467 10.6032L16.8205 10.2496ZM19.3536 12.1455L16.8536 14.6455L16.5 14.9991L16.1464 14.6455L14.6464 13.1455L15.3536 12.4384L16.5 13.5849L18.6464 11.4384L19.3536 12.1455Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default React.memo(HomeworkSvg);
