import * as React from 'react';
import cn from 'classnames';

import Blurable from 'components/common/Blurable';
import Label from 'components/form/Label';
import { WithFieldProps } from 'components/form/Field/types';
import Portal from 'components/common/Portal';

import commonStyles from '../styles/common.modules.scss';

import styles from './Dropdown.modules.scss';

export type DropdownOption<T = any> = {
  key: T;
  label: string;
};

type Props<T = any> = WithFieldProps & {
  options: DropdownOption<T>[];
  selected: T | null;
  onChange: (key: T) => void;
  classNameField?: string;
  classNameArrow?: string;
  classNameOptions?: string;
  placeholder?: string;
  noLabel?: boolean;
  noBorder?: boolean;
};

type State = {
  isOpened: boolean;
  optionsStyle: object;
};

class Dropdown extends React.Component<Props, State> {
  static defaultProps = {
    noLabel: false,
    noBorder: false,
    classNameOptions: '',
    classNameArrow: '',
    classNameField: '',
  };

  state = {
    isOpened: false,
    optionsStyle: {},
  };

  ref = React.createRef<HTMLDivElement>();

  get label(): string {
    const { options, selected, placeholder = '' } = this.props;
    const item = options.find(
      (item) => item.key.toString() === (selected && selected.toString())
    );
    return item ? item.label : placeholder;
  }

  toggle = (value: boolean): void => this.setState({ isOpened: value });

  open = (): void => {
    if (this.state.isOpened) {
      return this.close();
    }

    const root = this.ref.current;

    if (!root) {
      return;
    }

    const dimensions = root.getBoundingClientRect();
    const style: any = {
      width: dimensions.width,
      left: Math.min(
        dimensions.left,
        document.body.clientWidth - dimensions.width
      ),
      top: dimensions.top + dimensions.height + 10,
    };

    if (dimensions.top > window.innerHeight) {
      style.bottom = window.innerHeight - dimensions.top;
      style.top = 'unset';
    }

    this.setState({
      optionsStyle: style,
    });

    this.toggle(true);
  };

  close = (): void => {
    this.toggle(false);
  };

  handleCheck =
    (key: string | number) =>
    (e: React.MouseEvent<HTMLDivElement>): void => {
      const { isOpened } = this.state;
      if (!isOpened) {
        return;
      }
      const { onChange } = this.props;
      e.stopPropagation();

      onChange(key);
      this.close();
    };

  render(): React.ReactNode {
    const {
      placeholder,
      options,
      selected,
      classNameArrow = '',
      classNameField = '',
      classNameOptions = '',
      noLabel,
      noBorder,
    } = this.props;
    const { isOpened, optionsStyle } = this.state;

    return (
      <Blurable onBlur={this.close}>
        <div
          className={cn(
            commonStyles.inputWrapper,
            commonStyles.input,
            noBorder && commonStyles.inputNoBorder
          )}
          onClick={this.open}
          ref={this.ref}
        >
          {!noLabel && (
            <Label isFocused={isOpened} isUp={Boolean(selected)}>
              {placeholder}
            </Label>
          )}
          <div className={cn(styles.field, classNameField)}>{this.label}</div>
          <div
            className={cn(
              styles.arrow,
              noBorder && styles.arrowNoBorder,
              isOpened && styles.arrowOpen,
              classNameArrow
            )}
          />

          <Portal>
            <div
              className={cn(
                styles.options,
                isOpened && styles.optionsOpened,
                classNameOptions
              )}
              style={optionsStyle}
            >
              {options.map((item) => (
                <div
                  key={item.key}
                  className={styles.item}
                  onClick={this.handleCheck(item.key)}
                >
                  {item.label}
                </div>
              ))}
            </div>
          </Portal>
        </div>
      </Blurable>
    );
  }
}

export default Dropdown;
