import { useEffect, useState } from 'react';
import * as React from 'react';
import { observer } from 'mobx-react';

import Button from 'components/common/Button';
import PageSpinner from 'components/common/Spinner/PageSpinner';
import FullSideSpinner from 'components/common/Spinner/FullSideSpinner';
import ContentLayout from 'components/common/ContentLayout';
import { useCourseStore, useUserStore } from 'store/context';

import Filters from './Filters';
import HomeworkItem from './HomeworkItem';
import { HomeworkStore } from './HomeworkStore';
import styles from './Homework.modules.scss';

const Homework: React.FC = () => {
  const coursesStore = useCourseStore();
  const user = useUserStore();

  const [homeworkStore] = useState(() => new HomeworkStore());

  const fetch = async (): Promise<void> => {
    homeworkStore.clearList();
    await homeworkStore.loadMore();
  };

  useEffect(() => {
    homeworkStore.initCourses(coursesStore.userCompanyCourses);
  }, []);

  useEffect(() => {
    fetch();
  }, [
    homeworkStore.selectedStatuses,
    homeworkStore.selectedCourses,
    homeworkStore.selectedGroup,
    homeworkStore.onlyMine,
    homeworkStore.isDeadline,
    homeworkStore.selectedHomework,
  ]);

  if (!homeworkStore.isCoursesLoaded) {
    return <FullSideSpinner isOpen />;
  }

  return (
    <ContentLayout>
      <div className={styles.title}>
        {user.isAdmin
          ? `Проверка заданий (${homeworkStore.count})`
          : 'Мои задания'}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          {homeworkStore.isEmpty && (
            <div className={styles.empty}>
              Нет результатов по&nbsp;заданным фильтрам
            </div>
          )}
          {homeworkStore.homeworkList.map((h) => (
            <div className={styles.homeworkItem} key={h.id}>
              <HomeworkItem item={h} />
            </div>
          ))}
          {homeworkStore.isLoading && <PageSpinner />}
          {homeworkStore.hasMore && !homeworkStore.isLoading && (
            <div className={styles.loadMore}>
              <Button onClick={homeworkStore.loadMore}>Показать ещё</Button>
            </div>
          )}
        </div>
        <div className={styles.filters}>
          <Filters homeworkStore={homeworkStore} />
        </div>
      </div>
    </ContentLayout>
  );
};

export default observer(Homework);
