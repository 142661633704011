import { observer } from 'mobx-react';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import toast from 'cogo-toast';
import { useHistory } from 'react-router-dom';

import urls from 'config/urls';
import EditModal from 'components/common/EditModal/EditModal';
import { ImageField, InputField, TextAreaField } from 'components/form';
import ButtonDelete from 'components/common/Button/ButtonDelete';
import useForm from 'components/form/hooks/useForm';
import { useCourseStore } from 'store/context';
import {
  CourseCreationFormModel,
  CourseFieldsEnum,
} from 'store/models/course/CourseCreationFormModel';
import CourseModel from 'store/models/course/CourseModel';
import { useCurrentCompany } from 'store/stores';
import { useLocal } from 'utils/hooks/useLocal';
import SwitcherInput from 'components/form/SwitcherInput';
import { Field } from 'components/form/Field';

export type CourseCreationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (course: CourseModel) => void;
  resetOnOpen?: boolean;
  courseModel?: CourseModel;
  title?: string;
};

const CourseCreationModal: React.FC<CourseCreationModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  resetOnOpen,
  courseModel,
  title,
}: CourseCreationModalProps) => {
  const history = useHistory();
  const company = useCurrentCompany();
  const courseStore = useCourseStore();
  const courseForm = useLocal(
    () => new CourseCreationFormModel(courseModel || null)
  );
  const form = useForm(courseForm, Object.values(CourseFieldsEnum));
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (!isOpen && resetOnOpen) {
      courseForm.clear(courseModel);
    }
  }, [isOpen, resetOnOpen, courseModel]);

  const handleSave = useCallback(async () => {
    if (company) {
      const result = await courseForm.save(company);

      if (result) {
        onSuccess(result as CourseModel);
        onClose();
      }
    }
  }, [company, onClose, onSuccess]);

  const handleDelete = useCallback(async () => {
    if (!courseModel) {
      return;
    }

    setIsDeleting(true);
    const result = await courseStore.deleteCourse(courseModel.id);
    setIsDeleting(false);

    if (result) {
      toast.success('Курс успешно удален');
      history.replace(urls.courses);
    } else {
      toast.error('При удалении произошла ошибка, попробуйте еще раз');
    }
  }, [courseModel]);

  return (
    <EditModal
      isOpen={isOpen}
      onClose={onClose}
      isSaving={courseForm.isSaving}
      title={title || 'Добавление курса'}
      onSave={handleSave}
    >
      <InputField
        errors={form.title.errors}
        placeholder="Название:"
        value={form.title.value}
        onChange={form.title.setValue}
      />
      <ImageField
        attachment={form.background.value}
        onChange={form.background.setValue}
        errors={form.background.errors}
      />
      <TextAreaField
        errors={form.description.errors}
        placeholder="Описание:"
        value={form.description.value}
        onChange={form.description.setValue}
      />
      <Field>
        <SwitcherInput
          onChange={form.isPublished.setValue}
          isActive={form.isPublished.value}
        >
          Курс опубликован
        </SwitcherInput>
      </Field>
      <Field>
        <SwitcherInput
          onChange={form.oneDeadline.setValue}
          isActive={form.oneDeadline.value}
        >
          Запрещать сдачу работ при наступлении хотя бы одного дедлайна
        </SwitcherInput>
      </Field>
      {courseModel && (
        <ButtonDelete onClick={handleDelete} isLoading={isDeleting}>
          Удалить курс
        </ButtonDelete>
      )}
    </EditModal>
  );
};

export default observer(CourseCreationModal);
