import { action, makeObservable, observable, toJS } from 'mobx';

import type { IChunkModel, ChunkServerType } from 'store/models/chunks/types';

import { DeadlineMixin } from '../homework/DeadlineMixin';
import { DeadlineGetServerType } from '../homework/types';

import {
  ChunkCallbackTaskPostServerType,
  ChunkCallbackTaskServerType,
} from './types';

export class CallbackTaskChunkModel implements IChunkModel {
  isSecure: boolean;
  isDone?: boolean;
  userToken?: string;

  deadlineMixin: DeadlineMixin;

  constructor({
    is_secure,
    is_done,
    user_token,
    deadline,
    is_deadline,
  }: ChunkCallbackTaskServerType & DeadlineGetServerType) {
    makeObservable(this, {
      // observable
      isSecure: observable,
      isDone: observable,
      userToken: observable,
      // action
      toggleIsSecure: action.bound,
    });

    this.isSecure = is_secure;
    this.isDone = is_done;
    this.userToken = user_token;
    this.deadlineMixin = new DeadlineMixin(
      deadline ? new Date(deadline) : null,
      is_deadline
    );
  }

  get canPass(): boolean {
    return !this.deadlineMixin.isDeadline;
  }

  toggleIsSecure(): void {
    this.isSecure = !this.isSecure;
  }

  toJson(): ChunkCallbackTaskPostServerType {
    return toJS({
      is_secure: this.isSecure,
      ...this.deadlineMixin.toJson(),
    });
  }

  static createDefault(): CallbackTaskChunkModel {
    return new CallbackTaskChunkModel({
      is_secure: false,
      deadline: null,
      is_deadline: false,
    });
  }

  static fromJson(
    json: ChunkServerType<ChunkCallbackTaskServerType>
  ): CallbackTaskChunkModel {
    const { data } = json;

    return new CallbackTaskChunkModel(data);
  }
}
