import * as React from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import TextareaAutosize from 'react-textarea-autosize';

import { useAttachmentUpload } from 'store/hooks/useAttachmentUpload';
import Spinner from 'components/common/Spinner';
import AttachIcon from 'components/icons/AttachIcon';
import SendIcon from 'components/icons/SendIcon';
import { HomeworkMessageModel } from 'store/models/chunks/homework/chat/HomeworkMessageModel';
import CloseIcon from 'components/icons/CloseIconV2';
import { AttachmentUploadModel } from 'store/models/attachment/AttachmentUploadModel';

import styles from './Input.modules.scss';

type Props = {
  message: HomeworkMessageModel;
  onSendMessage(): void;
  isSending?: boolean;
};

const Input: React.FC<Props> = ({
  isSending,
  message,
  onSendMessage,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [uploadModel, handleFileChange, setUploadModel] = useAttachmentUpload(
    message.setAttachment
  );

  useEffect(() => {
    setUploadModel(new AttachmentUploadModel());
  }, [message, setUploadModel]);

  const handleChangeText = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!isSending) {
        message.setMessage(e.target.value);
      }
    },
    [message.setMessage, isSending]
  );

  const checkEnter = useCallback(
    (e: React.KeyboardEvent) => {
      if ((e.which == 13 || e.keyCode == 13) && !e.shiftKey && !isSending) {
        onSendMessage();
      }
    },
    [onSendMessage, isSending]
  );

  const handleDropFile = useCallback(() => {
    uploadModel.dropFile();
    message.setAttachment(null);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  }, [message.setAttachment, uploadModel.dropFile]);

  return (
    <div className={styles.wrapper}>
      {uploadModel.attachment && (
        <div className={styles.attachment}>
          <CloseIcon
            className={styles.attachmentDelete}
            onClick={handleDropFile}
          />
          {uploadModel.attachment.originalFileName}
        </div>
      )}
      <div className={styles.inputWrapper}>
        <div className={styles.attachIcon}>
          {uploadModel.isLoading ? <Spinner /> : <AttachIcon />}
          <input
            ref={inputRef}
            className={styles.attachInput}
            onChange={handleFileChange}
            type="file"
            multiple={false}
          />
        </div>
        <div className={styles.message}>
          <TextareaAutosize
            onKeyPress={checkEnter}
            value={message.message}
            onChange={handleChangeText}
            className={styles.input}
            placeholder="Напишите сообщение"
          />
        </div>
        <div className={styles.sendBtn} onClick={onSendMessage}>
          {isSending ? <Spinner /> : <SendIcon />}
        </div>
      </div>
    </div>
  );
};

export default observer(Input);
