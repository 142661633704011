import { computed, makeObservable } from 'mobx';

import { CompanyUserType, UserRoleEnum } from 'store/stores/UserStore/types';
import { normalizeCompanyUser } from 'store/stores/UserStore/normalizers';

import { StudentServerType } from './types';

type StudentType = CompanyUserType & {
  completedLessons: number;
  allLessons: number;
};

export class StudentModel implements StudentType {
  created: Date;
  id: number;
  completedLessons: number;
  lastName: string;
  firstName: string;
  allLessons: number;
  type: string;
  email: string;
  isActive: boolean;
  role: UserRoleEnum;
  companyUserId: number;
  accessLevel: number;

  constructor({
    id,
    created,
    completedLessons,
    lastName,
    firstName,
    allLessons,
    type,
    email,
    isActive,
    role,
    companyUserId,
    accessLevel,
  }: StudentType) {
    makeObservable(this, {
      // computed
      fullName: computed,
      progress: computed,
    });
    this.id = id;
    this.created = created;
    this.completedLessons = completedLessons;
    this.lastName = lastName;
    this.firstName = firstName;
    this.allLessons = allLessons;
    this.type = type;
    this.isActive = isActive;
    this.role = role;
    this.email = email;
    this.companyUserId = companyUserId;
    this.accessLevel = accessLevel;
  }

  get fullName(): string | null {
    if (!this.lastName || !this.firstName) {
      return null;
    }
    return `${this.firstName} ${this.lastName}`;
  }

  get progress(): string | null {
    if (!this.completedLessons || !this.allLessons) {
      return 'Не начал';
    }

    return `${this.completedLessons}/${this.allLessons}`;
  }

  static fromJson(data: StudentServerType): StudentModel {
    return new StudentModel({
      ...normalizeCompanyUser(data),
      completedLessons: data.completed_lessons,
      allLessons: data.all_lessons,
      accessLevel: data.access_level,
    });
  }
}
