import { action, makeObservable, observable, runInAction } from 'mobx';

import apiUrls from 'config/apiUrls';
import request from 'utils/request';

import { ServerResponse } from '../../../common/types';
import { HomeworkApproveEnum, HomeworkStatus } from '../types';

import { HomeworkServerType } from './types';

interface HomeworkType {
  status: HomeworkStatus;
  id: string;
  userId: number;
}

export class HomeworkModel implements HomeworkType {
  id: string;
  userId: number;

  status: HomeworkStatus;

  isApproving = false;

  constructor(hw: HomeworkType) {
    makeObservable(this, {
      status: observable,
      isApproving: observable,

      checkHomework: action,
    });

    this.id = hw.id;
    this.userId = hw.userId;
    this.status = hw.status;
  }

  async checkHomework(approve: HomeworkApproveEnum): Promise<boolean> {
    if (this.isApproving) {
      return false;
    }

    this.isApproving = true;

    const { response }: ServerResponse = await request(
      apiUrls.homework.checkHomework,
      'POST',
      {
        homework_chunk: this.id,
        user: this.userId,
        status: approve,
      }
    );

    if (response) {
      runInAction(() => {
        this.status =
          approve === HomeworkApproveEnum.wrong
            ? HomeworkStatus.rejected
            : HomeworkStatus.finished;

        this.isApproving = false;
      });
    }

    return Boolean(response);
  }

  approveHomework = this.checkHomework.bind(this, HomeworkApproveEnum.right);

  rejectHomework = this.checkHomework.bind(this, HomeworkApproveEnum.wrong);

  static async load(conversationId: string): Promise<HomeworkModel | null> {
    const { response }: ServerResponse<HomeworkServerType> = await request(
      apiUrls.homework.get,
      'GET',
      {
        conversation_id: conversationId,
      }
    );

    if (response) {
      return HomeworkModel.fromJson(response);
    }

    return null;
  }

  static fromJson(json: HomeworkServerType): HomeworkModel {
    return new HomeworkModel({
      status: json.status,
      id: json.homework_id,
      userId: json.user_id,
    });
  }
}
