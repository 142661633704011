import { useEffect, useRef } from 'react';
import * as React from 'react';
import { createPortal } from 'react-dom';

type Props = {
  children: React.ReactNode;
};

const Portal: React.FC<Props> = ({ children }: Props) => {
  const el = useRef(document.createElement('div')).current;
  useEffect(() => {
    el.style.position = 'fixed';
    el.style.zIndex = '2000';
    el.style.top = '0';
    el.style.left = '0';

    document.body.append(el);

    return () => {
      document.body.removeChild(el);
    };
  }, []);

  return createPortal(children, el);
};

export default Portal;
