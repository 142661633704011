import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import toast from 'cogo-toast';

import { DropdownField, InputField } from 'components/form';
import useForm from 'components/form/hooks/useForm';
import EditModal from 'components/common/EditModal/EditModal';
import { ChapterModel } from 'store/models/chapter/ChapterModel';
import { LessonTypeOptions } from 'store/models/lesson/types';
import noop from 'utils/noop';
import { useLocal } from 'utils/hooks/useLocal';
import { LessonModel } from 'store/models/lesson/LessonModel';
import {
  LessonCreationFormModel,
  LessonFieldsEnum,
} from 'store/models/lesson/LessonCreationFormModel';
import ButtonDelete from 'components/common/Button/ButtonDelete';

import { Field } from '../../../form/Field';
import SwitcherInput from '../../../form/SwitcherInput/SwitcherInput';

export type LessonCreationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: (lesson: LessonModel) => void;
  resetOnOpen?: boolean;
  chapter: ChapterModel;
  lessonModel?: LessonModel;
  title?: string;
};

const LessonCreationModal: React.FC<LessonCreationModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  resetOnOpen,
  chapter,
  lessonModel,
  title,
}: LessonCreationModalProps) => {
  const formModel = useLocal<LessonCreationFormModel>(
    () => new LessonCreationFormModel(chapter, lessonModel)
  );

  const form = useForm(formModel, Object.values(LessonFieldsEnum));

  useEffect(() => {
    if (!isOpen && resetOnOpen) {
      formModel.clear(lessonModel);
    }
  }, [isOpen, resetOnOpen, lessonModel]);

  const handleSave = useCallback(async () => {
    const lessonUpdate = await formModel.save();
    if (lessonUpdate) {
      toast.success('Изменения успешно сохранены!');
      if (onSuccess) {
        onSuccess(lessonUpdate as LessonModel);
      }
      onClose();
    }
  }, [onClose, onSuccess]);

  const handleDeleteLesson = useCallback(async () => {
    if (!lessonModel?.id) {
      return;
    }

    const result = await chapter.deleteLesson(lessonModel.id);

    if (result) {
      onClose();
    }
  }, [chapter.deleteLesson, lessonModel?.id, onClose]);

  return (
    <EditModal
      isOpen={isOpen}
      onClose={onClose}
      isSaving={formModel.isSaving}
      title={title || 'Добавление урока'}
      onSave={handleSave}
    >
      <InputField
        errors={form.title.errors}
        placeholder="Название:"
        value={form.title.value}
        onChange={form.title.setValue}
      />
      <DropdownField
        noLabel
        options={LessonTypeOptions}
        errors={form.type.errors}
        label="Тип:"
        selected={form.type.value}
        onChange={form.type.setValue}
      />
      <InputField
        errors={form.accessLevel.errors}
        placeholder="Уровень доступа:"
        value={form.accessLevel.value}
        onChange={form.accessLevel.setValue}
        type="number"
        min="0"
      />
      <Field>
        <SwitcherInput
          isActive={form.isPublished.value}
          onChange={form.isPublished.setValue}
        >
          Урок опубликован
        </SwitcherInput>
      </Field>
      {form.isPublished.value && (
        <Field>
          <SwitcherInput
            isActive={form.sendNotifications.value}
            onChange={form.sendNotifications.setValue}
          >
            Отправить уведомление о публикации
          </SwitcherInput>
        </Field>
      )}
      {lessonModel && (
        <ButtonDelete
          onClick={handleDeleteLesson}
          isLoading={chapter.isDeletingLesson}
        >
          Удалить урок
        </ButtonDelete>
      )}
    </EditModal>
  );
};

LessonCreationModal.defaultProps = {
  onSuccess: noop,
};

export default observer(LessonCreationModal);
