import * as React from 'react';

import Button, { ButtonColor } from '../Button';

import styles from './ControlsBar.modules.scss';

interface Props {
  onClose: () => void;
  onOk: () => void;
  isLoading?: boolean;
}

const ControlsBar: React.FC<Props> = ({ onClose, onOk, isLoading }: Props) => {
  return (
    <div className={styles.root}>
      <Button
        className={styles.button}
        color={ButtonColor.white}
        onClick={onClose}
      >
        Отмена
      </Button>
      <Button className={styles.button} onClick={onOk} isLoading={isLoading}>
        ОК
      </Button>
    </div>
  );
};

export default React.memo(ControlsBar);
