import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';
import * as React from 'react';

import { CodeTaskChunkModel } from 'store/models/chunks/codeTask/CodeTaskChunkModel';
import Badge from 'components/common/Badge/Badge';
import CodeInput from 'components/common/CodeInput/CodeInput';
import Button from 'components/common/Button';
import { useRunCode } from 'components/common/CodeInput/useRunCode';

import { WithChunk } from '../types';

import styles from './CodeEditorChunk.modules.scss';

type Props = WithChunk<CodeTaskChunkModel>;

const CodeEditorChunk: React.FC<Props> = ({
  chunk: { data, id },
  isEditMode,
  isAdmin,
}: Props) => {
  const { runCode, output } = useRunCode();
  const [isRunning, setIsRunning] = useState(false);

  const handleRun = useCallback(async () => {
    setIsRunning(true);
    if (isAdmin) {
      await runCode(data.fullCode);
    } else {
      await data.saveStudentCode();
      if (await runCode(data.fullCode)) {
        await data.codeTaskSuccess();
      }
    }
    setIsRunning(false);
  }, [isAdmin]);

  return (
    <div id={id}>
      {isEditMode ? (
        <>
          <div className={styles.codeEditorHelper}>Код для примера:</div>
          <CodeInput
            language="python"
            code={data.exampleCode}
            onChange={data.setExampleCode}
          />
          <div className={styles.codeEditorHelper}>Код теста:</div>
          <CodeInput
            language="python"
            code={data.testCode}
            onChange={data.setTestCode}
          />
        </>
      ) : (
        <>
          <div className={styles.codeEditorHelper}>Код решения:</div>
          <CodeInput
            // readonly={isAdmin}
            language="python"
            code={
              isAdmin ? data.exampleCode : data.studentCode || data.exampleCode
            }
            onChange={data.setStudentCode}
          />
        </>
      )}
      <div className={styles.btnWrapper}>
        {!isEditMode && !isAdmin && data.isPassed && (
          <Badge className={styles.success}>Задание сдано</Badge>
        )}
        <Button onClick={handleRun} isLoading={isRunning}>
          {isAdmin ? 'Запустить' : 'Сохранить и запустить'}
        </Button>
      </div>
      {output && <div className={styles.result}>{output}</div>}
    </div>
  );
};

export default observer(CodeEditorChunk);
