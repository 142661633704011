import { ChapterServerType } from 'store/models/chapter/types';

import { CompanyModel } from '../../stores/UserStore/types';
import { AttachmentServerType } from '../attachment';

export type CourseListResponseType = {
  courses: Array<CourseServerType>;
};

export type CourseCreateResponseType = {
  id: number;
};

export type CourseInnerServerType = {
  max_available_points: number;
  chapters_count: number;
  count_complete_lessons: number;
  points: number;
  unit_count: number;
  point_per_unit: number;
  point_per_test: number;
  lessons_count: number;
};

export type FirstUnitServerType = {
  lesson_id: number;
  id: number;
};

export type CourseServerType = {
  inner: CourseInnerServerType;
  background_image: AttachmentServerType;
  course_base_id: number;
  first_unit: FirstUnitServerType;
  description: string;
  status: string;
  progress_status: CourseProgressStatus;
  greeting: string;
  title: string;
  id: number;
  type: string;
  is_published: boolean;
  one_deadline: boolean;
  companies: CompanyModel[];
  chapters?: ChapterServerType[];
};

export enum CourseProgressStatus {
  notStarted = 'not_started',
  inProgress = 'in_progress',
  finished = 'finished',
}

export enum CourseStatusEnum {
  draft = 'draft',
  completed = 'completed',
  published = 'published',
}
