import { PaymentsType } from 'config/payments';

export interface IBasePayment {
  isLoading: boolean;
  isEditable: boolean;
  isError: boolean;

  type: PaymentsType;
}

export enum PaymentsError {
  notFoundPaymentMethod = 'payment_method_not_found',
}

export type BasePaymentUpdateApiRequest = {
  company: number;
  kind: PaymentsType;
};
