import { action, computed, makeObservable, observable } from 'mobx';

import apiUrls from 'config/apiUrls';
import request from 'utils/request';

import { ServerResponse } from '../../models/common/types';
import CourseModel from '../../models/course/CourseModel';
import { RootStore } from '../../RootStore';
import { GlobalStore } from '../types';

import {
  CompanyModel,
  UserRoleEnum,
  UserServerModel,
  UserTypeEnum,
} from './types';

export const USER_SELECTED_COMPANY_KEY = 'skils_selected_company';

export class UserStore implements GlobalStore {
  rootStore: RootStore | null = null;

  isAuthorizing = false;
  isLoading = false;
  isAuthorized = false;
  isInited = false;
  isIniting = false;
  initError = false;

  lastName: string | null = null;
  email = '';
  firstName = '';
  phone: string | null = null;
  id: number | null = null;
  type: UserTypeEnum | null = null;
  companies: CompanyModel[] = [];
  selectedCompany: CompanyModel | null = null;

  constructor(root: RootStore) {
    makeObservable(this, {
      // observable
      isAuthorized: observable,
      isLoading: observable,
      isAuthorizing: observable,
      isInited: observable,
      isIniting: observable,
      initError: observable,
      lastName: observable,
      email: observable,
      firstName: observable,
      phone: observable,
      type: observable,
      companies: observable,
      selectedCompany: observable,
      // computed
      fullName: computed,
      isAdmin: computed,
      // action
      selectCompany: action.bound,
      logout: action.bound,
      login: action.bound,
      init: action.bound,
    });

    this.rootStore = root;
  }

  get userData() {
    return {
      email: this.email,
      first_name: this.firstName,
      id: this.id,
      last_name: this.lastName,
      phone: this.phone,
      type: this.type,
    };
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get isAdmin(): boolean {
    return this.selectedCompany?.role === UserRoleEnum.staff;
  }

  isStaffInCourse(course: CourseModel): boolean {
    return (
      this.companies.find((c) => course.company.id === c.id)?.role ===
      UserRoleEnum.staff
    );
  }

  async selectCompany(id: number): Promise<void> {
    this.selectedCompany = this.companies.find((c) => c.id === id) || null;
    if (this.selectedCompany) {
      localStorage.setItem(
        USER_SELECTED_COMPANY_KEY,
        String(this.selectedCompany.id)
      );
    }

    window.location.reload();
  }

  async logout(): Promise<any> {
    await request(apiUrls.user.logout, 'POST');
    this.isAuthorized = false;
  }

  async login(email: string, password: string): Promise<boolean> {
    this.isAuthorizing = true;

    const { response }: ServerResponse<UserServerModel> = await request(
      apiUrls.user.login,
      'POST',
      {
        email,
        password,
      }
    );

    this.isAuthorizing = false;

    if (response) {
      this.fromJson(response);
      this.isAuthorized = true;

      this.rootStore?.onLogin();
    }

    return this.isAuthorized;
  }

  async init(): Promise<void> {
    this.isInited = false;
    this.isIniting = true;

    const { response }: ServerResponse<UserServerModel> = await request(
      apiUrls.user.current
    );
    if (response) {
      this.fromJson(response);
      this.isAuthorized = true;
    }

    this.isInited = true;
    this.isIniting = false;
  }

  fromJson(json: UserServerModel): void {
    const selectedCompanyId = localStorage.getItem(USER_SELECTED_COMPANY_KEY);
    let selectedCompany = null;

    if (json.companies.length > 0) {
      if (selectedCompanyId) {
        selectedCompany =
          json.companies.find((c) => c.id === Number(selectedCompanyId)) ||
          null;
      }
      if (!selectedCompany) {
        // eslint-disable-next-line prefer-destructuring
        selectedCompany = json.companies[0];
      }
    }

    this.lastName = json.last_name;
    this.firstName = json.first_name;
    this.email = json.email;
    this.type = json.type;
    this.id = json.id;
    this.companies = json.companies;
    this.phone = json.phone;
    this.selectedCompany = selectedCompany;
  }
}
