import {
  AttachmentServerType,
  AttachmentSourceEnum,
  AttachmentType,
} from './types';

export function parseAttachment(
  attachmentJson: AttachmentServerType
): AttachmentType {
  let data = {
    originalFileName: attachmentJson.original_file_name,
    name: attachmentJson.name,
    id: attachmentJson.id,
    url: attachmentJson.url,
    source: attachmentJson.source,
  } as AttachmentType;

  if (
    attachmentJson.source === AttachmentSourceEnum.bunny &&
    data.source === AttachmentSourceEnum.bunny
  ) {
    data = {
      ...data,
      playerUrl: attachmentJson.player_url,
      status: attachmentJson.status,
    };
  }

  return data;
}

export function parseAttachmentError(): string {
  return 'При загрузке произошла ошибка';
}
