import { ChunkModel } from 'store/models/chunks/ChunkModel';
import {
  ChunkAlignEnum,
  ChunkTypeEnum,
  ComplexChunkEnum,
} from 'store/models/chunks/types';
import { UnitModel } from 'store/models/unit/UnitModel';

export const generateComplexChunk = (
  unit: UnitModel,
  complexType: ComplexChunkEnum
): ChunkModel[] => {
  switch (complexType) {
    case ComplexChunkEnum.images2inRow: {
      const [img1Chunk, img2Chunk] = [
        // eslint-disable-next-line no-undef
        ChunkModel.createDefault(unit, ChunkTypeEnum.image),
        ChunkModel.createDefault(unit, ChunkTypeEnum.image),
      ];
      img1Chunk.layout.setWidth(50);
      img2Chunk.layout.setWidth(50);

      return [img1Chunk, img2Chunk];
    }
    case ComplexChunkEnum.imageWithLeftText: {
      const [imgChunk, textChunk] = [
        ChunkModel.createDefault(unit, ChunkTypeEnum.image),
        ChunkModel.createDefault(unit, ChunkTypeEnum.text),
      ];
      imgChunk.layout.setWidth(50);
      textChunk.layout.setWidth(50);
      // eslint-disable-next-line no-undef
      imgChunk.layout.setVerticalAlign(ChunkAlignEnum.center);
      textChunk.layout.setVerticalAlign(ChunkAlignEnum.center);

      return [textChunk, imgChunk];
    }
    case ComplexChunkEnum.imageWithRightText: {
      const [imgChunk, textChunk] = [
        ChunkModel.createDefault(unit, ChunkTypeEnum.image),
        ChunkModel.createDefault(unit, ChunkTypeEnum.text),
      ];
      imgChunk.layout.setWidth(50);
      textChunk.layout.setWidth(50);
      imgChunk.layout.setVerticalAlign(ChunkAlignEnum.center);
      textChunk.layout.setVerticalAlign(ChunkAlignEnum.center);

      return [imgChunk, textChunk];
    }
    case ComplexChunkEnum.text2columns: {
      const [text1Chunk, text2Chunk] = [
        ChunkModel.createDefault(unit, ChunkTypeEnum.text),
        ChunkModel.createDefault(unit, ChunkTypeEnum.text),
      ];
      text1Chunk.layout.setWidth(50);
      text2Chunk.layout.setWidth(50);

      return [text1Chunk, text2Chunk];
    }
    case ComplexChunkEnum.portfolio: {
      const [textChunk, ...imageChunks] = [
        ChunkModel.createDefault(unit, ChunkTypeEnum.text),
        ChunkModel.createDefault(unit, ChunkTypeEnum.image),
        ChunkModel.createDefault(unit, ChunkTypeEnum.image),
        ChunkModel.createDefault(unit, ChunkTypeEnum.image),
        ChunkModel.createDefault(unit, ChunkTypeEnum.image),
        ChunkModel.createDefault(unit, ChunkTypeEnum.image),
        ChunkModel.createDefault(unit, ChunkTypeEnum.image),
      ];
      imageChunks.forEach((imgChunk) => imgChunk.layout.setWidth(33));

      return [textChunk, ...imageChunks];
    }
    default:
      return [];
  }
};
