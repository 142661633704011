import { observer } from 'mobx-react';
import * as React from 'react';

import { useStudentsStore } from 'store/index';
import { GroupModel } from 'store/models/students/GroupModel';
import noop from 'utils/noop';

import AddGroupModal, { AddGroupModalProps } from './AddGroupModal';

const AddGroupModalContainer: React.FC<AddGroupModalProps> = ({
  isOpen,
  onClose,
  onSuccess = noop,
}: AddGroupModalProps) => {
  const studentsStore = useStudentsStore();

  const onSuccessLocal = async (group: GroupModel) => {
    studentsStore.addGroup(group);
    onSuccess(group);
  };

  return (
    <AddGroupModal
      isOpen={isOpen}
      onClose={onClose}
      onSuccess={onSuccessLocal}
    />
  );
};

export default observer(AddGroupModalContainer);
