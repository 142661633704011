import * as React from 'react';

import Badge from '../Badge';

import styles from './Progress.modules.scss';

type Props = React.PropsWithChildren<{
  progress: number;
  total: number;
  className?: string;
}>;

const Progress: React.FC<Props> = ({
  className,
  progress,
  total,
  children,
}: Props) => {
  return (
    <div className={className}>
      <div className={styles.info}>
        <div>{children}</div>
        <Badge className={styles.badge}>
          {progress}/{total}
        </Badge>
      </div>
      <div className={styles.line}>
        <div
          className={styles.lineInner}
          style={{ width: `${(progress / total) * 100}%` }}
        />
      </div>
    </div>
  );
};

export default React.memo(Progress);
