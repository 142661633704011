import cn from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
// import ImageViewer from 'react-simple-image-viewer';
// import PreviewImage from 'react-preview-image';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { useModal } from 'components/form/hooks/useModal';
import Spinner from 'components/common/Spinner';
import { useAttachmentUpload } from 'store/hooks/useAttachmentUpload';
import { ImageChunkModel } from 'store/models/chunks/images/ImageChunkModel';

import CircleButton from '../../../common/BaseEdit/CircleButton';
import { CircleButtonTypeEnum } from '../../../common/BaseEdit/CircleButton/config/types';
import styles from '../common/styles/MediaChunk.modules.scss';
import { WithChunk } from '../types';

import defaultImg from './img/defaultImg.svg';

type Props = WithChunk<ImageChunkModel>;

const ImageChunk: React.FC<Props> = ({ chunk, isEditMode }: Props) => {
  const { attachment, setAttachment } = chunk.data.image;
  const [showModal, toggleModal] = useModal();

  const [uploadModel, handleFileChange] = useAttachmentUpload(setAttachment);

  const onImgClick = (): void => {
    if (isMobile) {
      toggleModal();
    }
  };

  const imgSrc = attachment?.url || defaultImg;

  return (
    <div className={cn(styles.wrapper)}>
      {showModal && <Lightbox mainSrc={imgSrc} onCloseRequest={toggleModal} />}
      <img
        src={attachment?.url || defaultImg}
        className={styles.preview}
        onClick={onImgClick}
      />
      {isEditMode && (
        <div
          className={cn(
            styles.selectedWrapper,
            uploadModel.isLoading && styles.visible
          )}
        >
          {uploadModel.isLoading ? (
            <Spinner className={cn(styles.inputIcon, styles.loading)} />
          ) : (
            <CircleButton
              className={styles.inputIcon}
              type={CircleButtonTypeEnum.edit}
            >
              <input
                onChange={handleFileChange}
                type="file"
                accept="image/*"
                multiple={false}
                className={cn(styles.imgInput)}
              />
            </CircleButton>
          )}
        </div>
      )}
    </div>
  );
};

export default observer(ImageChunk);
