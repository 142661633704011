import * as React from 'react';

import FormElement from 'components/form/FormElement';

import { WithFieldProps } from './types';
import styles from './Field.modules.scss';

export interface FieldProps extends WithFieldProps {
  children?: React.ReactNode;
  formElementClassName?: string;
}

export const Field: React.FC<FieldProps> = ({
  errors,
  children,
  formElementClassName,
}: FieldProps) => {
  return (
    <FormElement className={formElementClassName}>
      {children}
      {(errors || []).map((error) => (
        <div className={styles.error} key={error}>
          {error}
        </div>
      ))}
    </FormElement>
  );
};
