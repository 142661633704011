import * as React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import CourseModel from 'store/models/course/CourseModel';

import ProgressPart from './ProgressPart';
import commonStyles from './styles/common.modules.scss';
import pointStyles from './styles/point.modules.scss';

type Props = {
  course: CourseModel;
  isEditMode: boolean;
};

const START_LINE_HEIGHT = commonStyles.startLineHeight;

const Progress: React.FC<Props> = ({ course, isEditMode }: Props) => {
  const rootRef = React.useRef<HTMLDivElement | null>(null);

  const loaded = React.useMemo(
    () => course.chapters.every((chapter) => chapter.isLoadedLessons),
    [course]
  );

  const [chaptersHeights, setChapterHeights] = React.useState<number[] | null>(
    null
  );

  const recalculate = React.useCallback(() => {
    if (!rootRef.current?.parentNode) {
      return;
    }

    const chaptersNodes: NodeList = rootRef.current.parentNode.childNodes;

    if (!chaptersNodes?.length) {
      return;
    }

    setChapterHeights(() =>
      course.chapters.map((chapter, index) => {
        let chapterHeight = (
          chaptersNodes.item(index) as HTMLDivElement
        ).getBoundingClientRect().height;

        if (index < course.chapters.length - 1) {
          chapterHeight += 40;
        }

        return chapterHeight;
      })
    );
  }, [course.chapters.length]);

  const lessonsCount = course.chapters.reduce(
    (acc, chapter) => acc + (chapter.lessons?.length || chapter.countLessons),
    0
  );

  React.useEffect(() => {
    recalculate();
  }, [isEditMode, lessonsCount, course.chapters.length]);

  React.useEffect(() => {
    window.addEventListener('resize', recalculate);

    return (): void => {
      window.removeEventListener('resize', recalculate);
    };
  }, [recalculate]);

  return (
    <div className={commonStyles.root} ref={rootRef}>
      {loaded && !!course.chapters.length && !!chaptersHeights && (
        <>
          <div className={cn(commonStyles.card, commonStyles.card_start)}>
            Старт
          </div>
          <div className={commonStyles.content}>
            {course.chapters.map((chapter, index) => {
              return (
                <ProgressPart
                  key={index}
                  height={chaptersHeights[index]}
                  count={chapter.countLessons || chapter.lessons?.length}
                  countCompleted={chapter.countCompleteLessons}
                  className={cn(pointStyles.lightBlue)}
                  offsetTop={index === 0 ? START_LINE_HEIGHT : undefined}
                />
              );
            })}
          </div>
          <div className={cn(commonStyles.card, commonStyles.card_finish)}>
            Финиш
          </div>
        </>
      )}
    </div>
  );
};

export default observer(Progress);
