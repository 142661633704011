import cookie from 'cookie-parse';

export function setCookie(
  cname: string,
  cvalue: string | number,
  dateStr: string
): void {
  const expires = `expires=${dateStr}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

export function dropCookie(cname: string): void {
  return setCookie(cname, '', 'Thu, 01 Jan 1970 00:00:00 UTC');
}

export const getCookieValue = (cookieName: string): string | undefined =>
  cookie.parse(document.cookie)[cookieName];
