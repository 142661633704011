import * as React from 'react';
import * as propTypes from 'prop-types';

const DocumentIcon = (props) => {
  const { className } = props;

  return (
    <svg
      width="15px"
      height="18px"
      viewBox="0 0 15 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="currentColor" fillRule="nonzero">
          <path d="M1.8,0 C0.81,0 0,0.81 0,1.8 L0,16.2 C0,17.19 0.81,18 1.8,18 L12.6,18 C13.59,18 14.4,17.19 14.4,16.2 L14.4,5.4 L9,0 L1.8,0 Z M8.1,6.3 L8.1,1.35 L13.05,6.3 L8.1,6.3 Z" />
        </g>
      </g>
    </svg>
  );
};

DocumentIcon.propTypes = {
  className: propTypes.string.isRequired,
};

export default DocumentIcon;
