import { DropdownOption } from 'components/form/Dropdown/Dropdown';
import { HomeworkStatusText } from 'store/models/chunks/homework/types';

import type { HomeworkOption } from '../HomeworkStore';

export const DEFAULT_OPTION: DropdownOption = {
  key: 'default',
  label: 'Выберите',
};

export const STATUS_OPTIONS: DropdownOption[] = Object.keys(
  HomeworkStatusText
).map((key) => ({
  key,
  label: HomeworkStatusText[key as keyof typeof HomeworkStatusText],
}));

export enum DeadlineOptionsKey {
  passed = 'passed',
  notPassed = 'notPassed',
  all = 'all',
}

export const DEADLINE_OPTIONS: DropdownOption[] = [
  {
    key: DeadlineOptionsKey.passed,
    label: 'Просроченные',
  },
  {
    key: DeadlineOptionsKey.notPassed,
    label: 'Непросроченные',
  },
  {
    key: DeadlineOptionsKey.all,
    label: 'Все',
  },
];

export const convertDeadline = (d: DeadlineOptionsKey) =>
  ({
    [DeadlineOptionsKey.all]: null,
    [DeadlineOptionsKey.passed]: true,
    [DeadlineOptionsKey.notPassed]: false,
  }[d]);

export const convertDeadlineValue = (d: null | boolean) =>
  d === null
    ? DeadlineOptionsKey.all
    : d
    ? DeadlineOptionsKey.passed
    : DeadlineOptionsKey.notPassed;

export const convertHomeworkToDropdownOptions = (
  homeworkOptions: HomeworkOption[]
): DropdownOption[] =>
  homeworkOptions.map(({ title, id }) => ({
    key: id,
    label: title,
  }));
