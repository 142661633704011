import * as React from 'react';
import * as propTypes from 'prop-types';
import cn from 'classnames';

import ArrowRightIcon from 'components/icons/ArrowRightIcon';

import styles from './Navigation.modules.scss';

const Navigation = (props) => {
  const { value, totalValue, onPrevClick, onNextClick, className } = props;

  const isValuesNormal = Boolean(value) && Boolean(totalValue);
  const currentIndex = isValuesNormal ? value : '-';
  const currentTotal = isValuesNormal ? totalValue : '-';

  return (
    <div className={className}>
      <span className={cn(styles.iconWrapper)} onClick={onPrevClick}>
        <ArrowRightIcon className={cn(styles.arrow, styles.arrowLeft)} />
      </span>

      <span className={styles.text}>
        {currentIndex}/{currentTotal}
      </span>

      <span className={cn(styles.iconWrapper)} onClick={onNextClick}>
        <ArrowRightIcon className={styles.arrow} />
      </span>
    </div>
  );
};

Navigation.propTypes = {
  value: propTypes.number.isRequired,
  totalValue: propTypes.number.isRequired,

  // onPrevClick: propTypes.func.isRequired,
  // onNextClick: propTypes.func.isRequired,
  onPrevClick: propTypes.func,
  onNextClick: propTypes.func,
  className: propTypes.string.isRequired,
};

export default Navigation;
