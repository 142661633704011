import * as React from 'react';
import cn from 'classnames';
import { useState, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import Disabled from 'components/common/Disabled';
import Drawer from 'components/common/Drawer';
import {
  AnyChunkType,
  ChunkGroupLabels,
  ChunkGroupTypeEnum,
  ChunkTypeEnum,
  ChunkTypeLabels,
} from 'store/models/chunks/types';
import noop from 'utils/noop';

import { useChunkContainer } from '../models/chunkContainer/context';

import Type from './Group';
import Element from './Element';
import styles from './ChunkCreation.modules.scss';

const ChunkCreation: React.FC = () => {
  const chunkContainer = useChunkContainer();

  const { showChunkCreation } = chunkContainer;
  const [group, setGroup] = useState<ChunkGroupTypeEnum | null>(null);

  const handleGroupClick = useCallback((selectedGroup: ChunkGroupTypeEnum) => {
    setGroup(selectedGroup);
  }, []);

  const handleSelectChunk = (type: AnyChunkType) => {
    chunkContainer.addChunk(type);
    chunkContainer.closeChunkCreationDrawer();
  };

  const handleCloseChunkCreation = () => {
    chunkContainer.closeChunkCreationDrawer();
  };

  useEffect(() => {
    setGroup(null);
  }, [showChunkCreation]);

  const disabledChunks = chunkContainer.disabledChunksToCreate;

  return (
    <Drawer isOpen={showChunkCreation} onClose={handleCloseChunkCreation}>
      <div className={styles.column}>
        <div className={styles.columnTitle}>Тип элемента:</div>
        {ChunkGroupLabels.map((item) => (
          <Type
            isSelected={item.key === group}
            onClick={handleGroupClick}
            label={item.label}
            id={item.key}
            key={item.key}
          />
        ))}
      </div>
      {group && (
        <div className={cn(styles.column, styles.columnElement)}>
          {ChunkTypeLabels?.[group]?.map(({ key, label }) => {
            const isDisabled = disabledChunks.includes(key as ChunkTypeEnum);

            return (
              <Disabled key={key} isDisabled={isDisabled}>
                <Element
                  label={label}
                  type={key}
                  onClick={isDisabled ? noop : handleSelectChunk}
                />
              </Disabled>
            );
          })}
        </div>
      )}
    </Drawer>
  );
};

export default observer(ChunkCreation);
