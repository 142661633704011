import { action, makeObservable, observable } from 'mobx';

import { LayoutEnum } from './types';

export class UiStore {
  showMenu = false;

  layout: LayoutEnum = LayoutEnum.absolute;

  constructor() {
    makeObservable(this, {
      // MENU
      // observable
      showMenu: observable,
      // action
      toggleMenu: action.bound,
      closeMenu: action.bound,
      // LAYOUT
      // observable
      layout: observable,
      // action
      setLayout: action.bound,
    });
  }

  toggleMenu(): void {
    this.showMenu = !this.showMenu;
  }

  closeMenu(): void {
    this.showMenu = false;
  }

  setLayout(value: LayoutEnum): void {
    this.layout = value;
  }
}
