export enum UserTypeEnum {
  client = 'client',
  admin = 'admin',
  business = 'business',
}

export enum UserRoleEnum {
  staff = 'staff',
  client = 'client',
}

export type UserServerModel = {
  type: UserTypeEnum;
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string | null;
  companies: CompanyModel[];
};

export type CompanyUserServerType = {
  created: string;
  id: number;
  company_user_id: number;
  email: string;
  last_name: string;
  type: string;
  first_name: string;
  is_active: boolean;
  role: UserRoleEnum;
  access_level: number;
};

export type PendingCompanyUserType = {
  isActive: boolean;
  email: string;
};

export type CompanyUserType = PendingCompanyUserType & {
  created: Date;
  id: number;
  lastName: string;
  firstName: string;
  type: string;
  role: UserRoleEnum;
  companyUserId: number;
  accessLevel: number;
};

export type CompanyModel = {
  name: string;
  id: number;
  role?: UserRoleEnum;
};
